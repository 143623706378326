import auditSidebarTabReducer from './auditSidebarTabReducer/auditSidebarTabReducer';
import autheTokensReducer from './authTokensReducer/authTokensReducer';
import TocPocSidebarTabReducer from './tocPocSidebarTabReducers/componentReducer';
import saveReducer from './tocPocSidebarTabReducers/saveReducer';
import searchReducer from './tocPocSidebarTabReducers/searchReducer';
import loadingReducer from './loadingReducer/loadingReducer';
import bulkOOCSearchSidebarTabReducer from './bulkOOCSearchSidebarTabReducer/bulkOOCSearchSidebarTabReducer';
import OpeningHoursSidebarTabReducer from './openingHoursSidebarTabReducer/openingHoursSidebarTabReducer';
import { combineReducers } from 'redux';

const combinedReducers = combineReducers({
  searchReducer,
  saveReducer,
  TocPocSidebarTabReducer,
  autheTokensReducer,
  auditSidebarTabReducer,
  loadingReducer,
  bulkOOCSearchSidebarTabReducer,
  OpeningHoursSidebarTabReducer,
});

export default combinedReducers;
