import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisabledDateRange } from 'src/components/CustomDatePickerInput/customDatePickerInput';
// import { openingHoursTableData } from 'src/pages/openingHoursSidebarTab/openingHoursTable/openingHoursTable';
import { OperatingPeriodType, OutOfChargeStatus } from 'src/enums/enum';
import { DatePickerInput, DateValue, DatesRangeValue } from '@mantine/dates';
import { OpeningHoursCalendarConfigDatePickerProps } from 'src/types/componentProps';
import {
  DATE_FORMAT,
  DATE_FORMAT_TO_BE_DISPLAYED,
  getFormattedDate,
  getFormattedDateToBeDisplayed,
} from 'src/utils/dateUtils';
import { useSelector } from 'react-redux';
import { SearchReducer } from 'src/types/searchReducer';
import Tippy from '@tippyjs/react';
import { Icon } from '@clearchannelinternational/ecooh-system';
import { areDisplayUnitIdsUnique } from 'src/pages/openingHoursSidebarTab/utils/utils';

const OpeningHoursCalendarConfigDatePicker: React.FC<OpeningHoursCalendarConfigDatePickerProps> = ({
  selectedDayPartData,
  openingHoursFormValues,
  setOpeningHoursFormValues,
  dateValue,
  setDateValue,
  getMatchingStartDateBetweenOpeningHoursDates,
  handleReset,
  openingHoursTableData,
  setOpeningHoursTableData,
}) => {
  const { t: translate } = useTranslation();
  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);

  const renderDay = (date: Date) => {
    const isDisabled = isWithinDisabledDateRanges(date);
    const OhOrOOCText = hasOverlappingOOCs(date) ? 'OOC' : hasOverlappingsOHs(date) ? 'OH' : '';

    const commonStyles: React.CSSProperties = {
      pointerEvents: 'none',
      opacity: isDisabled ? 0.4 : 1,
      textDecoration: isDisabled ? 'line-through' : 'none',
      color: 'black',
    };

    return (
      <div>
        <div style={commonStyles}>{date.getDate()}</div>
        {isDisabled && <p style={{ fontSize: '7px', color: 'black' }}>{OhOrOOCText}</p>}
      </div>
    );
  };

  function handleDateChange(e: DatesRangeValue) {
    if (e[0] === null && e[1] === null) {
      setDateValue([null, null]);
      handleReset();
      return;
    }

    const [start, end] = e;
    // if (isWithinDisabledDateRanges(start as Date) || isWithinDisabledDateRanges(end as Date)) {
    //   handleReset();
    // }

    const isStartValid = start && !isWithinDisabledDateRanges(start);
    const isEndValid = end && !isWithinDisabledDateRanges(end);

    if (isStartValid || isEndValid) {
      setDateValue([start, end]);
      setOpeningHoursFormValues((prevValues) => ({
        ...prevValues,
        startDate: isStartValid ? start.toISOString() : prevValues.startDate,
        endDate: isEndValid ? end.toISOString() : prevValues.endDate,
      }));
    }
  }

  function isWithinDisabledDateRanges(dateToCheck: Date) {
    return hasOverlappingsOHs(dateToCheck) || hasOverlappingOOCs(dateToCheck);
  }

  function hasOverlappingOOCs(dateToCheck: Date) {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const blockingOperatingPeriods = fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods;
        const hasOverlappingOOCs = blockingOperatingPeriods?.some((blockingOperatingPeriod) => {
          const startDate = new Date(blockingOperatingPeriod.dateAndTimeRanges.startDate);
          const endDate = new Date(blockingOperatingPeriod.dateAndTimeRanges.endDate);

          return (
            getFormattedDate(dateToCheck) === getFormattedDate(startDate) ||
            (dateToCheck >= startDate && dateToCheck <= endDate)
          );
        });
        return hasOverlappingOOCs;
      }
    } else {
      // To be implemented
    }
  }

  function hasOverlappingsOHs(dateToCheck: Date) {
    const updatedOHTableData = openingHoursTableData.filter(
      (tableData) => tableData.operatingPeriodDBId !== selectedDayPartData.operatingPeriodDbId
    );

    const hasOverlappingOHs = updatedOHTableData.some((data) => {
      if (data.name === OperatingPeriodType.BASE) {
        return false;
      }
      const startDate = new Date(data.startDate);
      const endDate = new Date(data.endDate);
      return (
        getFormattedDate(dateToCheck) === getFormattedDate(startDate) || (dateToCheck >= startDate && dateToCheck <= endDate)
      );
    });
    return hasOverlappingOHs;
  }

  function getDisabledDateRanges() {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const disabledDateRages = openingHoursTableData
          .filter(
            (data) =>
              data.name !== OperatingPeriodType.BASE && data.operatingPeriodDBId !== selectedDayPartData.operatingPeriodDbId
          )
          .map((data) => ({ startDate: data.startDate, endDate: data.endDate }));

        const blockingOperatingPeriods = fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods;
        const blockingOperatingPeriodsDateRanges = blockingOperatingPeriods
          ? blockingOperatingPeriods.map((data) => ({
              startDate: data.dateAndTimeRanges.startDate,
              endDate: data.dateAndTimeRanges.endDate,
            }))
          : [];
        const updatedDisabledDateRanges = [...disabledDateRages, ...blockingOperatingPeriodsDateRanges];
        return updatedDisabledDateRanges;
      }
    }

    return [];
  }

  function maxDateForEndDateInputField(): Date | null {
    return getDisabledDateRanges().reduce((minStartDate: Date | null, { startDate }: DisabledDateRange) => {
      const currentDate: Date = new Date(startDate);
      if (
        currentDate > new Date(openingHoursFormValues.startDate) &&
        (minStartDate === null || currentDate < minStartDate)
      ) {
        return currentDate;
      }
      return minStartDate;
    }, null);
  }

  function getMaxDate(): Date | undefined {
    const currentDate = dateValue[0];
    const ohStartDateGreaterThanCurrentSelectedStartDate = currentDate
      ? getFirstOHStartDateWhichIsGreaterThanSelectedStartDate(currentDate)
      : undefined;

    const oocStartDateGreaterThanCurrentSeelctedStartDate = currentDate
      ? getFirstOOCStartDateWhichIsGreaterThanSelectedStartDate(currentDate)
      : undefined;

    if (ohStartDateGreaterThanCurrentSelectedStartDate) {
      const date = new Date(ohStartDateGreaterThanCurrentSelectedStartDate);
      date.setDate(date.getDate() - 1);
      return date;
    }

    if (oocStartDateGreaterThanCurrentSeelctedStartDate) {
      const date = new Date(oocStartDateGreaterThanCurrentSeelctedStartDate);
      date.setDate(date.getDate() - 1);
      return date;
    }
    return dateValue[0] !== null && dateValue[1] != null ? maxDateForEndDateInputField() ?? undefined : undefined;
  }

  const getFirstOHStartDateWhichIsGreaterThanSelectedStartDate = (selectedStartDate: Date): string | undefined => {
    const updatedOpeningHoursData = openingHoursTableData.filter(
      (data) =>
        data.name !== OperatingPeriodType.BASE && data.operatingPeriodDBId !== selectedDayPartData.operatingPeriodDbId
    );

    const startDates = updatedOpeningHoursData.map((data) => ({ startDate: data.startDate }));

    const matchingStartDate = startDates
      .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
      .find(({ startDate }) => selectedStartDate <= new Date(startDate));

    return matchingStartDate?.startDate;
  };

  const getFirstOOCStartDateWhichIsGreaterThanSelectedStartDate = (currentDate: Date): string | undefined => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const blockingOperatingPeriods = fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods;

        if (!blockingOperatingPeriods) return;

        const startDates = blockingOperatingPeriods.map(({ dateAndTimeRanges: { startDate } }) => ({ startDate }));

        const matchingStartDate = startDates
          .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
          .find(({ startDate }) => currentDate <= new Date(startDate));

        return matchingStartDate?.startDate;
      }
    }
  };

  function shouldDateComponentBeDisabled(): boolean | undefined {
    return selectedDayPartData.name === OperatingPeriodType.BASE;
  }

  const getPocStartDate = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const updatedBlockingOperatingPeriodsSortedWithStartDate =
          fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods
            .filter((operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.POC)
            .sort(
              (a, b) => new Date(a.dateAndTimeRanges.startDate).getTime() - new Date(b.dateAndTimeRanges.startDate).getTime()
            );

        const checkAndGetPOCDataIfAny = updatedBlockingOperatingPeriodsSortedWithStartDate?.find(
          (operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.POC
        );

        if (checkAndGetPOCDataIfAny) {
          const pocStartDate = new Date(checkAndGetPOCDataIfAny?.dateAndTimeRanges.startDate as string);
          const oneDayLessThanPocStartDate = new Date(pocStartDate);
          oneDayLessThanPocStartDate.setDate(oneDayLessThanPocStartDate.getDate() - 1);

          return {
            oneDayLessThanPocStartDate: getFormattedDateToBeDisplayed(
              oneDayLessThanPocStartDate.toISOString(),
              DATE_FORMAT,
              DATE_FORMAT_TO_BE_DISPLAYED
            ),
            pocStartDate: getFormattedDateToBeDisplayed(
              pocStartDate.toISOString(),
              DATE_FORMAT,
              DATE_FORMAT_TO_BE_DISPLAYED
            ),
          };
        }
      }
    }
  };

  const isPanelInPOCOrTOC = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        return fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods.some(
          (operatingPeriod) =>
            operatingPeriod.oocType === OutOfChargeStatus.POC || operatingPeriod.oocType === OutOfChargeStatus.TOC
        );
      }
    }

    return false;
  };

  const getPOCPanelIds = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const checkAndGetPOCDataIfAny = fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods.find(
          (operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.POC
        );

        return checkAndGetPOCDataIfAny?.applicablePanelIds.join(', ');
      }
    }
  };

  const getTOCDetails = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const checkAndGetTOCDataIfAny = fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods.find(
          (operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.TOC
        );

        const tocStartDate = new Date(checkAndGetTOCDataIfAny?.dateAndTimeRanges.startDate as string);
        const tocEndDate = new Date(checkAndGetTOCDataIfAny?.dateAndTimeRanges.endDate as string);

        return {
          tocStartDate: getFormattedDateToBeDisplayed(tocStartDate.toISOString(), DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED),
          tocEndDate: getFormattedDateToBeDisplayed(tocEndDate.toISOString(), DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED),
          applicablePanelIds: checkAndGetTOCDataIfAny?.applicablePanelIds.join(', '),
        };
      }
    }
  };

  function getTooltipContent() {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const isPanelInPOC = fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods.some(
          (operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.POC
        );
        const isPanelInTOC = fetchedDataForOpeningHoursSearchRequest[0].blockingOperatingPeriods.some(
          (operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.TOC
        );

        if (isPanelInPOC) {
          return (
            <div className="street-view-tooltip-text">
              End Date is {getPocStartDate()?.oneDayLessThanPocStartDate} because there is a POC for Panel Id(s){' '}
              {getPOCPanelIds()} starting from {getPocStartDate()?.pocStartDate}
            </div>
          );
        }
        const tocDetails = getTOCDetails();
        if (isPanelInTOC && tocDetails) {
          const startDate = tocDetails.tocStartDate;
          const endDate = tocDetails.tocEndDate;
          const applicablePanelIds = tocDetails.applicablePanelIds;
          return (
            <div className="street-view-tooltip-text">
              There is a TOC associated with Panel Id(s) {applicablePanelIds} from {startDate} to {endDate}
            </div>
          );
        }
      }
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div className="openinghours-date-pickerinput-container" data-testid={`custom-date-picker-input`}>
        <DatePickerInput
          clearable
          data-testid={`date-picker-input`}
          type="range"
          mx="auto"
          renderDay={renderDay}
          label={translate('openingHoursSIdebarTab:dateRangePickerInputLabel')}
          className="opening-hours-date-picker-input"
          placeholder={translate('openingHoursSIdebarTab:selectDateRange')}
          value={dateValue}
          maxDate={getMaxDate()}
          onChange={(e) => handleDateChange(e)}
          allowSingleDateInRange={true}
          disabled={shouldDateComponentBeDisabled()}
          required
        />
      </div>

      {isPanelInPOCOrTOC() && (
        <div className="oh-date-warning">
          <Tippy
            appendTo={document.body}
            maxWidth="200rem"
            placement="top"
            interactive
            arrow={true}
            delay={200}
            content={
              <div className="google-maps-content">
                <Icon fontSize="1rem" iconName="information" />
                {getTooltipContent()}
              </div>
            }
          >
            <div className="start-date-info-icon">
              <Icon fontSize="1rem" color="orange" iconName="information" />
            </div>
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default OpeningHoursCalendarConfigDatePicker;
