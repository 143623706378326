import { SidebarTabType } from '../../components/sidebar/sidebarConfig';
import React, { useState, useEffect, useContext } from 'react';
import './App.scss';
import Sidebar from '../../components/sidebar/sidebar';
import FrameManagerHeader from 'src/components/header/header';
import FrameManagerFooter from 'src/components/footer/footer';
import {
  getAndSetAdministrationBranches,
  getAndSetAllUsers,
  getAndSetTOCReasons,
  setTableData,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { Loading, Button } from '@clearchannelinternational/ecooh-system';
import { AppContext } from 'src/contexts/appContext';
import { FrameType } from 'src/enums/enum';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { getAwsConfig } from 'src/amplify/amplifySetup';
import { forceSignOutUser, setAuthTokens } from 'src/redux/actions/authTokensActions/authTokensActions';

Auth.configure(getAwsConfig());

const App: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const appContext = useContext(AppContext);
  const [ActiveSideBarTab, setActiveSidebarTab] = useState<SidebarTabType | undefined>();
  const dispatch = useDispatch();
  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    if (loginStatus) {
      dispatch(getAndSetAllUsers() as unknown as AnyAction);
      dispatch(getAndSetTOCReasons(FrameType.DIGITAL) as unknown as AnyAction);
      dispatch(getAndSetAdministrationBranches(FrameType.DIGITAL) as unknown as AnyAction);
    }
  }, [loginStatus]);

  useEffect(() => {
    const checkIfUserLoggedIn = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        const decodedIdToken = setAuthTokens(session, dispatch);
        //Temp
        forceSignOutUser(decodedIdToken);

        return true;
      } catch {
        Auth.federatedSignIn();
        return false;
      }
    };

    checkIfUserLoggedIn().then((isLoggedIn) => {
      setLoginStatus(isLoggedIn);
      setLoading(false);
    });
  }, [loginStatus]);

  useEffect(() => {
    dispatch(setTableData([]));
  }, [ActiveSideBarTab]);

  return (
    <>
      {loginStatus && (
        <>
          {loading ? (
            <div className="loading">
              <Loading />
            </div>
          ) : (
            <>
              <div className="Main">
                <Sidebar setActiveSidebarTab={setActiveSidebarTab} ActiveSideBarTab={ActiveSideBarTab} />
                <FrameManagerHeader appContext={appContext} renderedSidebarLabel={ActiveSideBarTab?.label} />
                <div className="tab-container">
                  {ActiveSideBarTab && ActiveSideBarTab.label && (
                    <ActiveSideBarTab.component changeDetailsIndex="0" isAuditSidebarTabView={true} />
                  )}
                </div>
              </div>
              <div className="sign-out">
                <Button renderIcon="logout" kind="primary" size="small" label={'Re-Login'} onClick={() => Auth.signOut()} />
              </div>
              <FrameManagerFooter />
            </>
          )}
        </>
      )}
    </>
  );
};

export default App;
