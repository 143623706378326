import React from 'react';
import { Checkbox } from '@clearchannelinternational/ecooh-system';
import { MultiSelectCheckBoxProps } from 'src/types/componentProps';

const MultiSelectCheckBox: React.FC<MultiSelectCheckBoxProps> = ({
  fetchedData,
  handleCheckboxChange,
  shouldCheckboxBeDisabled,
  systemBasedExternalIds: itemsToDisplay,
  selectedItems,
}) => {
  return (
    <div className="externalID-checkboxes">
      {fetchedData &&
        itemsToDisplay.map((item) => (
          <Checkbox
            key={item}
            checked={(selectedItems as any[]).includes(item)}
            disabled={shouldCheckboxBeDisabled()}
            label={item}
            onChange={() => {
              handleCheckboxChange(item);
            }}
          />
        ))}
    </div>
  );
};

export default MultiSelectCheckBox;
