import React, { useContext, useEffect } from 'react';
import SearchField from 'src/components/searchField/searchField';
import { Options } from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tabsComponent/tocPocDetailsTab/renderInputFields/renderTocInputFields/renderTocInputFields';
import { AppContext } from 'src/contexts/appContext';
import { SearchReducer } from 'src/types/searchReducer';
import { useTranslation } from 'react-i18next';
import { setSearchFieldDetails } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerWithSearchCalendarProps } from 'src/types/componentProps';
import { BulkOOCTOCFields, OOCAttributes, OOCDateSearchType, RequestType, SearchFields } from 'src/enums/enum';
import { SearchBoxFieldType, getBulkTOCSearchPageFieldsList } from 'src/components/searchBox/inputFieldsConfig';
import { BulkOOCSearchRequestBody } from 'src/types/apiModels';
import { Select } from '@mantine/core';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { LoadingReducer } from 'src/types/loadinReducer';
import { setBulkTOCSearchFormValues } from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';

export const comparisonOptions: Options[] = [
  { label: 'is', value: 'EQ' },
  { label: 'on or after', value: 'GTE' },
  { label: 'on or before', value: 'LTE' },
];

const DatePickerWithSearchCalendar: React.FC<DatePickerWithSearchCalendarProps> = ({
  criteriaConfig: dateCriteriaConfig,
  updateModal,
}) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  const getInputFieldsListToBeRendered = () => {
    const countryCode = appContext.Country;
    return getBulkTOCSearchPageFieldsList(countryCode, t).filter((field: SearchBoxFieldType) =>
      field.countryCode.includes(countryCode)
    );
  };
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { bulkTOCSearchFormValues } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );
  const { loading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const updateFormData = (dateField) => {
      const updatedField = {
        ...updatedBulkOOCFormData.oocDateCriteria[dateField],
        date: searchFieldDetails.value.toString(),
      };
      const updatedState = {
        ...updatedBulkOOCFormData,
        oocDateCriteria: {
          ...updatedBulkOOCFormData.oocDateCriteria,
          [dateField]: updatedField,
        },
      };
      dispatch(setBulkTOCSearchFormValues(updatedState));
    };

    const updatedBulkOOCFormData = { ...(bulkTOCSearchFormValues as BulkOOCSearchRequestBody) };

    if (searchFieldDetails.accessorKey === SearchFields.OOC_START_DATE) {
      updateFormData(OOCAttributes.START_DATE);
    } else if (searchFieldDetails.accessorKey === SearchFields.OOC_END_DATE) {
      updateFormData(OOCAttributes.END_DATE);
    }
  }, [searchFieldDetails]);

  function shouldComparisonSelectComponentBeDisabled(criteria: BulkOOCTOCFields): boolean | undefined {
    return (
      (criteria === BulkOOCTOCFields.END_DATE && !bulkTOCSearchFormValues.oocDateCriteria.endDate.date) ||
      (criteria === BulkOOCTOCFields.START_DATE && !bulkTOCSearchFormValues.oocDateCriteria.startDate.date) ||
      loading
    );
  }

  return (
    <div className="date-picker-radio-calender-container">
      {dateCriteriaConfig.map((criteria) => {
        const searchInputComponent = getInputFieldsListToBeRendered().find((field) => field.label === `TOC ${criteria}`);

        function handleOnChangeComparisonSelectComponent(e: OOCDateSearchType) {
          if (e != null) {
            const updatedBulkOOCFormData = { ...(bulkTOCSearchFormValues as BulkOOCSearchRequestBody) };

            let updatedStartDateCriteria = updatedBulkOOCFormData.oocDateCriteria.startDate;
            let updatedEndDateCriteria = updatedBulkOOCFormData.oocDateCriteria.endDate;

            if (criteria === BulkOOCTOCFields.START_DATE) {
              updatedStartDateCriteria = {
                ...updatedStartDateCriteria,
                searchType: e,
              };
            } else if (criteria === BulkOOCTOCFields.END_DATE) {
              updatedEndDateCriteria = {
                ...updatedEndDateCriteria,
                searchType: e,
              };
            }

            const updatedState = {
              ...updatedBulkOOCFormData,
              oocDateCriteria: {
                startDate: updatedStartDateCriteria,
                endDate: updatedEndDateCriteria,
              },
            };

            dispatch(setBulkTOCSearchFormValues(updatedState));
          }
        }

        return (
          <>
            <div className="checkbox-date-calander-container" key={updateModal}>
              <div className="criteria-label">{criteria}</div>
              <div className="comparison-operator">
                <Select
                  radius={'md'}
                  size="xs"
                  defaultValue={comparisonOptions[0].value}
                  data={comparisonOptions}
                  disabled={shouldComparisonSelectComponentBeDisabled(criteria)}
                  value={
                    criteria === BulkOOCTOCFields.START_DATE
                      ? bulkTOCSearchFormValues.oocDateCriteria.startDate.searchType
                      : bulkTOCSearchFormValues.oocDateCriteria.endDate.searchType
                  }
                  onChange={handleOnChangeComparisonSelectComponent}
                  defaultChecked={true}
                />
              </div>

              <div className="date-calander">
                {searchInputComponent && (
                  <SearchField
                    label={searchInputComponent.label}
                    inputType={searchInputComponent.inputType}
                    isDateRangeField={searchInputComponent.isDateRangeField}
                    searchFieldDetails={searchFieldDetails}
                    setSearchFieldDetails={setSearchFieldDetails}
                    requestType={RequestType.OOC_BULK_SEARCH_REQUEST}
                    fetchNextPage={undefined as any}
                    isFetching={undefined as any}
                    selectedField={searchInputComponent.label}
                  />
                )}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default DatePickerWithSearchCalendar;
