/* eslint-disable camelcase */
import React, { useContext } from 'react';
import {
  setExistingTocPoc,
  setIsEditClicked,
  setIsUserInViewMode,
} from 'src/redux/actions/tocPocSidebarTabActions/componentActions';
import { SearchReducer, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import Table from 'src/components/table/table';
import { BulkUpdateValidationErrorType, OutOfChargeStatus, RequestType } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { InitialState, TocPocFormData } from 'src/types/TocPocModal';
import { AppContext, systemsConfig } from 'src/contexts/appContext';
import { MRT_ColumnDef } from 'mantine-react-table';
import { Button, H5, Icon } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { ExistingTocPocEntriesProps } from '../../../../../../types/componentProps';
import { ColumnVisibility } from 'src/types/table';
import { BaseTOCFields } from 'src/types/apiModels';
import { setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { actionsAccessorKey, getDigitalUnitIdsWithPanelNo, getTooltipText } from '../../../tocPocDetailsTableConfig';
import { setModalLoading } from 'src/redux/actions/loadingActions';
import { getFormattedDateToBeDisplayed, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED } from 'src/utils/dateUtils';

const ExistingTocPocTable: React.FC<ExistingTocPocEntriesProps> = ({
  formValues,
  setFormValues,
  existingTocsOrPoc,
  searchRequestType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const fieldsConfig = systemsConfig[appContext.system][appContext.Country];
  const { fetchedDataForCreateTocPocModal } = useSelector((state: SearchReducer) => state.searchReducer);

  const panelsAccessroKey = 'panelIds';
  const existingTocPocColumns = (): MRT_ColumnDef<TOCPOCDetailsTableData>[] => {
    return [
      {
        accessorKey: 'siteKey',
        header: fieldsConfig.siteKey && fieldsConfig.siteKey.label,
        size: 155,
        Cell: (cell) => {
          const panelIds = cell.row.original.panelIds;
          return (
            <>
              <div className="existing-toc-poc-accordion-sitekey-column">
                <>{cell.cell.getValue()}</>
                {fetchedDataForCreateTocPocModal && fetchedDataForCreateTocPocModal.frameIdentifiers.isMultiPanel && (
                  <Icon
                    iconName="book"
                    tooltip={getTooltipText(panelIds)}
                    tooltipIcon="information"
                    testId="double-sided-icon"
                  />
                )}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: panelsAccessroKey,
        header: `${t('table:digitalUnitIdWithPanelNo')}`,
        Cell: (cell) => {
          const targetId = cell.row.original.id;
          return getDigitalUnitIdsWithPanelNo(targetId, existingTocsOrPoc).join(', ');
        },
        size: 130,
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      },
      // {
      //   accessorKey: 'slotId',
      //   header: fieldsConfig.slotId && fieldsConfig.slotId.label,
      //   Cell: (cell) => {
      //     return <>{cell.cell.getValue() as string[]}</>;
      //   },
      //   size: 100,
      // },
      {
        accessorKey: 'outOfChargeStatus',
        header: t('table:outOfChargeType'),
        size: 145,
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      },
      {
        accessorKey: 'startDate',
        header: t('table:startDate'),
        size: 110,
        Cell: (cell) => {
          const tocPocStartDate = cell.row.original.startDate.slice(0, 10);
          return getFormattedDateToBeDisplayed(tocPocStartDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED);
        },
      },
      {
        accessorKey: 'endDate',
        header: t('table:endDate'),
        Cell: (cell) => {
          const endDate = (cell.row.original as BaseTOCFields).endDate.slice(0, 10);
          return endDate ? getFormattedDateToBeDisplayed(endDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED) : 'NA';
        },
        size: 110,
      },
      {
        accessorKey: 'errorType',
        header: t('table:errorType'),
        Cell: (cell) => {
          const errorType = cell.row.original.errorType;
          if (errorType) {
            return BulkUpdateValidationErrorType[errorType];
          }
          return BulkUpdateValidationErrorType.DEFAULT;
        },
        size: 110,
      },
      {
        accessorKey: actionsAccessorKey,
        header: t('table:actions'),
        size: 100,
        Cell: (cell) => {
          const outOfChargeStatus = cell.row.original.outOfChargeStatus;
          return (
            <>
              <Button
                hasIconOnly
                label="Button"
                renderIcon="edit"
                size="small"
                kind="tertiary"
                disabled={outOfChargeStatus === OutOfChargeStatus.POC}
                onClick={() => handleEditClick(existingTocsOrPoc[cell.row.id])}
                renderIconSize="secondary"
                testId="edit-button"
              />
            </>
          );
        },
      },
    ];
  };

  const handleEditClick = (toc: TOCPOCDetailsTableData) => {
    dispatch(setSuccessMessage(undefined));
    dispatch(setModalLoading(true));
    dispatch(setExistingTocPoc(toc));
    dispatch(setIsEditClicked(true));
    dispatch(setIsUserInViewMode(false));
    const formData: TocPocFormData = {
      ...toc,
    };
    if (setFormValues) {
      setFormValues(formData);
    }
    setTimeout(() => {
      dispatch(setModalLoading(false));
    }, 500);
  };

  const columnVisibilityConfig: ColumnVisibility = {
    oasis: {
      slotId: false,
      actions: searchRequestType !== RequestType.OOC_BULK_SEARCH_REQUEST,
      outOfChargeStatus: searchRequestType !== RequestType.OOC_BULK_SEARCH_REQUEST,
      errorType: searchRequestType === RequestType.OOC_BULK_SEARCH_REQUEST,
    },
    aida: {
      commercialFrameIds: false,
      siteKey: false,
    },
  };

  const initialState: InitialState = {
    columnVisibility: columnVisibilityConfig[appContext.system],
    columnPinning: {
      left: [panelsAccessroKey],
      right: [actionsAccessorKey],
    },
    sorting: [
      {
        id: 'startDate',
        desc: true,
      },
    ],
  };

  const getExistingTocPocTableMessage = () => {
    if (searchRequestType === RequestType.OOC_BULK_SEARCH_REQUEST) {
      return t('bulkOOCSearchSidebarTab:ineligibleTocsMessage');
    }
    return formValues && formValues.outOfChargeStatus === OutOfChargeStatus.TOC
      ? t('createNewTocPocModal:existingTocsMessage')
      : t('createNewTocPocModal:existingPocMessage');
  };

  return (
    <div className="accordian-container">
      <div className="tab-heading1">
        <H5 testId="existing-ooc-message">{getExistingTocPocTableMessage()}</H5>
      </div>
      <div className="existingTocPoc-table-container">
        <Table
          columns={existingTocPocColumns()}
          data={existingTocsOrPoc}
          enableGrouping={false}
          enablePinning={false}
          enableRowSelection={false}
          enablePagination={false}
          enableRowVirtualization={false}
          enableColumnFilterModes={false}
          enableTopToolbar={false}
          enableFilters={false}
          enableSorting={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          initialState={initialState}
        />
      </div>
    </div>
  );
};

export default ExistingTocPocTable;
