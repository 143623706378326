/* eslint-disable camelcase */
import {
  SET_AUDIT_TABLE_DATA,
  SET_IS_SCROLLING_AUDIT_RECORDS,
  SET_IS_SEARCH_FOR_ALL_RECORDS_BUTTON_CLICKED,
} from 'src/redux/reducers/auditSidebarTabReducer/auditSidebarTabReducer';
import {
  AuditSearchRequestDetails,
  AuditSearchResponseBody,
  AuditSyncStatus,
  BulkUpdateDetailsResponseData,
  FetchedAuditData,
  UserInfo,
} from 'src/types/apiModels';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { API_ENDPOINTS, api, buParam } from 'src/api/apiConfig';
import { SearchFieldDetails } from 'src/components/searchBox/inputFieldsConfig';
import { ModifiedOOCAttributes, ExternalSystems, OutOfChargeStatus, SearchFields } from 'src/enums/enum';
import { Action, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { AuditTableDetails } from 'src/types/table';
import { FetchNextPageOptions } from 'react-query';

export const setAuditTableData = (data: AuditTableDetails[]): Action<AuditTableDetails[]> => ({
  type: SET_AUDIT_TABLE_DATA,
  payload: data,
});

export const setIsScrollingAuditRecords = (isScrollingAuditRecords: boolean): Action<boolean> => ({
  type: SET_IS_SCROLLING_AUDIT_RECORDS,
  payload: isScrollingAuditRecords,
});

export const setIsSearchForAllRecordsButtonClicked = (isSearchForAllRecordsButtonClicked: boolean): Action<boolean> => ({
  type: SET_IS_SEARCH_FOR_ALL_RECORDS_BUTTON_CLICKED,
  payload: isSearchForAllRecordsButtonClicked,
});

export const onClickAuditSearch = async (
  searchFieldDetails: SearchFieldDetails,
  dispatch: Dispatch<AnyAction>,
  existingTocPoc: TOCPOCDetailsTableData,
  auditRequestBody: AuditSearchRequestDetails,
  offset: number,
  limit: number
) => {
  const response = await api.post(API_ENDPOINTS.AUDIT_SEARCH, auditRequestBody, {
    params: { ...buParam, offset: offset, limit: limit },
  });
  const auditTableData = handleSuccessResponseForAuditSearchRequest(response, dispatch, searchFieldDetails, existingTocPoc);
  return auditTableData;
};

export const handleSuccessResponseForAuditSearchRequest = (
  response: AxiosResponse,
  dispatch: Dispatch<AnyAction>,
  searchFieldDetails?: SearchFieldDetails,
  existingTocPoc?: TOCPOCDetailsTableData
) => {
  const fetchedAuditData: AuditSearchResponseBody = response.data;
  const auditTableData: AuditTableDetails[] = fetchedAuditData.data.map((auditData) => {
    return {
      auditEventType: auditData.auditEventType,
      siteKey: auditData.frameIdentifiers[0].oasis.siteKey,
      commercialFrameId: auditData.frameIdentifiers.map((frameIdentifier) => frameIdentifier.space.commercialFrameId),
      panelId: auditData.frameIdentifiers.map((frameIdentifier) => frameIdentifier.oasis.panelId),
      digitalUnitId: auditData.frameIdentifiers.map((frameIdentifier) => frameIdentifier.oasis.digitalUnitId),
      panelNo: auditData.frameIdentifiers.map((frameIdentifier) => frameIdentifier.oasis.panelNo),
      siteNo: auditData.frameIdentifiers[0].oasis.siteNo,
      screenId: auditData.frameIdentifiers.map((frameIdentifier) => frameIdentifier.broadsign.screenId),
      displayUnitId: auditData.frameIdentifiers.map((frameIdentifier) => frameIdentifier.broadsign.displayUnitId),
      frameIds: getBroadSignFrameIds(auditData),
      oldState: auditData.oldState,
      newState: auditData.newState,
      oocId: auditData.oocId,
      outOfChargeType: auditData.outOfChargeType,
      isDoubleSided: auditData.isDoubleSided,
      startDateModified: isAttributeModified(auditData, ModifiedOOCAttributes.TOC_START_DATE),
      endDateModified: isAttributeModified(auditData, ModifiedOOCAttributes.TOC_END_DATE),
      reasonModified: isAttributeModified(auditData, ModifiedOOCAttributes.TOC_REASON),
      commentModified: isAttributeModified(auditData, ModifiedOOCAttributes.TOC_COMMENT),
      broadSignSyncStatus: extractSyncStatus(auditData.syncStatusSummary, ExternalSystems.BROADSIGN),
      oasisSyncStatus: extractSyncStatus(auditData.syncStatusSummary, ExternalSystems.OASIS),
      updatedBy: auditData.updatedBy,
      updatedDate: auditData.updatedDate,
      actionDate: auditData.actionDate,
    } as AuditTableDetails;
  });

  if (searchFieldDetails?.value || existingTocPoc?.outOfChargeStatus !== OutOfChargeStatus.DEFAULT) {
    dispatch(setAuditTableData(auditTableData));
  }
  return auditTableData;
};

export const auditRequestBody = (
  existingTocPoc: TOCPOCDetailsTableData,
  searchFieldDetails: SearchFieldDetails,
  userDetails: UserInfo[],
  bulkUpdateDetailsResponseData: BulkUpdateDetailsResponseData
): AuditSearchRequestDetails => {
  const { id: oocId } = existingTocPoc;
  const { accessorKey, value } = searchFieldDetails;
  const valueWithoutSpace = value ? (value as string).trim() : undefined;

  if (oocId) {
    return {
      oocId,
      batchId:
        bulkUpdateDetailsResponseData && Object.values(bulkUpdateDetailsResponseData.batchSummary).length !== 0
          ? bulkUpdateDetailsResponseData.batchSummary.batchUUID
          : undefined,
    };
  }

  const parseInteger = (str: string | undefined): number | undefined => {
    if (str) {
      return parseInt(str);
    }
    return undefined;
  };

  const toDateFromValue = (dateValue: string) => {
    if (dateValue) {
      return { fromDate: dateValue, toDate: dateValue };
    }
    return undefined;
  };

  const getUserId = (userValue: string): string[] | undefined => {
    if (accessorKey === SearchFields.USER) {
      const userId = findUserId(userValue, userDetails) as string;
      return [userId];
    }
    return undefined;
  };

  return {
    panelId: accessorKey === SearchFields.PANEL_ID && valueWithoutSpace ? (valueWithoutSpace as string) : undefined,
    commercialFrameId:
      accessorKey === SearchFields.COMMERCIAL_FRAME_ID ? parseInteger(valueWithoutSpace as string) : undefined,
    sitekey: accessorKey === SearchFields.SITE_KEY ? (valueWithoutSpace as string) : undefined,
    oocId: oocId ? oocId : undefined,
    actionPerformedByUserIds: getUserId(value as string),
    actionDate: accessorKey === SearchFields.ACTION_DATE ? toDateFromValue(value as string) : undefined,
    startDate: accessorKey === SearchFields.OOC_START_DATE ? toDateFromValue(value as string) : undefined,
    endDate: accessorKey === SearchFields.OOC_END_DATE ? toDateFromValue(value as string) : undefined,
    broadsignControlFrameId:
      accessorKey === SearchFields.BROADSIGN_CONTROL_FRAME_ID ? (valueWithoutSpace as string) : undefined,
    digitalUnitId: accessorKey === SearchFields.DIGITAL_UNIT_ID ? parseInteger(valueWithoutSpace as string) : undefined,
  };
};

function getBroadSignFrameIds(auditData: FetchedAuditData): string[] {
  const extractedBroadSignFrameArrays = auditData.frameIdentifiers.map(
    (frameIdentifier) => frameIdentifier.broadsign.frameIds
  );
  return Array.from(extractedBroadSignFrameArrays.flat());
}

function findUserId(fullName: string, userDetails: UserInfo[]) {
  const [lastName, firstName] = fullName.split(' ').map((value) => value.trim());
  const user = userDetails.find((user) => user.firstName === firstName.trim() && user.lastName === lastName.trim());
  return user ? user.userId : null;
}

const isAttributeModified = (auditData: FetchedAuditData, attribute: ModifiedOOCAttributes): boolean => {
  return auditData.modifiedAttributes.includes(attribute);
};

const extractSyncStatus = (data: AuditSyncStatus[], externalSystem: ExternalSystems) => {
  const matchingItem = data.find((item) => item.externalSystem === externalSystem);
  return matchingItem ? matchingItem.syncStatus : undefined;
};

export const fetchNextPageOptions: FetchNextPageOptions = {
  cancelRefetch: true,
  pageParam: 0,
};
