import { AnyAction } from 'redux';
import { ComponentState } from '../../../types/componentReducers';
import { OutOfChargeStatus, PerformedByUserType, SyncStatusStates, TOCPOCCurrentStatus } from 'src/enums/enum';
import { TOCPOCDetailsTableData } from 'src/types/searchReducer';

export const actionTypes = {
  SET_IS_EDIT_CLICKED: 'SET_IS_EDIT_CLICKED',
  SET_CREATE_NEW_TOC_MODAL: 'CREATE_NEW_TOC_MODAL',
  SET_VIEW_TOC_POC_MODAL: 'VIEW_TOC_POC_MODAL',
  SET_DELETE_MODAL: 'SET_DELETE_MODAL',
  SET_IS_USER_IN_VIEW_MODE: 'SET_IS_USER_IN_VIEW_MODE',
  SET_CREATE_TOC_POC_FORM_DATA: 'SET_CREATE_TOC_POC_FORM_DATA',
  SET_EDIT_TOC_POC_FORM_DATA: 'SET_EDIT_TOC_POC_FORM_DATA',
  SET_EXISTING_TOC_POC: 'SET_EXISTING_TOC_POC',
  SET_TAB_START_INDEX: 'SET_TAB_START_INDEX',
};

export const initialExistingTocPoc: TOCPOCDetailsTableData = {
  id: '',
  startDate: '',
  endDate: '',
  comment: '',
  tocReasonCode: '',
  tocReasonText: '',
  updatedDate: '',
  updatedBy: '',
  createdBy: '',
  createdDate: '',
  updatedByUser: {
    userType: PerformedByUserType.DEFAULT,
    userId: '',
    email: '',
    firstName: '',
    lastName: '',
  },
  panelIds: [],
  commercialFrameIds: [],
  isMultiPanel: false,
  isOperative: false,
  // slotId: '',
  siteKey: '',
  outOfChargeStatus: OutOfChargeStatus.DEFAULT,
  tocPocCurrentStatus: TOCPOCCurrentStatus.DEFAULT,
  oasisSyncStatus: SyncStatusStates.DEFAULT,
  broadsignSyncStatus: SyncStatusStates.DEFAULT,
  panelIdIdentifierMappings: [],
  digitalUnitIdIdentifierMappings: [],
  administrationBranch: '',
};

const initialState: ComponentState = {
  isEditClicked: false,
  createNewTocModal: false,
  viewTocPocModal: false,
  deleteModal: false,
  isUserInViewMode: false,
  existingTocPoc: initialExistingTocPoc,
  tabStartIndex: 0,
};

const TocPocSidebarTabReducer = (state: ComponentState = initialState, action: AnyAction): ComponentState => {
  switch (action.type) {
    case actionTypes.SET_IS_EDIT_CLICKED:
      return { ...state, isEditClicked: action.payload };
    case actionTypes.SET_CREATE_NEW_TOC_MODAL:
      return { ...state, createNewTocModal: action.payload };
    case actionTypes.SET_VIEW_TOC_POC_MODAL:
      return { ...state, viewTocPocModal: action.payload };
    case actionTypes.SET_DELETE_MODAL:
      return { ...state, deleteModal: action.payload };
    case actionTypes.SET_IS_USER_IN_VIEW_MODE:
      return { ...state, isUserInViewMode: action.payload };
    case actionTypes.SET_TAB_START_INDEX:
      return { ...state, tabStartIndex: action.payload };
    case actionTypes.SET_EXISTING_TOC_POC:
      return { ...state, existingTocPoc: action.payload };
    default:
      return state;
  }
};

export default TocPocSidebarTabReducer;
