import React, { FC, useContext } from 'react';
import AuditViewTab from 'src/pages/auditViewSidebarTab/auditViewSidebarTab';
import TocPocDetailsTab from './tocPocDetailsTab/tocPocDetailsTab';
import SyncStatusDetailsTab from './syncStatusDetailsTab/syncStatusDetailsTab';
import { Tabs, Loading } from '@clearchannelinternational/ecooh-system';
import { TabComponentProps } from 'src/types/componentProps';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTabStartIndex } from 'src/redux/actions/tocPocSidebarTabActions/componentActions';
import { onClickSearch } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { AppContext } from 'src/contexts/appContext';
import { AnyAction } from 'redux';
import { RequestType } from 'src/enums/enum';
import { SearchReducer } from 'src/types/searchReducer';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { LoadingReducer } from 'src/types/loadinReducer';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { getInitialBulkOOCSearchForm } from 'src/pages/bulkOOCSearchSidebarTab/bulkOOCSearchBar/bulkOOCSearchBar';

export type Tab = {
  content: React.ReactNode;
  label: React.ReactNode;
};

const TabsComponent: FC<TabComponentProps> = (props) => {
  const { fetchedData, setFormData, updateModal } = props;
  const { t: translate } = useTranslation();
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { syncHistoryTableData } = useSelector((state: SearchReducer) => state.searchReducer);
  const { syncStatusTabLoading, modalLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { existingTocPoc } = useSelector((state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const isAuditSidebarTabView = false;
  const { bulkUpdateDetailsResponseData } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );

  const tabs: Tab[] = [
    {
      content: <TocPocDetailsTab setFormData={setFormData} fetchedData={fetchedData} updateModal={updateModal} />,
      label: (
        <div className="tab-label" onClick={() => dispatch(setTabStartIndex(0))}>
          {translate('createNewTocPocModal:details')}
        </div>
      ),
    },
    {
      content: <>{modalLoading ? <Loading /> : <AuditViewTab isAuditSidebarTabView={isAuditSidebarTabView} />}</>,
      label: (
        <div
          className="tab-label"
          onClick={async () => {
            dispatch(setTabStartIndex(1));
          }}
        >
          Audit History
        </div>
      ),
    },
    {
      content: <>{syncStatusTabLoading ? <Loading /> : <SyncStatusDetailsTab />}</>,
      label: (
        <div
          className="tab-label"
          onClick={() => {
            dispatch(setTabStartIndex(2));
            syncHistoryTableData.length === 0 && handleSyncTabClick();
          }}
        >
          Sync status
        </div>
      ),
    },
  ];

  const handleSyncTabClick = () => {
    dispatch(
      onClickSearch(
        searchFieldDetails,
        appContext,
        translate,
        RequestType.SYNC_HISTORY_REQUEST,
        existingTocPoc,
        getInitialBulkOOCSearchForm(),
        bulkUpdateDetailsResponseData
      ) as unknown as AnyAction
    );
  };

  return (
    <div className="toc-poc-tab-container">
      <Tabs items={tabs} startIndex={0} hasBottomBorder isOptionFullWidth={false} />
    </div>
  );
};

export default TabsComponent;
