/* eslint-disable camelcase */
import React from 'react';
import { SearchReducer } from 'src/types/searchReducer';
import { Loading } from '@clearchannelinternational/ecooh-system';
import { useContext, useEffect } from 'react';
import { AppContext } from 'src/contexts/appContext';
import { useDispatch, useSelector } from 'react-redux';
import { TocPocDetailsTableProps } from '../../../types/componentProps';
import { RequestType } from 'src/enums/enum';
import { LoadingReducer } from 'src/types/loadinReducer';
import { setLoading } from 'src/redux/actions/loadingActions';
import { MantineProvider } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';

const TocPocDetailsTable: React.FC<TocPocDetailsTableProps> = ({ tableInstance, searchRequestType }) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const { tableData, fetchedDataForTocPocTableDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { loading } = useSelector((state: LoadingReducer) => state.loadingReducer);

  useEffect(() => {
    if (searchRequestType !== RequestType.OPENING_HOURS_SEARCH_REQUEST) {
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500);
    }
  }, [appContext.Country]);

  return (
    <>
      {loading && searchRequestType !== RequestType.OPENING_HOURS_SEARCH_REQUEST ? (
        <Loading />
      ) : (
        <div className="table-container">
          <div className="fetched-records-text">
            {fetchedDataForTocPocTableDetails && searchRequestType === RequestType.OOC_BULK_SEARCH_REQUEST && (
              <>
                Found {tableData.length} TOC{(tableData.length > 1 || tableData.length === 0) && 's'}
              </>
            )}
          </div>
          <MantineProvider>
            <MantineReactTable table={tableInstance} />
          </MantineProvider>
        </div>
      )}
    </>
  );
};

export default TocPocDetailsTable;
