import React from 'react';
import { H5 } from '@clearchannelinternational/ecooh-system';
import { Radio } from '@clearchannelinternational/ecooh-system';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BasePOCFields, BaseTOCFields } from 'src/types/apiModels';
import { OutOfChargeTypeRadioGroupProps } from 'src/types/componentProps';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { OOCAttributes, OutOfChargeStatus } from 'src/enums/enum';

const OutOfChargeTypePicker: React.FC<OutOfChargeTypeRadioGroupProps> = ({
  isUserIsViewOrEditMode,
  isTOCFocused,
  isPOCFocused,
  setIsTOCFocused,
  setIsPOCFocused,
  handleInputChange,
  formValues,
}) => {
  const { t } = useTranslation();
  const { existingTocPoc } = useSelector((state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer);

  const handleRadioChange = (value: string) => {
    const tocFormData = formValues as BaseTOCFields;
    const pocFormData = formValues as BasePOCFields;
    const isTocFormFilled =
      formValues.startDate !== '' ||
      tocFormData.endDate !== '' ||
      tocFormData.tocReasonCode !== '' ||
      tocFormData.comment !== '';

    const isPocFormFilled = pocFormData.startDate !== '' || pocFormData.dismantleSite !== false;

    if (
      (value === OutOfChargeStatus.TOC && isPOCFocused && isPocFormFilled) ||
      (value === OutOfChargeStatus.POC && isTOCFocused && isTocFormFilled)
    ) {
      const switchAlertMessage = window.confirm(
        `Switching to ${value} will discard any unsaved changes. Do you want to continue?`
      );
      if (!switchAlertMessage) {
        if (value === OutOfChargeStatus.TOC) {
          setIsTOCFocused(false);
          setIsPOCFocused(true);
        } else {
          setIsTOCFocused(true);
          setIsPOCFocused(false);
        }
        return;
      }
    }
    if (value === OutOfChargeStatus.TOC) {
      setIsTOCFocused(true);
      setIsPOCFocused(false);
    } else {
      setIsTOCFocused(false);
      setIsPOCFocused(true);
    }
    handleInputChange(OOCAttributes.OUT_OF_CHARGE_STATUS, value);
  };

  return (
    <div className="toc-poc-radio-container">
      <H5>
        {isUserIsViewOrEditMode()
          ? t('createNewTocPocModal:chooseTypeOfOutOfCharge')
          : t('createNewTocPocModal:typeOfOutOfCharge')}
      </H5>
      <div className="toc-poc-radio">
        <Radio
          label={OutOfChargeStatus.TOC}
          name={OutOfChargeStatus.TOC}
          value={OutOfChargeStatus.TOC}
          checked={isTOCFocused}
          disabled={existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.DEFAULT}
          onChange={(e: string) => handleRadioChange(e)}
          testId="toc-radio"
        />
        <Radio
          label={OutOfChargeStatus.POC}
          name={OutOfChargeStatus.POC}
          value={OutOfChargeStatus.POC}
          checked={isPOCFocused}
          disabled={existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.DEFAULT}
          onChange={(e: string) => handleRadioChange(e)}
          testId="poc-radio"
        />
      </div>
    </div>
  );
};

export default OutOfChargeTypePicker;
