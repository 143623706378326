import React, { useContext, useEffect, useState } from 'react';
import Alert from 'src/components/alert/alert';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessageStates, ButtonKind, ModalSize, OperatingPeriodType, RequestType } from 'src/enums/enum';
import { OpeningHoursSyncModalProps } from 'src/types/componentProps';
import { SearchReducer } from 'src/types/searchReducer';
import { Button, useModal, Icon } from '@clearchannelinternational/ecooh-system';
import { Loader } from '@mantine/core';
import { SaveReducerState } from 'src/types/saveReducer';
import { setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { LoadingReducer } from 'src/types/loadinReducer';
import { ModalConfig } from 'src/types/TocPocModal';
import { useTranslation } from 'react-i18next';
import { setLoading } from 'src/redux/actions/loadingActions';
import { formatDateToMMMDDYYYY, getFormattedDate, opInfiniteDate } from 'src/utils/dateUtils';
import { onClickSearch } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { AppContext } from 'src/contexts/appContext';
import { AnyAction } from 'redux';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import Tippy from '@tippyjs/react';
import { areDisplayUnitIdsUnique } from 'src/pages/openingHoursSidebarTab/utils/utils';
import { Checkbox } from '@clearchannelinternational/ecooh-system';
import {
  checkIfRowsHaveDifferentOpeningHours,
  handleOnClickSaveChanges,
} from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';

const OpeningHoursSyncModal: React.FC<OpeningHoursSyncModalProps> = ({
  openingHoursFormValues,
  dateValue,
  setDateValue,
  selectedDayPartData,
  handleReset,
  operatingPeriodRequestType,
  checkedStates,
  setOpeningHoursFormValues,
  effectiveDateFrom,
  setEffectiveFromDate,
  shouldHideEffectiveFromField,
  selectedFrameDetailRow,
}) => {
  const dispatch = useDispatch();
  const { showModal, updateModal } = useModal();
  const { successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { modalLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const [openingHoursSyncModal, setOpeningHoursSyncModal] = useState<boolean>(false);
  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);
  const { t: translate } = useTranslation();
  const { isViewButtonClicked } = useSelector((state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer);
  const [isSyncToOtherPanelsChecked, setIsSyncToOtherPanelsChecked] = useState<boolean>(
    !checkIfRowsHaveDifferentOpeningHours(fetchedDataForOpeningHoursSearchRequest)
  );

  useEffect(() => {
    return updateModal(openingHoursSyncModal, configureOpeningHoursSyncModal());
  }, [successMessage, modalLoading]);

  const showOpeningHoursSyncModal = () => {
    const id = showModal(configureOpeningHoursSyncModal());
    setOpeningHoursSyncModal(id);
  };

  function getModalContent(): JSX.Element | undefined {
    return (
      <>
        Operating Period sync has been triggered for {operatingPeriodRequestType} request{' '}
        {modalLoading && <Loader className="sync-loader" />}
        <>{successMessage && <Alert message={successMessage} onClose={() => 'Closed'} />}</>
      </>
    );
  }

  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const appContext = useContext(AppContext);
  const configureOpeningHoursSyncModal = (): ModalConfig => {
    return {
      size: ModalSize.Medium,
      content: getModalContent(),
      primaryButtonKind: ButtonKind.Primary,
      primaryButtonAction: () => 'Close',
      primaryButtonDisabled: modalLoading,
      ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT'],
      primaryButtonLabel: 'Close',
      title: `Operating Period ${operatingPeriodRequestType} Sync`,
      onCloseModal: () => {
        if (successMessage.actionStatus !== AlertMessageStates.FAILED) {
          dispatch(
            onClickSearch(
              searchFieldDetails,
              appContext,
              translate,
              RequestType.OPENING_HOURS_SEARCH_REQUEST
            ) as unknown as AnyAction
          );
          dispatch(setLoading(true));
        }
        dispatch(setSuccessMessage(undefined));
      },
    };
  };

  const handlSaveButtonPress = () => {
    const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const invalidDays = checkedStates
      .map((state, index) => {
        if (state && openingHoursFormValues.operatingPeriod[daysOfWeek[index]].length === 0) {
          return daysOfWeek[index];
        }
      })
      .filter(Boolean);

    if (invalidDays.length > 0) {
      alert(`Please uncheck ${invalidDays.join(', ')} or provide values for them.`);
      return;
    }
    showOpeningHoursSyncModal();
    handleOnClickSaveChanges(
      dispatch,
      openingHoursFormValues,
      fetchedDataForOpeningHoursSearchRequest,
      effectiveDateFrom,
      isSyncToOtherPanelsChecked,
      selectedFrameDetailRow
    );
  };

  const getMinDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  const renderDay = (date: Date) => {
    return (
      <div
        style={{
          color: new Date(date) >= getMinDate() ? 'black' : undefined,
        }}
      >
        {date.getDate()}
      </div>
    );
  };

  function handleEffectiveDateChange(e: DateValue) {
    if (e != null) {
      setEffectiveFromDate(e);
      let effectiveDate = '';
      if (e instanceof Date && !isNaN(e.getTime())) {
        effectiveDate = e.toISOString().split('T')[0];
      } else {
        console.error('Invalid date:', e);
      }
      setOpeningHoursFormValues({
        ...openingHoursFormValues,
        effectiveDateFrom: effectiveDate,
      });
    } else {
      // handle the clear action
      setEffectiveFromDate(null);
      setOpeningHoursFormValues({
        ...openingHoursFormValues,
        effectiveDateFrom: '',
      });
    }
  }

  const isSaveChangesButtonDisabled = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      const nonBlockingBaseOperatingPeriods = fetchedDataForOpeningHoursSearchRequest.find((data) =>
        data.frameIdentifiers.panelIds.includes(selectedFrameDetailRow.panelId)
      )?.nonBlockingOperatingPeriods;

      const isOperatingPeriodChanged =
        JSON.stringify(openingHoursFormValues.operatingPeriod) !== JSON.stringify(selectedDayPartData.operatingPeriod);
      const isEffectiveDateFromChanged = !effectiveDateFrom
        ? false
        : getFormattedDate(new Date(selectedDayPartData.startDate)) !== getFormattedDate(effectiveDateFrom);

      const isChangesDetected =
        nonBlockingBaseOperatingPeriods && nonBlockingBaseOperatingPeriods.length === 1
          ? isOperatingPeriodChanged && isEffectiveDateFromChanged
          : isOperatingPeriodChanged || isEffectiveDateFromChanged;

      return !isChangesDetected;
    }
  };

  const isEffectiveFromFieldDisabled = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const nonBlockingBaseOperatingPeriods = fetchedDataForOpeningHoursSearchRequest[0].nonBlockingOperatingPeriods.map(
          (op) => op.operatingPeriodType === OperatingPeriodType.BASE
        );

        if (nonBlockingBaseOperatingPeriods?.length === 1 && isViewButtonClicked) {
          return true;
        }

        if (nonBlockingBaseOperatingPeriods && nonBlockingBaseOperatingPeriods.length > 1) {
          if (
            nonBlockingBaseOperatingPeriods &&
            nonBlockingBaseOperatingPeriods.length > 1 &&
            selectedDayPartData.endDate === opInfiniteDate
          ) {
            return false;
          }
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const getTooltipText = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        const nonBlockingBaseOperatingPeriods =
          fetchedDataForOpeningHoursSearchRequest[0].nonBlockingOperatingPeriods.filter(
            (op) => op.operatingPeriodType === OperatingPeriodType.BASE
          );

        const startDateOfFutureOP = nonBlockingBaseOperatingPeriods?.find(
          (op) => op.dateAndTimeRanges.endDate === opInfiniteDate
        )?.dateAndTimeRanges.startDate as string;

        if (nonBlockingBaseOperatingPeriods) {
          if (nonBlockingBaseOperatingPeriods.length > 1 && selectedDayPartData.endDate !== opInfiniteDate) {
            return `As there is a future Operating Period with Start Date ${formatDateToMMMDDYYYY(
              startDateOfFutureOP
            )}, changes to Effective Date are allowed only to that Operating Period`;
          }
          if (
            effectiveDateFrom &&
            nonBlockingBaseOperatingPeriods.length > 1 &&
            getFormattedDate(new Date(selectedDayPartData.startDate)) !== getFormattedDate(effectiveDateFrom)
          ) {
            return `On performing "Save Changes" action a new Operating Period with Start Date ${formatDateToMMMDDYYYY(
              effectiveDateFrom.toISOString()
            )} will be updated. This action will also update the End Date of the current Operating Period`;
          }

          if (nonBlockingBaseOperatingPeriods.length === 1 && effectiveDateFrom) {
            return `On performing "Save Changes" action a new Operating Period with Start Date ${formatDateToMMMDDYYYY(
              effectiveDateFrom.toISOString()
            )} will be created. This action will also update the End Date of the current Operating Period`;
          }
        }
      }
    }
  };

  const getCheckBoxLabel = () => {
    const isPanelDoubleSided =
      fetchedDataForOpeningHoursSearchRequest && fetchedDataForOpeningHoursSearchRequest.length === 2;
    const panelText = isPanelDoubleSided ? 'Panel' : 'Panels';
    return `Sync Changes to sibling ${panelText} as well`;
  };

  return (
    <>
      <div className="date-calendar-container">
        {shouldHideEffectiveFromField && (
          <div className="effective-from-date-calander">
            <DatePickerInput
              data-testid="date-picker-input"
              className="mantine-date-picker-input"
              minDate={getMinDate()}
              label={translate('openingHoursSIdebarTab:effectiveFrom')}
              renderDay={renderDay}
              clearable
              disabled={isEffectiveFromFieldDisabled()}
              placeholder={'Select Effective From Date'}
              value={effectiveDateFrom}
              onChange={(e) => handleEffectiveDateChange(e)}
              mx="auto"
              required={
                fetchedDataForOpeningHoursSearchRequest &&
                !areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest) &&
                fetchedDataForOpeningHoursSearchRequest[0].nonBlockingOperatingPeriods.length > 1
              }
            />
          </div>
        )}
        {getTooltipText() && (
          <div className="effective-date-warning">
            {shouldHideEffectiveFromField && (
              <Tippy
                appendTo={document.body}
                maxWidth="200rem"
                placement="top"
                interactive
                arrow={true}
                delay={200}
                content={
                  <div className="google-maps-content" style={{ width: '40rem', padding: '1rem', lineHeight: '2rem' }}>
                    <Icon fontSize="1rem" iconName="information" />
                    <span style={{ marginTop: '0.2rem', fontSize: '1.5rem' }}>{getTooltipText()}</span>
                  </div>
                }
              >
                <div className="start-date-info-icon">
                  <Icon fontSize="1rem" color="orange" iconName="information" />
                </div>
              </Tippy>
            )}
          </div>
        )}
        {shouldHideEffectiveFromField && areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest) && (
          <div className="sibling-panels-sync-checkbox">
            <Checkbox
              checked={isSyncToOtherPanelsChecked}
              label={getCheckBoxLabel()}
              onChange={() => setIsSyncToOtherPanelsChecked(!isSyncToOtherPanelsChecked)}
              disabled={isSaveChangesButtonDisabled()}
            />
          </div>
        )}
      </div>

      <Button
        renderIcon="save"
        kind="primary"
        size="small"
        disabled={
          isSaveChangesButtonDisabled()
          // 	||
          //   _.isEqual(openingHoursFormValues, selectedDayPartData)
        }
        value={openingHoursFormValues.effectiveDateFrom}
        label={translate('openingHoursSIdebarTab:saveChanges')}
        onClick={() => handlSaveButtonPress()}
      />
      <Button
        renderIcon="filter--reset"
        kind="secondary"
        size="small"
        label={translate('openingHoursSIdebarTab:discardChanges')}
        disabled={_.isEqual(openingHoursFormValues, selectedDayPartData)}
        onClick={() => {
          if (!selectedDayPartData.startDate && !selectedDayPartData.endDate) {
            setDateValue([null, null]);
          }
          handleReset();
        }}
      />
    </>
  );
};

export default OpeningHoursSyncModal;
