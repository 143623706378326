import { Action } from 'src/types/searchReducer';
import { Options } from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tabsComponent/tocPocDetailsTab/renderInputFields/renderTocInputFields/renderTocInputFields';
import {
  AUTO_REFRESH_CHECKED,
  BULK_SEARCH_FORM_VALUES,
  BULK_UPDATE_DETAILS_TABLE_RESPONSE_DATA,
  IS_BULK_RECORDS_DOWNLOADING,
  SET_ADMINISTRATION_BRANCHES,
  SET_BATCH_ID_FOR_BULK_DETAIL_TAB,
  SET_IS_RESET_CLICKED,
  SET_TAB_START_INDEX,
  SET_TOC_REASONS_MAPPING,
} from 'src/redux/reducers/bulkOOCSearchSidebarTabReducer/bulkOOCSearchSidebarTabReducer';
import { BulkOOCSearchRequestBody, BulkUpdateDetailParams, BulkUpdateDetailsResponseData } from 'src/types/apiModels';
import { API_ENDPOINTS, api } from 'src/api/apiConfig';

export const setTocReasonsMapping = (tocReasonsMapping: Options[]): Action<Options[]> => ({
  type: SET_TOC_REASONS_MAPPING,
  payload: tocReasonsMapping,
});

export const setAdministrationBranches = (administrationBranches: Options[]): Action<Options[]> => ({
  type: SET_ADMINISTRATION_BRANCHES,
  payload: administrationBranches,
});

export const setIsResetClicked = (isResetClicked: boolean): Action<boolean> => ({
  type: SET_IS_RESET_CLICKED,
  payload: isResetClicked,
});

export const setTabStartIndex = (tabStartIndex: number): Action<number> => ({
  type: SET_TAB_START_INDEX,
  payload: tabStartIndex,
});

export const setBulkUpdateDetailsTableResponseData = (
  bulkUpdateDetailsResponseData: BulkUpdateDetailsResponseData
): Action<BulkUpdateDetailsResponseData> => ({
  type: BULK_UPDATE_DETAILS_TABLE_RESPONSE_DATA,
  payload: bulkUpdateDetailsResponseData,
});

export const setBatchIdForBulkDetailTab = (batchIdForBulkDetailTab: string): Action<string> => ({
  type: SET_BATCH_ID_FOR_BULK_DETAIL_TAB,
  payload: batchIdForBulkDetailTab,
});

export const setAutoRefreshSetChecked = (autoRefreshChecked: boolean): Action<boolean> => ({
  type: AUTO_REFRESH_CHECKED,
  payload: autoRefreshChecked,
});

export const setBulkTOCSearchFormValues = (
  bulkTOCSearchFormValues: BulkOOCSearchRequestBody
): Action<BulkOOCSearchRequestBody> => ({
  type: BULK_SEARCH_FORM_VALUES,
  payload: bulkTOCSearchFormValues,
});

export const setIsBulkRecordsDownloading = (isBulkRecordsDownloading: boolean): Action<boolean> => ({
  type: IS_BULK_RECORDS_DOWNLOADING,
  payload: isBulkRecordsDownloading,
});

export const onClickBulkUpdateDetailsTab = async (batchId: string): Promise<BulkUpdateDetailsResponseData> => {
  const params: BulkUpdateDetailParams = {
    batchUUID: batchId,
  };
  const response = await api.get(API_ENDPOINTS.TOC_BULK_UPDATE_DETAIL, {
    params: params,
  });
  return response.data;
};
