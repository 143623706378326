import BulkTocSearchSidebarTab from 'src/pages/bulkOOCSearchSidebarTab/bulkOOCSearchSidebarTab';
import TocPocTab from '../../pages/tocPocTabSidebarTab/tocPocSidebarTab';
import AuditViewTab from 'src/pages/auditViewSidebarTab/auditViewSidebarTab';
import { AuditViewSidebarTabProps } from 'src/types/componentProps';
import FrameSearchSidebarTab from 'src/pages/frameSearchSidebarTab/frameSearchSidebarTab';
import { RequestType, WebsiteUrls } from 'src/enums/enum';
import OpeningHoursTab, { SelectedFrameDetailRow } from 'src/pages/openingHoursSidebarTab/openingHoursSidebarTab';

export type SidebarTabLabels = {
  tocPoc: string;
  auditView: string;
  openingHours: string;
  bulkTocSearch: string;
  frameSearch: string;
};

export type SidebarTabProp = {
  changeDetailsIndex?: string;
  searchRequestType?: RequestType;
  setSelectedFrameDetailRow?: React.Dispatch<React.SetStateAction<SelectedFrameDetailRow>>;
  selectedFrameDetailRow?: SelectedFrameDetailRow;
  setShouldOpeningHoursDetailedSectionBeEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type SidebarTabType = {
  label: string;
  component: React.FC<SidebarTabProp> | React.FC<AuditViewSidebarTabProps>;
};

export const getSideBarTabs = (sidebarTabLabels: SidebarTabLabels): SidebarTabType[] => {
  if (window.location.href === WebsiteUrls.PROD) {
    return [
      {
        label: sidebarTabLabels.tocPoc,
        component: TocPocTab,
      },
      {
        label: sidebarTabLabels.bulkTocSearch,
        component: BulkTocSearchSidebarTab,
      },
      {
        label: sidebarTabLabels.auditView,
        component: AuditViewTab,
      },
      {
        label: sidebarTabLabels.frameSearch,
        component: FrameSearchSidebarTab,
      },
    ];
  } else {
    return [
      {
        label: sidebarTabLabels.tocPoc,
        component: TocPocTab,
      },
      {
        label: sidebarTabLabels.bulkTocSearch,
        component: BulkTocSearchSidebarTab,
      },
      {
        label: sidebarTabLabels.auditView,
        component: AuditViewTab,
      },
      {
        label: sidebarTabLabels.openingHours,
        component: OpeningHoursTab,
      },
      {
        label: sidebarTabLabels.frameSearch,
        component: FrameSearchSidebarTab,
      },
    ];
  }
};
