import React, { FC } from 'react';
import CustomDatePickerInput from 'src/components/CustomDatePickerInput/customDatePickerInput';
import { Select, InputText } from '@clearchannelinternational/ecooh-system';
import { useSelector } from 'react-redux';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { RenderTocInputFieldsProps } from 'src/types/componentProps';
import { useTranslation } from 'react-i18next';
import { BaseTOCFields } from 'src/types/apiModels';
import { InputFieldName } from 'src/enums/enum';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';

export type Options = {
  label: string;
  value: string;
};

export const tocReasonMappings: Options[] = [
  { label: 'Obstructed', value: 'R1' },
  { label: 'Built - Not Commissioned', value: 'R8' },
  { label: 'Removed', value: 'R2' },
  { label: 'Damaged', value: 'R3' },
  { label: 'Unsafe', value: 'R4' },
  { label: 'No Power', value: 'R5' },
  { label: 'Other', value: 'R6' },
  { label: 'Non Existent', value: 'R7' },
];

const RenderTocInputFields: FC<RenderTocInputFieldsProps> = ({
  modalInputFieldsConfig,
  existingTocPoc,
  searchFieldDetails,
  isPOCFocused,
  isTOCFocused,
  formValues,
  fetchedData,
  handleInputChange,
}) => {
  const { t } = useTranslation();
  const { isEditClicked, isUserInViewMode } = useSelector(
    (state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer
  );
  const { tocReasonsMapping } = useSelector((state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer);

  return (
    <div data-testid="render-toc-input-fields">
      {isTOCFocused && (
        <div className="display-unit-details">
          {modalInputFieldsConfig.map((field, index) => {
            const { name, label, type, value, isDisabled } = field;

            if (name === InputFieldName.TOC_REASON_CODE) {
              return (
                <div className="cc-ds-select" key={index}>
                  {((existingTocPoc && (existingTocPoc as BaseTOCFields).tocReasonCode) ||
                    searchFieldDetails.value !== '') && (
                    <Select
                      testId="select-toc-reason-field"
                      isRequired={true}
                      isDisabled={!(isPOCFocused || isTOCFocused) || (!isEditClicked && isUserInViewMode)}
                      defaultValue={
                        (existingTocPoc as BaseTOCFields).tocReasonText
                          ? {
                              value: (existingTocPoc as BaseTOCFields).tocReasonText,
                              label: (existingTocPoc as BaseTOCFields).tocReasonText,
                            }
                          : undefined
                      }
                      label={label}
                      name={name}
                      onChange={(e) => handleInputChange(name, e)}
                      options={tocReasonsMapping}
                      // menuIsOpen={true}
                    />
                  )}
                </div>
              );
            }

            if (name === InputFieldName.START_DATE_END_DATE) {
              return (
                <CustomDatePickerInput
                  key={index}
                  config={field}
                  formValues={formValues}
                  fetchedData={fetchedData}
                  handleInputChange={handleInputChange}
                />
              );
            }

            return (
              <InputText
                key={index}
                name={name}
                label={label}
                type={type}
                placeholder={`${t('createNewTocPocModal:enter')} your ${label} here`}
                hasTouched={true}
                hasFullWidth={name === InputFieldName.COMMENT}
                isDisabled={!(isPOCFocused || isTOCFocused) || isDisabled || (!isEditClicked && isUserInViewMode)}
                hasAsterisk={field.hasAsterisk}
                value={existingTocPoc ? (existingTocPoc as BaseTOCFields).comment : value}
                onChange={(e) => handleInputChange(name, e.value)}
                testId="input-text-comment-field"
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RenderTocInputFields;
