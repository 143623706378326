/* eslint-disable camelcase */
import {
  getBulkTOCSearchInputFieldsListToBeRendered,
  getInputFieldsListToBeRenderedWithDisplayUnitId,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { RequestType, SearchFields } from 'src/enums/enum';
import { Translate } from 'src/types/TocPocModal';
import { AppContextType } from 'src/contexts/appContext';
import { TFunction } from 'i18next';
import { FetchNextPageOptions } from '@tanstack/react-query';

export const MAX_INPUT_LENGTH = 2;
export const DEBOUNCE_TIME = 1000;

type InputType = 'text' | 'number' | 'date';
export type SearchBoxFieldType = {
  label: string;
  inputType: InputType;
  isDateRangeField?: boolean;
  countryCode: string[];
  accessorKey: SearchFields;
};

export const getInputFieldsList = (countryCode: string, translate: Translate): SearchBoxFieldType[] => {
  const sitekeyField: SearchBoxFieldType = {
    label: translate('table:siteKey'),
    inputType: 'text',
    countryCode: ['UK'],
    accessorKey: SearchFields.SITE_KEY,
  };
  const panelIdField: SearchBoxFieldType = {
    label: 'Panel ID',
    inputType: 'text',
    countryCode: ['UK'],
    accessorKey: SearchFields.PANEL_ID,
  };
  const digitalUnitIdField: SearchBoxFieldType = {
    label: 'Digital Unit ID',
    inputType: 'text',
    countryCode: ['UK'],
    accessorKey: SearchFields.DIGITAL_UNIT_ID,
  };
  const broadSignFrameId: SearchBoxFieldType = {
    label: 'Broadsign Frame ID',
    inputType: 'text',
    countryCode: ['UK'],
    accessorKey: SearchFields.BROADSIGN_CONTROL_FRAME_ID,
  };
  const commercialFrameIdField: SearchBoxFieldType = {
    label: translate('table:commercialFrameId'),
    inputType: 'text',
    countryCode: ['UK'],
    accessorKey: SearchFields.COMMERCIAL_FRAME_ID,
  };
  const slotIdField: SearchBoxFieldType = {
    label: translate('table:slotId'),
    inputType: 'text',
    countryCode: ['FR'],
    accessorKey: SearchFields.SLOT_ID,
  };

  const inputFieldsList: SearchBoxFieldType[] = [
    panelIdField,
    digitalUnitIdField,
    commercialFrameIdField,
    sitekeyField,
    slotIdField,
    broadSignFrameId,
  ];
  return inputFieldsList;
};

export const getBulkTOCSearchPageFieldsList = (countryCode: string, translate: Translate): SearchBoxFieldType[] => {
  const startDate: SearchBoxFieldType = {
    label: translate('table:tocStartDate'),
    inputType: 'date',
    countryCode: ['UK', 'FR'],
    accessorKey: SearchFields.OOC_START_DATE,
  };
  const endDate: SearchBoxFieldType = {
    label: translate('table:tocEndDate'),
    inputType: 'date',
    countryCode: ['UK', 'FR'],
    accessorKey: SearchFields.OOC_END_DATE,
  };

  const inputFieldsList: SearchBoxFieldType[] = [startDate, endDate];
  return inputFieldsList;
};

export const getUserAndActionFields = (countryCode: string, translate: Translate): SearchBoxFieldType[] => {
  const userField: SearchBoxFieldType = {
    label: translate('table:user'),
    inputType: 'text',
    countryCode: ['UK'],
    accessorKey: SearchFields.USER,
  };
  const actionDateField: SearchBoxFieldType = {
    label: translate('table:actionDate'),
    inputType: 'date',
    countryCode: ['UK'],
    accessorKey: SearchFields.ACTION_DATE,
  };
  const inputFieldsList: SearchBoxFieldType[] = [userField, actionDateField];
  return inputFieldsList;
};

export const getAllInputFieldsToBeRedered = (appContext: AppContextType, t: TFunction<'translation', undefined>) => {
  return [
    ...getBulkTOCSearchInputFieldsListToBeRendered(appContext, t),
    ...getUserAndActionFields(appContext.Country, t),
    ...getInputFieldsListToBeRenderedWithDisplayUnitId(appContext.Country, t),
  ];
};

export type SearchFieldDetails = {
  label: string;
  value: string | number | Date;
  accessorKey: SearchFields;
};

export type InputFieldType = {
  label: string;
  inputType: InputType;
  isDateRangeField?: boolean;
  searchFieldDetails: SearchFieldDetails;
  setSearchFieldDetails: (details: SearchFieldDetails) => void;
  isSearchField?: boolean;
  requestType: RequestType;
  // fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<any>;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<any>;
  isFetching?: boolean;
  selectedField?: string;
};
