import React, { useContext, useEffect, useState } from 'react';
import RenderInputFields from './renderInputFields/renderInputFields';
import DisplayUnitDetailsAccordion from './displayUnitDetailsAccordion/displayUnitDetailsAccordion';
import OutOfChargeTypePicker from './outOfChargeTypePicker/outOfChargeTypePicker';
import SearchBox from 'src/components/searchBox/searchBox';
import SyncStatusWarningBanner from 'src/components/syncStatusWarningBanner/syncStatusWarningBanner';
import ExistingTocPocTable from './existingTocPocTable/existingTocPocTable';
import { H5, Loading } from '@clearchannelinternational/ecooh-system';
import { getEditableInputTocFieldDetails, getExistingTocsPoc, TocPocDetail } from './tocPocDetailsTabConfig';
import { InputFieldName, OutOfChargeStatus, RequestType, TOCPOCCurrentStatus } from 'src/enums/enum';
import { SearchReducer, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppContext } from 'src/contexts/appContext';
import { TocPocDetailsTabProps } from '../../../../../types/componentProps';
import { getInputFieldsList } from 'src/components/searchBox/inputFieldsConfig';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { transformTOCPOCData } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { BaseTOCFields, FetchedTOCPOCData } from 'src/types/apiModels';
import { TocPocFormData } from 'src/types/TocPocModal';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { hasFailedSyncStatus } from 'src/pages/tocPocTabSidebarTab/createTocPocModal/createTocPocModal';
import { SaveReducerState } from 'src/types/saveReducer';
import { LoadingReducer } from 'src/types/loadinReducer';
import { getFormattedDate } from 'src/utils/dateUtils';

const TocPocDetailsTab: React.FC<TocPocDetailsTabProps> = ({ setFormData, fetchedData, updateModal }) => {
  const {
    isEditClicked: isFormInEditMode,
    isUserInViewMode,
    existingTocPoc,
  } = useSelector((state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer);
  const appContext = useContext(AppContext);
  const initialFormValues = { ...initialExistingTocPoc, outOfChargeStatus: OutOfChargeStatus.TOC };
  const [formValues, setFormValues] = useState<TocPocFormData>(initialFormValues);
  const [isPOCFocused, setIsPOCFocused] = useState(existingTocPoc.outOfChargeStatus === OutOfChargeStatus.POC);
  const { successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { modalLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { t } = useTranslation();
  const [currentTocPocDetails, setCurrentTocPocDetails] = useState<TocPocDetail[]>(
    getEditableInputTocFieldDetails(false, initialExistingTocPoc)
  );
  const { fetchedDataForTocPocTableDetails, fetchedDataForCreateTocPocModal } = useSelector(
    (state: SearchReducer) => state.searchReducer
  );
  const [isTOCFocused, setIsTOCFocused] = useState(
    existingTocPoc.outOfChargeStatus === OutOfChargeStatus.POC ? false : true
  );
  const externalIds = fetchedDataForCreateTocPocModal ? fetchedDataForCreateTocPocModal.frameIdentifiers.panelIds : [];

  useEffect(() => {
    if (fetchedDataForCreateTocPocModal) {
      const formDataWithPanelIds = { ...formValues };
      formDataWithPanelIds.panelIds = fetchedDataForCreateTocPocModal.frameIdentifiers.panelIds;

      setFormValues(formDataWithPanelIds);
    }
  }, [fetchedDataForCreateTocPocModal]);

  useEffect(() => {
    if (fetchedData || fetchedDataForCreateTocPocModal) {
      setCurrentTocPocDetails(getEditableInputTocFieldDetails(isFormInEditMode, existingTocPoc));
    }
  }, [fetchedData, fetchedDataForCreateTocPocModal]);

  useEffect(() => {
    setFormData((prevFormValues) => {
      const filteredFormValues = {};

      for (const key in formValues) {
        const value = formValues[key];

        if (Array.isArray(value) && value.length === 0) continue;
        if (typeof value === 'string' && value.trim() === '') continue;
        if (typeof value === 'undefined') continue;

        filteredFormValues[key] = value;
      }
      return {
        ...prevFormValues,
        ...filteredFormValues,
      };
    });
  }, [formValues]);

  const handleInputChange = (name: string, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ...(name === InputFieldName.START_DATE_END_DATE
        ? {
            startDate: value[0],
            endDate: getFormattedDate(value[1]),
          }
        : { [name]: value }),
    }));
  };

  const isUserIsViewOrEditMode = () => {
    return !isUserInViewMode && !isFormInEditMode;
  };

  const getInputFieldsListToBeRendered = () => {
    const countryCode = appContext.Country;
    return getInputFieldsList(countryCode, t).filter(
      (field) => field.countryCode.includes(countryCode) && field.inputType != 'date'
    );
  };

  const getFilteredExistingTocsOrPoc = (
    tocPocData: TOCPOCDetailsTableData,
    formValues: TocPocFormData,
    existingTocPoc: TOCPOCDetailsTableData
  ) => {
    return (
      tocPocData.tocPocCurrentStatus !== TOCPOCCurrentStatus.Expired &&
      tocPocData.isOperative &&
      tocPocData.outOfChargeStatus === formValues.outOfChargeStatus &&
      tocPocData.startDate !== existingTocPoc.startDate &&
      (tocPocData as BaseTOCFields).endDate !== (existingTocPoc as BaseTOCFields).endDate
    );
  };

  const existingTocsOrPoc =
    fetchedData &&
    fetchedData
      .map((tocPocData: FetchedTOCPOCData) => transformTOCPOCData(tocPocData))
      .filter((tocPocData) => {
        return getFilteredExistingTocsOrPoc(tocPocData, formValues, existingTocPoc);
      });

  const shouldDisplaySyncWarnMessage = () => {
    const isExpiredOrDeleted = [TOCPOCCurrentStatus.Expired, TOCPOCCurrentStatus.Deleted].includes(
      existingTocPoc.tocPocCurrentStatus
    );
    const hasFailedSync =
      fetchedDataForCreateTocPocModal && hasFailedSyncStatus(getExistingTocsPoc(fetchedDataForCreateTocPocModal));
    const shouldDisplayWarnMessageForCreateRequest =
      !isExpiredOrDeleted && hasFailedSync && existingTocPoc.outOfChargeStatus === OutOfChargeStatus.DEFAULT;
    const shouldDisplayWarnMessageForUpdateRequest =
      existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.DEFAULT && isFormInEditMode && hasFailedSync;

    return shouldDisplayWarnMessageForCreateRequest || shouldDisplayWarnMessageForUpdateRequest;
  };

  function getPanelType() {
    if (externalIds.length === 2) {
      return t('createNewTocPocModal:doubleSided');
    } else if (externalIds.length === 1) {
      return t('createNewTocPocModal:singleSided');
    } else if (externalIds.length > 2) {
      return t('createNewTocPocModal:multiSided');
    }
  }

  function shouldDidplayExistingOOCSection() {
    return (
      fetchedDataForCreateTocPocModal &&
      existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.POC &&
      existingTocPoc.tocPocCurrentStatus !== TOCPOCCurrentStatus.Expired &&
      existingTocPoc.tocPocCurrentStatus != TOCPOCCurrentStatus.Deleted &&
      existingTocsOrPoc &&
      existingTocsOrPoc.length !== 0 &&
      !successMessage
    );
  }

  function getRequestType(existingTocPoc: TOCPOCDetailsTableData): RequestType {
    return existingTocPoc.outOfChargeStatus === OutOfChargeStatus.DEFAULT
      ? RequestType.CREATE_REQUEST
      : RequestType.UPDATE_REQUEST;
  }

  return (
    <div className="ooc-detail-tab">
      {shouldDisplaySyncWarnMessage() && <SyncStatusWarningBanner requestType={getRequestType(existingTocPoc)} />}
      <div className={`toc-poc-details ${shouldDisplaySyncWarnMessage() ? 'disabled' : ''}`}>
        <OutOfChargeTypePicker
          isUserIsViewOrEditMode={isUserIsViewOrEditMode}
          isPOCFocused={isPOCFocused}
          setIsTOCFocused={setIsTOCFocused}
          setIsPOCFocused={setIsPOCFocused}
          handleInputChange={handleInputChange}
          isTOCFocused={isTOCFocused}
          formValues={formValues}
        />
        {checkIfModalIsInEditMode() && (
          <div className={`createTocPoc-search-container ${shouldSearchBarBeDisabled() ? '' : 'disabled'}`}>
            <SearchBox
              searchRequestType={RequestType.CREATE_TOC_POC_MODAL_SEARCH_REQUEST}
              inputFieldsListToBeRendered={getInputFieldsListToBeRendered()}
            />
          </div>
        )}
        {shouldDidplayExistingOOCSection() && (
          <ExistingTocPocTable formValues={formValues} setFormValues={setFormValues} existingTocsOrPoc={existingTocsOrPoc} />
        )}
        <div className={`details ${shouldMainModalContentBeDisabled() ? '' : 'disabled'}`}>
          <div className="double-sided-panel-checkbox">
            <H5>{t('createNewTocPocModal:typeOfPanel')}:</H5>
            {shouldMainModalContentBeDisabled() && <span className="panel-type">{getPanelType()}</span>}
          </div>
          <div className="display-unit-details-conatiner">
            <DisplayUnitDetailsAccordion externalIds={externalIds} fetchedData={fetchedData} />
          </div>

          {shouldDisplayEditableFields() ? (
            <Loading />
          ) : (
            <div className="edit-tocPoc-details-container">
              <RenderInputFields
                modalInputFieldsConfig={currentTocPocDetails}
                searchFieldDetails={searchFieldDetails}
                isPOCFocused={isPOCFocused}
                isTOCFocused={isTOCFocused}
                formValues={formValues}
                fetchedData={fetchedData}
                handleInputChange={handleInputChange}
                externalIds={externalIds}
                updateModal={updateModal}
                setFormValues={setFormValues}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function shouldDisplayEditableFields() {
    return (
      modalLoading ||
      (!fetchedDataForCreateTocPocModal &&
        searchFieldDetails.value &&
        existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.DEFAULT)
    );
  }

  function shouldMainModalContentBeDisabled() {
    return (fetchedData && fetchedData.length > 0) || fetchedDataForCreateTocPocModal;
  }

  function shouldSearchBarBeDisabled() {
    return isPOCFocused || isTOCFocused || fetchedDataForTocPocTableDetails;
  }

  function checkIfModalIsInEditMode() {
    return existingTocPoc.outOfChargeStatus === OutOfChargeStatus.DEFAULT && !isFormInEditMode;
  }
};

export default TocPocDetailsTab;
