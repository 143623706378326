import React, { useContext, useEffect, useState } from 'react';
import Alert from 'src/components/alert/alert';
import Tippy from '@tippyjs/react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonKind, ModalSize, RequestType, SyncStatusStates } from 'src/enums/enum';
import { OpeningHoursResyncModalProps } from 'src/types/componentProps';
import { SearchReducer } from 'src/types/searchReducer';
import { Button, useModal, Icon } from '@clearchannelinternational/ecooh-system';
import { Loader } from '@mantine/core';
import { SaveReducerState } from 'src/types/saveReducer';
import { setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { setLoading, setModalLoading } from 'src/redux/actions/loadingActions';
import { LoadingReducer } from 'src/types/loadinReducer';
import { ModalConfig } from 'src/types/TocPocModal';
import { onClickReSyncButtonForOperatingPeriod } from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tocPocActions/resyncModal/resyncActions';
import { FrameScheduleDetails, FrameScheduleSearchResponse } from 'src/types/apiModels';
import { onClickSearch } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { AppContext } from 'src/contexts/appContext';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';

const OpeningHoursResyncModal: React.FC<OpeningHoursResyncModalProps> = ({ row, tableData, selectedFrameDetailRow }) => {
  const dispatch = useDispatch();
  const { showModal, updateModal } = useModal();
  const { successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { searchFieldDetails, fetchedDataForOpeningHoursSearchRequest } = useSelector(
    (state: SearchReducer) => state.searchReducer
  );
  const { modalLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const [resyncModal, setResyncModal] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const { t: translate } = useTranslation();

  useEffect(() => {
    return updateModal(resyncModal, configureResyncModal());
  }, [successMessage, modalLoading]);

  const createResyncModal = () => {
    const id = showModal(configureResyncModal());
    setResyncModal(id);
  };

  function getResyncModalContent(): JSX.Element | undefined {
    return (
      <>
        Operating Period sync has been triggered successfully {modalLoading && <Loader className="sync-loader" />}
        <>{successMessage && <Alert message={successMessage} onClose={() => 'Closed'} />}</>
      </>
    );
  }

  const configureResyncModal = (): ModalConfig => {
    return {
      size: ModalSize.Medium,
      content: getResyncModalContent(),
      primaryButtonKind: ButtonKind.Primary,
      primaryButtonAction: () => 'Close',
      primaryButtonDisabled: modalLoading,
      ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT'],
      primaryButtonLabel: 'Close',
      title: `Operating Period resync`,
      onCloseModal: () => {
        dispatch(
          onClickSearch(
            searchFieldDetails,
            appContext,
            translate,
            RequestType.OPENING_HOURS_SEARCH_REQUEST
          ) as unknown as AnyAction
        );
        dispatch(setLoading(true));
        dispatch(setSuccessMessage(undefined));
      },
    };
  };

  const getToolTipText = () => {
    const tableRowData = tableData[row.row.id];
    const oasisSyncStatus = tableRowData.oasisSystemSyncStatus;
    const broadSignSyncStatus = tableRowData.broadsignSystemSyncStatus;

    return oasisSyncStatus === SyncStatusStates.FAILURE && broadSignSyncStatus === SyncStatusStates.FAILURE
      ? 'Click to sync with BroadSign and Oasis'
      : broadSignSyncStatus === SyncStatusStates.FAILURE
      ? 'Click to sync with BroadSign'
      : oasisSyncStatus === SyncStatusStates.FAILURE || oasisSyncStatus === SyncStatusStates.EMAIL_FAILED
      ? 'Click to sync with Oasis'
      : undefined;
  };

  function isResyncButtonDisabled() {
    const broadsignSyncStatus = row.row.original.broadsignSystemSyncStatus;
    const oasisSyncStatus = row.row.original.oasisSystemSyncStatus;

    return !(broadsignSyncStatus === SyncStatusStates.FAILED || oasisSyncStatus === SyncStatusStates.EMAIL_FAILED);
  }

  function onClickResyncButton() {
    dispatch(setModalLoading(true));
    createResyncModal();

    const selectedOHData = fetchedDataForOpeningHoursSearchRequest.find((data) =>
      data.frameIdentifiers.panelIds.includes(selectedFrameDetailRow.panelId)
    ) as FrameScheduleDetails;
    onClickReSyncButtonForOperatingPeriod(tableData, row, dispatch, searchFieldDetails, selectedOHData);
  }

  function getResyncTooltipContent(): React.ReactNode {
    return (
      <div className="google-maps-content">
        <Icon fontSize="1rem" iconName="information" />
        <div className="street-view-tooltip-text">{getToolTipText()}</div>
      </div>
    );
  }

  return (
    <>
      <Tippy
        appendTo={document.body}
        maxWidth="200rem"
        placement="top"
        interactive
        arrow={true}
        disabled={isResyncButtonDisabled()}
        content={getResyncTooltipContent()}
      >
        <div>
          <Button
            hasIconOnly
            label="Button"
            renderIcon={'update-now'}
            size="small"
            kind="tertiary"
            renderIconSize="small"
            onClick={() => onClickResyncButton()}
            disabled={isResyncButtonDisabled()}
          />
        </div>
      </Tippy>
    </>
  );
};

export default OpeningHoursResyncModal;
