/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_TableInstance,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import {
  setBatchIdForBulkDetailTab,
  setBulkUpdateDetailsTableResponseData,
} from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import {
  getFormattedDateToBeDisplayed,
  DATE_TIME_INPUT_FORMAT,
  DATE_TIME_OUTPUT_FORMAT_WITHOUT_SECONDS,
} from 'src/utils/dateUtils';
import BulkUpdateChangeDetails from './bulkUpdateChangeDetails/bulkUpdateChangeDetails';
import { BulkUpdateRequest, BulkUpdateSummariesResponseData } from 'src/types/apiModels';
import Tippy from '@tippyjs/react';
import { BulkUpdatebatchStatus, OOC_API_PAGE_LIMITS } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { Icon, Button, H3 } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '@clearchannelinternational/ecooh-system';
import { LoadingReducer } from 'src/types/loadinReducer';
import { initialUpdateDetailsResponseData } from 'src/redux/reducers/bulkOOCSearchSidebarTabReducer/bulkOOCSearchSidebarTabReducer';
import { actionsAccessorKey } from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tocPocDetailsTableConfig';

export type BulkUpdateSumarryTableType = {
  batchDescription: string;
  submittedDate: string;
  batchStatus: BulkUpdatebatchStatus;
  submittedBy: string;
  numberOfTocsSubmitted: number;
  numberOfTocsProcessed: number;
  numberOfTocsSuccessfullyUpdated: number;
  numberOfTocsFailedToUpdate: number;
  changeDetails: BulkUpdateRequest;
  batchId: string;
  batchNumber: number;
  executedDate: string;
};

export type BulkUpdateSummariesTabProps = {
  bulkSummariesResponseData: BulkUpdateSummariesResponseData[];
  handleBulkUpdateSummariesTabClick: (offset: number, limit: number, isSummariesTabClicked: boolean) => void;
  bulkUpdateSummariesTableRowCount: number;
  isBulkUpdateSummariesTabClicked: boolean;
  enabledpagination: boolean;
  onClickBulkUpdateSummary: (batchId: string) => void;
};

const BulkUpdateSummariesTab: React.FC<BulkUpdateSummariesTabProps> = ({
  bulkSummariesResponseData,
  handleBulkUpdateSummariesTabClick,
  bulkUpdateSummariesTableRowCount,
  isBulkUpdateSummariesTabClicked,
  enabledpagination,
  onClickBulkUpdateSummary,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: OOC_API_PAGE_LIMITS.BULK_UPDATE_SUMMARIES_TABLE_LIMIT,
  });

  const { loading, bulkUpdateSummarriesPageLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);

  const bulkUpdateSummariesColumns = (): MRT_ColumnDef<BulkUpdateSumarryTableType>[] => {
    return [
      {
        accessorKey: 'batchNumber',
        header: translate('bulkOOCSearchSidebarTab:batchNumber'),
        size: 5,
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      },
      {
        accessorKey: 'batchDescription',
        header: translate('bulkOOCSearchSidebarTab:batchDescription'),
        Cell: (cell) => {
          const batchDescription = cell.row.original.batchDescription;
          return batchDescription;
        },
        size: 100,
      },
      {
        accessorKey: 'batchStatus',
        header: translate('bulkOOCSearchSidebarTab:batchStatus'),
        size: 100,
      },
      {
        accessorKey: 'updatedDetails',
        header: translate('bulkOOCSearchSidebarTab:updatedDetails'),
        Cell: (cell) => {
          const bulkUpdateChangeDetails = cell.row.original.changeDetails;
          return (
            <>
              <Tippy
                appendTo={document.body}
                maxWidth="200rem"
                placement="left"
                content={<BulkUpdateChangeDetails bulkUpdateDetails={bulkUpdateChangeDetails} />}
                interactive
              >
                <div className="audit-change-details-icon">
                  <Icon iconName="chat" />
                </div>
              </Tippy>
            </>
          );
        },
        size: 100,
      },
      {
        accessorKey: 'submittedBy',
        header: translate('bulkOOCSearchSidebarTab:submittedBy'),
        size: 100,
      },
      {
        accessorKey: 'submittedDate',
        header: translate('bulkOOCSearchSidebarTab:submittedDate'),
        size: 100,
        Cell: (cell) => {
          const submittedDate = cell.row.original.submittedDate;
          return getFormattedDateToBeDisplayed(
            submittedDate,
            DATE_TIME_INPUT_FORMAT,
            DATE_TIME_OUTPUT_FORMAT_WITHOUT_SECONDS
          );
        },
      },
      {
        accessorKey: 'executedDate',
        header: translate('bulkOOCSearchSidebarTab:executedDate'),
        size: 100,
        Cell: (cell) => {
          const executedDate = cell.row.original.executedDate;
          return getFormattedDateToBeDisplayed(
            executedDate,
            DATE_TIME_INPUT_FORMAT,
            DATE_TIME_OUTPUT_FORMAT_WITHOUT_SECONDS
          );
        },
      },
      {
        accessorKey: 'numberOfTocsSubmitted',
        header: translate('bulkOOCSearchSidebarTab:numberOfTocsSubmitted'),
        size: 100,
      },
      {
        accessorKey: 'numberOfTocsProcessed',
        header: translate('bulkOOCSearchSidebarTab:numberOfTocsProcessed'),
        size: 100,
      },
      {
        accessorKey: 'numberOfTocsSuccessfullyUpdated',
        header: translate('bulkOOCSearchSidebarTab:numberOfTocsSuccessfullyUpdated'),
        size: 100,
      },
      {
        accessorKey: 'numberOfTocsFailedToUpdate',
        header: translate('bulkOOCSearchSidebarTab:numberOfTocsFailedToUpdate'),
        size: 100,
      },
      {
        accessorKey: 'actions',
        header: translate('bulkOOCSearchSidebarTab:actions'),
        Cell: (cell) => {
          const batchId = cell.row.original.batchId;
          return (
            <Button
              hasIconOnly
              label="Button"
              renderIcon="view"
              size="small"
              kind="tertiary"
              renderIconSize="secondary"
              tooltip="View update details"
              tooltipPlacement="right"
              onClick={() => enabledpagination && onClickBulkUpdateSummary(batchId)}
            />
          );
        },
        size: 200,
      },
    ];
  };

  useEffect(() => {
    if (!isBulkUpdateSummariesTabClicked) {
      handleBulkUpdateSummariesTabClick(pagination.pageIndex, pagination.pageSize, false);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    dispatch(setBatchIdForBulkDetailTab(''));
    if (enabledpagination) {
      dispatch(setBulkUpdateDetailsTableResponseData(initialUpdateDetailsResponseData));
    }
    // dispatch(setAutoRefreshSetChecked(false));
  }, []);

  const bulkUpdateSummariesTableData = (): BulkUpdateSumarryTableType[] => {
    return bulkSummariesResponseData.map((row) => ({
      batchDescription: row.submitDetails.batchName,
      submittedDate: row.submitDetails.submittedTime,
      batchStatus: row.batchStatus,
      submittedBy: `${row.submitDetails.userDetails.lastName}, ${row.submitDetails.userDetails.firstName}` || '',
      numberOfTocsSubmitted: row.processDetails.processCountDetails.numberOfTOCsSubmitted,
      numberOfTocsProcessed: row.processDetails.processCountDetails.numberOfTOCsProcessed,
      changeDetails: row.submitDetails.bulkUpdateRequest,
      batchId: (row as any).batchUUID,
      batchNumber: row.batchNumber,
      numberOfTocsSuccessfullyUpdated: row.processDetails.processCountDetails.numberOfTOCsSuccessfullyUpdated,
      numberOfTocsFailedToUpdate: row.processDetails.processCountDetails.numberOfTOCSFailedUpdate,
      executedDate: row.processDetails.batchProcessDetails.processEndTime,
    }));
  };

  const tableInstance = useMantineReactTable({
    columns: bulkUpdateSummariesColumns(),
    data: bulkUpdateSummariesTableData(),
    enableTopToolbar: false,
    manualPagination: true,
    enableFilters: false,
    enableSorting: false,
    enableBottomToolbar: enabledpagination,
    onPaginationChange: setPagination,
    rowCount: bulkUpdateSummariesTableRowCount,
    state: {
      showProgressBars: bulkUpdateSummarriesPageLoading,
      pagination,
    },
    initialState: {
      columnVisibility: {
        actions: enabledpagination,
      },
      columnPinning: {
        right: [actionsAccessorKey],
      },
    },
  }) as MRT_TableInstance<BulkUpdateSumarryTableType>;

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="table-container">
          {!enabledpagination && (
            <div className="summary-text">
              <H3>Summary</H3>
            </div>
          )}
          <MantineReactTable table={tableInstance} />
        </div>
      )}
    </>
  );
};

export default BulkUpdateSummariesTab;
