import './i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/app/App';
import { ModalProvider, SnackbarContainer } from '@clearchannelinternational/ecooh-system';
import { AppContextProvider } from './contexts/appContext';
import { Provider } from 'react-redux';
import store from './redux/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

export const queryClient = new QueryClient();

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <div className="theme--blue-light">
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <AppContextProvider>
                <SnackbarContainer />
                <ModalProvider root='[class^="theme--"]'>
                  <App />
                </ModalProvider>
              </AppContextProvider>
            </Provider>
          </QueryClientProvider>
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
}
