import React, { useEffect } from 'react';
import {
  onClickDelete,
  setDeleteModal,
  setExistingTocPoc,
  setIsEditClicked,
} from '../../../../../redux/actions/tocPocSidebarTabActions/componentActions';
import Alert from 'src/components/alert/alert';
import { setIsSaveClicked, setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { SearchReducer, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { Button, useModal } from '@clearchannelinternational/ecooh-system';
import { ButtonKind, ModalSize, OutOfChargeStatus, RequestType, TOCPOCCurrentStatus } from 'src/enums/enum';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { SaveReducerState } from 'src/types/saveReducer';
import { useSelector, useDispatch } from 'react-redux';
import { ViewAndDeleteModalProps } from 'src/types/componentProps';
import { AnyAction } from 'redux';
import { BaseTOCFields } from 'src/types/apiModels';
import { useTranslation } from 'react-i18next';
import { hasFailedSyncStatus } from 'src/pages/tocPocTabSidebarTab/createTocPocModal/createTocPocModal';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import SyncStatusWarningBanner from 'src/components/syncStatusWarningBanner/syncStatusWarningBanner';
import { ModalConfig } from 'src/types/TocPocModal';
import { getFormattedDateToBeDisplayed, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED } from 'src/utils/dateUtils';

const DeleteModal: React.FC<ViewAndDeleteModalProps> = ({ row, handleOnClickSearch }) => {
  const dispatch = useDispatch();
  useTranslation();
  const startDate = row.row.original.startDate;
  const isStartDateLessThanToday = new Date(startDate) < new Date();
  const { showModal, updateModal } = useModal();
  const { tableData, searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { isSaveClicked, successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { deleteModal, existingTocPoc } = useSelector(
    (state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer
  );

  useEffect(() => {
    return updateModal(deleteModal, configureDeleteTocPocModal(existingTocPoc));
  }, [successMessage, existingTocPoc]);

  useEffect(() => {
    const modalConfig: ModalConfig = {
      primaryButtonLabel: isSaveClicked ? 'Deleting...' : 'Yes',
      primaryButtonDisabled: true,
      secondaryButtonLabel: 'Close',
      ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT'],
    };
    return updateModal(deleteModal, modalConfig);
  }, [isSaveClicked]);

  const createDeleteModal = (existingTocPoc: TOCPOCDetailsTableData) => {
    dispatch(setExistingTocPoc(existingTocPoc));
    dispatch(setSuccessMessage(undefined));
    const id = showModal(configureDeleteTocPocModal(existingTocPoc));

    dispatch(setDeleteModal(id));
  };

  const handleOnClickDelete = (existingTocPoc: TOCPOCDetailsTableData) => {
    dispatch(onClickDelete(existingTocPoc.id) as unknown as AnyAction);
  };

  const handlePrimaryButtonAction = () => {
    dispatch(setSuccessMessage(undefined));
    dispatch(setIsSaveClicked(true));
    handleOnClickDelete(existingTocPoc);
  };

  const getTableDataWithoutDeletedOrExpiredOOCs = () => {
    return tableData.filter(
      (data) =>
        data.tocPocCurrentStatus !== TOCPOCCurrentStatus.Expired && data.tocPocCurrentStatus !== TOCPOCCurrentStatus.Deleted
    );
  };

  const configureDeleteTocPocModal = (existingTocPoc: TOCPOCDetailsTableData): ModalConfig => {
    return {
      size: ModalSize.Medium,
      content: hasFailedSyncStatus(getTableDataWithoutDeletedOrExpiredOOCs()) ? (
        <SyncStatusWarningBanner requestType={RequestType.DELETE_REQUEST} />
      ) : (
        deleteModalContent(existingTocPoc)
      ),
      primaryButtonKind: ButtonKind.Primary,
      primaryButtonAction: !hasFailedSyncStatus(getTableDataWithoutDeletedOrExpiredOOCs())
        ? handlePrimaryButtonAction
        : undefined,
      primaryButtonDisabled: hasFailedSyncStatus(getTableDataWithoutDeletedOrExpiredOOCs()),
      ignoreCloseType: existingTocPoc.outOfChargeStatus === OutOfChargeStatus.TOC ? ['PRIMARY_BUTTON'] : [],
      primaryButtonLabel: isSaveClicked ? 'Saving...' : 'Yes',
      secondaryButtonLabel: hasFailedSyncStatus(getTableDataWithoutDeletedOrExpiredOOCs()) ? 'Close' : 'No',
      title: 'Delete TOC',
      onCloseModal: () => {
        handleOnClickSearchOnUpdate();
        dispatch(setSuccessMessage(undefined));
        dispatch(setIsEditClicked(false));
        dispatch(setExistingTocPoc(initialExistingTocPoc));
      },
    };
  };

  function deleteModalContent(existingTocPoc: TOCPOCDetailsTableData) {
    const { startDate, endDate } = existingTocPoc as BaseTOCFields;
    return (
      <>
        Do you wish to remove the TOC starting from{' '}
        <b>{getFormattedDateToBeDisplayed(startDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED)}</b> until{' '}
        <b>{getFormattedDateToBeDisplayed(endDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED)}</b>?
        {successMessage && <Alert message={successMessage} onClose={() => 'Closed'} />}
      </>
    );
  }

  const handleOnClickSearchOnUpdate = () => {
    if (successMessage) {
      handleOnClickSearch(searchFieldDetails, RequestType.TOC_POC_SEARCH_REQUEST);
    }
  };

  const isActonDisabled = () => {
    return (
      isStartDateLessThanToday ||
      tableData[row.row.id].outOfChargeStatus === OutOfChargeStatus.POC ||
      !tableData[row.row.id].isOperative
    );
  };

  return (
    <>
      <Button
        hasIconOnly
        label="Button"
        renderIcon="delete"
        size="small"
        kind="tertiary"
        renderIconSize="secondary"
        disabled={isActonDisabled()}
        onClick={() => {
          createDeleteModal(tableData[row.row.id]);
        }}
      />
    </>
  );
};

export default DeleteModal;
