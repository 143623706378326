import React from 'react';
import { Link } from 'react-router-dom';
import { getGoogleMapsURL } from 'src/api/apiConfig';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { P, Icon } from '@clearchannelinternational/ecooh-system';
import Tippy from '@tippyjs/react';

export type SteetViewProps = {
  latitude: number | null;
  longitude: number | null;
};

const StreetView: React.FC<SteetViewProps> = ({ latitude, longitude }) => {
  const isLongitudeOrLatitudeNull = !latitude || !longitude;

  return (
    <div style={{ zIndex: 99999 }} data-testid="street-view-component">
      <Link
        to={getGoogleMapsURL(latitude, longitude)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => {
          if (isLongitudeOrLatitudeNull) {
            event.preventDefault();
          }
        }}
      >
        <Tippy
          appendTo={document.body}
          maxWidth="200rem"
          placement="top"
          interactive
          arrow={true}
          content={
            <div className="google-maps-content">
              <Icon fontSize="1rem" iconName="information" />
              <div className="street-view-tooltip-text">
                {isLongitudeOrLatitudeNull ? 'Geo coordinates not available for panel' : 'View on Google Maps'}
              </div>
            </div>
          }
        >
          <img
            src="/googleMaps.png"
            alt="img"
            className={`${isLongitudeOrLatitudeNull ? 'google-maps-icon-disabled' : 'google-maps-icon'}`}
          />
        </Tippy>
      </Link>
    </div>
  );
};

export default StreetView;
