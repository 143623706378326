import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  getBulkTOCSearchPageFieldsAccessorKey,
  getInputFieldAccessorKey,
  onClickSearch,
  setSearchFieldDetails,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import DebouncedInput, { shouldResetValueAndSearchDetails } from 'src/components/debouncedInput/debouncedInput';
import { DatePickerInput } from '@mantine/dates';
import { InputFieldType, SearchFieldDetails } from '../searchBox/inputFieldsConfig';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from 'src/contexts/appContext';
import { useTranslation } from 'react-i18next';
import { SearchReducer } from 'src/types/searchReducer';
import { initialSearchFieldDetails } from 'src/redux/reducers/tocPocSidebarTabReducers/searchReducer';
import { rem } from '@mantine/core';
import { AuditSidebarTabReducer } from 'src/types/auditSidebarTabReducer';
import { RequestType } from 'src/enums/enum';
import { queryClient } from '../..';
import { AnyAction } from 'redux';
import { LoadingReducer } from 'src/types/loadinReducer';
import { fetchNextPageOptions } from 'src/redux/actions/auditSidebarTabAction/auditSidebarTabAction';
import { getFormattedDate } from 'src/utils/dateUtils';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';

export const renderDay = (date: Date) => {
  const formattedDate = getFormattedDate(date);
  const currentDate = getFormattedDate(new Date());

  if (formattedDate === currentDate) {
    return <div className="current-date-highlight">{date.getDate()}</div>;
  }

  return <div className="date-input">{date.getDate()}</div>;
};

const SearchField: FunctionComponent<InputFieldType> = (inputField) => {
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { t: translate } = useTranslation();
  const { loading, auditTableLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { isSearchForAllRecordsButtonClicked } = useSelector(
    (state: AuditSidebarTabReducer) => state.auditSidebarTabReducer
  );
  const { isResetClicked } = useSelector((state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer);

  const setSearchField = (label: string, value: string | number) => {
    dispatch(
      setSearchFieldDetails({
        label,
        value,
        accessorKey: getBulkTOCSearchPageFieldsAccessorKey(appContext, label, translate),
      })
    );
  };

  const handleDateSearch = (formattedDate: string, searchRequestType: RequestType) => {
    if (searchRequestType === RequestType.AUDIT_SEARCH_REQUEST) {
      handleAuditSearchRequest();
    } else {
      handleOnClickSearch(formattedDate, searchRequestType);
    }
  };

  const handleSearch = (formattedDate: string, searchRequestType: RequestType) => {
    if (formattedDate && searchRequestType !== RequestType.OOC_BULK_SEARCH_REQUEST) {
      handleDateSearch(formattedDate, searchRequestType);
      setSearchField(inputField.label, dateValue ? formattedDate : '');
    }
    if (searchRequestType === RequestType.OOC_BULK_SEARCH_REQUEST) {
      setSearchField(inputField.label, dateValue ? formattedDate : '');
    }
  };

  useEffect(() => {
    if (isResetClicked) {
      setDateValue(null);
    }
  }, [isResetClicked]);

  useEffect(() => {
    const formattedDate = getFormattedDate(dateValue);
    const searchRequestType = inputField.requestType;

    handleSearch(formattedDate, searchRequestType);
  }, [dateValue]);

  useEffect(() => {
    if (dateValue != null && shouldResetValueAndSearchDetails(inputField.selectedField, searchFieldDetails)) {
      setDateValue(null);
      dispatch(setSearchFieldDetails(initialSearchFieldDetails));
    }
  }, [inputField.selectedField]);

  useEffect(() => {
    const digitalUnitIdLabel = translate('table:digitalUnitId');
    if (isSearchForAllRecordsButtonClicked) {
      setDateValue(null);
      dispatch(
        setSearchFieldDetails({
          ...initialSearchFieldDetails,
          label: digitalUnitIdLabel,
          accessorKey: getInputFieldAccessorKey(appContext, digitalUnitIdLabel, translate),
        })
      );
    }
  }, [isSearchForAllRecordsButtonClicked]);

  async function handleAuditSearchRequest() {
    queryClient.clear();
    setTimeout(async () => {
      if (inputField.fetchNextPage) {
        await inputField.fetchNextPage({
          ...fetchNextPageOptions,
        });
      }
    });
  }

  const handleOnClickSearch = (searchValue: string | number | Date, searchRequestType: RequestType) => {
    const label = inputField.label;
    const searchFieldDetails: SearchFieldDetails = {
      label: label,
      value: searchValue,
      accessorKey: getInputFieldAccessorKey(appContext, label, translate),
    };
    dispatch(onClickSearch(searchFieldDetails, appContext, translate, searchRequestType) as unknown as AnyAction);
  };

  return (
    <>
      <div className="search-form-field" data-testid="search-field">
        {inputField.inputType === 'date' ? (
          <>
            <DatePickerInput
              data-testid="search-field-date-picker-input"
              rightSectionWidth={rem(85)}
              placeholder={`Select ${inputField.label}`}
              renderDay={renderDay}
              value={dateValue}
              disabled={loading || auditTableLoading}
              onChange={(newValue: Date) => setDateValue(newValue)}
              clearable={inputField.requestType === RequestType.OOC_BULK_SEARCH_REQUEST}
            />
          </>
        ) : (
          <DebouncedInput {...inputField} />
        )}
      </div>
    </>
  );
};

export default SearchField;
