import React, { useState } from 'react';
import FrameDetailsTable from './frameDetailsTable/frameDetailsTable';
import { setViewTocPocModal } from 'src/redux/actions/tocPocSidebarTabActions/componentActions';
import { ButtonKind, ModalSize } from 'src/enums/enum';
import { Button, useModal, Tabs } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { ModalConfig } from 'src/types/TocPocModal';
import { renderDigitalUnitWithStreetView } from '../../tocPocDetailsTableConfig';
import { SearchReducer } from 'src/types/searchReducer';
import { FrameSearchDetailsModalProps } from 'src/types/componentProps';
import { setShowTableform } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';

const FrameSearchDetailsModal: React.FC<FrameSearchDetailsModalProps> = ({ row }) => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { fetchedDataForFrameSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);

  const configureViewTocPocModal = (): ModalConfig => {
    return {
      size: ModalSize.MediumFullHeight,
      content: renderViewTocPocModalContent(),
      primaryButtonKind: ButtonKind.Primary,
      primaryButtonLabel: 'Close',
      title: `Frame details`,
      onCloseModal: () => {
        dispatch(setShowTableform(false));
      },
    };
  };

  const getTabs = () => {
    return fetchedDataForFrameSearchRequest?.data.map((frame) => {
      const commercialFrameId = `${frame.externalSystemIdentifiers.space.commercialFrameId}`;
      return {
        label: commercialFrameId,
        content: <FrameDetailsTable frameDetails={frame} />,
      };
    });
  };

  const renderViewTocPocModalContent = () => {
    return <Tabs items={getTabs()} startIndex={row.id} hasBottomBorder isOptionFullWidth={false} />;
  };

  const createViewModal = () => {
    const id = showModal(configureViewTocPocModal());
    dispatch(setViewTocPocModal(id));
  };

  return (
    <>
      <Button
        hasIconOnly
        label="Button"
        renderIcon="view"
        size="small"
        kind="tertiary"
        renderIconSize="secondary"
        onClick={() => {
          createViewModal();
        }}
      />
    </>
  );
};

export default FrameSearchDetailsModal;
