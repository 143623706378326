import { AnyAction } from 'redux';
import { SearchFieldDetails } from 'src/components/searchBox/inputFieldsConfig';
import { SearchFields } from 'src/enums/enum';
import { SearchReducerState } from 'src/types/searchReducer';

export const SET_SEARCH_FIELD_DETAILS = 'SET_SEARCH_FIELD_DETAILS';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_SYNC_HISTORY_TABLE_DATA = 'SET_SYNC_HISTORY_TABLE_DATA';
export const SET_FETCHED_DATA_FOR_CREATE_TOC_POC_MODAL = 'SET_FETCHED_DATA_FOR_CREATE_TOC_POC_MODAL';
export const SET_FETCHED_DATA_FOR_TOC_POC_TABLE_DETAILS = 'SET_FETCHED_DATA_FOR_TOC_POC_TABLE_DETAILS';
export const SET_TOTAL_RECORDS_FOR_OOC_SEARCH_TABLE = 'SET_TOTAL_RECORDS_FOR_OOC_SEARCH_TABLE';
export const SET_FETCHED_DATA_FOR_FRAME_SEARCH_REQUEST = 'SET_FETCHED_DATA_FOR_FRAME_SEARCH_REQUEST';
export const SET_FETCHED_DATA_FOR_OPENING_HOURS_SEARCH_REQUEST = 'SET_FETCHED_DATA_FOR_OPENING_HOURS_SEARCH_REQUEST';
export const SET_SHOW_TABLE_FORM = 'SET_SHOW_TABLE_FORM';

export const initialSearchFieldDetails: SearchFieldDetails = {
  label: '',
  value: '',
  accessorKey: SearchFields.DEFAULT,
};

const initialState: SearchReducerState = {
  searchFieldDetails: initialSearchFieldDetails,
  tableData: [],
  syncHistoryTableData: [],
  fetchedDataForCreateTocPocModal: undefined,
  fetchedDataForTocPocTableDetails: undefined,
  totalRecordsForOOCSearchTable: 0,
  fetchedDataForFrameSearchRequest: undefined,
  fetchedDataForOpeningHoursSearchRequest: [],
  showTableForm: false,
};

const searchReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SEARCH_FIELD_DETAILS:
      return { ...state, searchFieldDetails: action.payload };
    case SET_TABLE_DATA:
      return { ...state, tableData: action.payload };
    case SET_SYNC_HISTORY_TABLE_DATA:
      return { ...state, syncHistoryTableData: action.payload };
    case SET_FETCHED_DATA_FOR_CREATE_TOC_POC_MODAL:
      return { ...state, fetchedDataForCreateTocPocModal: action.payload };
    case SET_FETCHED_DATA_FOR_TOC_POC_TABLE_DETAILS:
      return { ...state, fetchedDataForTocPocTableDetails: action.payload };
    case SET_TOTAL_RECORDS_FOR_OOC_SEARCH_TABLE:
      return { ...state, totalRecordsForOOCSearchTable: action.payload };
    case SET_FETCHED_DATA_FOR_FRAME_SEARCH_REQUEST:
      return { ...state, fetchedDataForFrameSearchRequest: action.payload };
    case SET_FETCHED_DATA_FOR_OPENING_HOURS_SEARCH_REQUEST:
      return { ...state, fetchedDataForOpeningHoursSearchRequest: action.payload };
    case SET_SHOW_TABLE_FORM:
      return { ...state, showTableForm: action.payload };
    default:
      return state;
  }
};

export default searchReducer;
