/* eslint-disable camelcase */
import React from 'react';
import TocPocTab from '../tocPocTabSidebarTab/tocPocSidebarTab';
import { SidebarTabProp } from '../../components/sidebar/sidebarConfig';
import { RequestType } from 'src/enums/enum';

const FrameSearchSidebarTab: React.FC<SidebarTabProp> = () => {
  return <TocPocTab searchRequestType={RequestType.FRAME_SEARCH_REQUEST} />;
};

export default FrameSearchSidebarTab;
