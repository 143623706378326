import React, { useEffect } from 'react';
import Alert from 'src/components/alert/alert';
import BulkTOCUpdateModalDetails from './bulkTOCUpdateModalDetails/bulkTOCUpdateModalDetails';
import SyncStatusWarningBanner from 'src/components/syncStatusWarningBanner/syncStatusWarningBanner';
import { useState } from 'react';
import { Button, useModal, snackbar } from '@clearchannelinternational/ecooh-system';
import { AlertMessageStates, ButtonKind, ModalSize, RequestType, TOCPOCCurrentStatus } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { ModalConfig } from 'src/types/TocPocModal';
import { BulkTOCUpdateModalProps } from 'src/types/componentProps';
import { useDispatch, useSelector } from 'react-redux';
import { SaveReducerState } from 'src/types/saveReducer';
import { setIsSaveClicked, setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { setTabStartIndex } from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import { TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { hasFailedSyncStatus } from 'src/pages/tocPocTabSidebarTab/createTocPocModal/createTocPocModal';
import { isReadWriteRole } from 'src/utils/roleUtils';
import { AuthTokensReducer } from 'src/types/componentReducers';

const BulkTOCUpdateModal: React.FC<BulkTOCUpdateModalProps> = ({ bulkOOCTableInstance, onClickBulkUpdateSummary }) => {
  const { t: translate } = useTranslation();
  const { showModal, updateModal } = useModal();
  const [bulkTOCUpdateModal, setBulkTOCUpdateModal] = useState<boolean>(false);
  const selectedRows = bulkOOCTableInstance.getSelectedRowModel().rows;
  const { successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { userRole } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsSaveClicked(false));
    return updateModal(bulkTOCUpdateModal, configureBulkTOCUpdateModal());
  }, [successMessage]);

  const createBulkTOCUpdateModal = () => {
    const id = showModal(configureBulkTOCUpdateModal());
    setBulkTOCUpdateModal(id);
  };

  const getTableData = () => {
    return bulkOOCTableInstance.getSelectedRowModel().rows.map((row) => row.original);
  };

  const configureBulkTOCUpdateModal = (): ModalConfig => {
    return {
      size: ModalSize.MediumFullHeight,
      content: (
        <>
          {hasFailedSyncStatus(getTableData()) && (
            <SyncStatusWarningBanner requestType={RequestType.OOC_BULK_SEARCH_REQUEST} />
          )}

          <div className={`toc-poc-details ${hasFailedSyncStatus(getTableData()) ? 'disabled' : ''}`}>
            <BulkTOCUpdateModalDetails
              bulkOOCTableInstance={bulkOOCTableInstance}
              onClickBulkUpdateSummary={onClickBulkUpdateSummary}
            />
          </div>
          <div className="bulk-update-modal-success-message">
            {successMessage && <Alert message={successMessage} onClose={() => 'Closed'} />}
          </div>
        </>
      ),
      ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT'],
      primaryButtonLabel: 'Close',
      primaryButtonKind: ButtonKind.Secondary,
      title: `Bulk update ${selectedRows.length} frames`,
      onCloseModal: () => {
        if (successMessage && successMessage.actionStatus === AlertMessageStates.SUCCESS) {
          dispatch(setTabStartIndex(2));
          // dispatch(setBatchIdForBulkDetailTab())
        }
        dispatch(setSuccessMessage(undefined));
      },
    };
  };

  function hasExpiredOOC(existingTocsPoc: TOCPOCDetailsTableData[]) {
    return existingTocsPoc?.some((item) => item.tocPocCurrentStatus === TOCPOCCurrentStatus.Expired);
  }

  return (
    <>
      {isReadWriteRole(userRole) && (
        <Button
          renderIcon="edit"
          kind="primary"
          size="small"
          disabled={selectedRows.length === 0}
          label={translate('bulkOOCSearchSidebarTab:bulkUpdate')}
          // disabled={selectedRows.length === 0}
          onClick={() => {
            if (hasExpiredOOC(getTableData())) {
              snackbar.info('Update cannot proceed as some of the selected TOCs are expired.', {
                title: 'Expired TOCs!',
              });
              return;
            }
            createBulkTOCUpdateModal();
          }}
        />
      )}
    </>
  );
};

export default BulkTOCUpdateModal;
