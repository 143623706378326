import React, { useContext, useEffect } from 'react';
import Alert from 'src/components/alert/alert';
import TabsComponent from '../../tabsComponent/tabsComponent';
import {
  setExistingTocPoc,
  setIsEditClicked,
  setIsUserInViewMode,
  setTabStartIndex,
  setViewTocPocModal,
} from 'src/redux/actions/tocPocSidebarTabActions/componentActions';
import {
  onClickSearch,
  setFetchedDataForCreateTocPocModal,
  setSearchFieldDetails,
  setSyncHistoryTableData,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { onClickSave, setIsSaveClicked, setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { SearchReducer, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { ButtonKind, ModalSize, OutOfChargeStatus, RequestType, SearchFields, TOCPOCCurrentStatus } from 'src/enums/enum';
import { Button, useModal } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { SaveReducerState } from 'src/types/saveReducer';
import { AuthTokensReducer, TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { AnyAction } from 'redux';
import { ViewAndDeleteModalProps } from 'src/types/componentProps';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { ModalConfig, TocPocFormData } from 'src/types/TocPocModal';
import { BaseTOCFields } from 'src/types/apiModels';
import { useTranslation } from 'react-i18next';
import { setAuditTableData } from 'src/redux/actions/auditSidebarTabAction/auditSidebarTabAction';
import { queryClient } from '../../../../../../src';
import { LoadingReducer } from 'src/types/loadinReducer';
import { getFormattedDate } from 'src/utils/dateUtils';
import { AppContext } from 'src/contexts/appContext';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { isReadWriteRole } from 'src/utils/roleUtils';

const ViewModal: React.FC<ViewAndDeleteModalProps> = ({
  row,
  handleOnClickSearch,
  searchRequestType,
  bulkUpdateDetailsTableData,
  tableData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const { showModal, updateModal } = useModal();
  const { fetchedDataForCreateTocPocModal, searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { modalLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { successMessage, isSaveClicked } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { isEditClicked, isUserInViewMode, viewTocPocModal, existingTocPoc, tabStartIndex } = useSelector(
    (state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer
  );
  const [editTocPocFormData, setEditTocPocFormData] = React.useState<TocPocFormData>(initialExistingTocPoc);
  const { bulkTOCSearchFormValues } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );
  const { userRole } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);

  const closeModalConfig: ModalConfig = {
    primaryButtonLabel: isSaveClicked ? 'Saving...' : 'Close',
    primaryButtonDisabled: isSaveClicked ? true : false,
    ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT'],
  };

  useEffect(() => {
    if (existingTocPoc && Object.keys(existingTocPoc).length > 0) {
      updateModal(viewTocPocModal, configureViewTocPocModal(existingTocPoc));
    }
  }, [existingTocPoc, isUserInViewMode, successMessage, tabStartIndex, fetchedDataForCreateTocPocModal, modalLoading]);

  useEffect(() => {
    updateModal(viewTocPocModal, closeModalConfig);
  }, [isSaveClicked]);

  useEffect(() => {
    if (tabStartIndex !== 0) {
      updateModal(viewTocPocModal, closeModalConfig);
    }
  }, [tabStartIndex]);

  useEffect(() => {
    if (!existingTocPoc.isOperative) {
      updateModal(viewTocPocModal, closeModalConfig);
    }
  }, [existingTocPoc]);

  useEffect(() => {
    if (isEditClicked && editTocPocFormData && Object.keys(editTocPocFormData).length > 0) {
      updateModal(viewTocPocModal, saveModalConfig);
    }
  }, [isEditClicked, editTocPocFormData]);

  useEffect(() => {
    if (bulkUpdateDetailsTableData) {
      updateModal(viewTocPocModal, closeModalConfig);
    }
  }, [bulkUpdateDetailsTableData]);

  const isButtonDisabled = (editTocPocFormData: TocPocFormData) => {
    const existingTocData = { ...(existingTocPoc as BaseTOCFields) };
    const tocFormData = { ...(editTocPocFormData as BaseTOCFields) };

    return (
      (!tocFormData.startDate ||
        getFormattedDate(new Date(existingTocData.startDate)) === getFormattedDate(new Date(tocFormData.startDate))) &&
      (!tocFormData.endDate ||
        getFormattedDate(new Date(existingTocData.endDate)) === getFormattedDate(new Date(tocFormData.endDate))) &&
      (!tocFormData.tocReasonCode || existingTocData.tocReasonCode === tocFormData.tocReasonCode) &&
      (!tocFormData.comment || existingTocData.comment === tocFormData.comment)
    );
  };

  const saveModalConfig: ModalConfig = {
    primaryButtonLabel: !successMessage ? 'Save' : 'Close',
    primaryButtonDisabled: isButtonDisabled(editTocPocFormData),
    ignoreCloseType: successMessage ? [] : ['PRIMARY_BUTTON'],
    primaryButtonAction: !successMessage ? handlSaveAction() : undefined,
  };

  const getUpdateTOCFormDate = (): TOCPOCDetailsTableData => {
    const baseTOCFields = editTocPocFormData as BaseTOCFields;
    const existingBaseTOCFields = existingTocPoc as BaseTOCFields;

    return {
      ...existingTocPoc,
      startDate: baseTOCFields.startDate || existingBaseTOCFields.startDate,
      endDate: baseTOCFields.endDate || existingBaseTOCFields.endDate,
      tocReasonCode: baseTOCFields.tocReasonCode || existingBaseTOCFields.tocReasonCode,
      comment: baseTOCFields.comment || existingBaseTOCFields.comment,
      panelIds: existingTocPoc.panelIds,
      id: existingTocPoc.id,
    };
  };

  const handleOnClickSave = (formData: TocPocFormData) => {
    if (fetchedDataForCreateTocPocModal) {
      dispatch(
        onClickSave(
          formData,
          fetchedDataForCreateTocPocModal,
          existingTocPoc,
          RequestType.UPDATE_REQUEST
        ) as unknown as AnyAction
      );
    }
  };

  function handlSaveAction() {
    return () => {
      dispatch(setIsSaveClicked(true));
      const updateFormData = getUpdateTOCFormDate();

      handleOnClickSave(updateFormData);
    };
  }

  function updateEditTocPocFormData(existingTocPoc) {
    const { startDate, endDate, comments, reason, commercialFrameIds, lastUpdatedBy, lastUpdatedDate } = existingTocPoc;

    return {
      ...existingTocPoc,
      startDate,
      endDate,
      comments,
      reason,
      commercialFrameIds,
      lastUpdatedBy,
      lastUpdatedDate,
    };
  }

  const handleEditClick = () => {
    const updatedTocPocFormData = updateEditTocPocFormData(existingTocPoc);

    setEditTocPocFormData(updatedTocPocFormData);
    dispatch(setIsEditClicked(true));
    // dispatch(setIsUserInViewMode(false));
  };

  const configureViewTocPocModal = (existingTocPoc: TOCPOCDetailsTableData): ModalConfig => {
    return {
      size: ModalSize.MediumFullHeight,
      content: renderViewTocPocModalContent(),
      primaryButtonKind: ButtonKind.Primary,
      primaryButtonAction: () => (isExpiredOrDeleted() && isReadWriteRole(userRole) ? handleEditClick() : undefined),
      ignoreCloseType: isExpiredOrDeleted() && isReadWriteRole(userRole) ? ['PRIMARY_BUTTON'] : [],
      primaryButtonLabel: getButtonLabel(),
      title: `${existingTocPoc.outOfChargeStatus || 'TOC'} details`,
      onCloseModal: () => {
        handleOnClickSearchOnUpdate();
        resetStateVariables();
      },
    };
  };

  const renderViewTocPocModalContent = () => {
    return (
      <>
        <TabsComponent
          fetchedData={fetchedDataForCreateTocPocModal ? fetchedDataForCreateTocPocModal.existingOOCDetails : []}
          setFormData={setEditTocPocFormData}
          updateModal={updateModal}
        />
        {successMessage && tabStartIndex === 0 && <Alert message={successMessage} onClose={() => 'Closed'} />}
      </>
    );
  };

  const handleOnClickSearchOnUpdate = () => {
    if (successMessage && searchRequestType === RequestType.OOC_BULK_SEARCH_REQUEST) {
      dispatch(
        onClickSearch(
          searchFieldDetails,
          appContext,
          t,
          RequestType.OOC_BULK_SEARCH_REQUEST,
          initialExistingTocPoc,
          bulkTOCSearchFormValues
        ) as unknown as AnyAction
      );
    } else if (successMessage) {
      dispatch(setSearchFieldDetails(searchFieldDetails));
      handleOnClickSearch(searchFieldDetails, RequestType.TOC_POC_SEARCH_REQUEST);
    }
  };

  const resetStateVariables = () => {
    dispatch(setAuditTableData([]));
    dispatch(setExistingTocPoc(initialExistingTocPoc));
    dispatch(setSuccessMessage(undefined));
    dispatch(setTabStartIndex(0));
    dispatch(setIsEditClicked(false));
    dispatch(setIsUserInViewMode(false));
    dispatch(setFetchedDataForCreateTocPocModal(undefined as any));
    dispatch(setSyncHistoryTableData([]));
    queryClient.clear();
  };

  const getButtonLabel = () => {
    if (modalLoading) {
      return '';
    }
    if (isExpiredOrDeleted()) {
      if (isEditClicked) {
        return 'Save';
      } else if (!isReadWriteRole(userRole)) {
        return 'Close';
      } else {
        return 'Edit';
      }
    }

    return 'Close';
  };

  function isExpiredOrDeleted() {
    return (
      existingTocPoc.tocPocCurrentStatus !== TOCPOCCurrentStatus.Deleted &&
      existingTocPoc.tocPocCurrentStatus !== TOCPOCCurrentStatus.Expired &&
      existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.POC
    );
  }

  const createViewModal = (existingTocPoc: TOCPOCDetailsTableData) => {
    dispatch(setExistingTocPoc(existingTocPoc));
    dispatch(setSuccessMessage(undefined));
    const id = showModal(configureViewTocPocModal(existingTocPoc));

    dispatch(setViewTocPocModal(id));
  };
  const startDate = row.row.original.startDate;
  const endDate = row.row.original.endDate;
  const isEndDateGreaterThanToday = new Date(endDate) > new Date();
  const isStartDateLessThanToday = new Date(startDate) < new Date();

  const searchCriteria =
    searchRequestType === RequestType.OOC_BULK_SEARCH_REQUEST
      ? {
          label: t('table:panelId'),
          value: tableData[row.row.id].panelIds[0],
          accessorKey: SearchFields.PANEL_ID,
        }
      : searchFieldDetails;

  return (
    <>
      <Button
        hasIconOnly
        label="Button"
        renderIcon="view"
        size="small"
        kind="tertiary"
        renderIconSize="secondary"
        onClick={() => {
          dispatch(setIsUserInViewMode(true));
          handleOnClickSearch(searchCriteria, RequestType.CREATE_TOC_POC_MODAL_SEARCH_REQUEST);
          createViewModal(tableData[row.row.id]);
        }}
      />
      {isReadWriteRole(userRole) && (
        <>
          {!bulkUpdateDetailsTableData && (
            <Button
              hasIconOnly
              label="Button"
              renderIcon="edit"
              size="small"
              kind="tertiary"
              renderIconSize="secondary"
              onClick={() => {
                handleOnClickSearch(searchCriteria, RequestType.CREATE_TOC_POC_MODAL_SEARCH_REQUEST);
                dispatch(setIsUserInViewMode(true));
                createViewModal(tableData[row.row.id]);
                handleEditClick();
              }}
              disabled={
                (!isEndDateGreaterThanToday && isStartDateLessThanToday) ||
                (!isEndDateGreaterThanToday && !isStartDateLessThanToday) ||
                !tableData[row.row.id].isOperative ||
                tableData[row.row.id].outOfChargeStatus === OutOfChargeStatus.POC
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default ViewModal;
