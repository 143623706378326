import { createContext, useState } from 'react';
import React from 'react';
import { Region } from 'src/enums/enum';

export type Countries = Region.UK | Region.GB;
export type Systems = 'oasis' | 'aida';

export type AppContextType = {
  Country: Countries;
  setCountry: (country: Countries) => void;
  system: Systems;
  setSystem: (system: Systems) => void;
};

const initialContext: AppContextType = {
  Country: Region.UK,
  setCountry: () => {
    console.log('initial');
  },
  system: 'oasis',
  setSystem: () => {
    console.log('initial');
  },
};

export type OasisOrAidaFields = {
  [key: string]: {
    label: string;
    accessorKey: string;
  };
};

export type SystemConfig = {
  [systemName: string]: {
    [countryCode: string]: OasisOrAidaFields;
  };
};

export const systemsConfig: SystemConfig = {
  oasis: {
    UK: {
      commercialFrameId: {
        label: 'Commercial Frame Id',
        accessorKey: 'commercialFrameId',
      },
      siteKey: {
        label: 'Site Key',
        accessorKey: 'siteKey',
      },
      digitalUnitId: {
        label: 'Panel ID',
        accessorKey: 'digitalUnitId',
      },
    },
  },
  aida: {
    FR: {
      slotId: {
        label: 'Slot ID',
        accessorKey: 'slotId',
      },
    },
  },
};

export const AppContext = createContext<AppContextType>(initialContext);

export const AppContextProvider: React.FC<any> = ({ children }) => {
  const [country, setCountry] = useState<Countries>(initialContext.Country);
  const [system, setSystem] = useState<Systems>(initialContext.system);

  const handleSetCountry = (newCountry: Countries) => {
    setCountry(newCountry);
  };

  const handleSetSystem = (system: Systems) => {
    setSystem(system);
  };

  const contextValue: AppContextType = {
    Country: country,
    setCountry: handleSetCountry,
    system: system,
    setSystem: handleSetSystem,
  };

  return (
    <>
      <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
    </>
  );
};
