export enum TOCPOCCurrentStatus {
  Upcoming = 'Upcoming',
  Active = 'Active',
  Expired = 'Expired',
  Deleted = 'Deleted',
  DEFAULT = '',
}

export enum OutOfChargeStatus {
  TOC = 'TOC',
  POC = 'POC',
  DEFAULT = '',
}

export enum System {
  OASIS = 'oasis',
  AIDA = 'aida',
  DEFAULT = '',
}

export enum ExternalSystems {
  BROADSIGN = 'BROADSIGN',
  OASIS = 'OASIS',
}
export enum SyncStatusStates {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILED',
  FAILED = 'FAILED',
  NA = 'NA',
  DEFAULT = '',
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_FAILED = 'EMAIL_FAILED',
  SCHEDULED = 'SCHEDULED',
  UNEXPECTED_FAILURE_FROM_EXTERNAL_SYSTEM = 'UNEXPECTED_FAILURE_FROM_EXTERNAL_SYSTEM',
}
export enum PerformedByUserType {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
  DEFAULT = 'DEFAULT',
}
export enum ErrorFieldType {
  PARAM = 'PARAM',
  REQUEST = 'PARAM',
}

export enum SearchFields {
  PANEL_ID = 'PANEL_ID',
  COMMERCIAL_FRAME_ID = 'COMMERCIAL_FRAME_ID',
  SITE_KEY = 'SITE_KEY',
  OOC_END_DATE = 'OOC_END_DATE',
  OOC_START_DATE = 'OOC_START_DATE',
  ACTION_DATE = 'ACTION_DATE',
  DIGITAL_UNIT_ID = 'DIGITAL_UNIT_ID',
  BROADSIGN_CONTROL_FRAME_ID = 'BROADSIGN_CONTROL_FRAME_ID',
  SLOT_ID = 'SLOT_ID',
  USER = 'USER',
  DISPLAY_UNIT_ID = 'DISPLAY_UNIT_ID',
  DEFAULT = '',
}

export enum RequestType {
  TOC_POC_SEARCH_REQUEST = 'TOC_POC_SEARCH_REQUEST',
  CREATE_TOC_POC_MODAL_SEARCH_REQUEST = 'CREATE_TOC_POC_MODAL_SEARCH_REQUEST',
  AUDIT_SEARCH_REQUEST = 'AUDIT_SEARCH_REQUEST',
  SYNC_HISTORY_REQUEST = 'SYNC_HISTORY_REQUEST',
  OOC_BULK_SEARCH_REQUEST = 'OOC_BULK_SEARCH_REQUEST',
  FRAME_SEARCH_REQUEST = 'FRAME_SEARCH_REQUEST',
  OPENING_HOURS_SEARCH_REQUEST = 'OPENING_HOURS_SEARCH_REQUEST',
  CREATE_REQUEST = 'CREATE_REQUEST',
  UPDATE_REQUEST = 'UPDATE_REQUEST',
  DELETE_REQUEST = 'DELETE_REQUEST',
  RESYNC_REQUEST = 'RESYNC_REQUEST',
  OPERATING_PERIOD_UPDATE_REQUEST = 'OPERATING_PERIOD_UPDATE_REQUEST',
}

export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
export enum OOCAttributes {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  REASON_CODE = 'reasonCode',
  REASON_TEXT = 'reasonText',
  COMMENT = 'comment',
  DISMANTLE_SITE = 'dismantleSite',
  OUT_OF_CHARGE_STATUS = 'outOfChargeStatus',
}

export enum BusinessUnits {
  GB = 'GB',
  UK = 'UK',
}

export enum HttpStatus {
  OK = 200,
  ACCEPTED = 202,
  BAD_REQUEST = 400,
  INTERNAL_SERVER_ERROR = 500,
}

export enum AlertMessageStates {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILURE = 'Failed',
  FAILED = 'Failed',
  DELETED = 'deleted',
}

export enum RequestTypeForSyncHistoryRecord {
  TOC_CREATE = 'Create TOC',
  TOC_UPDATE = 'Update TOC',
  TOC_DELETE = 'Delete TOC',
  POC_CREATE = 'Create POC',
  TOC_CREATE_RESYNC = 'Create TOC resync',
  TOC_UPDATE_RESYNC = 'Update TOC resync',
  TOC_DELETE_RESYNC = 'Delete TOC resync',
  POC_CREATE_RESYNC = 'Create POC resync',
}
export enum AuditEventType {
  TOC_CREATE = 'TOC_CREATE',
  TOC_UPDATE = 'TOC_UPDATE',
  TOC_DELETE = 'TOC_DELETE',
  POC_CREATE = 'POC_CREATE',
}
export enum ModifiedOOCAttributes {
  TOC_REASON = 'TOC_REASON',
  TOC_END_DATE = 'TOC_END_DATE',
  TOC_START_DATE = 'TOC_START_DATE',
  TOC_COMMENT = 'TOC_COMMENT',
  DISMANTLE_SITE = 'DISMANTLE_SITE',
}

export enum OOC_API_PAGE_LIMITS {
  TOC_POC_SEARCH_REQUEST = 200,
  // OOC_BULK_SEARCH_REQUEST = 1000,
  OOC_BULK_SEARCH_REQUEST = 20,
  AUDIT_SEARCH_PAGE_LIMIT = 50,
  SYNC_HISTORY_REQUEST = 50,
  CREATE_TOC_POC_MODAL_SEARCH_REQUEST = 50,
  SUGGEST_API_LIMIT = 15,
  BULK_UPDATE_SUMMARIES_TABLE_LIMIT = 10,
}

export enum ModalSize {
  Fullscreen = 'fullscreen',
  Large = 'large',
  LargeFullHeight = 'largeFullHeight',
  Medium = 'medium',
  MediumFullHeight = 'mediumFullHeight',
  Small = 'small',
  SmallFullHeight = 'smallFullHeight',
  XL = 'xl',
  XLFullHeight = 'xlFullHeight',
}

export enum ButtonKind {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
  Quinary = 'quinary',
  Senary = 'senary',
}

export enum InputType {
  TEXT = 'text',
  TEXT_AREA = 'text-area',
  DATE = 'date',
}

export enum InputFieldName {
  START_DATE_END_DATE = 'startDateEndDate',
  TOC_REASON_CODE = 'tocReasonCode',
  COMMENT = 'comment',
}

export enum Region {
  UK = 'UK',
  GB = 'GB',
}

export enum BulkOOCTOCFields {
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
  TOC_REASONS = 'TOC Reason',
  TOC_COMMENTS = 'Comments',
}

export enum FrameType {
  DIGITAL = 'DIGITAL',
  CLASSIC = 'CLASSIC',
}

export enum FrameTypeForAutoSuggest {
  DIGITAL = 'Digital',
  CLASSIC = 'Classic',
}

export enum OOCDateSearchType {
  EQ = 'EQ',
  LTE = 'LTE',
  GTE = 'GTE',
}

export enum OOCCurrentStatus {
  Expired = '-1',
  Active = '0',
  Upcoming = '1',
}

export enum BulkUpdateValidationErrorType {
  OVERLAPPING = 'Overlapping',
  DEFAULT = '',
}

export enum BulkUpdatebatchStatus {
  SUBMITTED = 'SUBMITTED',
  IN_QUEUE = 'IN-QUEUE',
  IN_PROCESS = 'IN-PROCESS',
  COMPLETED = 'COMPLETED',
}

export enum BulkOOCDownlaodStatus {
  TRIGGERED = 'TRIGGERED',
  COMPLETED = 'COMPLETED',
}

export enum AutoSuggestSearchType {
  PREFIX = 'PREFIX',
  PREFIX_AND_CONTAINS = 'PREFIX_AND_CONTAINS',
}

export enum GeoLevelMapping {
  'location.levels.level1' = 'TV Area',
  'location.levels.level2' = 'Conurbation',
  'location.levels.level3' = 'Province',
  'location.levels.level4' = 'District',
  'location.levels.level5' = 'Town',
}

export enum OperatingPeriodType {
  BASE = 'BASE',
  OVERIDING = 'OVERRIDING',
  DEFAULT = '',
}

export enum DaysToIndexMapping {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum WeekToShortFormMapping {
  SUNDAY = 'Sun',
  MONDAY = 'Mon',
  TUESDAY = 'Tue',
  WEDNESDAY = 'Wed',
  THURSDAY = 'Thu',
  FRIDAY = 'Fri',
  SATURDAY = 'Sat',
}

export enum OperatingPeriodRequestType {
  CREATE = 'Create',
  UPDATE = 'Update',
  DELETE = 'Delete',
}

export enum FullCalanderViewTypes {
  TIME_GRID_DAY = 'timeGridDay',
  TIME_GRID_WEEK = 'timeGridWeek',
  DAY_GRID_MONTH = 'dayGridMonth',
}

export enum Role {
  READ = 'R',
  READ_WRITE = 'RW',
}

export enum WebsiteUrls {
  DEV = 'https://dev.mydigiops.com/',
  TEST = 'https://test.mydigiops.com/',
  UAT = 'https://uat.mydigiops.com/',
  PROD = 'https://www.mydigiops.com/',
}
