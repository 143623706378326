/* eslint-disable camelcase */
import React, { useContext } from 'react';
import Table from 'src/components/table/table';
import StreetView from 'src/components/steetView/streetView';
import { DisplayUnitDetailsOutputObject, InitialState } from 'src/types/TocPocModal';
import { Accordion, H5 } from '@clearchannelinternational/ecooh-system';
import { useTranslation } from 'react-i18next';
import { AppContext, systemsConfig } from 'src/contexts/appContext';
import { MRT_ColumnDef } from 'mantine-react-table';
import { DisplayUnitDetailsAccordionProps } from 'src/types/componentProps';
import { ColumnVisibility } from 'src/types/table';
import { useSelector } from 'react-redux';
import { SearchReducer } from 'src/types/searchReducer';
import { getMappingForPanelId } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';

function getAddressWithMapsIcon(cellValue: string, latitude: number | null, longitude: number | null): React.ReactNode {
  return (
    <div key={cellValue} className="digital-unit-ids-with-street-view">
      {cellValue} <StreetView latitude={latitude} longitude={longitude} />
    </div>
  );
}

const DisplayUnitDetailsAccordion: React.FC<DisplayUnitDetailsAccordionProps> = ({ externalIds, fetchedData }) => {
  const appContext = useContext(AppContext);
  const fieldsConfig = systemsConfig[appContext.system][appContext.Country];
  const { t } = useTranslation();
  const { fetchedDataForCreateTocPocModal } = useSelector((state: SearchReducer) => state.searchReducer);
  const { existingTocPoc } = useSelector((state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer);

  const getDisplayUnitDetailsData = (): DisplayUnitDetailsOutputObject[] => {
    return (
      fetchedData &&
      externalIds.map((frameId, index) => {
        return {
          siteKey: fetchedDataForCreateTocPocModal
            ? fetchedDataForCreateTocPocModal.frameIdentifiers.siteKey
            : existingTocPoc.siteKey,
          address: fetchedDataForCreateTocPocModal ? fetchedDataForCreateTocPocModal.frameIdentifiers.address : '',
          // // slotId: frameId,
          panelId: fetchedDataForCreateTocPocModal ? fetchedDataForCreateTocPocModal.frameIdentifiers.panelIds[index] : '',
        } as DisplayUnitDetailsOutputObject;
      })
    );
  };

  const getDisplayUnitDetailsTableColumns = () => {
    const displayUnitDetails: MRT_ColumnDef<DisplayUnitDetailsOutputObject>[] = [
      {
        accessorKey: 'siteKey',
        header: fieldsConfig.siteKey && fieldsConfig.siteKey.label,
        size: 120,
        Cell: (cell) => {
          const cellValue = cell.cell.getValue() as string;
          return <span className="common-field">{cellValue}</span>;
        },
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 250,
        Cell: (cell) => {
          const cellValue = cell.cell.getValue() as string;
          const panelId = cell.row.original.panelId;
          const mapping =
            fetchedDataForCreateTocPocModal &&
            getMappingForPanelId(panelId, fetchedDataForCreateTocPocModal.frameIdentifiers.panelIdIdentifierMappings);
          const latitude = mapping ? mapping.geoLocation.latitude : null;
          const longitude = mapping ? mapping.geoLocation.longitude : null;
          return getAddressWithMapsIcon(cellValue, latitude, longitude);
        },
      },
      // {
      //   accessorKey: 'slotId',
      //   header: fieldsConfig.slotId && fieldsConfig.slotId.label,
      //   size: 150,
      // },
      {
        accessorKey: 'digitalUnitId',
        header: t('table:digitalUnitIdWithPanelNo'),
        Cell: (cell) => {
          const panelId = cell.row.original.panelId as string;
          const mapping =
            fetchedDataForCreateTocPocModal &&
            getMappingForPanelId(panelId, fetchedDataForCreateTocPocModal.frameIdentifiers.panelIdIdentifierMappings);
          return `${mapping?.digitalUnitId} (${mapping?.panelNumber})`;
        },
        size: 150,
      },
      {
        accessorKey: 'panelId',
        header: t('table:panelId'),
        size: 150,
      },
      {
        accessorKey: 'panelNo',
        header: t('table:panelNo'),
        Cell: (cell) => {
          const panelId = cell.row.original.panelId as string;
          const mapping =
            fetchedDataForCreateTocPocModal &&
            getMappingForPanelId(panelId, fetchedDataForCreateTocPocModal.frameIdentifiers.panelIdIdentifierMappings);
          return mapping?.panelNumber;
        },
        size: 150,
      },
    ];

    return displayUnitDetails.filter((displayUnitDetail) => {
      return displayUnitDetail.header != undefined;
    });
  };

  const columnVisibilityConfig: ColumnVisibility = {
    oasis: {
      slotId: false,
    },
    aida: {
      commercialFrameId: false,
      siteKey: false,
      digitalUnitId: false,
    },
  };

  const initialState: InitialState = {
    columnVisibility: columnVisibilityConfig[appContext.system],
  };

  return (
    <>
      <Accordion
        hasOuterBorders={true}
        items={[
          {
            content: (
              <div className="existingTocPoc-table-container" data-testid="display-unit-details">
                {fetchedData && (
                  <Table
                    columns={getDisplayUnitDetailsTableColumns()}
                    data={getDisplayUnitDetailsData()}
                    enableGrouping={false}
                    enablePinning={false}
                    enableRowSelection={false}
                    enablePagination={false}
                    enableRowVirtualization={false}
                    enableColumnFilterModes={false}
                    enableTopToolbar={false}
                    enableFilters={false}
                    enableSorting={false}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    initialState={initialState}
                  />
                )}
              </div>
            ),
            title: (
              <span className="display-unit-details-accordion-title">
                <H5>{t('createNewTocPocModal:displayUnitDetails')}</H5>
              </span>
            ),
          },
        ]}
      />
    </>
  );
};

export default DisplayUnitDetailsAccordion;
