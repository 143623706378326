import React, { useContext, useEffect } from 'react';
import { Checkbox } from '@clearchannelinternational/ecooh-system';
import { useSelector } from 'react-redux';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { FrameIDCheckboxesProps } from 'src/types/componentProps';
import { OutOfChargeStatus, System } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { SearchReducer } from 'src/types/searchReducer';
import { AppContext } from 'src/contexts/appContext';
import { getMappingForPanelId } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { TocPocFormData } from 'src/types/TocPocModal';

const FrameIDCheckboxes: React.FC<FrameIDCheckboxesProps> = ({
  fetchedData,
  externalIds,
  existingTocPoc,
  updateModal,
  formValues,
  setFormValues,
  selectedPanels,
  setSelectedPanels,
}) => {
  const appContext = useContext(AppContext);
  const {
    isEditClicked: isFormInEditMode,
    isUserInViewMode,
    createNewTocModal,
  } = useSelector((state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer);
  const { fetchedDataForCreateTocPocModal } = useSelector((state: SearchReducer) => state.searchReducer);

  const { t } = useTranslation();
  const panelIds = fetchedDataForCreateTocPocModal
    ? fetchedDataForCreateTocPocModal.frameIdentifiers.panelIds
    : existingTocPoc.panelIds;
  const systemBasedExternalIds = appContext.system === System.OASIS ? panelIds : externalIds;

  function getCheckedPanelIds() {
    const getExistingPOCsPanelIds = fetchedDataForCreateTocPocModal?.existingOOCDetails
      .filter((item) => item.oocType === OutOfChargeStatus.POC)
      .map((item) => item.frameIdentifiers.panelIds)
      .flat();

    return (formValues as TocPocFormData).panelIds.filter((item) => !getExistingPOCsPanelIds?.includes(item));
  }

  useEffect(() => {
    if (formValues.outOfChargeStatus === OutOfChargeStatus.POC && formValues.panelIds.length >= 2) {
      const checkedPanelIds = getCheckedPanelIds();
      setSelectedPanels(checkedPanelIds);
      const tocPocFormData = { ...formValues };
      tocPocFormData.panelIds = checkedPanelIds;
      setFormValues(tocPocFormData);
      return;
    }
    if (existingTocPoc.panelIds.length > 0) {
      setSelectedPanels(existingTocPoc.panelIds);
    } else if (fetchedDataForCreateTocPocModal) {
      setSelectedPanels(systemBasedExternalIds as any);
    }
  }, [fetchedData, isFormInEditMode, existingTocPoc.panelIds, formValues.outOfChargeStatus]);

  const handleCheckboxChange = (panelId: string): void => {
    if (selectedPanels.includes(panelId) && selectedPanels.length === 1) {
      return;
    }
    const updatedPanelIds = selectedPanels.includes(panelId)
      ? selectedPanels.filter((id) => id !== panelId)
      : [...selectedPanels, panelId];

    setSelectedPanels(updatedPanelIds);
    updateModalWithSelectedPanels(updatedPanelIds);
  };

  const updateModalWithSelectedPanels = (selectedPanels: string[]): void => {
    const tocPocFormData = { ...formValues };
    tocPocFormData.panelIds = selectedPanels;
    setFormValues(tocPocFormData);
    updateModal(createNewTocModal);
  };

  const shouldCheckboxBeDisabled = (panelId: string) => {
    if (shouldCheckboxBeDisabledForMultiPanelPOCs(panelId)) {
      return true;
    }

    if (selectedPanels.length === 1 && selectedPanels[0] === panelId) {
      return true;
    }

    return (
      isFormInEditMode ||
      isUserInViewMode ||
      // isPocFocused ||
      (fetchedDataForCreateTocPocModal && !fetchedDataForCreateTocPocModal.frameIdentifiers.allowPanelSelection) ||
      externalIds.length === 1
    );
  };

  function shouldCheckboxBeDisabledForMultiPanelPOCs(panelId: string) {
    const updatedExistingPocs = fetchedDataForCreateTocPocModal?.existingOOCDetails.filter(
      (item) => item.oocType === OutOfChargeStatus.POC
    );
    return (
      updatedExistingPocs?.length !== 0 &&
      formValues.outOfChargeStatus === OutOfChargeStatus.POC &&
      !getCheckedPanelIds().includes(panelId)
    );
  }

  return (
    <div className={'externalID-checkboxes-container'} data-testid="frameId-checkboxes">
      <div className="checkbox-label">
        {appContext.system === System.OASIS ? `${t('table:digitalUnitIdWithPanelNo')}:` : t('table:slotId')}
      </div>
      <div className="externalID-checkboxes">
        {fetchedData &&
          systemBasedExternalIds.map((panelId) => {
            const correspondingAttributesForPanelId =
              fetchedDataForCreateTocPocModal &&
              getMappingForPanelId(panelId, fetchedDataForCreateTocPocModal.frameIdentifiers.panelIdIdentifierMappings);
            return (
              <>
                {correspondingAttributesForPanelId && (
                  <>
                    <Checkbox
                      key={panelId}
                      testId={`checkbox-${panelId}`}
                      checked={selectedPanels.includes(panelId)}
                      disabled={shouldCheckboxBeDisabled(panelId)}
                      label={`${correspondingAttributesForPanelId.digitalUnitId} (${correspondingAttributesForPanelId.panelNumber})`}
                      onChange={() => {
                        if (!isFormInEditMode && !shouldCheckboxBeDisabled(panelId)) {
                          handleCheckboxChange(panelId);
                        }
                      }}
                    />
                  </>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default FrameIDCheckboxes;
