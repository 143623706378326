/* eslint-disable camelcase */
import React, { FunctionComponent } from 'react';
import { MantineReactTable } from 'mantine-react-table';
import { TableProps } from 'src/types/table';

const Table: FunctionComponent<TableProps> = (props) => {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (props.data) {
  //     setIsLoading(false);
  //   }
  // }, [props.data]);

  return (
    <div className="common-table">
      {props.columns ? (
        <MantineReactTable
          columns={props.columns as any}
          data={props.data as any}
          enableGrouping={props.enableGrouping}
          enablePinning={props.enablePinning}
          enableRowSelection={props.enableRowSelection}
          enableFilters={props.enableFilters}
          enableSorting={props.enableSorting}
          enablePagination={props.enablePagination}
          enableRowVirtualization={props.enableRowVirtualization}
          enableColumnFilterModes={props.enableColumnFilterModes}
          enableTopToolbar={props.enableTopToolbar}
          enableBottomToolbar={props.enableTopToolbar}
          enableColumnActions={props.enableColumnActions}
          enableColumnFilters={props.enableColumnFilters}
          // enableHiding={true}
          enableFullScreenToggle={false}
          initialState={props.initialState}
          mantineTableContainerProps={props.mantineTableContainerProps}
          state={props.state}
          paginationDisplayMode="pages"
          mantinePaginationProps={{
            radius: 'xl',
            size: 'lg',
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Table;
