/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import TocPocDetailsTable from './tocPocDetailsTable/tocPocDetailsTable';
import SearchBox from 'src/components/searchBox/searchBox';
import CreateTocPocModal from './createTocPocModal/createTocPocModal';
import {
  getInputFieldsListToBeRendered,
  getInputFieldsListToBeRenderedWithDisplayUnitId,
  setFetchedDataForFrameSearchRequest,
  setFetchedDataForOpeningHoursRequest,
  setFetchedDataForTocPocTableDetails,
  setSearchFieldDetails,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import {
  actionsAccessorKey,
  commercialFrameIdAccessorKey,
  digitalUnitIdAccessorKey,
  getFrameSearchTableColumns,
  getOpeningHoursSearchTableColumns,
  getTocPocTableColumns,
  siteKeyAccessorKey,
} from './tocPocDetailsTable/tocPocDetailsTableConfig';
import {
  setBulkUpdateDetailsTableResponseData,
  setTabStartIndex,
} from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import {
  AssociatedAttributesForPanel,
  AttributesForPanel as FrameIdentiFiersTableData,
  FrameSearchTableData,
  OpeningHoursTableData,
} from 'src/types/apiModels';
import { SidebarTabProp } from '../../components/sidebar/sidebarConfig';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/contexts/appContext';
import { SearchFields, RequestType } from 'src/enums/enum';
import { useDispatch, useSelector } from 'react-redux';
import { queryClient } from '../../../src';
import { setAuditTableData } from 'src/redux/actions/auditSidebarTabAction/auditSidebarTabAction';
import { useMantineReactTable, MRT_TableInstance } from 'mantine-react-table';
import { InitialState } from 'src/types/TocPocModal';
import { OOCDetailsTableType } from 'src/types/table';
import { SearchReducer } from 'src/types/searchReducer';
import { initialUpdateDetailsResponseData } from 'src/redux/reducers/bulkOOCSearchSidebarTabReducer/bulkOOCSearchSidebarTabReducer';
import { LoadingReducer } from 'src/types/loadinReducer';
import { H3, H4 } from '@clearchannelinternational/ecooh-system';
import { areDisplayUnitIdsUnique } from '../openingHoursSidebarTab/utils/utils';
import { VariedOpeningHoursWarningBanner } from 'src/components/syncStatusWarningBanner/syncStatusWarningBanner';
import { checkIfRowsHaveDifferentOpeningHours } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';

const TocPocTab: React.FC<SidebarTabProp> = ({
  searchRequestType,
  setSelectedFrameDetailRow,
  selectedFrameDetailRow,
  setShouldOpeningHoursDetailedSectionBeEnabled,
}) => {
  const appContext = useContext(AppContext);
  const isBulkTocSearchTab = false;
  const { t } = useTranslation();
  const digitalUnitId = t('table:digitalUnitId');
  const dispatch = useDispatch();
  const { tableData, fetchedDataForFrameSearchRequest, fetchedDataForOpeningHoursSearchRequest } = useSelector(
    (state: SearchReducer) => state.searchReducer
  );
  const { loading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const requestType = searchRequestType ? searchRequestType : RequestType.TOC_POC_SEARCH_REQUEST;

  useEffect(() => {
    dispatch(
      setSearchFieldDetails({
        label: digitalUnitId,
        value: '',
        accessorKey: SearchFields.DIGITAL_UNIT_ID,
      })
    );
    dispatch(setAuditTableData([]));
    dispatch(setFetchedDataForTocPocTableDetails(undefined as any));
    queryClient.clear();
    dispatch(setTabStartIndex(0));
    dispatch(setBulkUpdateDetailsTableResponseData(initialUpdateDetailsResponseData));
    dispatch(setFetchedDataForFrameSearchRequest(undefined as any));
    dispatch(setFetchedDataForOpeningHoursRequest(undefined as any));
  }, []);

  useEffect(() => {
    if (fetchedDataForOpeningHoursSearchRequest && fetchedDataForOpeningHoursSearchRequest.length > 0) {
      const sortedOpeningHoursData = fetchedDataForOpeningHoursSearchRequest
        .map((data) => data.frameIdentifiers.panelIdIdentifierMappings[0].associatedAttributesForPanel)
        .sort((a, b) => parseInt(a.panelNumber) - parseInt(b.panelNumber));

      setSelectedFrameDetailRow &&
        setSelectedFrameDetailRow({
          index: 0,
          panelId: sortedOpeningHoursData[0].panelId,
        });
    }
  }, [fetchedDataForOpeningHoursSearchRequest]);

  const getOpeningHoursTableData = (): FrameIdentiFiersTableData[] => {
    if (fetchedDataForOpeningHoursSearchRequest && fetchedDataForOpeningHoursSearchRequest.length > 0) {
      const haveUniqueDisplayUnitIds = areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest);

      if (!haveUniqueDisplayUnitIds && fetchedDataForOpeningHoursSearchRequest.length === 1) {
        return fetchedDataForOpeningHoursSearchRequest[0].frameIdentifiers.panelIdIdentifierMappings.map(
          (mapping) => {
            return setOpeningHoursTableAttributes(mapping.associatedAttributesForPanel);
          }
        );
      } else {
        return fetchedDataForOpeningHoursSearchRequest.map((frame) => {
          return setOpeningHoursTableAttributes(
            frame.frameIdentifiers.panelIdIdentifierMappings[0].associatedAttributesForPanel
          );
        });
      }
    }
    return [];
  };

  function setOpeningHoursTableAttributes(
    associatedAttributesForPanel: AssociatedAttributesForPanel
  ): FrameIdentiFiersTableData {
    return {
      ...associatedAttributesForPanel,
      broadsignFrameId: associatedAttributesForPanel.broadsignFrameIds.join(','),
      panaleNo: associatedAttributesForPanel.panelNumber,
    } as FrameIdentiFiersTableData;
  }

  const getFrameSearchTableData = (): FrameSearchTableData[] => {
    if (fetchedDataForFrameSearchRequest) {
      return fetchedDataForFrameSearchRequest.data.map((frame) => {
        return {
          digitalUnitId: frame.externalSystemIdentifiers.oasis.digitalUnitId,
          siteKey: frame.externalSystemIdentifiers.oasis.siteKey,
          panelId: frame.externalSystemIdentifiers.oasis.panelId,
          panaleNo: frame.externalSystemIdentifiers.oasis.panelNo,
          commercialFrameId: frame.externalSystemIdentifiers.space.commercialFrameId,
          broadsignFrameId: frame.externalSystemIdentifiers.broadsign.frameIds.join(','),
          screenId: frame.externalSystemIdentifiers.broadsign.screenId,
          displayUnitId: frame.externalSystemIdentifiers.broadsign.displayUnitId,
          frameName: frame.externalSystemIdentifiers.broadsign.frameName,
          siteNo: frame.externalSystemIdentifiers.oasis.siteNo,
          isActive: frame.isActive,
          address: frame.location.address,
          postcode: frame.location.postcode,
          latitude: frame.location.geoLocation.latitude,
          longitude: frame.location.geoLocation.longitude,
          frameType: frame.frameType,
        };
      });
    }
    return [];
  };

  const oasisSystemColumnVisibility = {
    slotId: false,
    aida: false,
    panelIds: false,
    broadsignControlFrameIds: false,
    commercialFrameIds: false,
    administrationBranch: false,
  };

  const frameDetailsSidebarTableColumnVisibility = {
    siteNo: false,
    displayUnitId: false,
    frameName: false,
  };

  const getLeftPinnedField = (): string[] | undefined => {
    if (requestType === RequestType.OPENING_HOURS_SEARCH_REQUEST) {
      return [siteKeyAccessorKey];
    } else if (requestType === RequestType.FRAME_SEARCH_REQUEST) {
      return [commercialFrameIdAccessorKey];
    } else {
      return [digitalUnitIdAccessorKey];
    }
  };

  const initialState: InitialState = {
    columnVisibility:
      requestType === RequestType.FRAME_SEARCH_REQUEST
        ? frameDetailsSidebarTableColumnVisibility
        : oasisSystemColumnVisibility,
    columnPinning: {
      right: [actionsAccessorKey],
      left: requestType !== RequestType.OPENING_HOURS_SEARCH_REQUEST ? getLeftPinnedField() : undefined,
    },
    sorting: [
      {
        id: 'startDate',
        desc: true,
      },
    ],
  };

  const getTableData = () => {
    if (requestType === RequestType.TOC_POC_SEARCH_REQUEST) {
      return tableData;
    } else if (requestType === RequestType.FRAME_SEARCH_REQUEST) {
      return getFrameSearchTableData();
    } else if (requestType === RequestType.OPENING_HOURS_SEARCH_REQUEST) {
      return getOpeningHoursTableData();
    }
  };

  const getTableColumns = () => {
    if (requestType === RequestType.TOC_POC_SEARCH_REQUEST) {
      return getTocPocTableColumns(tableData, isBulkTocSearchTab, t, requestType);
    } else if (requestType === RequestType.FRAME_SEARCH_REQUEST) {
      return getFrameSearchTableColumns(t);
    } else if (requestType === RequestType.OPENING_HOURS_SEARCH_REQUEST) {
      return getOpeningHoursSearchTableColumns(
        t,
        selectedFrameDetailRow,
        setSelectedFrameDetailRow,
        fetchedDataForOpeningHoursSearchRequest,
        setShouldOpeningHoursDetailedSectionBeEnabled
      );
    }
  };

  const tocPocSearchTableIntance = useMantineReactTable({
    columns: getTableColumns() as any,
    data: getTableData() as any,
    enableGrouping: false,
    enablePinning: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enablePagination: false,
    enableRowVirtualization: false,
    enableColumnFilterModes: false,
    enableTopToolbar: requestType !== RequestType.OPENING_HOURS_SEARCH_REQUEST,
    enableBottomToolbar: false,
    enableFilters: false,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    initialState: initialState,
    mantineTableProps: {
      striped: false,
      highlightOnHover: false,
      withBorder: false,
    },
  }) as MRT_TableInstance<OOCDetailsTableType | FrameSearchTableData | OpeningHoursTableData>;

  const determinePanelTypeForIndividualPlayers = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (fetchedDataForOpeningHoursSearchRequest.length === 2) {
        return t('openingHoursSIdebarTab:doubleSidedPanelIndividualPlayer');
      } else {
        return t('openingHoursSIdebarTab:multiSidedPanelIndividualPlayer');
      }
    }
  };

  const determinePanelTypeForSinglePlayer = () => {
    if (fetchedDataForOpeningHoursSearchRequest && fetchedDataForOpeningHoursSearchRequest.length > 0) {
      const panelIndentifiers = fetchedDataForOpeningHoursSearchRequest[0].frameIdentifiers.panelIdIdentifierMappings;
      if (panelIndentifiers.length === 2) {
        return t('openingHoursSIdebarTab:doubleSidedPanelSinglePlayer');
      } else if (panelIndentifiers.length > 2) {
        return t('openingHoursSIdebarTab:mul tiSidedPanelSinglePlayer');
      } else {
        return t('openingHoursSIdebarTab:singleSidedPanel');
      }
    }
  };

  const getPanelType = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      if (areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        return determinePanelTypeForIndividualPlayers();
      } else {
        return determinePanelTypeForSinglePlayer();
      }
    }
  };

  return (
    <div data-testid="toc-poc-search-sidebar-tab">
      <div className="search-new-button-container">
        <SearchBox
          searchRequestType={requestType}
          inputFieldsListToBeRendered={
            searchRequestType === RequestType.OPENING_HOURS_SEARCH_REQUEST
              ? getInputFieldsListToBeRenderedWithDisplayUnitId(appContext.Country, t)
              : getInputFieldsListToBeRendered(appContext, t)
          }
        />
        {!searchRequestType && <CreateTocPocModal />}
      </div>

      {loading &&
      fetchedDataForOpeningHoursSearchRequest &&
      searchRequestType === RequestType.OPENING_HOURS_SEARCH_REQUEST ? (
        <></>
      ) : (
        <>
          {searchRequestType === RequestType.OPENING_HOURS_SEARCH_REQUEST && (
            <>
              {getPanelType() && (
                <div className="frame-type-text">
                  <H4>
                    {t('openingHoursSIdebarTab:frameType')}: {getPanelType()}
                  </H4>
                </div>
              )}
              <div className="identifiers-text">
                <H3>{t('openingHoursSIdebarTab:frameIdentifiers')}</H3>
              </div>
            </>
          )}
          <TocPocDetailsTable tableInstance={tocPocSearchTableIntance} searchRequestType={requestType} />
          {checkIfRowsHaveDifferentOpeningHours(fetchedDataForOpeningHoursSearchRequest) && (
            <div className="varied-openinghours-banner">
              <VariedOpeningHoursWarningBanner />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TocPocTab;
