import React, { useEffect, useState } from 'react';
import Alert from 'src/components/alert/alert';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonKind, ModalSize, OutOfChargeStatus, SyncStatusStates, TOCPOCCurrentStatus } from 'src/enums/enum';
import { ResyncModalProps } from 'src/types/componentProps';
import { SearchReducer, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { Button, useModal, Icon } from '@clearchannelinternational/ecooh-system';
import { Loader } from '@mantine/core';
import { SaveReducerState } from 'src/types/saveReducer';
import { onClickSyncButton } from './resyncActions';
import { setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import Tippy from '@tippyjs/react';
import { setLoading, setModalLoading } from 'src/redux/actions/loadingActions';
import { LoadingReducer } from 'src/types/loadinReducer';
import { ModalConfig } from 'src/types/TocPocModal';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';

const ResyncModal: React.FC<ResyncModalProps> = ({ row, tableData, onClickBulkUpdateSummary }) => {
  const dispatch = useDispatch();
  const { showModal, updateModal } = useModal();
  const { successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { modalLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const [resyncModal, setResyncModal] = useState<boolean>(false);
  const { bulkUpdateDetailsResponseData } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );

  useEffect(() => {
    return updateModal(resyncModal, configureResyncModal());
  }, [successMessage, modalLoading]);

  const createResyncModal = () => {
    const id = showModal(configureResyncModal());
    setResyncModal(id);
  };

  function getResyncModalContent(): JSX.Element | undefined {
    const currentTableData = tableData[row.row.id];
    return (
      <>
        {currentTableData.outOfChargeStatus} sync has been triggered successfully{' '}
        {modalLoading && <Loader className="sync-loader" />}
        <>{successMessage && <Alert message={successMessage} onClose={() => 'Closed'} />}</>
      </>
    );
  }

  const configureResyncModal = (): ModalConfig => {
    const currentTableData = tableData[row.row.id];
    return {
      size: ModalSize.Medium,
      content: getResyncModalContent(),
      primaryButtonKind: ButtonKind.Primary,
      primaryButtonAction: () => 'Close',
      primaryButtonDisabled: modalLoading,
      ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT'],
      primaryButtonLabel: 'Close',
      title: `${
        currentTableData.outOfChargeStatus !== undefined ? currentTableData.outOfChargeStatus : OutOfChargeStatus.TOC
      } resync`,
      onCloseModal: () => {
        if (
          bulkUpdateDetailsResponseData &&
          Object.values(bulkUpdateDetailsResponseData.batchSummary).length !== 0 &&
          onClickBulkUpdateSummary
        ) {
          onClickBulkUpdateSummary((bulkUpdateDetailsResponseData as any).batchSummary.batchUUID);
          dispatch(setSuccessMessage(undefined));
          return;
        }
        dispatch(setLoading(true));
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 500);
        dispatch(setLoading(true));
        dispatch(setSuccessMessage(undefined));
      },
    };
  };

  const getToolTipText = () => {
    const tableRowData: TOCPOCDetailsTableData = tableData[row.row.id];
    const oasisSyncStatus = tableRowData.oasisSyncStatus;
    const broadSignSyncStatus = tableRowData.broadsignSyncStatus;

    return oasisSyncStatus === SyncStatusStates.FAILURE && broadSignSyncStatus === SyncStatusStates.FAILURE
      ? 'Click to sync with BroadSign and Oasis'
      : broadSignSyncStatus === SyncStatusStates.FAILURE
      ? 'Click to sync with BroadSign'
      : oasisSyncStatus === SyncStatusStates.FAILURE
      ? 'Click to sync with Oasis'
      : undefined;
  };

  const isSyncDisabled = () => {
    const tableRowData: TOCPOCDetailsTableData = tableData[row.row.id];
    if (tableRowData.tocPocCurrentStatus === TOCPOCCurrentStatus.Expired) {
      return true;
    }
    const oasisSyncStatus = tableRowData.oasisSyncStatus;
    const broadSignSyncStatus = tableRowData.broadsignSyncStatus;
    return (
      !(oasisSyncStatus === SyncStatusStates.FAILURE) &&
      !(broadSignSyncStatus === SyncStatusStates.FAILURE) &&
      !(broadSignSyncStatus === SyncStatusStates.UNEXPECTED_FAILURE_FROM_EXTERNAL_SYSTEM) &&
      !(oasisSyncStatus === SyncStatusStates.UNEXPECTED_FAILURE_FROM_EXTERNAL_SYSTEM)
    );
  };

  return (
    <>
      <Tippy
        appendTo={document.body}
        maxWidth="200rem"
        placement="top"
        interactive
        arrow={true}
        disabled={isSyncDisabled()}
        content={
          <div className="google-maps-content">
            <Icon fontSize="1rem" iconName="information" />
            <div className="street-view-tooltip-text">{getToolTipText()}</div>
          </div>
        }
      >
        <div>
          <Button
            hasIconOnly
            label="Button"
            renderIcon="update-now"
            size="small"
            kind="tertiary"
            renderIconSize="secondary"
            onClick={() => {
              dispatch(setModalLoading(true));
              createResyncModal();
              onClickSyncButton(tableData, row, dispatch, searchFieldDetails, bulkUpdateDetailsResponseData);
            }}
            disabled={isSyncDisabled()}
          />
        </div>
      </Tippy>
    </>
  );
};

export default ResyncModal;
