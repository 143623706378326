import React from 'react';
import { RequestType } from 'src/enums/enum';
import { getActionName } from '../alert/alertMessages';
import { SyncStatusWarningBannerProps } from 'src/types/componentProps';
import { SearchReducer } from 'src/types/searchReducer';
import { useSelector } from 'react-redux';
import { Icon } from '@clearchannelinternational/ecooh-system';
import Tippy from '@tippyjs/react';

const SyncStatusWarningBanner: React.FC<SyncStatusWarningBannerProps> = ({ requestType }) => {
  const actionName = getActionName(requestType);

  return (
    <div className="warning-banner">
      {requestType === RequestType.OOC_BULK_SEARCH_REQUEST
        ? 'Sync operations are currently pending for some of the selected TOCs. TOCs updation shall be allowed only after the pending sync is completed.'
        : `Sync operations are currently pending for existing TOCs/POC. ${
            requestType === RequestType.CREATE_REQUEST ? 'New' : ''
          }
      TOC/POC ${actionName} shall be allowed only after the pending sync is completed.`}
    </div>
  );
};

export const VariedOpeningHoursWarningBanner: React.FC = () => {
  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);

  const extractPanelNumbers = () => {
    return fetchedDataForOpeningHoursSearchRequest
      .map((data) => data.frameIdentifiers.panelIdIdentifierMappings[0].associatedAttributesForPanel.panelNumber)
      .sort((a, b) => parseInt(a) - parseInt(b));
  };

  const getBannerMessage = () => {
    const panelNumbers = extractPanelNumbers();
    return `Panels ${panelNumbers.join(' and ')} have different Opening Hours, please review and make changes if needed.`;
  };

  const getTooltipText = () => {
    return `Please note that the Panels of this multi-site have independent Media Players (Broadsign Player) attached to each of the Panels, and hence their Opening Hours can be managed independently as well if desired.`;
  };

  return (
    <div className="different-opening-hours-banner">
      {getBannerMessage()}
      <Tippy
        appendTo={document.body}
        maxWidth="200rem"
        placement="top"
        interactive
        arrow={true}
        delay={200}
        content={
          <div className="google-maps-content" style={{ width: '40rem', padding: '1rem', lineHeight: '2rem' }}>
            <Icon fontSize="1rem" iconName="information" />
            <span style={{ marginTop: '0.2rem', fontSize: '1.5rem' }}>{getTooltipText()}</span>
          </div>
        }
      >
        <div className="start-date-info-icon" style={{ marginTop: '-1rem' }}>
          <Icon fontSize="1rem" color="orange" iconName="information" />
        </div>
      </Tippy>
    </div>
  );
};

export default SyncStatusWarningBanner;
