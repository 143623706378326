/* eslint-disable camelcase */
import React, { useState } from 'react';
import Table from 'src/components/table/table';
import { MRT_ColumnDef } from 'mantine-react-table';
import { ToggleSwitch } from '@clearchannelinternational/ecooh-system';
import { renderDigitalUnitWithStreetView } from '../../../tocPocDetailsTableConfig';
import { GeoLevelMapping } from 'src/enums/enum';
import { FrameDetailsTableProps } from 'src/types/componentProps';
import { useDispatch, useSelector } from 'react-redux';
import { SearchReducer } from 'src/types/searchReducer';
import { setShowTableform } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { FrameSearchResponseData } from 'src/types/apiModels';

const FrameDetailsTable: React.FC<FrameDetailsTableProps> = ({ frameDetails }) => {
  const { showTableForm } = useSelector((state: SearchReducer) => state.searchReducer);
  const dispatch = useDispatch();

  const frameDetailsTableColumns = (): MRT_ColumnDef<{ field: string; value: string }>[] => {
    return [
      {
        accessorKey: 'field',
        header: 'Field',
        Cell: (cell) => {
          const { renderedCellValue } = cell;

          if (
            renderedCellValue === 'location.geoLocation.location.coordinates' ||
            renderedCellValue === 'location.address'
          ) {
            const correspondingColumnValue = (frameDetails as unknown as FrameSearchResponseData)[
              'location.geoLocation.location.coordinates'
            ];

            const [longitude, latitude] = correspondingColumnValue
              ? correspondingColumnValue.split(',').map(parseFloat)
              : [null, null];
            // return <></>;
            return renderDigitalUnitWithStreetView(renderedCellValue as string, latitude, longitude);
          }

          const isLocationLevel = Object.keys(GeoLevelMapping).includes((renderedCellValue as string) || '');

          if (isLocationLevel) {
            return `${cell.renderedCellValue} (${GeoLevelMapping[(renderedCellValue as string)!]})`;
          }

          if (renderedCellValue === 'externalSystemIdentifiers.broadsign.frameIds') {
            return 'externalSystemIdentifiers.broadsign.frameIds / externalSystemIdentifiers.broadsign.broadsignControlIds';
          }

          if (renderedCellValue === 'externalSystemIdentifiers.broadsign.screenId') {
            return 'externalSystemIdentifiers.broadsign.broadsignDirectId / externalSystemIdentifiers.broadsign.screenId';
          }

          return renderedCellValue;
        },
      },
      {
        accessorKey: 'value',
        header: 'Value',
      },
    ];
  };

  function flattenObject(obj: Record<string, any>, prefix = ''): { field: string; value: any }[] {
    return Object.keys(obj).reduce<{ field: string; value: any }[]>((acc, key) => {
      const value = obj[key];
      const newKey = prefix ? `${prefix}.${key}` : key;

      if (Array.isArray(value)) {
        if (value.length > 0 && typeof value[0] === 'object') {
          return [...acc, ...value.flatMap((item, index) => flattenObject(item, `${newKey}[${index}]`))];
        } else {
          const arrayValue = value.join(', ');
          return [...acc, { field: newKey, value: arrayValue }];
        }
      } else if (typeof value === 'object' && value !== null) {
        return [...acc, ...flattenObject(value, newKey)];
      } else if (typeof value === 'boolean') {
        return [...acc, { field: newKey, value: value.toString() }];
      } else {
        return [...acc, { field: newKey, value }];
      }
    }, []);
  }

  return (
    <div className="frame-details-container">
      <div className="frame-details-toggle">
        <ToggleSwitch
          checked={showTableForm}
          hasFullWidth
          helperText="Toggle to switch between json or table form"
          label={['Show JSON form', 'Show table form']}
          onChange={() => dispatch(setShowTableform(!showTableForm))}
        />
      </div>
      <div className="frame-details-table-container">
        {!showTableForm && (
          <Table
            columns={frameDetailsTableColumns()}
            // data={frameDetails && frameDetails.flatMap((frame) => flattenObject(frame))}
            data={frameDetails && flattenObject(frameDetails)}
            enableGrouping={false}
            enablePinning={false}
            enableRowSelection={false}
            enablePagination={false}
            enableRowVirtualization={false}
            enableColumnFilterModes={false}
            enableTopToolbar={false}
            enableFilters={false}
            enableSorting={false}
            enableColumnActions={false}
            enableColumnFilters={false}
          />
        )}
        {showTableForm && <pre>{JSON.stringify(frameDetails, null, 4)}</pre>}
      </div>
    </div>
  );
};

export default FrameDetailsTable;
