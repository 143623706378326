import React, { useEffect, useState } from 'react';
import CalendarConfig, { getDayDate } from './calendarConfig/calendarConfig';
import CalendarView from './calendarView/calendarView';
import FullCalendar from '@fullcalendar/react';
import { Tab } from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tabsComponent/tabsComponent';
import { Tabs } from '@clearchannelinternational/ecooh-system';
import { OpeningHoursTabsComponentType } from 'src/types/componentProps';
import { DaysToIndexMapping, OutOfChargeStatus } from 'src/enums/enum';
import { GroupedWeeklyTimeRanges } from '../openingHoursSidebarTab';
import { DatesRangeValue } from '@mantine/dates';
import { useDispatch, useSelector } from 'react-redux';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import { setInitialOpeningHoursCalanderEvents } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';
import { SearchReducer } from 'src/types/searchReducer';
import { FrameScheduleDetails } from 'src/types/apiModels';

const OpeningHoursTabsComponent: React.FC<OpeningHoursTabsComponentType> = ({
  selectedDayPartData,
  shouldOpeningHoursDetailedSectionBeEnabled,
  openingHoursTableData,
  setOpeningHoursTableData,
  selectedFrameDetailRow,
}) => {
  const [openingHoursFormValues, setOpeningHoursFormValues] = useState(selectedDayPartData);
  const { initialOpeningHoursCalanderEvents } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );
  const [dateValue, setDateValue] = React.useState(getDatePickerInputValue());
  const [effectiveDateFrom, setEffectiveFromDate] = useState<Date | null>(null);
  const [eventId, setEventId] = useState(0);
  const calendarRef = React.useRef<FullCalendar>(null);
  const dispatch = useDispatch();
  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);

  useEffect(() => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      setOpeningHoursFormValues(selectedDayPartData);
      const currentOpeningHoursData = selectedDayPartData.operatingPeriod;
      const openingHoursEvents = convertDataToEvents(currentOpeningHoursData);
      dispatch(
        setInitialOpeningHoursCalanderEvents({ ...initialOpeningHoursCalanderEvents, configData: openingHoursEvents })
      );
      setInitialDateRangesValues();
      setInitialEffectiveFromDataValue();
    }
  }, [selectedDayPartData]);

  const setInitialEffectiveFromDataValue = () => {
    const effectiveDateFrom = selectedDayPartData.effectiveDateFrom ? new Date(selectedDayPartData.effectiveDateFrom) : null;
    setEffectiveFromDate(effectiveDateFrom);
  };

  const setInitialDateRangesValues = () => {
    if (fetchedDataForOpeningHoursSearchRequest) {
      const selectedFrameDetailsRow = fetchedDataForOpeningHoursSearchRequest.find((data) =>
        data.frameIdentifiers.panelIds.includes(selectedFrameDetailRow.panelId)
      ) as FrameScheduleDetails;

      const updatedBlockingOperatingPeriodsSortedWithStartDate = selectedFrameDetailsRow?.blockingOperatingPeriods
        .filter((operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.POC)
        .sort(
          (a, b) => new Date(a.dateAndTimeRanges.startDate).getTime() - new Date(b.dateAndTimeRanges.startDate).getTime()
        );
      const checkAndGetPOCDataIfAny = updatedBlockingOperatingPeriodsSortedWithStartDate.find(
        (operatingPeriod) => operatingPeriod.oocType === OutOfChargeStatus.POC
      );
      if (checkAndGetPOCDataIfAny) {
        const pocStartDate = new Date(checkAndGetPOCDataIfAny?.dateAndTimeRanges.startDate as string);
        pocStartDate.setDate(pocStartDate.getDate() - 1);
        setDateValue([new Date(selectedDayPartData.startDate), new Date(pocStartDate)] as DatesRangeValue);
        return;
      }
    }
    setDateValue(getDatePickerInputValue());
  };

  function getDatePickerInputValue() {
    return selectedDayPartData.startDate && selectedDayPartData.endDate
      ? ([new Date(selectedDayPartData.startDate), new Date(selectedDayPartData.endDate)] as DatesRangeValue)
      : ([null, null] as DatesRangeValue);
  }

  const convertDataToEvents = (data: GroupedWeeklyTimeRanges) => {
    let eventId = 0;
    const events = Object.entries(data).flatMap(([day, hours]) => {
      const dayIndex = DaysToIndexMapping[day];
      return hours.map((hour) => {
        const updatedEndTime = hour.end === '00:00:00' ? '24:00:00' : hour.end;
        const start = getDayDate(dayIndex, hour.start);
        const end = getDayDate(dayIndex, updatedEndTime);
        return { id: eventId++, start, end };
      });
    });

    setEventId(eventId);
    return events;
  };

  const tabs: Tab[] = [
    {
      content: (
        <CalendarConfig
          selectedDayPartData={selectedDayPartData}
          shouldOpeningHoursDetailedSectionBeEnabled={shouldOpeningHoursDetailedSectionBeEnabled}
          openingHoursTableData={openingHoursTableData}
          setOpeningHoursTableData={setOpeningHoursTableData}
          openingHoursFormValues={openingHoursFormValues}
          setOpeningHoursFormValues={setOpeningHoursFormValues}
          initialEvents={initialOpeningHoursCalanderEvents}
          eventId={eventId}
          setEventId={setEventId}
          dateValue={dateValue}
          setDateValue={setDateValue}
          setEffectiveFromDate={setEffectiveFromDate}
          effectiveDateFrom={effectiveDateFrom}
          calendarRef={calendarRef}
          convertDataToEvents={convertDataToEvents}
          selectedFrameDetailRow={selectedFrameDetailRow}
        />
      ),
      label: 'Opening Hours',
    },
    {
      content: (
        <CalendarView
          operatingPeriodsData={openingHoursTableData}
          selectedDayPartData={selectedDayPartData}
          selectedFrameDetailRow={selectedFrameDetailRow}
        />
      ),
      label: 'Calendar View',
    },
  ];

  return (
    <div className="oh-tabs-component">
      <Tabs items={tabs} startIndex={0} hasBottomBorder isOptionFullWidth={false} />
    </div>
  );
};

export default OpeningHoursTabsComponent;

export const convertDataToEvents = (data: GroupedWeeklyTimeRanges) => {
  let eventId = 0;
  const events = Object.entries(data).flatMap(([day, hours]) => {
    const dayIndex = DaysToIndexMapping[day];
    return hours.map((hour) => {
      const updatedEndTime = hour.end === '00:00:00' ? '24:00:00' : hour.end;
      const start = getDayDate(dayIndex, hour.start);
      const end = getDayDate(dayIndex, updatedEndTime);
      return { id: eventId++, start, end };
    });
  });

  return events;
};
