import { AxiosResponse } from 'axios';
import { actionTypes, initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { Action, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { setIsSaveClicked, setSuccessMessage } from './saveActions';
import { TocPocFormData } from 'src/types/TocPocModal';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { buParam, headers, API_ENDPOINTS } from 'src/api/apiConfig';
import { Methods, RequestType } from 'src/enums/enum';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { getSuccessMessageToBeDisplayed } from 'src/components/alert/alertMessages';
import { getErrorMessageToBeDisplayed } from '../../../components/alert/alertMessages';

export const setIsEditClicked = (isEditClicked: boolean): Action<boolean> => ({
  type: actionTypes.SET_IS_EDIT_CLICKED,
  payload: isEditClicked,
});

export const setCreateNewTocModal = (createNewTocModal: boolean): Action<boolean> => ({
  type: actionTypes.SET_CREATE_NEW_TOC_MODAL,
  payload: createNewTocModal,
});

export const setViewTocPocModal = (viewTocPocModal: boolean): Action<boolean> => ({
  type: actionTypes.SET_VIEW_TOC_POC_MODAL,
  payload: viewTocPocModal,
});

export const setDeleteModal = (deleteModal: boolean): Action<boolean> => ({
  type: actionTypes.SET_DELETE_MODAL,
  payload: deleteModal,
});

export const setIsUserInViewMode = (isUserInViewMode: boolean): Action<boolean> => ({
  type: actionTypes.SET_IS_USER_IN_VIEW_MODE,
  payload: isUserInViewMode,
});

export const setCreateTocPocformData = (createTocPocformData: TocPocFormData): Action<TocPocFormData> => ({
  type: actionTypes.SET_IS_USER_IN_VIEW_MODE,
  payload: createTocPocformData,
});

export const setEditTocPocFormData = (editTocPocFormData: TocPocFormData): Action<TocPocFormData> => ({
  type: actionTypes.SET_IS_USER_IN_VIEW_MODE,
  payload: editTocPocFormData,
});

export const setExistingTocPoc = (existingTocPoc: TOCPOCDetailsTableData): Action<TOCPOCDetailsTableData> => ({
  type: actionTypes.SET_EXISTING_TOC_POC,
  payload: existingTocPoc,
});

export const setTabStartIndex = (tabStartIndex: number): Action<number> => ({
  type: actionTypes.SET_TAB_START_INDEX,
  payload: tabStartIndex,
});

export const onClickDelete = (databaseIdOfTOCToBeDeleted: string) => {
  return (dispatch) => {
    const deleteTocRequestBody = {
      oocId: databaseIdOfTOCToBeDeleted,
    };

    const deleteTOCRequstOptions: RequestOptions = {
      params: buParam,
      errorCallback: handleFailureResponseForDeleteRequest,
      headers: headers,
      successCallback: handleSuccessResponse,
      url: API_ENDPOINTS.DELETE_TOC_URL,
      data: deleteTocRequestBody,
      method: Methods.DELETE,
    };

    sendAxiosRequest(deleteTOCRequstOptions, dispatch, initialExistingTocPoc, [initialExistingTocPoc]);
  };
};

const handleSuccessResponse = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
  const alertMessageObj = getSuccessMessageToBeDisplayed(response.data, RequestType.DELETE_REQUEST);
  dispatch(setIsSaveClicked(false));
  dispatch(setSuccessMessage(alertMessageObj));
};

const handleFailureResponseForDeleteRequest = (error, dispatch: Dispatch<AnyAction>) => {
  const errorMessageObject = getErrorMessageToBeDisplayed(error, RequestType.UPDATE_REQUEST);
  dispatch(setSuccessMessage(errorMessageObject));
  dispatch(setIsSaveClicked(false));
};
