import React, { Dispatch, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  BulkTOCUpdateSubmitRequestBody,
  BulkTOCUpdateValidationRequestBody,
  BulkTOCUpdateValidationResponseBody,
  OOCValidationResult,
  ValidationResult,
} from 'src/types/apiModels';
import ExistingTocPocTable from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tabsComponent/tocPocDetailsTab/existingTocPocTable/existingTocPocTable';
import { useTranslation } from 'react-i18next';
import { BulkTOCFormFieldsValidationProps } from 'src/types/componentProps';
import { Button, Loading, P, InputTextField, H4 } from '@clearchannelinternational/ecooh-system';
import { AlertMessageStates, BulkUpdateValidationErrorType, ButtonKind, Methods, RequestType } from 'src/enums/enum';
import { API_ENDPOINTS, buParam, headers } from 'src/api/apiConfig';
import { getInitialBulkTOCUpdateForm } from '../bulkTOCUpdateModalDetails/bulkTOCUpdateModalDetails';
import { OOCDetailsTableType } from 'src/types/table';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { initialSearchFieldDetails } from 'src/redux/reducers/tocPocSidebarTabReducers/searchReducer';
import { AxiosResponse } from 'axios';
import { setIsSaveClicked, setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { AnyAction } from 'redux';
import { SaveReducerState } from 'src/types/saveReducer';
import { setBatchIdForBulkDetailTab } from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';

const BulkTOCFormFieldsValidation: React.FC<BulkTOCFormFieldsValidationProps> = ({
  bulkOOCTableInstance,
  bulkTOCUpdateFormValues,
  selectRef,
  setIsResetClicked,
  onClickBulkUpdateSummary,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
  const [tocDataWhichHasErrors, setTOCDataWhichHasErrors] = useState<OOCDetailsTableType[]>([]);
  const [bulkUpdateDescription, setBulkUpdateDescription] = useState<string>('');
  const { isSaveClicked, successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);

  const onClickReset = () => {
    if (selectRef?.current) {
      selectRef.current.setValue(undefined);
    }
    setIsResetClicked(true);
    setValidationResult(null);
  };

  const getOOCIdsFromSelectedRows = () => {
    return bulkOOCTableInstance.getSelectedRowModel().rows.map((row) => row.original.id);
  };

  const getTOCDataWhichHasErrorsFromSelectedRows = (overlappingTocData: OOCValidationResult[]) => {
    const selectedRows = bulkOOCTableInstance.getSelectedRowModel().rows;
    const tocDataWhichHasErrors = selectedRows
      .filter((tocData) => overlappingTocData.some((row) => row.tocId === tocData.original.id))
      .map((tocData) => ({
        ...tocData.original,
        errorType:
          overlappingTocData.find((row) => row.tocId === tocData.original.id)?.errorDetails.errorType ||
          BulkUpdateValidationErrorType.DEFAULT,
      }));

    setTOCDataWhichHasErrors(tocDataWhichHasErrors);
  };

  const handleSuccessResponseForValidareBulkTOCRequest = (response: AxiosResponse) => {
    const responseData: BulkTOCUpdateValidationResponseBody = response.data;
    setValidationResult(responseData.validationResult);

    if (responseData.validationResult.tocWithErrors.length > 0) {
      getTOCDataWhichHasErrorsFromSelectedRows(responseData.validationResult.tocWithErrors);
    }
    setLoading(false);
  };

  const validateBulkTOCUpdateRequest = () => {
    setLoading(true);

    const bulkTOCUpdateRequestBody: BulkTOCUpdateValidationRequestBody = {
      oocIds: getOOCIdsFromSelectedRows(),
      bulkUpdateRequest: bulkTOCUpdateFormValues,
    };

    const axiosOptions: RequestOptions = {
      method: Methods.POST,
      params: buParam,
      data: bulkTOCUpdateRequestBody,
      errorCallback: handleFailureResponse,
      headers: headers,
      successCallback: handleSuccessResponseForValidareBulkTOCRequest,
      url: API_ENDPOINTS.VALIDATE_BULK_TOC_UPDATE,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [], initialSearchFieldDetails);
  };

  const getEligibleOOCIds = () => {
    const oodIds = getOOCIdsFromSelectedRows();
    return oodIds.filter((id) => !tocDataWhichHasErrors.some((item) => item.id === id));
  };

  const onSubmitBulkUpdate = () => {
    dispatch(setIsSaveClicked(true));
    const bulkTOCUpdateRequestBody: BulkTOCUpdateSubmitRequestBody = {
      oocIds: getEligibleOOCIds(),
      bulkUpdateName: bulkUpdateDescription,
      bulkUpdateRequest: bulkTOCUpdateFormValues,
    };
    const axiosOptions: RequestOptions = {
      method: Methods.PUT,
      params: buParam,
      data: bulkTOCUpdateRequestBody,
      errorCallback: handleFailureResponse,
      headers: headers,
      successCallback: handleSuccessResponseForSubmitBulkUpdate,
      url: API_ENDPOINTS.SUBMIT_BULK_TOC_UPDATE,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [], initialSearchFieldDetails);
  };
  const { batchIdForBulkDetailTab } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );

  useEffect(() => {
    if (batchIdForBulkDetailTab) {
      onClickBulkUpdateSummary(batchIdForBulkDetailTab);
    }
  }, [batchIdForBulkDetailTab]);

  const handleSuccessResponseForSubmitBulkUpdate = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
    if (response.data) {
      dispatch(setBatchIdForBulkDetailTab(response.data.bulkUpdateBatchId));
    }
    const alertMessage = {
      actionStatus: AlertMessageStates.SUCCESS,
      actionMessage: translate('bulkOOCSearchSidebarTab:bulkUpdateSubmitSuccessMessage'),
    };
    dispatch(setSuccessMessage(alertMessage));
  };

  const handleFailureResponse = () => {
    const alertMessage = {
      actionStatus: AlertMessageStates.FAILED,
      actionMessage: translate('bulkOOCSearchSidebarTab:bulkUpdateSubmitFailureMessage'),
    };
    setLoading(false);
    dispatch(setSuccessMessage(alertMessage));
  };

  const getSubmiteButtonLabel = () => {
    return isSaveClicked
      ? translate('bulkOOCSearchSidebarTab:submitting')
      : translate('bulkOOCSearchSidebarTab:submitBulkUpdate');
  };

  const renderValidationResult = () => {
    if (!validationResult) {
      return null;
    }

    return (
      <div className="validation-result">
        <div className="horizontal-line"></div>
        {validationResult.tocWithErrors.length > 0 && (
          <div className="eligible-TOCs-message">
            <P>
              {validationResult.tocWithErrors.length} TOC(s) are not eligible, please find details below. You can skip these{' '}
              {validationResult.tocWithErrors.length} and submit the remaining{' '}
              {bulkOOCTableInstance.getSelectedRowModel().rows.length - validationResult.tocWithErrors.length}
            </P>
          </div>
        )}

        {validationResult.tocWithErrors.length === 0 && (
          <div className="eligible-TOCs-message">
            <P>
              All {bulkOOCTableInstance.getSelectedRowModel().rows.length} TOCs are eligible for update. Please provide a
              short desc and submit the batch below
            </P>
          </div>
        )}

        {tocDataWhichHasErrors.length > 0 && (
          <div className="ineligible-tocs-table">
            <ExistingTocPocTable
              existingTocsOrPoc={tocDataWhichHasErrors}
              searchRequestType={RequestType.OOC_BULK_SEARCH_REQUEST}
            />
          </div>
        )}

        <div className="horizontal-line"></div>
        <H4>Batch Description</H4>
        <div className="batch-description">
          <div className="bulk-update-short-description">
            <InputTextField
              inputType="text"
              labels={{
                fieldLabel: translate('bulkOOCSearchSidebarTab:batchShortDescription'),
              }}
              placeholder={translate('bulkOOCSearchSidebarTab:enterDescription')}
              sizing="medium"
              name="email"
              onChange={(e) => setBulkUpdateDescription(e.value)}
            />
          </div>

          <div className="submit-bulk-update">
            <Button
              renderIcon="send"
              kind={ButtonKind.Primary}
              label={getSubmiteButtonLabel()}
              disabled={bulkUpdateDescription.length === 0 || successMessage || isSaveClicked}
              onClick={() => onSubmitBulkUpdate()}
            />
          </div>
        </div>
        <div className="horizontal-line-white-border"></div>
      </div>
    );
  };

  return (
    <>
      <div className="valid-update-request-button">
        <Button
          renderIcon="filter--reset"
          kind={ButtonKind.Secondary}
          size="small"
          label={translate('bulkOOCSearchSidebarTab:reset')}
          onClick={onClickReset}
          disabled={_.isEqual(bulkTOCUpdateFormValues, getInitialBulkTOCUpdateForm())}
        />
        <Button
          kind={ButtonKind.Primary}
          size="small"
          label={translate('bulkOOCSearchSidebarTab:validteBulkUpdateRequest')}
          disabled={_.isEqual(bulkTOCUpdateFormValues, getInitialBulkTOCUpdateForm())}
          onClick={() => {
            validateBulkTOCUpdateRequest();
            setLoading(true);
          }}
        />
      </div>
      {loading ? <Loading /> : renderValidationResult()}
    </>
  );
};

export default BulkTOCFormFieldsValidation;
