import React, { FC, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { SidebarTabLabels, SidebarTabType, getSideBarTabs } from './sidebarConfig';
import { SidePanel } from '@clearchannelinternational/ecooh-system';
import { AppContext } from 'src/contexts/appContext';
import { useDispatch, useSelector } from 'react-redux';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import { CalendarEventType } from 'src/pages/openingHoursSidebarTab/openingHoursTabsComponent/calendarConfig/calendarConfig';
import { convertDataToEvents } from 'src/pages/openingHoursSidebarTab/openingHoursTabsComponent/openingHoursTabsComponent';
import { setInitialOpeningHoursCalanderEvents } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';

type SidebarProps = {
  setActiveSidebarTab: React.Dispatch<React.SetStateAction<SidebarTabType | undefined>>;
  ActiveSideBarTab: SidebarTabType | undefined;
};

const Sidebar: FC<SidebarProps> = ({ setActiveSidebarTab, ActiveSideBarTab }) => {
  const appContext = useContext(AppContext);
  const [sidebarTabs, setSidebarTabs] = useState<SidebarTabType[]>([]);
  const [activeTab, setActiveTab] = useState('');
  const dispatch = useDispatch();
  const { initialOpeningHoursCalanderEvents, selectedDayPartDate } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );

  const handleTabClick = (tabName: string) => {
    const initialEvents: CalendarEventType = { configData: convertDataToEvents(selectedDayPartDate.operatingPeriod) };
    const isDataChanged =
      Object.entries(selectedDayPartDate.operatingPeriod).length > 0
        ? !_.isEqual(initialEvents, initialOpeningHoursCalanderEvents)
        : false;

    if (ActiveSideBarTab?.label === 'Opening Hours' && isDataChanged) {
      const isConfirmed = window.confirm(
        'You have unsaved changes that will be lost if you leave this page. Are you sure you want to leave this page?'
      );
      if (!isConfirmed) {
        setActiveTab(activeTab);
        return;
      } else {
        dispatch(setInitialOpeningHoursCalanderEvents(initialEvents));
      }
    }

    setActiveTab(tabName);
  };

  const loadSidebarLabelsJsonFile = async (): Promise<SidebarTabLabels> => {
    const sidebarTabLabelsFolderPath = `../../Translation/${appContext.Country}/sidebar.json`;
    const response = await fetch(sidebarTabLabelsFolderPath);
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    loadSidebarLabelsJsonFile().then((sidebarTabLabels: SidebarTabLabels) => {
      const sidebarTabs = getSideBarTabs(sidebarTabLabels).filter((tab) => tab.label != undefined);
      setSidebarTabs(sidebarTabs);
      setActiveTab(Object.values(sidebarTabLabels)[0]);
    });
  }, [appContext.Country]);

  useEffect(() => {
    const initialEvents: CalendarEventType = { configData: convertDataToEvents(selectedDayPartDate.operatingPeriod) };
    const extractedSidebar = Object.values(sidebarTabs).find((tab: SidebarTabType) => tab.label === activeTab);

    const isTabOpeningHours = extractedSidebar?.label === 'Opening Hours';
    const isDataChanged =
      Object.entries(selectedDayPartDate.operatingPeriod).length > 0
        ? !_.isEqual(initialEvents, initialOpeningHoursCalanderEvents)
        : false;

    if (isTabOpeningHours) {
      setActiveSidebarTab(extractedSidebar);
      return;
    }

    if (ActiveSideBarTab?.label === 'Opening Hours' && isDataChanged) {
      const isConfirmed = window.confirm(
        'You have unsaved changes that will be lost if you leave this page. Are you sure you want to leave this page?'
      );
      if (!isConfirmed) {
        return;
      } else {
        dispatch(setInitialOpeningHoursCalanderEvents(initialEvents));
      }
    }

    setActiveSidebarTab(extractedSidebar);
  }, [activeTab]);

  return (
    <div className="sideBar">
      <SidePanel
        backgroundTone="backgroundBase"
        collapsedState="narrow"
        narrowWidth="190px"
        padding="none"
        toggleButtonVisibility="never"
      >
        <img src={'/cciOutdoorLogo.png'} className="accountImg" alt="img" />
        <div className="Sidebar">
          <ul className="Sidebar-tabs">
            {sidebarTabs.map((tab, index: number) => (
              <React.Fragment key={index}>
                {tab.label === 'Frame Search' && <div className="border"></div>}
                <li
                  className={`Sidebar-tab ${activeTab === tab.label ? 'active' : ''}`}
                  onClick={() => handleTabClick(tab.label)}
                  data-testid={`sidebar-tab-${index}`}
                >
                  {tab.label}
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </SidePanel>
    </div>
  );
};

export default Sidebar;
