import React, { useContext, useEffect } from 'react';
import OutOfChargeCheckBoxes from './outOfChargeCheckBoxes/outOfChargeCheckBoxes';
import EventStatusCheckBoxes from './eventStatusCheckBoxes/eventStatusCheckBoxes';
import DatePickerWithSearchCalendar from './datePickerWithSearchCalendar/datePickerWithSearchCalendar';
import _ from 'lodash';
import {
  setBulkTOCSearchFormValues,
  setIsResetClicked,
} from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import Tippy from '@tippyjs/react';
import { AnyAction } from 'redux';
import { AppContext } from 'src/contexts/appContext';
import { H4, Button } from '@clearchannelinternational/ecooh-system';
import { MultiSelect } from '@mantine/core';
import { onClickSearch } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { useTranslation } from 'react-i18next';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { BulkOOCTOCFields, OOCCurrentStatus, OOCDateSearchType, OutOfChargeStatus, RequestType } from 'src/enums/enum';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingReducer } from 'src/types/loadinReducer';
import { SearchReducer } from 'src/types/searchReducer';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { BulkOOCSearchRequestBody } from 'src/types/apiModels';

export const getInitialBulkOOCSearchForm = (): BulkOOCSearchRequestBody => {
  return {
    oocDateCriteria: {
      startDate: {
        searchType: OOCDateSearchType.EQ,
        date: '',
      },
      endDate: {
        searchType: OOCDateSearchType.EQ,
        date: '',
      },
    },
    oocStatus: [OOCCurrentStatus.Active, OOCCurrentStatus.Upcoming],
    tocReasons: [],
    administrationBranches: [],
    oocTypes: [OutOfChargeStatus.TOC],
  };
};

const BulkOOCSearchBar = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const appContext = useContext(AppContext);
  const dateCriteriaConfig = [BulkOOCTOCFields.START_DATE, BulkOOCTOCFields.END_DATE];
  const { modalLoading, loading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { tocReasonsMapping, isResetClicked, bulkTOCSearchFormValues, administrationBranches } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );

  useEffect(() => {
    if (isResetClicked) {
      dispatch(setBulkTOCSearchFormValues({ ...getInitialBulkOOCSearchForm() }));
      dispatch(setIsResetClicked(false));
    }
  }, [isResetClicked]);

  const handleOnClickSearch = () => {
    dispatch(
      onClickSearch(
        searchFieldDetails,
        appContext,
        translate,
        RequestType.OOC_BULK_SEARCH_REQUEST,
        initialExistingTocPoc,
        bulkTOCSearchFormValues
      ) as unknown as AnyAction
    );
  };

  function shouldResetButtonsBeDisabled() {
    return loading || modalLoading || _.isEqual(bulkTOCSearchFormValues, getInitialBulkOOCSearchForm());
  }

  function shouldSearchButtonsBeDisabled() {
    return loading || modalLoading || shouldSearchBarBeDisabledForWhenNoOOCStatusIsSelected();
  }

  const shouldSearchBarBeDisabledForWhenNoOOCStatusIsSelected = () => {
    return (
      bulkTOCSearchFormValues.oocStatus.length === 0 &&
      !bulkTOCSearchFormValues.oocDateCriteria.startDate.date &&
      !bulkTOCSearchFormValues.oocDateCriteria.endDate.date &&
      bulkTOCSearchFormValues.tocReasons.length === 0
    );
  };

  return (
    <div className="bulk-ooc-search-container">
      <div className="bulk-ooc-search-bar">
        <OutOfChargeCheckBoxes
          fetchedData={[]}
          externalIds={[]}
          existingTocPoc={initialExistingTocPoc}
          updateModal={undefined}
          isPocFocused={false}
        />
        <div className="horizontal-line"></div>
        <div className="checkbox-calender-container">
          <div className="event-status-checkbox">
            <div className="status-text">
              <H4>Status</H4>
            </div>
            <EventStatusCheckBoxes
              fetchedData={[]}
              externalIds={[]}
              existingTocPoc={initialExistingTocPoc}
              updateModal={undefined}
              isPocFocused={false}
            />
          </div>
          <DatePickerWithSearchCalendar
            key="foo22"
            fetchedData={[]}
            externalIds={[]}
            existingTocPoc={initialExistingTocPoc}
            updateModal={undefined}
            isPocFocused={false}
            criteriaConfig={dateCriteriaConfig}
          />
          <div className="toc-reason-field-container">
            <MultiSelect
              className="toc-reason-field"
              radius={'md'}
              size="xl"
              label={translate('bulkOOCSearchSidebarTab:tocReason')}
              placeholder={translate('bulkOOCSearchSidebarTab:selectTOCReason')}
              disabled={_.isEqual(bulkTOCSearchFormValues.oocTypes, [OutOfChargeStatus.POC]) || loading}
              data={tocReasonsMapping}
              clearable
              value={bulkTOCSearchFormValues.tocReasons}
              onChange={(e) => dispatch(setBulkTOCSearchFormValues({ ...bulkTOCSearchFormValues, tocReasons: e }))}
            />
          </div>
          <MultiSelect
            className="administration-branch-field"
            radius={'md'}
            size="xl"
            label={translate('bulkOOCSearchSidebarTab:administrationBranch')}
            placeholder={translate('bulkOOCSearchSidebarTab:selectAdministrationBranch')}
            disabled={loading}
            data={administrationBranches}
            clearable
            value={bulkTOCSearchFormValues.administrationBranches}
            onChange={(e) => dispatch(setBulkTOCSearchFormValues({ ...bulkTOCSearchFormValues, administrationBranches: e }))}
          />
        </div>
      </div>

      <div className="bulk-buttons-container">
        <div className="reset-button">
          <Button
            renderIcon="filter--reset"
            kind="secondary"
            size="small"
            label={translate('search:resetButtonLabel')}
            disabled={shouldResetButtonsBeDisabled()}
            onClick={() => dispatch(setIsResetClicked(true))}
          />
        </div>
        <Tippy
          disabled={!shouldSearchBarBeDisabledForWhenNoOOCStatusIsSelected()}
          appendTo={document.body}
          maxWidth="200rem"
          interactive
          arrow={true}
          content={
            <div className="google-maps-content">
              <div className="bulk-search-button-tooltip-text">Please select a search criteria</div>
            </div>
          }
        >
          <div className="search-button">
            <Button
              renderIcon="search"
              kind="primary"
              size="small"
              label={translate('search:searchButtonLabel')}
              disabled={shouldSearchButtonsBeDisabled()}
              onClick={() => handleOnClickSearch()}
            />
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default BulkOOCSearchBar;
