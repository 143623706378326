/* eslint-disable camelcase */
import React, { useContext } from 'react';
import Table from 'src/components/table/table';
import { MRT_ColumnDef } from 'mantine-react-table';
import { AppContext } from 'src/contexts/appContext';
import { ColumnVisibility } from '../../../../../types/table';
import { InitialState } from 'src/types/TocPocModal';
import { Box } from '@mantine/core';
import { SyncHistoryTableData } from 'src/types/table';
import { useSelector } from 'react-redux';
import { SearchReducer } from 'src/types/searchReducer';
import { RequestTypeForSyncHistoryRecord } from 'src/enums/enum';
import { getFormattedDateToBeDisplayed, DATE_TIME_INPUT_FORMAT, DATE_TIME_OUTPUT_FORMAT } from 'src/utils/dateUtils';

export const syncStatusCell = (isSynced: boolean) => (
  <>
    <Box
      className="syncStatusCell"
      sx={() => ({
        backgroundColor: isSynced ? '#E8F5E9' : '#E3E3E3',
        color: isSynced ? '#008008' : 'black',
      })}
    >
      {isSynced ? 'Synchronized' : 'Error'}
    </Box>
  </>
);

const SyncStatusDetailsTab = () => {
  const appContext = useContext(AppContext);
  const { syncHistoryTableData } = useSelector((state: SearchReducer) => state.searchReducer);

  const syncStatusDetailsTableColumns = (): MRT_ColumnDef<SyncHistoryTableData>[] => {
    return [
      {
        accessorKey: 'oocRequesttype',
        header: 'Request type',
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        Cell: (cell) => {
          const requestType = cell.row.original.oocRequesttype;
          return RequestTypeForSyncHistoryRecord[requestType];
        },
        size: 100,
      },
      {
        accessorKey: 'externalSystem',
        header: 'External system',
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        size: 100,
      },
      {
        accessorKey: 'syncStartDate',
        header: 'Sync start date',
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        Cell: (cell) => {
          const updatedDate = cell.row.original.syncStartDate;
          return getFormattedDateToBeDisplayed(updatedDate, DATE_TIME_INPUT_FORMAT, DATE_TIME_OUTPUT_FORMAT);
        },
        size: 115,
      },
      {
        accessorKey: 'syncEndDate',
        header: 'Sync end date',
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        Cell: (cell) => {
          const updatedDate = cell.row.original.syncEndDate;
          return getFormattedDateToBeDisplayed(updatedDate, DATE_TIME_INPUT_FORMAT, DATE_TIME_OUTPUT_FORMAT);
        },
        size: 115,
      },
      {
        accessorKey: 'syncStatus',
        header: 'Sync status',
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        size: 95,
      },
      {
        accessorKey: 'syncByUserType',
        header: 'Synced by',
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        size: 105,
      },
      {
        accessorKey: 'syncInfo',
        header: 'Sync info',
        size: 300,
        Cell: (cell) => {
          const syncHistoryRowData = cell.row.original;
          const externalSystem = cell.row.original.externalSystem;
          const {
            syncTimeTakenInSeconds,
            externalSystemResponseDetails: { statusCode },
          } = syncHistoryRowData;
          return `The synchronization completed in ${syncTimeTakenInSeconds} seconds, and ${externalSystem} returned a status code of ${statusCode}`;
        },
      },
    ];
  };

  const columnVisibilityConfig: ColumnVisibility = {
    oasis: {
      slotId: false,
      aidaSyncStatus: false,
    },
    aida: {
      commercialFrameIds: false,
      siteKey: false,
      digitalUnitIds: false,
      broadSignSyncStatus: false,
      oasisSyncStatus: false,
    },
  };

  const initialState: InitialState = {
    columnVisibility: columnVisibilityConfig[appContext.system],
  };

  return (
    <>
      <div data-testid="sync-status-table-container" className="existingTocPoc-table-container">
        <Table
          columns={syncStatusDetailsTableColumns()}
          data={syncHistoryTableData}
          enableGrouping={false}
          enablePinning={false}
          enableRowSelection={false}
          enablePagination={false}
          enableRowVirtualization={true}
          enableColumnFilterModes={false}
          enableTopToolbar={false}
          enableFilters={false}
          enableSorting={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          initialState={initialState}
        />
      </div>
    </>
  );
};

export default SyncStatusDetailsTab;
