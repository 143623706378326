import { DateValue } from '@mantine/dates';
import dayjs from 'dayjs';

export const INFINITE_DATE = '8000-12-31';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_INPUT_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_TIME_OUTPUT_FORMAT = 'DD-MMM-YYYY HH:mm:ss';
export const DATE_TIME_OUTPUT_FORMAT_WITHOUT_SECONDS = 'DD-MMM-YYYY HH:mm';
export const DATE_FORMAT_TO_BE_DISPLAYED = 'DD-MMM-YYYY';
export const opInfiniteDate = '9999-12-31T23:59';

export const getFormattedDate = (date: DateValue) => {
  if (date === null) {
    return '';
  }
  return dayjs(date).format(DATE_FORMAT);
};

export const getFormattedDateToBeDisplayed = (
  dateToBeFormatted: string,
  inputFormat: string,
  outputFormat: string
): string => {
  const parsedDate = dayjs(dateToBeFormatted, inputFormat);
  return parsedDate.format(outputFormat);
};

export const formatDateToMMMDDYYYY = (date: string): string => {
  return dayjs(date).format('MMM DD YYYY');
};
