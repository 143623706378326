import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

//Import all translation files
import headerEn from './Translation/UK/header.json';
import headerFr from './Translation/FR/header.json';
import footerEn from './Translation/UK/footer.json';
import footerFr from './Translation/FR/footer.json';
import sidebarEn from './Translation/UK/sidebar.json';
import searchEn from './Translation/UK/search.json';
import searchFr from './Translation/FR/search.json';
import createTocPocModalEn from './Translation/UK/createNewTocPocModal.json';
import createTocPocModalFr from './Translation/FR/createNewTocPocModal.json';
import tableEn from './Translation/UK/table.json';
import tableFr from './Translation/FR/table.json';
import bulkOOCSearchSidebarTabEn from './Translation/UK/bulkOOCSearchSidebarTab.json';
import openingHoursSIdebarTabEn from './Translation/UK/openingHoursSIdebarTab.json';

const resources = {
  en: {
    header: headerEn,
    footer: footerEn,
    sideBar: sidebarEn,
    search: searchEn,
    createNewTocPocModal: createTocPocModalEn,
    table: tableEn,
    bulkOOCSearchSidebarTab: bulkOOCSearchSidebarTabEn,
    openingHoursSIdebarTab: openingHoursSIdebarTabEn,
  },
  fr: {
    header: headerFr,
    footer: footerFr,
    sideBar: sidebarEn,
    search: searchFr,
    createNewTocPocModal: createTocPocModalFr,
    table: tableFr,
  },
};

if (i18next) {
  i18next.use(initReactI18next).init({
    resources,
    lng: 'en',
  });
}

export default i18next;
