/* eslint-disable camelcase */
import React from 'react';
import Table from 'src/components/table/table';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { OOCAttributes } from 'src/enums/enum';
import { BulkUpdateDetailsProps } from 'src/types/componentProps';
import { useSelector } from 'react-redux';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';

type BulkUpdateChangeDetails = {
  tocFields: string;
  value: string;
};

const BulkUpdateChangeDetails: React.FC<BulkUpdateDetailsProps> = ({ bulkUpdateDetails }) => {
  const { t: translate } = useTranslation();
  const { tocReasonsMapping } = useSelector((state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer);

  const auditTableChangeDetailsColumns = (): MRT_ColumnDef<BulkUpdateChangeDetails>[] => {
    return [
      {
        accessorKey: 'tocFields',
        header: 'Fields',
      },
      {
        accessorKey: 'value',
        header: 'Value',
      },
    ];
  };

  const changeDetailsData = () => {
    const TOCAttributesArray = [
      OOCAttributes.START_DATE,
      OOCAttributes.END_DATE,
      OOCAttributes.REASON_CODE,
      OOCAttributes.COMMENT,
    ];
    return bulkUpdateDetails
      ? TOCAttributesArray.map((field) => {
          if (field === OOCAttributes.REASON_CODE) {
            const foundItem = tocReasonsMapping.find((item) => item.value === bulkUpdateDetails[`${field}`])?.label;
            return {
              tocFields: translate(`table:${field}`),
              value: foundItem ? foundItem : '',
            };
          }

          return {
            tocFields: translate(`table:${field}`),
            value: bulkUpdateDetails[`${field}`] ? bulkUpdateDetails[`${field}`] : '',
          };
        }).filter((fieldData) => fieldData.value !== '')
      : [];
  };

  return (
    <>
      <div data-testid="table-container" className="table-container">
        <Table
          columns={auditTableChangeDetailsColumns()}
          data={changeDetailsData()}
          enableGrouping={false}
          enablePinning={false}
          enableRowSelection={false}
          enablePagination={false}
          enableRowVirtualization={false}
          enableColumnFilterModes={false}
          enableTopToolbar={false}
          enableFilters={false}
          enableSorting={false}
          enableColumnActions={false}
          enableColumnFilters={false}
        />
      </div>
    </>
  );
};

export default BulkUpdateChangeDetails;
