export function getAwsConfig() {
  return {
    Auth: {
      region: process.env.REACT_APP_AUTH_REGION,
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL,
      userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT,
      oauth: {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        redirectSignIn: `${process.env.REACT_APP_SITE_URL}`,
        redirectSignOut: `${process.env.REACT_APP_SITE_URL}`,
        responseType: 'code',
      },
    },
  };
}
