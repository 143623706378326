/* eslint-disable camelcase */
import {
  SET_SEARCH_FIELD_DETAILS,
  SET_TABLE_DATA,
  SET_FETCHED_DATA_FOR_CREATE_TOC_POC_MODAL,
  SET_FETCHED_DATA_FOR_TOC_POC_TABLE_DETAILS,
  SET_SYNC_HISTORY_TABLE_DATA,
  SET_TOTAL_RECORDS_FOR_OOC_SEARCH_TABLE,
  SET_FETCHED_DATA_FOR_FRAME_SEARCH_REQUEST,
  SET_SHOW_TABLE_FORM,
  SET_FETCHED_DATA_FOR_OPENING_HOURS_SEARCH_REQUEST,
  initialSearchFieldDetails,
} from '../../reducers/tocPocSidebarTabReducers/searchReducer';
import {
  SearchBoxFieldType,
  SearchFieldDetails,
  getBulkTOCSearchPageFieldsList,
  getInputFieldsList,
} from 'src/components/searchBox/inputFieldsConfig';
import {
  FetchedTOCPOCData,
  SearchForCreateEditTOCPOCResponseBody,
  SearchTOCRequestDetails,
  SyncStatusDetails,
  BaseTOCFields,
  SyncHistoryResponseData,
  TOCReasonsResponseData,
  BulkOOCSearchRequestBody,
  FetchedBulkTOCPOCData,
  BulkUpdateDetailsResponseData,
  SyncHistorySearchRequestDetails,
  FrameSearchResponseBody,
  FrameScheduleSearchResponse,
} from 'src/types/apiModels';
import {
  TOCPOCCurrentStatus,
  ExternalSystems,
  SearchFields,
  SyncStatusStates,
  Methods,
  OOC_API_PAGE_LIMITS,
  FrameType,
} from 'src/enums/enum';
import { AxiosResponse } from 'axios';
import { Action } from 'src/types/searchReducer';
import { AppContextType, Countries } from '../../../contexts/appContext';
import { AnyAction, Dispatch } from 'redux';
import { TOCPOCDetailsTableData } from '../../../types/searchReducer';
import { Translate } from 'src/types/TocPocModal';
import { TFunction } from 'i18next';
import { RequestType } from 'src/enums/enum';
import { sendAxiosRequest } from 'src/api/axios';
import { SyncHistoryTableData } from 'src/types/table';
import { API_ENDPOINTS, buParam, params } from 'src/api/apiConfig';
import { headers } from '../../../api/apiConfig';
import { getAllInputFieldsToBeRedered } from '../../../components/searchBox/inputFieldsConfig';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { setLoading, setModalLoading, setSyncStatusTabLoading } from '../loadingActions';
import { setUsersDetails } from '../authTokensActions/authTokensActions';
import {
  setAdministrationBranches,
  setTocReasonsMapping,
} from '../bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import { areDisplayUnitIdsUnique } from 'src/pages/openingHoursSidebarTab/utils/utils';

export const setSearchFieldDetails = (details: SearchFieldDetails): Action<SearchFieldDetails> => ({
  type: SET_SEARCH_FIELD_DETAILS,
  payload: details,
});

export const setTableData = (
  data: TOCPOCDetailsTableData[] | TOCPOCDetailsTableData[]
): Action<TOCPOCDetailsTableData[] | TOCPOCDetailsTableData[]> => ({
  type: SET_TABLE_DATA,
  payload: data,
});

export const setSyncHistoryTableData = (data: SyncHistoryTableData[]): Action<SyncHistoryTableData[]> => ({
  type: SET_SYNC_HISTORY_TABLE_DATA,
  payload: data,
});

export const setFetchedDataForCreateTocPocModal = (
  data: SearchForCreateEditTOCPOCResponseBody | undefined
): Action<SearchForCreateEditTOCPOCResponseBody> => ({
  type: SET_FETCHED_DATA_FOR_CREATE_TOC_POC_MODAL,
  payload: data,
});

export const setFetchedDataForTocPocTableDetails = (data: FetchedTOCPOCData[]): Action<FetchedTOCPOCData[]> => ({
  type: SET_FETCHED_DATA_FOR_TOC_POC_TABLE_DETAILS,
  payload: data,
});

export const setFetchedDataForFrameSearchRequest = (data: FrameSearchResponseBody): Action<FrameSearchResponseBody> => ({
  type: SET_FETCHED_DATA_FOR_FRAME_SEARCH_REQUEST,
  payload: data,
});

export const setFetchedDataForOpeningHoursRequest = (
  data: FrameScheduleSearchResponse
): Action<FrameScheduleSearchResponse> => ({
  type: SET_FETCHED_DATA_FOR_OPENING_HOURS_SEARCH_REQUEST,
  payload: data,
});

export const setTotalRecordsForOOCSearchTable = (totalRecordsForOOCSearchTable: number): Action<number> => ({
  type: SET_TOTAL_RECORDS_FOR_OOC_SEARCH_TABLE,
  payload: totalRecordsForOOCSearchTable,
});

export const setShowTableform = (showTableForm: boolean): Action<boolean> => ({
  type: SET_SHOW_TABLE_FORM,
  payload: showTableForm,
});

export const getAndSetAllUsers = () => {
  return (dispatch: Dispatch) => {
    const handleSuccessResponseForGetAllUsers = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
      const users = response.data;
      dispatch(setUsersDetails(users));
    };

    const axiosOptions = {
      method: Methods.GET,
      params: buParam,
      headers: headers,
      url: API_ENDPOINTS.ALL_USERS_SEARCH,
      errorCallback: handlerFailureResponse,
      successCallback: handleSuccessResponseForGetAllUsers,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [initialExistingTocPoc], initialSearchFieldDetails);
  };
};

export const getAndSetTOCReasons = (frameType: FrameType) => {
  return (dispatch: Dispatch) => {
    const convertResponseToTOCReasonsMapping = (inputMapping: TOCReasonsResponseData) => {
      return inputMapping.tocReasons.map((reason) => ({
        label: reason.text,
        value: reason.code,
      }));
    };
    const handleSuccessResponseForGetTOCReasons = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
      const tocReasonsConvertedMappings = convertResponseToTOCReasonsMapping(response.data);
      dispatch(setTocReasonsMapping(tocReasonsConvertedMappings));
    };

    const axiosOptions = {
      method: Methods.GET,
      params: { ...buParam, frameType: frameType },
      headers: headers,
      url: API_ENDPOINTS.TOC_REASONS,
      errorCallback: handlerFailureResponse,
      successCallback: handleSuccessResponseForGetTOCReasons,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [initialExistingTocPoc], initialSearchFieldDetails);
  };
};

export const getAndSetAdministrationBranches = (frameType: FrameType) => {
  return (dispatch: Dispatch) => {
    const convertResponseToAdministrationBranchMappings = (administrationBranches: string[]) => {
      return administrationBranches.map((administrationBranch) => ({
        label: administrationBranch,
        value: administrationBranch,
      }));
    };
    const handleSuccessResponseForAdministrationBranches = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
      const administrationBrnaches: string[] = response.data ? response.data.values : [];
      const administrationBrnachesMappings = convertResponseToAdministrationBranchMappings(administrationBrnaches);
      dispatch(setAdministrationBranches(administrationBrnachesMappings));
    };

    const params = { ...buParam, attr_name: 'commercialInfo.administrationBranch', frameType };
    const axiosOptions = {
      method: Methods.GET,
      params: params,
      headers: headers,
      url: API_ENDPOINTS.V2_DISTINCT,
      errorCallback: handlerFailureResponse,
      successCallback: handleSuccessResponseForAdministrationBranches,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [initialExistingTocPoc], initialSearchFieldDetails);
  };
};

export const onClickSearch = (
  searchFieldDetails: SearchFieldDetails,
  appContext: AppContextType,
  translate: Translate,
  searchRequestType: RequestType,
  existingTocPoc?: TOCPOCDetailsTableData,
  bulkTOCSearchFormValues?: BulkOOCSearchRequestBody,
  bulkUpdateDetailsResponseData?: BulkUpdateDetailsResponseData
) => {
  return (dispatch) => {
    if (
      searchRequestType === RequestType.TOC_POC_SEARCH_REQUEST ||
      searchRequestType === RequestType.OOC_BULK_SEARCH_REQUEST ||
      searchRequestType === RequestType.FRAME_SEARCH_REQUEST ||
      searchRequestType === RequestType.OPENING_HOURS_SEARCH_REQUEST
    ) {
      dispatch(setLoading(true));
    }
    if (searchRequestType === RequestType.CREATE_TOC_POC_MODAL_SEARCH_REQUEST) {
      dispatch(setModalLoading(true));
    }
    if (searchRequestType === RequestType.SYNC_HISTORY_REQUEST) {
      dispatch(setSyncStatusTabLoading(true));
    }
    const axiosOptions = getRequestOptions(
      searchRequestType,
      searchFieldDetails,
      existingTocPoc,
      bulkTOCSearchFormValues,
      bulkUpdateDetailsResponseData
    );
    return sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [initialExistingTocPoc], searchFieldDetails);
  };
};

export const getUpdatedSearchFormValues = (bulkTOCSearchFormValues?: BulkOOCSearchRequestBody) => {
  if (!bulkTOCSearchFormValues) {
    return;
  }

  const getUpdatedOOCDateCriteria = () => {
    const { oocDateCriteria } = bulkTOCSearchFormValues;
    if (
      !oocDateCriteria ||
      Object.values(oocDateCriteria).length === 0 ||
      (!oocDateCriteria.startDate.date && !oocDateCriteria.endDate.date)
    ) {
      return undefined;
    }

    const { startDate, endDate } = oocDateCriteria;
    if (startDate.date && !endDate.date) {
      return { startDate, endDate: undefined };
    } else if (!startDate.date && endDate.date) {
      return { startDate: undefined, endDate };
    } else {
      return { startDate, endDate };
    }
  };

  const { oocStatus, oocTypes, tocReasons, administrationBranches } = bulkTOCSearchFormValues;
  const updatedFormValues = {
    oocDateCriteria: getUpdatedOOCDateCriteria(),
    oocStatus: oocStatus.length !== 0 ? oocStatus : undefined,
    oocTypes: oocTypes.length !== 0 ? oocTypes : undefined,
    tocReasons: tocReasons.length !== 0 ? tocReasons : undefined,
    administrationBranches: administrationBranches.length !== 0 ? administrationBranches : undefined,
  };

  return updatedFormValues;
};

function getDataForSearchField(accessorKey: string, value: string | number | Date): Record<string, any> | undefined {
  return {
    siteKeys: accessorKey === SearchFields.SITE_KEY ? [value] : undefined,
    panelIds: accessorKey === SearchFields.PANEL_ID ? [value] : undefined,
    digitalUnitIds: accessorKey === SearchFields.DIGITAL_UNIT_ID ? [parseInt(value as string)] : undefined,
    commercialFrameIds: accessorKey === SearchFields.COMMERCIAL_FRAME_ID ? [parseInt(value as string)] : undefined,
    broadsignFrameIds: accessorKey === SearchFields.BROADSIGN_CONTROL_FRAME_ID ? [value] : undefined,
  };
}

const getRequestOptions = (
  searchRequestType: RequestType,
  searchFieldDetails: SearchFieldDetails,
  existingTocPoc?: TOCPOCDetailsTableData,
  bulkTOCSearchFormValues?: BulkOOCSearchRequestBody,
  bulkUpdateDetailsResponseData?: BulkUpdateDetailsResponseData
) => {
  const updatedBulkSearchFormValues = getUpdatedSearchFormValues(bulkTOCSearchFormValues);
  const syncHistoryRequestData: SyncHistorySearchRequestDetails = {
    oocId: existingTocPoc ? existingTocPoc.id : '',
    batchId:
      bulkUpdateDetailsResponseData && Object.values(bulkUpdateDetailsResponseData.batchSummary).length !== 0
        ? bulkUpdateDetailsResponseData.batchSummary.batchUUID
        : undefined,
  };

  const searchCriteria: SearchTOCRequestDetails = {
    searchCriterias: [
      {
        field: searchFieldDetails.accessorKey,
        value: getValueWithSuitableType(searchFieldDetails),
      },
    ],
  };

  const commonsOptions = {
    method: Methods.POST,
    params: params,
    data: searchCriteria,
    errorCallback: handlerFailureResponse(),
    headers: headers,
  };

  switch (searchRequestType) {
    case RequestType.TOC_POC_SEARCH_REQUEST:
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForSearchTOCPOCRequest,
        url: API_ENDPOINTS.TOC_POC_SEARCH_URL,
        params: { ...params },
      };
    case RequestType.FRAME_SEARCH_REQUEST:
      const data = getDataForSearchField(searchFieldDetails.accessorKey, searchFieldDetails.value);
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForFrameSearchRequest,
        url: API_ENDPOINTS.FRAME_SEARCH_REQUEST,
        params: { ...params },
        data: data,
      };
    case RequestType.OPENING_HOURS_SEARCH_REQUEST:
      const data2 = getDataForSearchField(searchFieldDetails.accessorKey, searchFieldDetails.value);
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForOpeningHoursRequest,
        url: API_ENDPOINTS.OPENING_HOURS_SEARCH_REQUEST,
        params: { ...buParam },
        data: searchCriteria,
      };
    case RequestType.CREATE_TOC_POC_MODAL_SEARCH_REQUEST:
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForCreateTOCPOCModalSearchRequest,
        url: API_ENDPOINTS.OOC_SEARCH_URL_FOR_CREATE_MODAL,
        params: { ...params, limit: OOC_API_PAGE_LIMITS.CREATE_TOC_POC_MODAL_SEARCH_REQUEST },
      };
    case RequestType.OOC_BULK_SEARCH_REQUEST:
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForSearchTOCPOCRequest,
        url: API_ENDPOINTS.OOC_BULK_SEARCH,
        data: { ...updatedBulkSearchFormValues },
        params: { ...buParam },
      };
    case RequestType.SYNC_HISTORY_REQUEST:
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForSyncHistorySearchRequest,
        url: API_ENDPOINTS.OOC_SYNC_HISTOEY,
        data: syncHistoryRequestData,
        params: { ...params, limit: OOC_API_PAGE_LIMITS.SYNC_HISTORY_REQUEST },
      };
    default:
      throw new Error('Invalid searchRequestType');
  }
};

export const handleSuccessResponseForSyncHistorySearchRequest = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
  const fetchedSyncHistoryData: SyncHistoryResponseData = response.data;
  const syncHistorytableData = fetchedSyncHistoryData.syncHistories.map((syncHistoryData) => {
    return {
      ...syncHistoryData,
      syncInfo: '',
    };
  });

  dispatch(setSyncHistoryTableData(syncHistorytableData));
};

export const processFetchedTOCPOCData = (
  fetchedTOCPOCData: FetchedTOCPOCData[] | FetchedBulkTOCPOCData[],
  requestType?: RequestType
): TOCPOCDetailsTableData[] => {
  return fetchedTOCPOCData.map((tocPocData: FetchedTOCPOCData | FetchedBulkTOCPOCData) =>
    transformTOCPOCData(tocPocData, requestType)
  );
};

export const handleSuccessResponseForCreateTOCPOCModalSearchRequest = (
  response: AxiosResponse,
  dispatch: Dispatch<AnyAction>
) => {
  const fetchedTOCPOCData: SearchForCreateEditTOCPOCResponseBody = response.data;
  const fetchedData = fetchedTOCPOCData as SearchForCreateEditTOCPOCResponseBody;
  dispatch(setFetchedDataForCreateTocPocModal(fetchedData));
};

export const handleSuccessResponseForSearchTOCPOCRequest = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
  const searchTOCPOCRequestResponseBody = response.data;
  const totalCountOfRecords = searchTOCPOCRequestResponseBody.totalCount;
  const fetchedData = searchTOCPOCRequestResponseBody.data;
  const tocPocTableData = processFetchedTOCPOCData(fetchedData);
  dispatch(setTableData(tocPocTableData));
  dispatch(setTotalRecordsForOOCSearchTable(totalCountOfRecords));
  dispatch(setFetchedDataForTocPocTableDetails(fetchedData));
  return tocPocTableData;
};

export const handleSuccessResponseForFrameSearchRequest = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
  const searchTOCPOCRequestResponseBody: FrameSearchResponseBody = response.data;
  dispatch(setFetchedDataForFrameSearchRequest(searchTOCPOCRequestResponseBody));
};

export const handleSuccessResponseForOpeningHoursRequest = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
  const searchTOCPOCRequestResponseBody: FrameScheduleSearchResponse = response.data;
  sortTheResponseWithIncreasingOrderOfPanelNo(searchTOCPOCRequestResponseBody);
  dispatch(setFetchedDataForOpeningHoursRequest(searchTOCPOCRequestResponseBody));
};

export const transformTOCPOCData = (
  tocPocData: FetchedTOCPOCData | FetchedBulkTOCPOCData,
  requestType?: RequestType
): TOCPOCDetailsTableData => {
  const fetchedTocPocData = { ...tocPocData } as FetchedTOCPOCData;
  if (requestType === RequestType.OOC_BULK_SEARCH_REQUEST) {
    fetchedTocPocData.oocDetails = (tocPocData as FetchedBulkTOCPOCData).tocDetails;
  }
  const { syncStatus, ...baseData } = fetchedTocPocData.oocDetails;
  const statusStage = getStatusStage(baseData.startDate, (baseData as BaseTOCFields).endDate, fetchedTocPocData);
  const oasisSyncStatusObject = getSyncStatusObject(syncStatus, ExternalSystems.OASIS);

  const broadSignSyncStatusObject = getSyncStatusObject(fetchedTocPocData.oocDetails.syncStatus, ExternalSystems.BROADSIGN);
  return {
    id: tocPocData.id,
    ...tocPocData.frameIdentifiers,
    ...baseData,
    outOfChargeStatus: tocPocData.oocType,
    tocPocCurrentStatus: statusStage,
    oasisSyncStatus: oasisSyncStatusObject?.syncInfo.syncDetails.syncStatus ?? SyncStatusStates.DEFAULT,
    broadsignSyncStatus: broadSignSyncStatusObject?.syncInfo.syncDetails.syncStatus ?? SyncStatusStates.DEFAULT,
    isMultiPanel: tocPocData.frameIdentifiers.isMultiPanel,
    isOperative: fetchedTocPocData.oocDetails.isOperative,
    administrationBranch: fetchedTocPocData.frameIdentifiers.administrationBranch,
  };
};

const getSyncStatusObject = (syncStatus: SyncStatusDetails[], externalSystem: ExternalSystems) => {
  return syncStatus.find((syncStatusDetails) => syncStatusDetails.externalSystem === externalSystem);
};

const getStatusStage = (startDate: string, endDate: string, tocPocData: FetchedTOCPOCData): TOCPOCCurrentStatus => {
  const currentDate = new Date();
  if (!tocPocData.oocDetails.isOperative) {
    return TOCPOCCurrentStatus.Deleted;
  } else if (new Date(startDate) > currentDate) {
    return TOCPOCCurrentStatus.Upcoming;
  } else if (new Date(endDate) < currentDate) {
    return TOCPOCCurrentStatus.Expired;
  } else {
    return TOCPOCCurrentStatus.Active;
  }
};
export const getInputFieldAccessorKey = (appContext: AppContextType, inputLabel: string, translate: Translate) => {
  const inputFieldObject = getAllInputFieldsToBeRedered(appContext, translate).find(
    (inputField) => inputField.label === inputLabel
  );

  return inputFieldObject ? inputFieldObject.accessorKey : SearchFields.DEFAULT;
};

export const getBulkTOCSearchPageFieldsAccessorKey = (
  appContext: AppContextType,
  inputLabel: string,
  translate: Translate
) => {
  const inputFieldObject = getAllInputFieldsToBeRedered(appContext, translate).find(
    (inputField) => inputField.label === inputLabel
  );

  return inputFieldObject ? inputFieldObject.accessorKey : SearchFields.DEFAULT;
};

export const getInputFieldsListToBeRendered = (appContext: AppContextType, translate: Translate) => {
  const countryCode = appContext.Country;
  return getInputFieldsList(countryCode, translate).filter((field: SearchBoxFieldType) =>
    field.countryCode.includes(countryCode)
  );
};

export const getInputFieldsListToBeRenderedWithDisplayUnitId = (
  countryCode: Countries,
  translate: Translate
): SearchBoxFieldType[] => {
  const inputFieldsList = getInputFieldsList(countryCode, translate).filter((field: SearchBoxFieldType) =>
    field.countryCode.includes(countryCode)
  );
  const displayUnitIdField: SearchBoxFieldType = {
    label: translate('table:displayUnitId'),
    inputType: 'text',
    countryCode: ['UK'],
    accessorKey: SearchFields.DISPLAY_UNIT_ID,
  };
  return [...inputFieldsList, displayUnitIdField];
};

export const getBulkTOCSearchInputFieldsListToBeRendered = (
  appContext: AppContextType,
  t: TFunction<'translation', undefined>
) => {
  const countryCode = appContext.Country;
  return getBulkTOCSearchPageFieldsList(countryCode, t).filter((field: SearchBoxFieldType) =>
    field.countryCode.includes(countryCode)
  );
};

function sortTheResponseWithIncreasingOrderOfPanelNo(searchTOCPOCRequestResponseBody: FrameScheduleSearchResponse) {
  const haveUniqueDisplayUnitIds = areDisplayUnitIdsUnique(searchTOCPOCRequestResponseBody);

  if (!haveUniqueDisplayUnitIds && searchTOCPOCRequestResponseBody.length === 1) {
    searchTOCPOCRequestResponseBody[0].frameIdentifiers.panelIdIdentifierMappings.sort(
      (a, b) => parseInt(a.associatedAttributesForPanel.panelNumber) - parseInt(b.associatedAttributesForPanel.panelNumber)
    );
  } else {
    searchTOCPOCRequestResponseBody.sort(
      (a, b) =>
        parseInt(a.frameIdentifiers.panelIdIdentifierMappings[0].associatedAttributesForPanel.panelNumber) -
        parseInt(b.frameIdentifiers.panelIdIdentifierMappings[0].associatedAttributesForPanel.panelNumber)
    );
  }
}

function getValueWithSuitableType(searchFieldDetails: SearchFieldDetails): string | number | Date {
  return searchFieldDetails.accessorKey === SearchFields.COMMERCIAL_FRAME_ID ||
    searchFieldDetails.accessorKey === SearchFields.DIGITAL_UNIT_ID
    ? parseInt((searchFieldDetails.value as string).trim())
    : (searchFieldDetails.value as string).trim();
}

export function handlerFailureResponse() {
  return () => console.error('error');
}
