import { AnyAction } from 'redux';
import { BulkOOCSearchSidebarTabState } from 'src/types/bulkOOCSearchSidebarTab';
import { BulkUpdateDetailsResponseData, BulkUpdateSummariesResponseData, FetchedBulkTOCPOCData } from 'src/types/apiModels';
import { getInitialBulkOOCSearchForm } from 'src/pages/bulkOOCSearchSidebarTab/bulkOOCSearchBar/bulkOOCSearchBar';

export const SET_TOC_REASONS_MAPPING = 'SET_TOC_REASONS_MAPPING';
export const SET_IS_RESET_CLICKED = 'SET_IS_RESET_CLICKED';
export const SET_TOC_REASONS = 'SET_TOC_REASONS';
export const SET_TAB_START_INDEX = 'SET_TAB_START_INDEX';
export const BULK_UPDATE_DETAILS_TABLE_RESPONSE_DATA = 'BULK_UPDATE_DETAILS_TABLE_RESPONSE_DATA';
export const SET_BATCH_ID_FOR_BULK_DETAIL_TAB = 'SET_BATCH_ID_FOR_BULK_DETAIL_TAB';
export const AUTO_REFRESH_CHECKED = 'AUTO_REFRESH_CHECKED';
export const BULK_SEARCH_FORM_VALUES = 'BULK_SEARCH_FORM_VALUES';
export const IS_BULK_RECORDS_DOWNLOADING = 'IS_BULK_RECORDS_DOWNLOADING';
export const SET_ADMINISTRATION_BRANCHES = 'SET_ADMINISTRATION_BRANCHES';

export const initialUpdateDetailsResponseData: BulkUpdateDetailsResponseData = {
  batchSummary: {} as BulkUpdateSummariesResponseData,
  tocs: {} as FetchedBulkTOCPOCData[],
};

const initialState: BulkOOCSearchSidebarTabState = {
  tocReasonsMapping: [],
  isResetClicked: false,
  tabStartIndex: 0,
  bulkUpdateDetailsResponseData: initialUpdateDetailsResponseData,
  batchIdForBulkDetailTab: '',
  autoRefreshChecked: false,
  bulkTOCSearchFormValues: getInitialBulkOOCSearchForm(),
  isBulkRecordsDownloading: false,
  administrationBranches: [],
};

const bulkOOCSearchSidebarTabReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_TOC_REASONS_MAPPING:
      return {
        ...state,
        tocReasonsMapping: action.payload,
      };
    case SET_IS_RESET_CLICKED:
      return {
        ...state,
        isResetClicked: action.payload,
      };
    case SET_TAB_START_INDEX:
      return {
        ...state,
        tabStartIndex: action.payload,
      };
    case BULK_UPDATE_DETAILS_TABLE_RESPONSE_DATA:
      return {
        ...state,
        bulkUpdateDetailsResponseData: action.payload,
      };
    case SET_BATCH_ID_FOR_BULK_DETAIL_TAB:
      return {
        ...state,
        batchIdForBulkDetailTab: action.payload,
      };
    case AUTO_REFRESH_CHECKED:
      return {
        ...state,
        autoRefreshChecked: action.payload,
      };
    case BULK_SEARCH_FORM_VALUES:
      return {
        ...state,
        bulkTOCSearchFormValues: action.payload,
      };
    case IS_BULK_RECORDS_DOWNLOADING:
      return {
        ...state,
        isBulkRecordsDownloading: action.payload,
      };
    case SET_ADMINISTRATION_BRANCHES:
      return {
        ...state,
        administrationBranches: action.payload,
      };
    default:
      return state;
  }
};

export default bulkOOCSearchSidebarTabReducer;
