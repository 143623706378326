export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_IS_SAVE_CLICKED = 'SET_IS_SAVE_CLICKED';

const initialState = {
  isSaveClicked: false,
  successMessage: undefined,
};

const saveReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload,
      };
    case SET_IS_SAVE_CLICKED:
      return {
        ...state,
        isSaveClicked: action.payload,
      };
    default:
      return state;
  }
};

export default saveReducer;
