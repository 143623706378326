import React, { FC, useEffect, useState } from 'react';
import { Checkbox } from '@clearchannelinternational/ecooh-system';
import { DatePickerInput } from '@mantine/dates';
import { OOCAttributes, OutOfChargeStatus } from 'src/enums/enum';
import { RenderPocInputFieldsProps } from 'src/types/componentProps';
import { useTranslation } from 'react-i18next';
import { BasePOCFields, BaseTOCFields } from 'src/types/apiModels';
import { SearchReducer } from 'src/types/searchReducer';
import { useSelector } from 'react-redux';

const RenderPocInputFields: FC<RenderPocInputFieldsProps> = ({
  existingTocPoc,
  isPOCFocused,
  handleInputChange,
  formValues,
  existingTocsOrPoc,
  selectedPanels,
}) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const { fetchedDataForCreateTocPocModal } = useSelector((state: SearchReducer) => state.searchReducer);

  const getMaxEndDate = () => {
    const filteredTOCData =
      existingTocsOrPoc && existingTocsOrPoc.filter((data) => data.outOfChargeStatus === OutOfChargeStatus.TOC);
    if (!Array.isArray(filteredTOCData) || filteredTOCData.length === 0) {
      return new Date();
    }
    const maxEndDateString = filteredTOCData.reduce((maxEndDate, exitingOOC) => {
      const existingToc = exitingOOC as BaseTOCFields;
      return existingToc.endDate > maxEndDate ? existingToc.endDate : maxEndDate;
    }, (filteredTOCData[0] as BaseTOCFields).endDate);
    const maxEndDate = new Date(maxEndDateString);

    maxEndDate.setDate(maxEndDate.getDate() + 1);

    return maxEndDate;
  };

  const renderDay = (date: Date) => {
    return (
      <div
        style={{
          pointerEvents: 'none',
          color: new Date(date) >= getMaxEndDate() ? 'black' : undefined,
        }}
      >
        {date.getDate()}
      </div>
    );
  };

  function getPocStartDate() {
    const existingPoc = existingTocPoc;
    if (formValues.outOfChargeStatus === OutOfChargeStatus.POC && formValues.startDate) {
      return new Date(formValues.startDate);
    }
    if (existingPoc.startDate) {
      return new Date(existingPoc.startDate);
    }
    return undefined;
  }

  function shouldDismantleSiteBeDisabled(): boolean {
    if (fetchedDataForCreateTocPocModal) {
      return (
        existingTocPoc.outOfChargeStatus === OutOfChargeStatus.POC ||
        (fetchedDataForCreateTocPocModal.frameIdentifiers.isMultiPanel && formValues.panelIds.length === 1)
      );
    }
    return false;
  }

  useEffect(() => {
    if (
      fetchedDataForCreateTocPocModal &&
      fetchedDataForCreateTocPocModal.frameIdentifiers.isMultiPanel &&
      selectedPanels.length === 1
    ) {
      setChecked(false);
      handleInputChange(OOCAttributes.DISMANTLE_SITE, false);
    }
  }, [selectedPanels]);

  return (
    <div data-testid="render-poc-input-fields">
      {isPOCFocused && (
        <div className="poc-input-fields-container">
          <div className="dismantle-site-checkbox-container">
            <span
              className={`checkbox-label ${
                existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.DEFAULT || !shouldDismantleSiteBeDisabled()
                  ? ''
                  : 'disabled'
              }`}
            >
              {t('table:dismantleSite')}:
            </span>
            <div className="externalID-checkboxes">
              <Checkbox
                testId="dismantle-site-checkbox"
                checked={checked || (existingTocPoc as BasePOCFields).dismantleSite}
                disabled={existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.DEFAULT || shouldDismantleSiteBeDisabled()}
                onChange={() => {
                  handleInputChange(OOCAttributes.DISMANTLE_SITE, !checked);
                  setChecked(!checked);
                }}
              />
            </div>
          </div>

          <div className="poc-date-picker-input-container">
            <span className="date-label">
              {t('table:startDate')}
              <span> *</span>
            </span>
            <DatePickerInput
              data-testid="date-picker-input"
              className="mantine-date-picker-input"
              minDate={getMaxEndDate()}
              renderDay={renderDay}
              placeholder={t('createNewTocPocModal:selectStartDate')}
              disabled={existingTocPoc.outOfChargeStatus === OutOfChargeStatus.POC}
              value={getPocStartDate()}
              onChange={(e) => {
                if (e != null) {
                  handleInputChange(OOCAttributes.START_DATE, e.toDateString());
                }
              }}
              mx="auto"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RenderPocInputFields;
