/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  auditRequestBody,
  fetchNextPageOptions,
  onClickAuditSearch,
  setAuditTableData,
  setIsScrollingAuditRecords,
  setIsSearchForAllRecordsButtonClicked,
} from 'src/redux/actions/auditSidebarTabAction/auditSidebarTabAction';
import {
  getBulkTOCSearchInputFieldsListToBeRendered,
  getInputFieldsListToBeRendered,
  setSearchFieldDetails,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import SearchBox from 'src/components/searchBox/searchBox';
import {
  MRT_Virtualizer,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_TableInstance,
  MantineReactTable,
} from 'mantine-react-table';
import { OutOfChargeStatus, RequestType, SearchFields } from 'src/enums/enum';
import { AppContext, systemsConfig } from 'src/contexts/appContext';
import { AuditDetailsTableType, ColumnVisibility } from 'src/types/table';
import { InitialState } from 'src/types/TocPocModal';
import { Loading, Button, H4 } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { AuthTokensReducer, TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { useTranslation } from 'react-i18next';
import { SearchReducer } from 'src/types/searchReducer';
import { AuditViewSidebarTabProps } from 'src/types/componentProps';
import { useInfiniteQuery } from '@tanstack/react-query';
import { queryClient } from '../../../src';
import { getUserAndActionFields } from 'src/components/searchBox/inputFieldsConfig';
import { AuditSidebarTabReducer } from '../../types/auditSidebarTabReducer';
import { getAuditViewTableColumns } from './auditViewSidebarTabConfig/auditViewSidebarTableConfig';
import { digitalUnitIdAccessorKey } from '../tocPocTabSidebarTab/tocPocDetailsTable/tocPocDetailsTableConfig';
import { LoadingReducer } from 'src/types/loadinReducer';
import { setAuditTableLoading } from 'src/redux/actions/loadingActions';
import { MantineProvider } from '@mantine/core';
import { setTabStartIndex } from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';

const AuditViewTab: React.FC<AuditViewSidebarTabProps> = ({ isAuditSidebarTabView }) => {
  const auditTableLimit = 100;
  const minimumRecordsRequiredForTableToScroll = 12;
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const fieldsConfig = systemsConfig[appContext.system][appContext.Country];
  const { userDetails } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);
  const { t } = useTranslation();
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { auditTableLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { isUserInViewMode, existingTocPoc, tabStartIndex } = useSelector(
    (state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer
  );
  const { auditTableData, isScrollingAuditRecords, isSearchForAllRecordsButtonClicked } = useSelector(
    (state: AuditSidebarTabReducer) => state.auditSidebarTabReducer
  );
  const { bulkUpdateDetailsResponseData } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const inputFieldsToBeRedered = [
    ...getInputFieldsListToBeRendered(appContext, t),
    ...getBulkTOCSearchInputFieldsListToBeRendered(appContext, t),
    ...getUserAndActionFields(appContext.Country, t),
  ];

  const { data, fetchNextPage, isFetching } = useInfiniteQuery<any>({
    queryKey: ['table-data'],
    enabled: false,
    queryFn: async ({ pageParam = 0 }) => {
      const payload = isSearchForAllRecordsButtonClicked ? ({} as any) : searchFieldDetails;
      return await onClickAuditSearch(
        payload,
        dispatch,
        existingTocPoc,
        auditRequestBody(existingTocPoc, payload, userDetails, bulkUpdateDetailsResponseData),
        pageParam,
        auditTableLimit
      );
    },
    getNextPageParam: (lastGroup, groups) => auditTableLimit * groups.length,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (!isLastPageSizeIsEqualToZero()) {
      setTotalRecords(flatData.length);
    }

    const paginationRowCount = tableInstance.getPaginationRowModel().rows.length;

    if (
      !isUserInViewMode &&
      tableInstance.getState().columnFilters.length !== 0 &&
      paginationRowCount <= minimumRecordsRequiredForTableToScroll
    ) {
      dispatch(setAuditTableLoading(false));
      return;
    }

    if (isFetching && !isScrollingAuditRecords) {
      setTotalRecords(0);
      dispatch(setAuditTableLoading(true));
    } else {
      dispatch(setIsScrollingAuditRecords(false));
      dispatch(setAuditTableLoading(false));
    }
  }, [isFetching]);

  useEffect(() => {
    if (existingTocPoc.outOfChargeStatus === OutOfChargeStatus.DEFAULT) {
      dispatch(
        setSearchFieldDetails({
          label: t('table:digitalUnitId'),
          value: '',
          accessorKey: SearchFields.DIGITAL_UNIT_ID,
        })
      );
      dispatch(setAuditTableData([]));
      queryClient.clear();
      dispatch(setTabStartIndex(0));
    }
  }, []);

  useEffect(() => {
    if (!isUserInViewMode) {
      queryClient.clear();
    }
  }, [isUserInViewMode]);

  useEffect(() => {
    if (tabStartIndex === 1 && flatData.length === 0) {
      fetchNextPage(fetchNextPageOptions);
    }
  }, [tabStartIndex]);

  useEffect(() => {
    if (!isUserInViewMode) {
      dispatch(setAuditTableLoading(true));
      setTimeout(() => {
        dispatch(setAuditTableLoading(false));
      }, 500);
    }
  }, [appContext.Country]);

  useEffect(() => {
    tableInstance.setColumnFilters([]);
  }, [searchFieldDetails]);

  const flatData = useMemo(() => data?.pages.flatMap((page) => page) ?? [], [data]);

  const totalFetched = flatData.length;

  function isLastPageSizeIsEqualToZero() {
    //isLastPageSizeIsEqualToLimit
    return data && data.pages[data.pages.length - 1]?.length !== 0; //params.limit;
  }
  function shouldCallAuditApi(scrollHeight: number, scrollTop: number, clientHeight: number) {
    return (
      scrollHeight - parseInt(scrollTop.toString()) - clientHeight < 300 &&
      !isFetching &&
      isLastPageSizeIsEqualToZero() &&
      !isUserInViewMode
    );
  }

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (shouldCallAuditApi(scrollHeight, scrollTop, clientHeight)) {
          dispatch(setIsScrollingAuditRecords(true));
          fetchNextPage({ cancelRefetch: true });
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched]
  );

  useEffect(() => {
    setTimeout(() => {
      if (rowVirtualizerInstanceRef && rowVirtualizerInstanceRef.current && !isFetching && data && data?.pages.length > 1) {
        try {
          const pixelsToScroll = 200;
          rowVirtualizerInstanceRef.current.scrollToOffset(rowVirtualizerInstanceRef.current.scrollOffset + pixelsToScroll, {
            behavior: 'smooth',
          });
        } catch (e) {
          return;
        }
      }
    }, 1000);
  }, [flatData]);

  const columnVisibilityConfig: ColumnVisibility = {
    oasis: {
      slotId: false,
      aidaSyncStatus: false,
      panelId: false,
      commercialFrameId: false,
      frameIds: false,
    },
    aida: {
      commercialFrameIds: false,
      siteKey: false,
      digitalUnitIds: false,
      broadSignSyncStatus: false,
      oasisSyncStatus: false,
    },
  };

  const initialState: InitialState = {
    columnVisibility: columnVisibilityConfig[appContext.system],
    showColumnFilters: true,
    columnPinning: {
      left: [digitalUnitIdAccessorKey],
    },
  };

  function handleOnScrollAuditRecords(event) {
    if (!isFetching) {
      fetchMoreOnBottomReached(event.target as HTMLDivElement);
    }
  }

  async function handleAuditSearchRequest() {
    queryClient.clear();
    await fetchNextPage(fetchNextPageOptions);

    dispatch(setIsSearchForAllRecordsButtonClicked(false));
  }

  useEffect(() => {
    if (isSearchForAllRecordsButtonClicked) {
      handleAuditSearchRequest();
    }
  }, [isSearchForAllRecordsButtonClicked]);

  const tableInstance = useMantineReactTable({
    columns: getAuditViewTableColumns(fieldsConfig, t, flatData, userDetails) as MRT_ColumnDef<any>[],
    data: flatData && flatData[0] ? flatData : [],
    enableGrouping: isAuditSidebarTabView,
    enablePinning: isAuditSidebarTabView,
    enableRowSelection: false,
    enablePagination: false,
    enableRowVirtualization: isAuditSidebarTabView,
    enableColumnFilterModes: isAuditSidebarTabView,
    enableTopToolbar: isAuditSidebarTabView,
    enableFilters: isAuditSidebarTabView,
    enableSorting: isAuditSidebarTabView,
    enableColumnActions: isAuditSidebarTabView,
    enableColumnFilters: isAuditSidebarTabView,
    initialState: initialState,
    mantineTableContainerProps: {
      onScroll: handleOnScrollAuditRecords,
      sx: { maxHeight: '800px', minHeight: isUserInViewMode ? '' : '400px' },
    },
    state: {
      // isLoading,
      showProgressBars: isFetching,
    },
    rowVirtualizerProps: {
      overscan: 10,
      scrollingDelay: 20,
    },
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,
  }) as MRT_TableInstance<AuditDetailsTableType>;

  useEffect(() => {
    if (
      !isUserInViewMode &&
      flatData.length != 0 &&
      tableInstance.getPaginationRowModel().rows.length < minimumRecordsRequiredForTableToScroll &&
      isLastPageSizeIsEqualToZero()
    ) {
      fetchNextPage();
    }
  }, [tableInstance.getPaginationRowModel().rows]);

  return (
    <div data-testid="audit-search-sidebar-tab">
      {!isUserInViewMode && (
        <div className="search-new-button-container">
          <SearchBox
            searchRequestType={RequestType.AUDIT_SEARCH_REQUEST}
            inputFieldsListToBeRendered={inputFieldsToBeRedered}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching}
          />
          <div className="or-text">
            <H4>OR</H4>
          </div>
          <div className="search-all-audit-records-button">
            <Button
              label="Click here to search for all records"
              renderIcon="search"
              kind="secondary"
              onClick={() => dispatch(setIsSearchForAllRecordsButtonClicked(true))}
              disabled={auditTableLoading || isFetching}
            />
          </div>
        </div>
      )}

      {auditTableLoading ? (
        <Loading />
      ) : (
        auditTableData && (
          <div
            data-testid="table-container"
            className={isUserInViewMode ? 'existingTocPoc-table-container' : 'audit-table-container'}
          >
            {flatData.length !== 0 && !isUserInViewMode && (
              <>
                Fetched {flatData.length} of {totalRecords === 0 ? '...' : totalRecords} records
              </>
            )}
            <MantineProvider theme={{ primaryColor: 'blue', primaryShade: 8 }}>
              <MantineReactTable table={tableInstance as any} />
            </MantineProvider>
            {flatData.length !== 0 && !isUserInViewMode && (
              <>
                Fetched {flatData.length} of {totalRecords === 0 ? '...' : totalRecords} records
              </>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default AuditViewTab;
