import { AxiosError } from 'axios';
import { RequestType, AlertMessageStates, SyncStatusStates, ExternalSystems } from 'src/enums/enum';
import { OperatingPeriodsFormValues } from 'src/pages/openingHoursSidebarTab/openingHoursSidebarTab';
import { AlertMsgObj } from 'src/types/saveReducer';
import { formatDateToMMMDDYYYY } from 'src/utils/dateUtils';

function calculateTimeDifference(dateTimeString) {
  const givenDate = new Date(dateTimeString).getTime();
  const currentDate = Date.now();
  const timeDifferenceMs = givenDate - currentDate;
  const timeDifferenceMinutes = Math.floor(timeDifferenceMs / 60000);
  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  return rtf.format(timeDifferenceMinutes, 'minute');
}

export function getSuccessMessageToBeDisplayed(syncInfo: any, requestType: RequestType, requestBody?: any): AlertMsgObj {
  const syncData = (syncInfo as any).syncStatuses ? (syncInfo as any).syncStatuses : syncInfo;
  const successSystems = syncData
    .filter(
      (system) =>
        system.syncInfo.syncDetails.syncStatus === SyncStatusStates.SUCCESS ||
        system.syncInfo.syncDetails.syncStatus === SyncStatusStates.SCHEDULED
    )
    .map((system) => system.externalSystem);

  const failedSystems = syncData
    .filter(
      (system) =>
        system.syncInfo.syncDetails.syncStatus === SyncStatusStates.FAILURE ||
        system.syncInfo.syncDetails.syncStatus === SyncStatusStates.EMAIL_FAILED ||
        system.syncInfo.syncDetails.syncStatus === SyncStatusStates.UNEXPECTED_FAILURE_FROM_EXTERNAL_SYSTEM
    )
    .map((system) => ({
      system: system.externalSystem,
      errorDesc: system.syncInfo.syncDetails.syncDetail.desc || '[Error description]',
      // nextSyncSchedule: system.syncInfo.syncDetails.nextSyncSchedule
      //   ? `Next sync attempt: ${calculateTimeDifference(system.syncInfo.syncDetails.nextSyncSchedule.scheduleTime)}`
      //   : '',
    }));

  const messages: string[] = generateSyncMessages(successSystems, failedSystems, requestType, requestBody);
  let actionStatus: AlertMessageStates;
  if (failedSystems.length > 0 && requestType === RequestType.RESYNC_REQUEST) {
    actionStatus = AlertMessageStates.FAILURE;
  } else if (failedSystems.length > 0) {
    actionStatus = AlertMessageStates.PENDING;
  } else {
    actionStatus = AlertMessageStates.SUCCESS;
  }
  const alertMessageObj: AlertMsgObj = {
    actionStatus: actionStatus,
    actionMessage: messages.join('\n'),
  };
  return alertMessageObj;
}

export function getErrorMessageToBeDisplayed(errorObject: AxiosError, requestType: RequestType) {
  const response = errorObject.response;
  const status = response ? response.status : (undefined as any);
  const body = response ? response.data : undefined;

  const statusToMessageMap = {
    400: (body) => {
      if (body && body.errors && body.errors.length > 0) {
        const errorMessages = body.errors.map((error) => `- ${error.msg || 'Invalid input'}`);
        return [`Error: Invalid input provided.`, ...errorMessages];
      }
      return ['Error: Invalid input provided.'];
    },
    403: (body) => {
      if (body.error && body.error.description) {
        return [`Error: ${body.error.description}`];
      }
      return ['Error: Forbidden. Access denied.'];
    },
    401: () => ['Error: Authentication failed. Please verify your credentials.'],
    default: () => ['Error: An unexpected error occurred. Please contact support for assistance.'],
  };

  const messageFunction = statusToMessageMap[status] || statusToMessageMap.default;
  const action = getActionName(requestType);
  const OOCorOH = requestType === RequestType.OPERATING_PERIOD_UPDATE_REQUEST ? 'Operating Period' : 'OOC ';
  const firstMessage = `Error in ${OOCorOH} ${action}${action ? '' : 'synchronization'}:`;

  const errorMessages: string[] = [firstMessage];

  errorMessages.push(...messageFunction(body));

  const errorMessageObj: AlertMsgObj = {
    actionStatus: AlertMessageStates.FAILURE,
    actionMessage: errorMessages.join('\n'),
  };
  return errorMessageObj;
}

export const getActionName = (requestType: RequestType) => {
  switch (requestType) {
    case RequestType.CREATE_REQUEST:
      return 'creation ';
    case RequestType.UPDATE_REQUEST:
      return 'updation ';
    case RequestType.DELETE_REQUEST:
      return 'deletion ';
    case RequestType.RESYNC_REQUEST:
      return '';
    case RequestType.OPERATING_PERIOD_UPDATE_REQUEST:
      return 'updation ';
    default:
      return '';
  }
};

function generateSyncMessages(
  successSystems: string[],
  failedSystems: any[],
  requestType: RequestType,
  requestBody?: any
): string[] {
  const action = getActionName(requestType);
  const messages: string[] = [];

  if (requestType === RequestType.OPERATING_PERIOD_UPDATE_REQUEST && requestBody) {
    const date = new Date(requestBody[0].effectiveFromDate);
    date.setDate(date.getDate() - 1);

    if (successSystems.includes(ExternalSystems.BROADSIGN)) {
      messages.push(
        `${
          ExternalSystems.BROADSIGN
        }: This change will be synced with Broadsign Control around 11 PM on ${formatDateToMMMDDYYYY(date.toISOString())}`
      );
    }

    if (successSystems.includes(ExternalSystems.OASIS)) {
      messages.push(`${ExternalSystems.OASIS}: An e-mail has been sent to Oasis with the details of this update.`);
    }

    return messages;
  }

  const isOHorOOC = requestType === RequestType.OPERATING_PERIOD_UPDATE_REQUEST ? 'Operating hours' : 'OOC';
  if (successSystems.length > 0) {
    messages.push(`${isOHorOOC} ${action}synchronization:`);
    messages.push(`- Successful for ${successSystems.join(', ')}`);
  }

  if (failedSystems.length > 0) {
    if (successSystems.length === 0) {
      messages.push(`OOC ${action}synchronization:`);
    }

    messages.push(`- Failed for ${failedSystems.map((system) => system.system).join(', ')}`);

    failedSystems.forEach((system) => {
      messages.push('');
      messages.push(`Details for the ${system.system} synchronization failure:`);
      messages.push(`- Error: ${system.errorDesc}`);
      // messages.push(`- ${system.nextSyncSchedule}`);
    });
  }
  return messages;
}
