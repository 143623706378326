import React, { useContext } from 'react';
import ViewModal from './viewModal/viewModal';
import ResyncModal from './resyncModal/resyncModal';
import DeleteModal from './deleteModal/deleteModal';
import { onClickSearch } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { TocPocActionsProps } from 'src/types/componentProps';
import { RequestType } from 'src/enums/enum';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { AppContext } from 'src/contexts/appContext';
import { useTranslation } from 'react-i18next';
import { SearchFieldDetails } from 'src/components/searchBox/inputFieldsConfig';
import { isReadWriteRole } from 'src/utils/roleUtils';
import { AuthTokensReducer } from 'src/types/componentReducers';

const TocPocActions: React.FC<TocPocActionsProps> = ({
  row,
  isBulkTocSearchTab,
  searchRequestType,
  bulkUpdateDetailsTableData,
  tableData,
  onClickBulkUpdateSummary,
}) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const handleOnClickSearch = (searchFieldDetails: SearchFieldDetails, searchRequestType: RequestType) => {
    dispatch(onClickSearch(searchFieldDetails, appContext, translate, searchRequestType) as unknown as AnyAction);
  };
  const { userRole } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);

  return (
    <>
      <div className="buttonContainer">
        <ViewModal
          row={row}
          handleOnClickSearch={handleOnClickSearch}
          isBulkTocSearchTab={false}
          searchRequestType={searchRequestType}
          tableData={tableData}
          bulkUpdateDetailsTableData={bulkUpdateDetailsTableData}
        />
        {isReadWriteRole(userRole) && (
          <>
            <ResyncModal row={row} tableData={tableData} onClickBulkUpdateSummary={onClickBulkUpdateSummary} />
            {!isBulkTocSearchTab && (
              <DeleteModal
                row={row}
                handleOnClickSearch={handleOnClickSearch}
                isBulkTocSearchTab={isBulkTocSearchTab}
                searchRequestType={searchRequestType}
                tableData={tableData}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TocPocActions;
