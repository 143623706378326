import { AxiosResponse, AxiosError } from 'axios';
import { API_BASE_URL, buParam, headers } from 'src/api/apiConfig';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { getSuccessMessageToBeDisplayed, getErrorMessageToBeDisplayed } from 'src/components/alert/alertMessages';
import {
  ExternalSystems,
  SyncStatusStates,
  OperatingPeriodType,
  WeekToShortFormMapping,
  Methods,
  RequestType,
} from 'src/enums/enum';
import {
  GroupedWeeklyTimeRanges,
  OperatingPeriodsFormValues,
  SelectedFrameDetailRow,
} from 'src/pages/openingHoursSidebarTab/openingHoursSidebarTab';
import { CalendarEventType } from 'src/pages/openingHoursSidebarTab/openingHoursTabsComponent/calendarConfig/calendarConfig';
import { actionTypes } from 'src/redux/reducers/openingHoursSidebarTabReducer/openingHoursSidebarTabReducer';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import {
  OperatingPeriod,
  WeeklyTimeRange,
  OpeningHoursTableData,
  SyncStatusDetails,
  FrameScheduleSearchResponse,
} from 'src/types/apiModels';
import { Action } from 'src/types/searchReducer';
import { getFormattedDate } from 'src/utils/dateUtils';
import { setModalLoading } from '../loadingActions';
import { setSuccessMessage } from '../tocPocSidebarTabActions/saveActions';
import { AnyAction, Dispatch } from 'redux';
import { areDisplayUnitIdsUnique } from 'src/pages/openingHoursSidebarTab/utils/utils';
import _ from 'lodash';

export const setInitialOpeningHoursCalanderEvents = (
  initialOpeningHoursCalanderEvents: CalendarEventType
): Action<CalendarEventType> => ({
  type: actionTypes.SET_INITIAL_OPENING_HOURS_CALENDER_EVENTS,
  payload: initialOpeningHoursCalanderEvents,
});

export const setSelectedDayPartData = (
  selectedDayPartData: OperatingPeriodsFormValues
): Action<OperatingPeriodsFormValues> => ({
  type: actionTypes.SET_SELECTED_DAY_PART,
  payload: selectedDayPartData,
});

export const setIsViewButtonClicked = (isViewButtonClicked: boolean): Action<boolean> => ({
  type: actionTypes.IS_VIEW_BUTTON_CLICKED,
  payload: isViewButtonClicked,
});

export function buildOperatingPeriodsData(nonBlockingOperatingPeriods: OperatingPeriod[] | undefined, panelId: string) {
  return nonBlockingOperatingPeriods
    ? nonBlockingOperatingPeriods.map((operatingPeriod) => {
        return {
          name: OperatingPeriodType.BASE,
          startDate: operatingPeriod.dateAndTimeRanges.startDate,
          endDate: operatingPeriod.dateAndTimeRanges.endDate,
          oasisSystemSyncStatus: getSystemSyncDetails(ExternalSystems.OASIS, operatingPeriod),
          broadsignSystemSyncStatus: getSystemSyncDetails(ExternalSystems.BROADSIGN, operatingPeriod),
          operatingPeriod: groupOpeningHours(operatingPeriod.dateAndTimeRanges.weeklyTimeRanges),
          operatingPeriodDBId: operatingPeriod.operatingPeriodDbId,
        } as OpeningHoursTableData;
      })
    : [];
}

const getSystemSyncDetails = (externalSystem: ExternalSystems, operatingPeriod: OperatingPeriod): SyncStatusStates => {
  return operatingPeriod.syncStatusDetails.find((syncStatus) => syncStatus.externalSystem === externalSystem)?.syncInfo
    .syncDetails.syncStatus as SyncStatusStates;
};

const groupOpeningHours = (dateAndTimeRanges: WeeklyTimeRange[]) => {
  const grouped: GroupedWeeklyTimeRanges = {};

  Object.keys(WeekToShortFormMapping).forEach((fullDay) => {
    grouped[fullDay] = dateAndTimeRanges
      .filter(({ dayOfWeek }) => dayOfWeek === fullDay)
      .map(({ startTime, endTime }) => ({ start: startTime, end: endTime }));
  });

  return grouped;
};

const getUpdateOPRequestBody = (
  fetchedDataForOpeningHoursSearchRequest: FrameScheduleSearchResponse,
  openingHoursFormValues: OperatingPeriodsFormValues,
  effectiveDateFrom: Date | null,
  isSyncToOtherPanelsChecked: boolean,
  selectedFrameDetailRow: SelectedFrameDetailRow
) => {
  const updatedFetchedData = isSyncToOtherPanelsChecked
    ? fetchedDataForOpeningHoursSearchRequest
    : fetchedDataForOpeningHoursSearchRequest.filter((data) =>
        data.frameIdentifiers.panelIds.includes(selectedFrameDetailRow.panelId)
      );
  return updatedFetchedData.map((frameSchedule) => {
    const getOperatingPeriodId = () => {
      if (frameSchedule.nonBlockingOperatingPeriods.length === 1) {
        return frameSchedule.nonBlockingOperatingPeriods[0].operatingPeriodDbId;
      }
      const lastIndex = frameSchedule.nonBlockingOperatingPeriods.length - 1;
      return frameSchedule.nonBlockingOperatingPeriods[lastIndex].operatingPeriodDbId;
    };

    const getStarDate = () => {
      if (frameSchedule.nonBlockingOperatingPeriods.length === 1) {
        return frameSchedule.nonBlockingOperatingPeriods[0].dateAndTimeRanges.startDate;
      }
      const lastIndex = frameSchedule.nonBlockingOperatingPeriods.length - 1;
      return frameSchedule.nonBlockingOperatingPeriods[lastIndex].dateAndTimeRanges.startDate;
    };

    const getEndDate = () => {
      if (frameSchedule.nonBlockingOperatingPeriods.length === 1) {
        return frameSchedule.nonBlockingOperatingPeriods[0].dateAndTimeRanges.endDate;
      }
      const lastIndex = frameSchedule.nonBlockingOperatingPeriods.length - 1;
      return frameSchedule.nonBlockingOperatingPeriods[lastIndex].dateAndTimeRanges.endDate;
    };

    return {
      frameScheduleId: frameSchedule.frameScheduleDbId,
      operatingPeriodId: getOperatingPeriodId(),
      startDate: getFormattedDate(new Date(getStarDate())),
      endDate: getFormattedDate(new Date(getEndDate())),
      weeklyTimeRanges: convertToWeeklyTimeRange(openingHoursFormValues.operatingPeriod),
      effectiveFromDate: getFormattedDate(effectiveDateFrom),
    };
  });
};

export const handleOnClickSaveChanges = (
  dispatch: Dispatch<AnyAction>,
  openingHoursFormValues: OperatingPeriodsFormValues,
  fetchedDataForOpeningHoursSearchRequest: FrameScheduleSearchResponse,
  effectiveDateFrom: Date | null,
  isSyncToOtherPanelsChecked: boolean,
  selectedFrameDetailRow: SelectedFrameDetailRow
) => {
  dispatch(setModalLoading(true));
  const operatingPeriodDbId = openingHoursFormValues.operatingPeriodDbId;
  if (fetchedDataForOpeningHoursSearchRequest) {
    const requestBody = getUpdateOPRequestBody(
      fetchedDataForOpeningHoursSearchRequest,
      openingHoursFormValues,
      effectiveDateFrom,
      isSyncToOtherPanelsChecked,
      selectedFrameDetailRow
    );
    const openingHoursDbId = fetchedDataForOpeningHoursSearchRequest[0].frameScheduleDbId;
    const axiosOptions: RequestOptions = {
      url: `${API_BASE_URL}fs/${openingHoursDbId}/op/${operatingPeriodDbId}`,
      method: Methods.PUT,
      params: { ...buParam },
      data: requestBody,
      errorCallback: (err) => handleFailureResponse(err, dispatch),
      headers: headers,
      successCallback: (res: AxiosResponse) => handleSuccessResponseForUpdateOperatingPeriod(res, dispatch, requestBody),
    };

    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, []);
  }
};

const handleSuccessResponseForUpdateOperatingPeriod = (
  response: AxiosResponse,
  dispatch: Dispatch<AnyAction>,
  requestBody?: any
) => {
  const syncStatusResponse = response.data;
  const syncStatuses: SyncStatusDetails[] = syncStatusResponse.length !== 0 ? syncStatusResponse[0].syncStatuses : [];
  const alertMessageObj = getSuccessMessageToBeDisplayed(
    syncStatuses,
    RequestType.OPERATING_PERIOD_UPDATE_REQUEST,
    requestBody
  );
  dispatch(setSuccessMessage(alertMessageObj));
};

const handleFailureResponse = (error: AxiosError, dispatch: Dispatch<AnyAction>) => {
  const errorMessage = getErrorMessageToBeDisplayed(error, RequestType.OPERATING_PERIOD_UPDATE_REQUEST);
  dispatch(setSuccessMessage(errorMessage));
};

const convertToWeeklyTimeRange = (input: GroupedWeeklyTimeRanges): WeeklyTimeRange[] => {
  const updatedWeeklyTimeRange = adjustEndTimes(input);
  return Object.keys(updatedWeeklyTimeRange).reduce<WeeklyTimeRange[]>((acc, dayOfWeek) => {
    const timeRanges = updatedWeeklyTimeRange[dayOfWeek];
    timeRanges.forEach(({ start, end }) => {
      acc.push({
        dayOfWeek,
        startTime: start,
        endTime: end,
      });
    });
    return acc;
  }, []);
};

const adjustEndTimes = (input: GroupedWeeklyTimeRanges): GroupedWeeklyTimeRanges => {
  const output = { ...input };
  for (const day in output) {
    output[day] = output[day].map((timeRange) => {
      // Convert endTime to Date object
      const endTimeDate = new Date(`1970-01-01T${timeRange.end}Z`);

      // Check if minutes are 29 or 59
      const minutes = endTimeDate.getUTCMinutes();
      if (minutes === 29) {
        // Add one minute
        endTimeDate.setUTCMinutes(minutes + 1);
        endTimeDate.setUTCSeconds(0);
        // Convert back to string
        const endTime = endTimeDate.toISOString().slice(11, 19);
        return { ...timeRange, end: endTime };
      }

      return timeRange;
    });
  }
  return output;
};

export const checkIfRowsHaveDifferentOpeningHours = (
  fetchedDataForOpeningHoursSearchRequest: FrameScheduleSearchResponse
) => {
  if (fetchedDataForOpeningHoursSearchRequest && areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
    if (fetchedDataForOpeningHoursSearchRequest.every((fs) => fs.nonBlockingOperatingPeriods.length === 2)) {
      // in this case compare only weeklytimeRanges of second operating period in nonBlockingOperatingPeriods
      const firstRowOpeningHours =
        fetchedDataForOpeningHoursSearchRequest[0].nonBlockingOperatingPeriods[1].dateAndTimeRanges.weeklyTimeRanges;
      return fetchedDataForOpeningHoursSearchRequest.some((frameSchedule) => {
        const openingHours = frameSchedule.nonBlockingOperatingPeriods[1].dateAndTimeRanges.weeklyTimeRanges;
        return JSON.stringify(firstRowOpeningHours) !== JSON.stringify(openingHours);
      });
    }

    const firstRowOpeningHours = fetchedDataForOpeningHoursSearchRequest[0].nonBlockingOperatingPeriods.map(
      (op) => op.dateAndTimeRanges.weeklyTimeRanges
    );
    return fetchedDataForOpeningHoursSearchRequest.some((frameSchedule) => {
      const openingHours = frameSchedule.nonBlockingOperatingPeriods.map((op) => op.dateAndTimeRanges.weeklyTimeRanges);
      return JSON.stringify(firstRowOpeningHours) !== JSON.stringify(openingHours);
    });
  }
  return false;
};
