import React from 'react';
import {
  setFetchedDataForTocPocTableDetails,
  setSearchFieldDetails,
  setTotalRecordsForOOCSearchTable,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import BulkOOCSearchUpdateTable from './bulkOOCSearchUpdateTab/bulkOOCSearchUpdateTab';
import BulkUpdateSummariesTab from './bulkUpdateSummariesTab/bulkUpdateSummariesTab';
import BulkUpdateDetailsTab from './bulkUpdateDetailsTab/bulkUpdateDetailsTab';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { queryClient } from '../..';
import { useTranslation } from 'react-i18next';
import { Tabs, Loading } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessUnits, Methods, OOC_API_PAGE_LIMITS, SearchFields } from 'src/enums/enum';
import { AppContext } from 'src/contexts/appContext';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import {
  setBatchIdForBulkDetailTab,
  setBulkTOCSearchFormValues,
  setBulkUpdateDetailsTableResponseData,
  setTabStartIndex,
} from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import {
  setBulkPageLoading,
  setBulkUpdateDetailsTabLoading,
  setBulkUpdateSummarriesPageLoading,
  setLoading,
} from 'src/redux/actions/loadingActions';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { API_ENDPOINTS, buParam, headers } from 'src/api/apiConfig';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { initialSearchFieldDetails } from 'src/redux/reducers/tocPocSidebarTabReducers/searchReducer';
import { AxiosError, AxiosResponse } from 'axios';
import { BulkUpdateDetailParams, BulkUpdateDetailsResponseData, BulkUpdateSummariesResponseData } from 'src/types/apiModels';
import { LoadingReducer } from 'src/types/loadinReducer';
import { getInitialBulkOOCSearchForm } from './bulkOOCSearchBar/bulkOOCSearchBar';

const BulkTocSearchSidebarTab = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const endDateLabel = translate('table:tocEndDate');
  const { tabStartIndex } = useSelector((state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer);
  const { bulkPageLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const [bulkSummariesResponseData, setBulkSummariesResponseData] = useState<BulkUpdateSummariesResponseData[]>([]);
  const [bulkUpdateSummariesTableRowCount, setBulkUpdateSummariesTableRowCount] = useState(0);
  const [isBulkUpdateSummariesTabClicked, setIsBulkUpdateSummariesTabClicked] = useState(false);

  useEffect(() => {
    dispatch(
      setSearchFieldDetails({
        label: endDateLabel,
        value: '',
        accessorKey: SearchFields.OOC_END_DATE,
      })
    );
    queryClient.clear();
    dispatch(setFetchedDataForTocPocTableDetails(undefined as any));
    dispatch(setTabStartIndex(0));
    dispatch(setBatchIdForBulkDetailTab(''));
    dispatch(setBulkTOCSearchFormValues(getInitialBulkOOCSearchForm()));
    dispatch(setTotalRecordsForOOCSearchTable(0));
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 500);
  }, [appContext.Country]);

  useEffect(() => {
    if (bulkPageLoading) {
      dispatch(setBulkPageLoading(false));
    } else {
      // dispatch(setTabStartIndex(0));
    }
  }, [bulkPageLoading, tabStartIndex]);

  const handleSuccessResponseForBulkUpdateSummariesData = (response: AxiosResponse<BulkUpdateSummariesResponseData[]>) => {
    if (response.data) {
      const responseData: any = response.data;
      setBulkUpdateSummariesTableRowCount(responseData.totalCount);
      const bulkUpdateSummarriesData = responseData.data;
      setBulkSummariesResponseData(bulkUpdateSummarriesData);
    }
    dispatch(setBulkUpdateSummarriesPageLoading(false));
    setIsBulkUpdateSummariesTabClicked(false);
  };

  const handleBulkUpdateSummariesTabClick = (offset: number, limit: number, isSummariesTabClicked: boolean) => {
    dispatch(setTabStartIndex(1));
    if (isSummariesTabClicked) {
      setIsBulkUpdateSummariesTabClicked(true);
    }
    dispatch(setBulkUpdateSummarriesPageLoading(true));
    const params = {
      bu: BusinessUnits.GB,
      offset: offset * limit,
      limit: limit,
    };

    const axiosOptions: RequestOptions = {
      method: Methods.GET,
      params: params,
      data: {},
      errorCallback: handleFailureResponse,
      headers: headers,
      successCallback: handleSuccessResponseForBulkUpdateSummariesData,
      url: API_ENDPOINTS.TOC_BULK_UPDATE_SUMMARY,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [], initialSearchFieldDetails);
  };

  const handleSuccessResponseForBulkUpdateDetailsTab = (response: AxiosResponse<BulkUpdateDetailsResponseData>) => {
    dispatch(setBulkUpdateDetailsTabLoading(true));
    dispatch(setBulkPageLoading(true));
    dispatch(setTabStartIndex(2));
    dispatch(setBulkUpdateDetailsTableResponseData(response.data));
    setTimeout(() => {
      dispatch(setBulkUpdateDetailsTabLoading(false));
    }, 500);
  };

  const onClickBulkUpdateSummary = (batchId: string) => {
    const params: BulkUpdateDetailParams = {
      ...buParam,
      batchUUID: batchId,
    };
    const axiosOptions: RequestOptions = {
      method: Methods.GET,
      params: params,
      data: {},
      errorCallback: handleFailureResponse,
      headers: headers,
      successCallback: handleSuccessResponseForBulkUpdateDetailsTab,
      url: API_ENDPOINTS.TOC_BULK_UPDATE_DETAIL,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [], initialSearchFieldDetails);
  };

  return (
    <>
      {bulkPageLoading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : (
        <div className="bulk-ooc-sidebar-tab">
          <Tabs
            hasBottomBorder
            isOptionFullWidth={false}
            items={[
              {
                content: <BulkOOCSearchUpdateTable onClickBulkUpdateSummary={onClickBulkUpdateSummary} />,
                label: (
                  <div
                    onClick={() => {
                      dispatch(setTabStartIndex(0));
                      setBulkSummariesResponseData([]);
                    }}
                  >
                    {translate('bulkOOCSearchSidebarTab:bulkSearchUpdate')}
                  </div>
                ),
              },
              {
                content: (
                  <BulkUpdateSummariesTab
                    bulkSummariesResponseData={bulkSummariesResponseData}
                    handleBulkUpdateSummariesTabClick={handleBulkUpdateSummariesTabClick}
                    bulkUpdateSummariesTableRowCount={bulkUpdateSummariesTableRowCount}
                    isBulkUpdateSummariesTabClicked={isBulkUpdateSummariesTabClicked}
                    enabledpagination={true}
                    onClickBulkUpdateSummary={onClickBulkUpdateSummary}
                  />
                ),
                label: (
                  <div
                    onClick={() =>
                      bulkSummariesResponseData.length === 0 &&
                      handleBulkUpdateSummariesTabClick(0, OOC_API_PAGE_LIMITS.BULK_UPDATE_SUMMARIES_TABLE_LIMIT, true)
                    }
                  >
                    {translate('bulkOOCSearchSidebarTab:bulkUpdateSummarries')}
                  </div>
                ),
              },
              {
                content: (
                  <BulkUpdateDetailsTab
                    setBulkSummariesResponseData={setBulkSummariesResponseData}
                    handleBulkUpdateSummariesTabClick={handleBulkUpdateSummariesTabClick}
                    onClickBulkUpdateSummary={onClickBulkUpdateSummary}
                  />
                ),
                isDisabled: true,
                label: <div>{translate('bulkOOCSearchSidebarTab:bulkUpdateDetail')}</div>,
              },
            ]}
            startIndex={tabStartIndex}
          />
        </div>
      )}
    </>
  );
};

export default BulkTocSearchSidebarTab;

export function handleFailureResponse(error: AxiosError<unknown, any>): void {
  throw new Error('Function not implemented.', error);
}
