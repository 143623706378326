import { AnyAction } from 'redux';
import { AuditSidebarTabState } from 'src/types/auditSidebarTabReducer';

export const SET_AUDIT_TABLE_OFFSET = 'SET_AUDIT_TABLE_OFFSET';
export const SET_AUDIT_TABLE_DATA = 'SET_AUDIT_TABLE_DATA';
export const SET_IS_SCROLLING_AUDIT_RECORDS = 'SET_IS_SCROLLING_AUDIT_RECORDS';
export const SET_IS_SEARCH_FOR_ALL_RECORDS_BUTTON_CLICKED = 'SET_IS_SEARCH_FOR_ALL_RECORDS_BUTTON_CLICKED';

const initialState: AuditSidebarTabState = {
  auditTableData: [],
  isScrollingAuditRecords: false,
  isSearchForAllRecordsButtonClicked: false,
};

const auditSidebarTabReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_AUDIT_TABLE_DATA:
      return {
        ...state,
        auditTableData: action.payload,
      };
    case SET_IS_SCROLLING_AUDIT_RECORDS:
      return {
        ...state,
        isScrollingAuditRecords: action.payload,
      };
    case SET_IS_SEARCH_FOR_ALL_RECORDS_BUTTON_CLICKED:
      return {
        ...state,
        isSearchForAllRecordsButtonClicked: action.payload,
      };
    default:
      return state;
  }
};

export default auditSidebarTabReducer;
