import { BaseTOCFields, PanelIdIdentifierMapping, SearchForCreateEditTOCPOCResponseBody } from 'src/types/apiModels';
import { Methods, OutOfChargeStatus, RequestType } from 'src/enums/enum';
import { TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { TocPocFormData } from 'src/types/TocPocModal';
import { API_ENDPOINTS, buParam, headers } from 'src/api/apiConfig';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { AnyAction, Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { AlertMsgObj } from 'src/types/saveReducer';
import { BasePOCFields } from '../../../types/apiModels';
import { getErrorMessageToBeDisplayed, getSuccessMessageToBeDisplayed } from 'src/components/alert/alertMessages';
import { SET_IS_SAVE_CLICKED, SET_SUCCESS_MESSAGE } from 'src/redux/reducers/tocPocSidebarTabReducers/saveReducer';
import { getFormattedDate } from 'src/utils/dateUtils';

export const setIsSaveClicked = (isSaveClickd: boolean) => ({
  type: SET_IS_SAVE_CLICKED,
  payload: isSaveClickd,
});

export const setSuccessMessage = (successMessage: AlertMsgObj | undefined) => ({
  type: SET_SUCCESS_MESSAGE,
  payload: successMessage,
});

export const onClickSave = (
  formData: TocPocFormData,
  fetchedDataForCreateTocPocModal: SearchForCreateEditTOCPOCResponseBody,
  existingTocPoc: TOCPOCDetailsTableData,
  saveRequestType: RequestType
) => {
  if (formData.outOfChargeStatus === OutOfChargeStatus.TOC) {
    const transformedTOCFormData = transformTOCFormData(formData);
    return (dispatch) => {
      const createTOCRequestOptions = getCreateUpdateTOCOptions(saveRequestType, transformedTOCFormData, existingTocPoc);

      performSaveRequestAction(saveRequestType, createTOCRequestOptions, dispatch);
    };
  } else {
    const transformedPOCFormData = transformPOCFormData(formData);
    return (dispatch) => {
      const createPOCRequestOptions = getCreateUpdateTOCOptions(saveRequestType, transformedPOCFormData, existingTocPoc);

      performSaveRequestAction(saveRequestType, createPOCRequestOptions, dispatch);
    };
  }
};

const performSaveRequestAction = (
  saveRequestType: RequestType,
  axiosOptions: RequestOptions,
  dispatch: Dispatch<AnyAction>
) => {
  switch (saveRequestType) {
    case RequestType.CREATE_REQUEST:
      sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [initialExistingTocPoc]);
      break;
    case RequestType.UPDATE_REQUEST:
      sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [initialExistingTocPoc]);
      break;
    default:
      break;
  }
};

const getCreateUpdateTOCOptions = (
  savRequestType: RequestType,
  formData: TocPocFormData,
  existingTocPoc: TOCPOCDetailsTableData
): RequestOptions => {
  const createOOCRequestBody = getCreateOOCRequestBody(formData);
  const updateTOCRequestBody = { ...createOOCRequestBody, oocId: existingTocPoc.id };
  const commonsOptions = {
    params: buParam,
    headers: headers,
  };

  switch (savRequestType) {
    case RequestType.CREATE_REQUEST:
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForCreateToc,
        errorCallback: handleFailureResponseForCreateRequest,
        url:
          formData.outOfChargeStatus === OutOfChargeStatus.TOC ? API_ENDPOINTS.CREATE_TOC_URL : API_ENDPOINTS.CREATE_POC_URL,
        data: createOOCRequestBody,
        method: Methods.POST,
      };
    case RequestType.UPDATE_REQUEST:
      return {
        ...commonsOptions,
        successCallback: handleSuccessResponseForUpdateToc,
        errorCallback: handleFailureResponseForUpdateRequest,
        url: API_ENDPOINTS.UPDATE_TOC_URL,
        data: updateTOCRequestBody,
        method: Methods.PUT,
      };
    default:
      throw new Error('Invalid searchRequestType');
  }
};

const handleSuccessResponseForCreateToc = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
  const alertMessage = getSuccessMessageToBeDisplayed(response.data, RequestType.CREATE_REQUEST);
  resetStates(dispatch, alertMessage);
};

const handleSuccessResponseForUpdateToc = (response: AxiosResponse, dispatch: Dispatch<AnyAction>) => {
  const alertMessageObj = getSuccessMessageToBeDisplayed(response.data, RequestType.UPDATE_REQUEST);
  resetStates(dispatch, alertMessageObj);
};

const handleFailureResponseForUpdateRequest = (error, dispatch: Dispatch<AnyAction>) => {
  const errorMessageObject = getErrorMessageToBeDisplayed(error, RequestType.UPDATE_REQUEST);
  resetStates(dispatch, errorMessageObject);
};

const handleFailureResponseForCreateRequest = (error, dispatch: Dispatch<AnyAction>) => {
  const errorMessageObject = getErrorMessageToBeDisplayed(error, RequestType.CREATE_REQUEST);
  resetStates(dispatch, errorMessageObject);
};

const getCreateOOCRequestBody = (formData: TocPocFormData) => {
  const tocData = formData as BaseTOCFields;
  const pocData = formData as BasePOCFields;
  const tocRequestBody = {
    startDate: tocData.startDate,
    endDate: tocData.endDate,
    reasonCode: tocData.tocReasonCode,
    comment: tocData.comment,
    panelIds: formData.panelIds,
  };
  const pocRequestBody = {
    startDate: pocData.startDate,
    dismantleSite: pocData.dismantleSite,
    panelIds: formData.panelIds,
  };

  return formData.outOfChargeStatus === OutOfChargeStatus.TOC ? tocRequestBody : pocRequestBody;
};

const transformTOCFormData = (formData: TocPocFormData): TocPocFormData => {
  const updatedFormDate = { ...formData };
  updatedFormDate.startDate = getFormattedDate(new Date(updatedFormDate.startDate));
  (updatedFormDate as BaseTOCFields).endDate = getFormattedDate(new Date((updatedFormDate as BaseTOCFields).endDate));
  return updatedFormDate;
};

const transformPOCFormData = (formData: TocPocFormData): TocPocFormData => {
  const updatedFormDate = { ...formData };
  updatedFormDate.startDate = getFormattedDate(new Date(updatedFormDate.startDate));
  return updatedFormDate;
};

export function resetStates(dispatch: Dispatch<AnyAction>, alertMessageObj: AlertMsgObj) {
  dispatch(setSuccessMessage(alertMessageObj));
  dispatch(setIsSaveClicked(false));
}

export function getMappingForPanelId(panelId: string, panelIdIdentifierMappings: PanelIdIdentifierMapping[]) {
  const mapping = panelIdIdentifierMappings.find((mapping) => mapping.panelId === panelId);
  return mapping ? mapping.associatedAttributesForPanel : undefined;
}
