import React, { useContext, useEffect } from 'react';
import TocPocTab from '../tocPocTabSidebarTab/tocPocSidebarTab';
import OpeningHoursTable from './openingHoursTable/openingHoursTable';
import OpeningHoursTabsComponent, { convertDataToEvents } from './openingHoursTabsComponent/openingHoursTabsComponent';
import { FunctionComponent } from 'react';
import { SidebarTabProp } from '../../components/sidebar/sidebarConfig';
import { OperatingPeriodType, RequestType } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SearchReducer } from 'src/types/searchReducer';
import { setLoading } from 'src/redux/actions/loadingActions';
import { AppContext } from 'src/contexts/appContext';
import { LoadingReducer } from 'src/types/loadinReducer';
import { Loading, Button, H3 } from '@clearchannelinternational/ecooh-system';
import { OpeningHoursTableData } from 'src/types/apiModels';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import {
  setInitialOpeningHoursCalanderEvents,
  setSelectedDayPartData,
} from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';
import _ from 'lodash';
import { CalendarEventType } from './openingHoursTabsComponent/calendarConfig/calendarConfig';

export type GroupedWeeklyTimeRanges = {
  [day: string]: {
    start: string;
    end: string;
  }[];
};

export type OperatingPeriodsFormValues = {
  operatingPeriodDbId: string;
  startDate: string;
  endDate: string;
  operatingPeriod: GroupedWeeklyTimeRanges;
  name: OperatingPeriodType;
  effectiveDateFrom: string;
};

export const initialFormValues: OperatingPeriodsFormValues = {
  operatingPeriodDbId: '',
  startDate: '',
  endDate: '',
  operatingPeriod: {},
  name: OperatingPeriodType.DEFAULT,
  effectiveDateFrom: '',
};

export type SelectedFrameDetailRow = {
  index: number;
  panelId: string;
};
const initialSelectedFrameDetails: SelectedFrameDetailRow = {
  index: 0,
  panelId: '',
};

const OpeningHoursTab: FunctionComponent<SidebarTabProp> = () => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [shouldOpeningHoursDetailedSectionBeEnabled, setShouldOpeningHoursDetailedSectionBeEnabled] = React.useState(false);
  const { selectedDayPartDate, initialOpeningHoursCalanderEvents } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );
  const { loading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { t: translate } = useTranslation();
  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);
  const [openingHoursTableData, setOpeningHoursTableData] = React.useState<OpeningHoursTableData[]>([]);
  const [selectedFrameDetailRow, setSelectedFrameDetailRow] =
    React.useState<SelectedFrameDetailRow>(initialSelectedFrameDetails);

  useEffect(() => {
    setShouldOpeningHoursDetailedSectionBeEnabled(false);
    dispatch(setSelectedDayPartData(initialFormValues));
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 500);
    dispatch(setInitialOpeningHoursCalanderEvents({ configData: [] }));
    dispatch(setSelectedDayPartData(initialFormValues));
  }, [appContext]);

  useEffect(() => {
    if (loading) {
      setShouldOpeningHoursDetailedSectionBeEnabled(false);
      dispatch(setSelectedDayPartData(initialFormValues));
      setSelectedFrameDetailRow(initialSelectedFrameDetails);
    }
  }, [loading]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const initialEvents: CalendarEventType = { configData: convertDataToEvents(selectedDayPartDate.operatingPeriod) };
      const isDataChanged =
        Object.entries(selectedDayPartDate.operatingPeriod).length > 0
          ? !_.isEqual(initialEvents, initialOpeningHoursCalanderEvents)
          : false;

      if (isDataChanged) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [initialOpeningHoursCalanderEvents]);

  const getDayPartDetailsLabel = () => {
    if (fetchedDataForOpeningHoursSearchRequest && fetchedDataForOpeningHoursSearchRequest.length === 1) {
      const panelIds = fetchedDataForOpeningHoursSearchRequest.map((data) => data.frameIdentifiers?.panelIds).flat();
      if (panelIds.length === 1) {
        return `${translate('openingHoursSIdebarTab:dayPartDetails')} for PanelId ${panelIds}`;
      } else {
        return `${translate('openingHoursSIdebarTab:dayPartDetails')} for PanelIds ${panelIds.join(', ')}`;
      }
    } else if (selectedFrameDetailRow.panelId) {
      return `${translate('openingHoursSIdebarTab:dayPartDetails')} for PanelId ${selectedFrameDetailRow.panelId}`;
    }

    return translate('openingHoursSIdebarTab:dayPartDetails');
  };

  const getOpeningHoursTabsHeading = () => {
    const currentOrFutureOPText =
      new Date(selectedDayPartDate.startDate) > new Date() ? 'Future Base/Default' : 'Current Base/Default';
    return `Opening hours for "${currentOrFutureOPText}" Operating Period`;
  };

  return (
    <>
      <TocPocTab
        searchRequestType={RequestType.OPENING_HOURS_SEARCH_REQUEST}
        setSelectedFrameDetailRow={setSelectedFrameDetailRow}
        selectedFrameDetailRow={selectedFrameDetailRow}
        setShouldOpeningHoursDetailedSectionBeEnabled={setShouldOpeningHoursDetailedSectionBeEnabled}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="day-part-details-text">
            <H3>{getDayPartDetailsLabel()}</H3>
          </div>
          <div className="opening-hours-new-button">
            <Button
              label={translate('openingHoursSIdebarTab:new')}
              renderIcon="edit"
              disabled={fetchedDataForOpeningHoursSearchRequest === undefined}
              onClick={() => {
                dispatch(setSelectedDayPartData(initialFormValues));
                setShouldOpeningHoursDetailedSectionBeEnabled(true);
              }}
            />
          </div>
          <OpeningHoursTable
            shouldOpeningHoursDetailedSectionBeEnabled={shouldOpeningHoursDetailedSectionBeEnabled}
            setShouldOpeningHoursDetailedSectionBeEnabled={setShouldOpeningHoursDetailedSectionBeEnabled}
            selectedDayPartData={selectedDayPartDate}
            openingHoursTableData={openingHoursTableData}
            setOpeningHoursTableData={setOpeningHoursTableData}
            selectedFrameDetailRow={selectedFrameDetailRow}
          />

          {fetchedDataForOpeningHoursSearchRequest && shouldOpeningHoursDetailedSectionBeEnabled && (
            <>
              <div className="current-future-op-text">
                <H3>{getOpeningHoursTabsHeading()}</H3>
              </div>
              <div className="opening-hours-tabs">
                <OpeningHoursTabsComponent
                  selectedDayPartData={selectedDayPartDate}
                  shouldOpeningHoursDetailedSectionBeEnabled={shouldOpeningHoursDetailedSectionBeEnabled}
                  openingHoursTableData={openingHoursTableData}
                  setOpeningHoursTableData={setOpeningHoursTableData}
                  selectedFrameDetailRow={selectedFrameDetailRow}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OpeningHoursTab;
