import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, AnyAction } from 'redux';
import { headers, API_ENDPOINTS, buParam } from 'src/api/apiConfig';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { SearchFieldDetails } from 'src/components/searchBox/inputFieldsConfig';
import { SyncStatusStates, ExternalSystems, Methods, RequestType, OutOfChargeStatus } from 'src/enums/enum';
import { setSuccessMessage } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { setTableData } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import {
  BulkUpdateDetailsResponseData,
  FrameScheduleDetails,
  OpeningHoursTableData,
  SyncStatusDetails,
} from 'src/types/apiModels';
import { TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { getErrorMessageToBeDisplayed, getSuccessMessageToBeDisplayed } from 'src/components/alert/alertMessages';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';

export const onClickSyncButton = (
  tableData: TOCPOCDetailsTableData[],
  row: any,
  dispatch: Dispatch<AnyAction>,
  searchFieldDetails: SearchFieldDetails,
  bulkUpdateDetailsResponseData?: BulkUpdateDetailsResponseData
) => {
  const existingTocPoc = tableData[row.row.id];

  const axiosOptions: RequestOptions = {
    method: Methods.PUT,
    params: buParam,
    data: {
      oocId: existingTocPoc.id,
    },
    errorCallback: handleFailureResponse,
    headers: headers,
    successCallback:
      bulkUpdateDetailsResponseData &&
      Object.values(bulkUpdateDetailsResponseData.batchSummary).length === 0 &&
      handleSuccessResponse,
    url: getResyncUrl(existingTocPoc),
  };
  sendAxiosRequest(axiosOptions, dispatch, existingTocPoc, tableData, searchFieldDetails);
};

const handleFailureResponse = (error: AxiosError, dispatch: Dispatch<AnyAction>) => {
  const errorMessage = getErrorMessageToBeDisplayed(error, RequestType.RESYNC_REQUEST);
  dispatch(setSuccessMessage(errorMessage));
};

const handleSuccessResponse = (
  response: AxiosResponse,
  dispatch: Dispatch<AnyAction>,
  existingTocPoc: TOCPOCDetailsTableData,
  tableData: TOCPOCDetailsTableData[]
) => {
  dispatch(setSuccessMessage(getSuccessMessageToBeDisplayed(response.data, RequestType.RESYNC_REQUEST)));
  const syncStatusResponse: SyncStatusDetails[] = response.data;
  const updatedTableData = updateTableData(syncStatusResponse, existingTocPoc, tableData);
  dispatch(setTableData(updatedTableData));
};

const updateTableData = (
  syncStatusResponse: SyncStatusDetails[],
  existingTocPoc: TOCPOCDetailsTableData,
  tableData: TOCPOCDetailsTableData[]
) => {
  const tableDataClone = [...tableData];
  return tableDataClone.map((item) => {
    if (item.id === existingTocPoc.id) {
      return updateSyncStatusForSelectedTableRow(item, syncStatusResponse);
    }
    return item;
  });
};

const updateSyncStatusForSelectedTableRow = (
  selectedTableRow: TOCPOCDetailsTableData,
  syncStatusResponse: SyncStatusDetails[]
) => {
  const updatedItem = { ...selectedTableRow };

  if (isSyncStatusEmpty(syncStatusResponse)) {
    setSyncStatusToSuccessForSuccessResponse(updatedItem);
  } else {
    updateTableRowFromResponseStatus(syncStatusResponse, updatedItem);
  }

  return updatedItem;
};

const isSyncStatusEmpty = (syncStatusResponse: SyncStatusDetails[]) => Object.values(syncStatusResponse).length === 0;

const setSyncStatusToSuccessForSuccessResponse = (updatedItem: TOCPOCDetailsTableData) => {
  updatedItem.oasisSyncStatus = updatedItem.oasisSyncStatus !== '' ? SyncStatusStates.SUCCESS : '';
  updatedItem.broadsignSyncStatus = updatedItem.broadsignSyncStatus !== '' ? SyncStatusStates.SUCCESS : '';
};

const updateTableRowFromResponseStatus = (syncStatusResponse: SyncStatusDetails[], updatedItem: TOCPOCDetailsTableData) => {
  syncStatusResponse.forEach((syncStatus) => {
    if (syncStatus.externalSystem === ExternalSystems.OASIS) {
      updatedItem.oasisSyncStatus = syncStatus.syncInfo.syncDetails.syncStatus;
    } else if (syncStatus.externalSystem === ExternalSystems.BROADSIGN) {
      updatedItem.broadsignSyncStatus = syncStatus.syncInfo.syncDetails.syncStatus;
    }
  });
};

function getResyncUrl(existingTocPoc: TOCPOCDetailsTableData): string {
  if (existingTocPoc.outOfChargeStatus === OutOfChargeStatus.TOC) {
    return API_ENDPOINTS.TOC_RESYNC;
  } else if (existingTocPoc.outOfChargeStatus === OutOfChargeStatus.POC) {
    return API_ENDPOINTS.POC_RESYNC;
  } else {
    return API_ENDPOINTS.TOC_RESYNC;
  }
}

export const onClickReSyncButtonForOperatingPeriod = (
  tableData: OpeningHoursTableData[],
  row: any,
  dispatch: Dispatch<AnyAction>,
  searchFieldDetails: SearchFieldDetails,
  selectedOHData: FrameScheduleDetails
) => {
  const currentTableData = tableData[row.row.id];
  const axiosOptions: RequestOptions = {
    url: API_ENDPOINTS.OH_RESYNC,
    method: Methods.PUT,
    params: buParam,
    data: {
      fsId: selectedOHData.frameScheduleDbId,
      opId: currentTableData.operatingPeriodDBId,
      effectiveFromDate: currentTableData.startDate,
    },
    errorCallback: handleFailureResponse,
    headers: headers,
    successCallback: handleSuccessResponse,
  };

  setTimeout(() => {
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [], searchFieldDetails);
  }, 1000);
};
