import React, { FC, useMemo, useState } from 'react';
import { DatePickerInput, DateValue, DatesRangeValue } from '@mantine/dates';
import { CustomDatePickerInputProps } from 'src/types/componentProps';
import { SearchReducer, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { transformTOCPOCData } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { BaseTOCFields, FetchedTOCPOCData } from 'src/types/apiModels';
import { OutOfChargeStatus, TOCPOCCurrentStatus } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from 'src/utils/dateUtils';
import { useSelector } from 'react-redux';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';

export type DisabledDateRange = {
  startDate: string;
  endDate: string;
};
const pocEndDate = '9999-12-31T23:59';

const CustomDatePickerInput: FC<CustomDatePickerInputProps> = ({ config, formValues, fetchedData, handleInputChange }) => {
  const { isEditClicked, isUserInViewMode, existingTocPoc } = useSelector(
    (state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer
  );
  const { fetchedDataForCreateTocPocModal } = useSelector((state: SearchReducer) => state.searchReducer);
  const { t } = useTranslation();
  const existingTocsPoc = fetchedData && fetchedData.map((tocPocData: FetchedTOCPOCData) => transformTOCPOCData(tocPocData));
  const [value, setValue] = useState(getInitialValue());

  const getDisabledDateRanges = useMemo<DisabledDateRange[]>(() => {
    const disabledPanelIds: string[] = fetchedDataForCreateTocPocModal
      ? fetchedDataForCreateTocPocModal.frameIdentifiers.panelIds
      : [];
    return existingTocsPoc
      .filter(
        (item) =>
          item.panelIds.some((id) => disabledPanelIds.includes(id)) &&
          item.startDate !== existingTocPoc.startDate &&
          (item as BaseTOCFields).endDate !== (existingTocPoc as BaseTOCFields).endDate &&
          item.isOperative
      )
      .map((item: TOCPOCDetailsTableData) => {
        const { startDate, endDate } = item as BaseTOCFields;
        return { startDate, endDate };
      });
  }, [existingTocsPoc, existingTocPoc.startDate]);

  const maxDateForEndDateInputField = useMemo<Date | null>((): Date | null => {
    if (
      existingTocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Upcoming &&
      formValues.startDate &&
      (formValues as BaseTOCFields).endDate
    ) {
      return null;
    }
    return getDisabledDateRanges.reduce((minStartDate: Date | null, { startDate }: DisabledDateRange) => {
      const currentDate: Date = new Date(startDate);
      if (currentDate > new Date(formValues.startDate) && (minStartDate === null || currentDate < minStartDate)) {
        return currentDate;
      }
      return minStartDate;
    }, null);
  }, [existingTocsPoc, existingTocPoc.startDate]);

  const isWithinDisabledRanges = useMemo(() => {
    return (dateToCheck: DateValue) => {
      if (isTOCActiveAndStartDateIsSameAsInputDate(dateToCheck)) {
        return {
          isDisabled: false,
          isTocPoc: OutOfChargeStatus.DEFAULT,
        };
      }

      const formattedDate = getFormattedDate(dateToCheck);
      if (new Date(formattedDate) && new Date(formattedDate) < new Date(getFormattedDate(new Date()))) {
        return {
          isDisabled: true,
          isTocPoc: OutOfChargeStatus.DEFAULT,
        };
      }

      const disabledDateRanges = getDisabledDateRanges;

      for (const range of disabledDateRanges) {
        const { startDate, endDate } = range;
        const rangeStartDate = new Date(startDate);
        const rangeEndDate = new Date(endDate);

        if (dateToCheck && dateToCheck >= rangeStartDate && dateToCheck <= rangeEndDate) {
          const isTocPoc = endDate === pocEndDate ? OutOfChargeStatus.POC : OutOfChargeStatus.TOC;
          return { isTocPoc, isDisabled: true };
        }
      }

      return { isTocPoc: OutOfChargeStatus.DEFAULT, isDisabled: false };
    };
  }, [getFormattedDate, getDisabledDateRanges, isTOCActiveAndStartDateIsSameAsInputDate]);

  function isTOCActiveAndStartDateIsSameAsInputDate(dateToCheck: DateValue) {
    return (
      existingTocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Active &&
      getFormattedDate(new Date(existingTocPoc.startDate)) === getFormattedDate(dateToCheck)
    );
  }

  const renderDay = (date) => {
    if (isTOCActiveAndStartDateIsSameAsInputDate(date)) {
      return (
        <div data-testid={`start-date-highlight-${date.getDate()}`} className="start-date-highlight">
          {date.getDate()}
        </div>
      );
    }

    const isTocOrPocDisabled = isWithinDisabledRanges(date);
    const dateToCheck = getFormattedDate(date);

    const commonStyles: React.CSSProperties = {
      pointerEvents: 'none',
      opacity:
        !isTocOrPocDisabled.isDisabled ||
        isDateBetweenStartDateAndEndDateForActiveTOC(dateToCheck) ||
        shouldHaveActiveTOCBackgroundColor(dateToCheck)
          ? 1
          : 0.4,
      textDecoration: isTocOrPocDisabled.isDisabled ? 'line-through' : 'none',
      color: new Date(date) >= getMinDate() ? 'black' : 'none',
    };
    const activeTOCBackgroundStyle: React.CSSProperties = {
      backgroundColor: 'rgba(156, 196, 210, 0.25)',
      padding: '6px',
      borderRadius: '8px',
    };

    if (dateToCheck === getFormattedDate(new Date())) {
      const currentDateStyle =
        isEditClicked &&
        (isDateBetweenStartDateAndEndDateForActiveTOC(dateToCheck) || shouldHaveActiveTOCBackgroundColor(dateToCheck))
          ? { ...activeTOCBackgroundStyle, ...commonStyles }
          : { ...commonStyles };

      return (
        <div
          className="current-date-highlight"
          style={currentDateStyle}
          data-testid={`current-date-highlight-${date.getDate()}`}
        >
          {date.getDate()}
          {isTocOrPocDisabled && isTocOrPocDisabled.isTocPoc !== OutOfChargeStatus.DEFAULT && (
            <p style={{ fontSize: '7px', color: 'black' }}>{isTocOrPocDisabled.isTocPoc}</p>
          )}
        </div>
      );
    }

    if (shouldHaveActiveTOCBackgroundColor(dateToCheck)) {
      return (
        <div data-testid={`active-toc-backgroud-${date.getDate()}`} style={{ ...commonStyles, ...activeTOCBackgroundStyle }}>
          {date.getDate()}
        </div>
      );
    } else if (isEditClicked && isDateBetweenStartDateAndEndDateForActiveTOC(dateToCheck)) {
      return (
        <div data-testid={`active-toc-backgroud-${date.getDate()}`} style={{ ...commonStyles, ...activeTOCBackgroundStyle }}>
          {date.getDate()}
        </div>
      );
    }

    return (
      <div data-testid={`rendered-date-${new Date(getFormattedDate(date)).getDate()}`}>
        <div style={commonStyles}>{date.getDate()}</div>
        {isTocOrPocDisabled && isTocOrPocDisabled.isTocPoc !== OutOfChargeStatus.DEFAULT && (
          <p style={{ fontSize: '7px', color: 'black' }}>{isTocOrPocDisabled.isTocPoc}</p>
        )}
      </div>
    );
  };

  function shouldHaveActiveTOCBackgroundColor(dateToCheck: string) {
    const tocFormData = formValues as BaseTOCFields;
    return (
      isEditClicked &&
      (tocFormData.startDate || tocFormData.endDate) &&
      new Date(dateToCheck) >= new Date(tocFormData.startDate) &&
      new Date(dateToCheck) <= new Date(tocFormData.endDate)
    );
  }

  function isDateBetweenStartDateAndEndDateForActiveTOC(dateToCheck: string) {
    const tocFormData = formValues as BaseTOCFields;
    const existingTocStartDate = getFormattedDate(new Date(existingTocPoc.startDate));
    const existingTocEndDate = getFormattedDate(new Date((existingTocPoc as BaseTOCFields).endDate));

    return (
      existingTocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Active &&
      (!tocFormData.startDate || !tocFormData.endDate) &&
      new Date(dateToCheck) >= new Date(existingTocStartDate) &&
      new Date(dateToCheck) <= new Date(existingTocEndDate)
    );
  }

  function getInitialValue(): DatesRangeValue {
    const tocFormData = formValues as BaseTOCFields;
    if (isEditClicked && (formValues.startDate || tocFormData.endDate)) {
      const startDate =
        existingTocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Active
          ? new Date(existingTocPoc.startDate)
          : new Date(formValues.startDate);
      const endDate = tocFormData.endDate ? new Date(tocFormData.endDate) : null;
      return [startDate, endDate];
    } else {
      const startDate = formValues.startDate
        ? new Date(formValues.startDate)
        : existingTocPoc.startDate
        ? new Date(existingTocPoc.startDate)
        : null;
      const endDate = (existingTocPoc as BaseTOCFields).endDate ? new Date((existingTocPoc as BaseTOCFields).endDate) : null;
      return [startDate, endDate];
    }
  }

  function getMinDate(): Date {
    return existingTocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Active
      ? new Date(existingTocPoc.startDate)
      : new Date();
  }
  function getMaxDate(): Date | undefined {
    return formValues.startDate != '' && formValues.startDate != null ? maxDateForEndDateInputField ?? undefined : undefined;
  }

  const isDateWithinTocPocRange = (date, tocPoc) => {
    const formattedDate = getFormattedDate(date);
    const startDate = getFormattedDate(new Date(tocPoc.startDate));
    const endDate = getFormattedDate(new Date());

    return (
      tocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Active &&
      new Date(formattedDate) >= new Date(startDate) &&
      new Date(formattedDate) < new Date(endDate)
    );
  };

  function handleDateChange(e) {
    if (isDateWithinTocPocRange(e[1], existingTocPoc)) {
      return;
    }

    const [start, end] = e;
    const isStartValid = start && !isWithinDisabledRanges(start).isDisabled;
    const isEndValid = end && !isWithinDisabledRanges(end).isDisabled;

    if (isStartValid === false || isEndValid === false) {
      return;
    }

    if (isStartValid || isEndValid) {
      setValue(e);
      handleInputChange(config.name, e);
    } else if (isEditClicked) {
      setValue(getEditDateRange());
    } else if (existingTocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Active) {
      setValue(getInitialValue());
    } else {
      setValue([null, null]);
    }
  }

  function getEditDateRange(): DatesRangeValue {
    const tocFormData = formValues as BaseTOCFields;
    if (tocFormData.startDate && tocFormData.endDate) {
      return [new Date(tocFormData.startDate), new Date(tocFormData.endDate)];
    }
    const startDate = new Date(existingTocPoc.startDate);
    const endDate = (existingTocPoc as BaseTOCFields).endDate ? new Date((existingTocPoc as BaseTOCFields).endDate) : null;
    return [startDate, endDate];
  }

  function onCalenderOpen() {
    if (existingTocPoc.tocPocCurrentStatus === TOCPOCCurrentStatus.Active) {
      const value = [getInitialValue()[0], null] as DatesRangeValue;
      setValue(value);
      // handleInputChange(config.name, value);
    }
  }

  return (
    <div className="date-pickerinput-container" data-testid={`custom-date-picker-input`}>
      <DatePickerInput
        data-testid={`date-picker-input`}
        type="range"
        mx="auto"
        label={config.label}
        className="mantine-date-picker-input"
        renderDay={renderDay}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        disabled={!isEditClicked && isUserInViewMode}
        placeholder={t('createNewTocPocModal:selectDateRange')}
        value={value as DatesRangeValue}
        onChange={(e) => handleDateChange(e)}
        onClick={() => onCalenderOpen()}
        allowSingleDateInRange={true}
        excludeDate={(date) => {
          return getFormattedDate(new Date()) !== getFormattedDate(date) && isTOCActiveAndStartDateIsSameAsInputDate(date);
        }}
        required
      />
    </div>
  );
};

export default CustomDatePickerInput;
