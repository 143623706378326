import { Auth } from 'aws-amplify';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { decodeIdToken } from 'src/components/header/header';
import { Role } from 'src/enums/enum';
import {
  SET_ACCESS_TOKEN,
  SET_DEOCODED_ID_TOKEN,
  SET_ID_TOKEN,
  SET_REFRESH_TOKEN,
  SET_USER_DETAILS,
  SET_USER_ROLE,
} from 'src/redux/reducers/authTokensReducer/authTokensReducer';
import { User } from 'src/types/apiModels';
import { Action } from 'src/types/searchReducer';

export const setIdToken = (idToken: string): Action<string> => ({
  type: SET_ID_TOKEN,
  payload: idToken,
});

export const setAccessToken = (accessToken: string): Action<string> => ({
  type: SET_ACCESS_TOKEN,
  payload: accessToken,
});

export const setRefreshToken = (refreshToken: string): Action<string> => ({
  type: SET_REFRESH_TOKEN,
  payload: refreshToken,
});

export const setUsersDetails = (userDetails: User[]): Action<User[]> => ({
  type: SET_USER_DETAILS,
  payload: userDetails,
});

export const setDecodedIdToken = (decodedIdToken: any): Action<any> => ({
  type: SET_DEOCODED_ID_TOKEN,
  payload: decodedIdToken,
});

export const setUserRole = (userRole: Role): Action<Role> => ({
  type: SET_USER_ROLE,
  payload: userRole,
});

export const setAuthTokens = (session, dispatch: Dispatch<AnyAction>) => {
  const idToken = session.getIdToken().getJwtToken();
  const accessToken = session.getRefreshToken().getToken();
  const refreshToken = session.getAccessToken().getJwtToken();

  const decodedPayload = decodeIdToken(idToken);
  const role = decodedPayload['cognito:groups'] ?? [];
  const userRole = role.includes(Role.READ_WRITE) ? Role.READ_WRITE : Role.READ;

  dispatch(setUserRole(userRole));
  dispatch(setDecodedIdToken(decodedPayload));
  dispatch(setIdToken(idToken));
  dispatch(setAccessToken(accessToken));
  dispatch(setRefreshToken(refreshToken));

  return decodedPayload;
};

export const forceSignOutUser = (decodedIdToken) => {
  const role = decodedIdToken['cognito:groups'] ?? [];
  if (!role.includes(Role.READ) && !role.includes(Role.READ_WRITE)) {
    Auth.signOut();
  }
};
