import React, { useContext, useEffect, useState } from 'react';
import { Header, H1 } from '@clearchannelinternational/ecooh-system';
import { useTranslation } from 'react-i18next';
import { AppContext, Countries, Systems } from 'src/contexts/appContext';
import { useDispatch, useSelector } from 'react-redux';
import { AuthTokensReducer } from '../../types/componentReducers';
import { Region, Role } from 'src/enums/enum';
import { setDecodedIdToken, setUserRole } from 'src/redux/actions/authTokensActions/authTokensActions';

const FrameManagerHeader: React.FC<any> = ({ renderedSidebarLabel }) => {
  const { t, i18n } = useTranslation();
  const ukFlag = <img src={'/ukFlag.png'} className="remove-icon" alt="img" />;
  const [label, setLabel] = useState<any>(ukFlag);
  const { Country, setCountry } = useContext(AppContext);
  const { system, setSystem } = useContext(AppContext);
  const handleCountryChange = (country: Countries) => {
    setCountry(country);
  };

  const handleSetSystem = (system: Systems) => {
    setSystem(system);
  };

  const onClickLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const { idToken } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);
  const payloadData = decodeIdToken(idToken);
  const firstName = payloadData['custom:firstname'];
  const lastName = payloadData['custom:lastname'];
  const email = payloadData['email'];
  const jobTitle = payloadData['custom:jobtitle'];
  const businessunit = payloadData['custom:businessunit'];
  const { userRole } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);

  const getAvatar = () => {
    return `${firstName[0]}${lastName[0]}`;
  };

  const getRoleLabel = () => {
    if (userRole === Role.READ) {
      return 'Read Only';
    } else if (userRole === Role.READ_WRITE) {
      return 'Read Write';
    } else {
      return '';
    }
  };

  return (
    <div className="headerContainer">
      <span className="frameManagerText">
        <H1>
          {renderedSidebarLabel === 'Bulk TOC Search' ? (
            <>
              {' '}
              {t('header:header')} - in dev <img src={'/hammer.gif'} className="hammer" alt="img" />
            </>
          ) : (
            t('header:header')
          )}
        </H1>
      </span>

      <Header
        avatarLabel={getAvatar()}
        avatarMenuItems={[
          {
            label: (
              <>
                <b>Name:</b>
                {firstName} {lastName}
              </>
            ),
            leadingIcon: 'person',
          },
          {
            label: (
              <>
                <b>Email:</b>
                {email}
              </>
            ),
            leadingIcon: 'email',
          },
          {
            label: (
              <>
                <b>Job title:</b>
                {jobTitle}
              </>
            ),
            leadingIcon: 'account',
          },
          {
            label: (
              <>
                <b>Business unit:</b>
                {businessunit}
              </>
            ),
            leadingIcon: 'location',
          },
          {
            label: (
              <>
                <b>Role:</b>
                {getRoleLabel()}
              </>
            ),
            leadingIcon: 'user--admin',
          },
        ]}
        itemLinks={[
          {
            label: label,
            subMenu: [
              {
                label: ukFlag,
                onClick: () => {
                  handleCountryChange(Region.UK);
                  setLabel(ukFlag);
                  handleSetSystem('oasis');
                  onClickLanguageChange('en');
                },
              },
              // {
              //   label: frFlag,
              //   onClick: () => {
              //     handleCountryChange('FR');
              //     setLabel(frFlag);
              //     onClickLanguageChange('fr');
              //     handleSetSystem('aida');
              //   },
              // },
            ],
          },
        ]}
      ></Header>
    </div>
  );
};

export default FrameManagerHeader;

export function decodeIdToken(idToken: string) {
  const tokenParts = idToken.split('.');
  const payload = tokenParts[1];
  const base64DecodedPayload = Uint8Array.from(atob(payload), (c) => c.charCodeAt(0));
  const textDecoder = new TextDecoder('utf-8');
  const decodedPayload = textDecoder.decode(base64DecodedPayload);
  const payloadData = JSON.parse(decodedPayload);
  return payloadData;
}
