import React, { useRef, useState } from 'react';
import SearchField from '../searchField/searchField';
import { onClickSearch, setSearchFieldDetails } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { SetStateAction, useContext, useEffect } from 'react';
import { SearchBoxFieldType } from './inputFieldsConfig';
import { AppContext } from 'src/contexts/appContext';
import { SearchByProps } from 'src/types/componentProps';
import { Select, Button } from '@clearchannelinternational/ecooh-system';
import { Options } from '../../pages/tocPocTabSidebarTab/tocPocDetailsTable/tabsComponent/tocPocDetailsTab/renderInputFields/renderTocInputFields/renderTocInputFields';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { SearchReducer } from 'src/types/searchReducer';
import { useTranslation } from 'react-i18next';
import { RequestType } from 'src/enums/enum';
import { queryClient } from '../../../src';
import { LoadingReducer } from 'src/types/loadinReducer';
import { fetchNextPageOptions } from 'src/redux/actions/auditSidebarTabAction/auditSidebarTabAction';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import _ from 'lodash';
import {
  CalendarEventType,
  confirmOpeningHoursDataChange,
} from 'src/pages/openingHoursSidebarTab/openingHoursTabsComponent/calendarConfig/calendarConfig';
import { convertDataToEvents } from 'src/pages/openingHoursSidebarTab/openingHoursTabsComponent/openingHoursTabsComponent';
import { setInitialOpeningHoursCalanderEvents } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';

const SearchBox: React.FC<SearchByProps> = (props) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { searchFieldDetails } = useSelector((state: SearchReducer) => state.searchReducer);
  const { loading, modalLoading, auditTableLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const sortedInputFieldsListToBeRendered = props.inputFieldsListToBeRendered.sort((a, b) => a.label.localeCompare(b.label));
  const [fields, setFields] = useState<SearchBoxFieldType[]>(sortedInputFieldsListToBeRendered);
  const [selectedField, setSelectedField] = useState(props.inputFieldsListToBeRendered[0].label);
  const [currentSearchInputComponent, setCurrentSearchInputComponent] = useState<SearchBoxFieldType>();

  useEffect(() => {
    const searchFields = props.inputFieldsListToBeRendered;
    setFields(props.inputFieldsListToBeRendered);
    setSelectedField(searchFields[0].label);
  }, [appContext.Country]);

  useEffect(() => {
    const searchInputComponent = fields.find((field) => field.label === selectedField);
    searchInputComponent ? setCurrentSearchInputComponent(searchInputComponent) : undefined;
  }, [selectedField]);

  const selectRef: any = useRef(null);

  const handleDropdownInputChange = (event: SetStateAction<string>) => {
    setSelectedField(event);
  };

  const searchDropdownFields: Options[] = fields.map(({ label }) => ({
    label,
    value: label,
  }));

  useEffect(() => {
    setSelectedField(searchFieldDetails.label);
    if (
      searchFieldDetails.label &&
      selectRef &&
      selectRef?.current &&
      (selectedField || searchFieldDetails.label !== selectedField)
    ) {
      selectRef.current.setValue({
        label: searchFieldDetails.label,
        value: searchFieldDetails.label,
      });
    }
  }, [searchFieldDetails]);

  const { initialOpeningHoursCalanderEvents, selectedDayPartDate } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );

  const handleOnClickSearch = (searchValue: string | number | Date, searchRequestType: RequestType) => {
    if (searchRequestType === RequestType.OPENING_HOURS_SEARCH_REQUEST) {
      const initialEvents: CalendarEventType = { configData: convertDataToEvents(selectedDayPartDate.operatingPeriod) };
      const isDataChanged =
        Object.entries(selectedDayPartDate.operatingPeriod).length > 0
          ? !_.isEqual(initialEvents, initialOpeningHoursCalanderEvents)
          : false;
      if (!confirmOpeningHoursDataChange(isDataChanged, dispatch, initialEvents)) {
        return;
      }
    }

    dispatch(onClickSearch(searchFieldDetails, appContext, translate, searchRequestType) as unknown as AnyAction);
  };

  async function handleAuditSearchRequest() {
    if (props.fetchNextPage) {
      queryClient.clear();
      await props.fetchNextPage(fetchNextPageOptions);
    }
  }

  const isDisabled = () => {
    return loading || modalLoading || props.isFetching || auditTableLoading;
  };

  return (
    <>
      <div className="search-bar">
        <label>{translate('search:searchModalHeading')}</label>

        <div className="select-container">
          <Select
            ref={selectRef}
            defaultValue={{
              label: selectedField,
              value: selectedField,
            }}
            label=""
            isClearable={false}
            isLocked={isDisabled()}
            isSearchable={true}
            name="searchBy"
            onChange={handleDropdownInputChange}
            options={searchDropdownFields}
            optionSize="small"
            testId="search-select-component"
          />
        </div>

        {currentSearchInputComponent && (
          <SearchField
            label={currentSearchInputComponent.label}
            inputType={currentSearchInputComponent.inputType}
            isDateRangeField={currentSearchInputComponent.isDateRangeField}
            searchFieldDetails={searchFieldDetails}
            setSearchFieldDetails={setSearchFieldDetails}
            requestType={props.searchRequestType}
            fetchNextPage={props.fetchNextPage}
            isFetching={props.isFetching}
            selectedField={selectedField}
          />
        )}

        <div className="search-button">
          <Button
            renderIcon="search"
            kind="tertiary"
            testId="search-button"
            label=""
            disabled={isDisabled()}
            onClick={async () => {
              const { searchRequestType } = props;

              if (searchFieldDetails.value && searchRequestType === RequestType.AUDIT_SEARCH_REQUEST) {
                handleAuditSearchRequest();
              } else if (searchFieldDetails.value) {
                handleOnClickSearch(searchFieldDetails.value, searchRequestType);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SearchBox;
