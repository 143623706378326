import i18next from 'src/i18n';
import { InputFieldName, InputType } from 'src/enums/enum';
import { transformTOCPOCData } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { BasePOCFields, BaseTOCFields, FetchedTOCPOCData, SearchForCreateEditTOCPOCResponseBody } from 'src/types/apiModels';
import { TOCPOCDetailsTableData } from 'src/types/searchReducer';

export type InputField = {
  label: string;
  name: string;
  type: string;
  value: string | Date[] | boolean | null | undefined;
  isDisabled?: boolean;
  hasFullWidth?: boolean;
};

export type CurrentTocPocDetail = InputField & {
  hasFullWidth?: boolean;
  isEditable?: boolean;
  hasAsterisk?: boolean;
};

export type TocPocDetail = InputField & { isEditable?: boolean };

export const getEditableInputTocFieldDetails = (
  isEditClicked: boolean,
  existingTocPoc: TOCPOCDetailsTableData
): CurrentTocPocDetail[] => {
  return [
    {
      label: i18next.t('createNewTocPocModal:dateRangePickerInputLabel'),
      name: InputFieldName.START_DATE_END_DATE,
      type: InputType.DATE,
      value:
        existingTocPoc && isEditClicked
          ? [new Date(existingTocPoc.startDate), new Date((existingTocPoc as BaseTOCFields).endDate)]
          : undefined,
      isEditable: false,
      hasAsterisk: true,
    },
    {
      label: i18next.t('table:reason'),
      name: InputFieldName.TOC_REASON_CODE,
      type: InputType.TEXT,
      value:
        existingTocPoc && Object.keys(existingTocPoc).length > 0
          ? (existingTocPoc as BaseTOCFields).tocReasonCode
          : undefined,
      isEditable: false,
      hasAsterisk: true,
    },
    {
      label: i18next.t('table:comment'),
      name: InputFieldName.COMMENT,
      type: InputType.TEXT_AREA,
      value:
        existingTocPoc && Object.keys(existingTocPoc).length > 0 ? (existingTocPoc as BaseTOCFields).comment : undefined,
      isEditable: false,
      hasAsterisk: false,
    },
  ];
};

export const getEditableInputPocFieldDetails = (
  isEditClicked: boolean,
  existingTocPoc: TOCPOCDetailsTableData
): CurrentTocPocDetail[] => {
  return [
    {
      label: 'Start Date',
      name: 'startDate',
      type: 'date',
      value: existingTocPoc && isEditClicked ? existingTocPoc.startDate : undefined,
      hasAsterisk: true,
    },
    {
      label: 'Dismantle Site',
      name: 'dismantleSite',
      type: 'checkbox',
      value:
        existingTocPoc && Object.keys(existingTocPoc).length > 0
          ? (existingTocPoc as BasePOCFields).dismantleSite
          : undefined,
      hasAsterisk: false,
    },
  ];
};

export const getExistingTocsPoc = (fetchedDataForCreateTocPocModal: SearchForCreateEditTOCPOCResponseBody) => {
  return (
    fetchedDataForCreateTocPocModal &&
    fetchedDataForCreateTocPocModal.existingOOCDetails.map((tocPocData: FetchedTOCPOCData) =>
      transformTOCPOCData(tocPocData)
    )
  );
};
