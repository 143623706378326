import {
  setAutoSuggestLoading,
  setLoading,
  setModalLoading,
  setSyncStatusTabLoading,
} from 'src/redux/actions/loadingActions';
import { AxiosError, AxiosResponse, Method, AxiosHeaders, RawAxiosRequestHeaders } from 'axios';
import { Dispatch, AnyAction } from 'redux';
import { api } from './apiConfig';
import { SearchFieldDetails } from 'src/components/searchBox/inputFieldsConfig';
import { TOCPOCDetailsTableData } from 'src/types/searchReducer';

export type RequestOptions = {
  url: string;
  method: Method;
  params?: Record<string, any>;
  data?: any;
  headers?: RawAxiosRequestHeaders | AxiosHeaders;
  successCallback: any;
  errorCallback: (error: AxiosError, dispatch: Dispatch<AnyAction>) => void;
};

export const sendAxiosRequest = (
  options: RequestOptions,
  dispatch: Dispatch<AnyAction>,
  existingTocPoc: TOCPOCDetailsTableData,
  tableData: TOCPOCDetailsTableData[],
  searchFieldDetails?: SearchFieldDetails
) => {
  const { url, method, params, data, successCallback, errorCallback } = options;

  api
    .request({ url, method, params, data })
    .then((response: AxiosResponse) => {
      successCallback(response, dispatch, existingTocPoc, tableData, searchFieldDetails);
    })
    .catch((error: AxiosError) => {
      errorCallback(error, dispatch);
    })
    .finally(() => {
      dispatch(setAutoSuggestLoading(false));
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500);
      dispatch(setModalLoading(false));
      dispatch(setSyncStatusTabLoading(false));
    });
};
