import React from 'react';
import Tippy from '@tippyjs/react';
import BulkTOCFormFieldsValidation from '../bulkTOCFormFieldsValidation/bulkTOCFormFieldsValidation';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { BulkOOCTOCFields, TOCPOCCurrentStatus } from 'src/enums/enum';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { Select, Icon } from '@clearchannelinternational/ecooh-system';
import { Textarea, rem } from '@mantine/core';
import { getFormattedDate } from 'src/utils/dateUtils';
import { BulkTOCUpdateModalDetailsProps } from 'src/types/componentProps';
import { useSelector } from 'react-redux';
import { SaveReducerState } from 'src/types/saveReducer';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { useTranslation } from 'react-i18next';
import { BaseTOCFields } from 'src/types/apiModels';

export type BulkTOCUpdateForm = {
  startDate?: string;
  endDate?: string;
  reasonCode?: string;
  comment?: string;
};

export const getInitialBulkTOCUpdateForm = (): BulkTOCUpdateForm => {
  return {};
};

const BulkTOCUpdateModalDetails: React.FC<BulkTOCUpdateModalDetailsProps> = ({
  bulkOOCTableInstance,
  onClickBulkUpdateSummary,
}) => {
  const [bulkTOCUpdateFormValues, setBulkTOCUpdateFormValues] = useState<BulkTOCUpdateForm>(getInitialBulkTOCUpdateForm());
  const [isResetClicked, setIsResetClicked] = useState<boolean>(false);
  const selectRef: any = useRef(null);
  const { t: translate } = useTranslation();
  const { successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { tocReasonsMapping } = useSelector((state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer);
  const dateFieldsToBeUpdatedForBulkTOCUpdate = [
    BulkOOCTOCFields.START_DATE,
    BulkOOCTOCFields.END_DATE,
    BulkOOCTOCFields.TOC_REASONS,
    BulkOOCTOCFields.TOC_COMMENTS,
  ];

  useEffect(() => {
    if (isResetClicked) {
      setBulkTOCUpdateFormValues(getInitialBulkTOCUpdateForm());
      setIsResetClicked(false);
    }
  }, [isResetClicked]);

  const getTOCDateValue = (criteria: BulkOOCTOCFields) => {
    return criteria === BulkOOCTOCFields.START_DATE && bulkTOCUpdateFormValues.startDate
      ? new Date(bulkTOCUpdateFormValues.startDate)
      : criteria === BulkOOCTOCFields.END_DATE && bulkTOCUpdateFormValues.endDate
      ? new Date(bulkTOCUpdateFormValues.endDate)
      : null;
  };

  const handleOnChangeDateField = (newValue: Date, criteria: BulkOOCTOCFields) => {
    const formmattedDate = getFormattedDate(newValue);
    if (criteria === BulkOOCTOCFields.START_DATE) {
      setBulkTOCUpdateFormValues({ ...bulkTOCUpdateFormValues, startDate: formmattedDate });
    } else if (criteria === BulkOOCTOCFields.END_DATE) {
      setBulkTOCUpdateFormValues({ ...bulkTOCUpdateFormValues, endDate: formmattedDate });
    }
  };

  const renderDay = (date: Date, criteria: BulkOOCTOCFields) => {
    const formattedDate = getFormattedDate(date);
    const currentDate = getFormattedDate(new Date());

    if (formattedDate === currentDate) {
      return <div className="current-date-highlight">{date.getDate()}</div>;
    }

    const selectedStartDate = bulkTOCUpdateFormValues.startDate;
    const selectedEndDate = bulkTOCUpdateFormValues.endDate;
    const dateObj = new Date(formattedDate);

    if (
      criteria === BulkOOCTOCFields.END_DATE &&
      !bulkTOCUpdateFormValues.startDate &&
      dateObj < getMaxOfAllStartDatesFromTableData()
    ) {
      return date.getDate();
    }

    if (
      criteria === BulkOOCTOCFields.START_DATE &&
      !bulkTOCUpdateFormValues.endDate &&
      dateObj > getMinOfAllEndDatesFromTableData()
    ) {
      return date.getDate();
    }

    if (criteria === BulkOOCTOCFields.END_DATE && selectedStartDate && dateObj < new Date(selectedStartDate)) {
      return date.getDate();
    }

    if (criteria === BulkOOCTOCFields.START_DATE && selectedEndDate && dateObj > new Date(selectedEndDate)) {
      return date.getDate();
    }

    return <div className="date-input">{date.getDate()}</div>;
  };

  const getMinOfAllEndDatesFromTableData = () => {
    const bulkTableData = bulkOOCTableInstance.getSelectedRowModel().rows.map((row) => row.original);
    const endDates = bulkTableData.map((item) => (item as BaseTOCFields).endDate);
    const minEndDate = endDates.reduce((a, b) => (new Date(a) < new Date(b) ? a : b));
    return new Date(minEndDate);
  };

  const getMaxDateForStartDateField = (criteria: BulkOOCTOCFields): Date | undefined => {
    if (criteria === BulkOOCTOCFields.START_DATE && !bulkTOCUpdateFormValues.endDate) {
      return getMinOfAllEndDatesFromTableData();
    }

    return criteria === BulkOOCTOCFields.START_DATE && bulkTOCUpdateFormValues.endDate
      ? new Date(bulkTOCUpdateFormValues.endDate)
      : undefined;
  };

  const getMaxOfAllStartDatesFromTableData = () => {
    const bulkTableData = bulkOOCTableInstance.getSelectedRowModel().rows.map((row) => row.original);
    const startDates = bulkTableData.map((item) => item.startDate);
    const maxStartDate = startDates.reduce((a, b) => (new Date(a) > new Date(b) ? a : b));
    if (new Date(maxStartDate) > new Date()) {
      return new Date(maxStartDate);
    } else {
      return new Date();
    }
  };

  const getMinDateForEndDateField = (criteria: BulkOOCTOCFields): Date | undefined => {
    if (criteria === BulkOOCTOCFields.END_DATE && !bulkTOCUpdateFormValues.startDate) {
      return getMaxOfAllStartDatesFromTableData();
    }

    return criteria === BulkOOCTOCFields.END_DATE && bulkTOCUpdateFormValues.startDate
      ? new Date(bulkTOCUpdateFormValues.startDate)
      : new Date();
  };

  const hasActiveTocs = () => {
    const bulkTableData = bulkOOCTableInstance.getSelectedRowModel().rows.map((row) => row.original);
    return bulkTableData.some((item) => item.tocPocCurrentStatus === TOCPOCCurrentStatus.Active);
  };

  return (
    <div className={`bulk-toc-update-form ${successMessage ? 'disabled' : ''}`}>
      {dateFieldsToBeUpdatedForBulkTOCUpdate.map((criteria) => {
        return (
          <>
            <div className="checkbox-date-calander-container">
              {(criteria === BulkOOCTOCFields.START_DATE || criteria === BulkOOCTOCFields.END_DATE) && (
                <div className="date-info-field">
                  <div
                    className={`date-calander ${
                      criteria === BulkOOCTOCFields.START_DATE && hasActiveTocs() ? 'disabled' : ''
                    }`}
                  >
                    <DatePickerInput
                      key={criteria}
                      data-testid="search-field-date-picker-input"
                      rightSectionWidth={rem(85)}
                      placeholder={`Select ${criteria}`}
                      label={criteria}
                      renderDay={(date) => renderDay(date, criteria)}
                      value={getTOCDateValue(criteria)}
                      onChange={(newValue: Date) => handleOnChangeDateField(newValue, criteria)}
                      clearable={true}
                      maxDate={getMaxDateForStartDateField(criteria)}
                      minDate={getMinDateForEndDateField(criteria)}
                      disabled={criteria === BulkOOCTOCFields.START_DATE && hasActiveTocs()}
                    />
                  </div>

                  <div>
                    {((criteria === BulkOOCTOCFields.START_DATE && hasActiveTocs()) ||
                      (criteria === BulkOOCTOCFields.START_DATE && !hasActiveTocs() && !bulkTOCUpdateFormValues.endDate) ||
                      (criteria === BulkOOCTOCFields.END_DATE &&
                        !bulkTOCUpdateFormValues.startDate &&
                        getMaxOfAllStartDatesFromTableData() > new Date())) && (
                      <Tippy
                        appendTo={document.body}
                        maxWidth="200rem"
                        placement="top"
                        interactive
                        arrow={true}
                        delay={200}
                        content={
                          <div className="google-maps-content">
                            <Icon fontSize="1rem" iconName="information" />
                            {criteria === BulkOOCTOCFields.START_DATE &&
                              !bulkTOCUpdateFormValues.endDate &&
                              !hasActiveTocs() && (
                                <div className="street-view-tooltip-text">
                                  {`The earliest "End Date" among the selected TOC(s) is ${getFormattedDate(
                                    getMinOfAllEndDatesFromTableData() as DateValue
                                  )}, so you can only choose a "Start Date" before that`}
                                </div>
                              )}
                            {criteria === BulkOOCTOCFields.START_DATE && hasActiveTocs() && (
                              <div className="street-view-tooltip-text">
                                {translate('bulkOOCSearchSidebarTab:disabledStartDateMessage')}
                              </div>
                            )}
                            {criteria === BulkOOCTOCFields.END_DATE && (
                              <div className="street-view-tooltip-text">
                                {`The latest "Start Date" among the selected TOC(s) is ${getFormattedDate(
                                  getMaxOfAllStartDatesFromTableData() as DateValue
                                )}, so you can only choose an "End Date" after that`}
                              </div>
                            )}
                          </div>
                        }
                      >
                        <div className="start-date-info-icon">
                          <Icon fontSize="1rem" color="orange" iconName="information" />
                        </div>
                      </Tippy>
                    )}
                  </div>
                </div>
              )}

              {criteria === BulkOOCTOCFields.TOC_REASONS && (
                <div className="toc-reason-select">
                  <Select
                    ref={selectRef}
                    testId="select-toc-reason-field"
                    label={criteria}
                    onChange={(e: string) => setBulkTOCUpdateFormValues({ ...bulkTOCUpdateFormValues, reasonCode: e })}
                    options={tocReasonsMapping}
                    placeholder={`Choose ${criteria}`}
                  />
                </div>
              )}

              {criteria === BulkOOCTOCFields.TOC_COMMENTS && (
                <Textarea
                  className="input-text-area"
                  placeholder={`Enter Comments (Please note that the text provided here will overwrite the 'comments' for all selected TOCs)`}
                  label={BulkOOCTOCFields.TOC_COMMENTS}
                  value={bulkTOCUpdateFormValues.comment}
                  onChange={(e) => setBulkTOCUpdateFormValues({ ...bulkTOCUpdateFormValues, comment: e.target.value })}
                />
              )}
            </div>
          </>
        );
      })}

      <BulkTOCFormFieldsValidation
        bulkOOCTableInstance={bulkOOCTableInstance}
        bulkTOCUpdateFormValues={bulkTOCUpdateFormValues}
        selectRef={selectRef}
        setIsResetClicked={setIsResetClicked}
        onClickBulkUpdateSummary={onClickBulkUpdateSummary}
      />
    </div>
  );
};

export default BulkTOCUpdateModalDetails;
