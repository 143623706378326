import React, { useState } from 'react';
import RenderTocInputFields from './renderTocInputFields/renderTocInputFields';
import FrameIDCheckboxes from '../frameIdCheckBoxes/frameIdCheckBoxes';
import RenderPocInputFields from './renderTocInutFields/renderPocInputFields';
import { RenderInputFieldsProps } from 'src/types/componentProps';
import { OutOfChargeStatus } from 'src/enums/enum';
import { TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { transformTOCPOCData } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { FetchedTOCPOCData } from 'src/types/apiModels';
import { H5 } from '@clearchannelinternational/ecooh-system';
import { SaveReducerState } from 'src/types/saveReducer';
import { SearchReducer } from 'src/types/searchReducer';

const RenderInputFields: React.FC<RenderInputFieldsProps> = ({
  modalInputFieldsConfig,
  searchFieldDetails,
  isPOCFocused,
  isTOCFocused,
  formValues,
  fetchedData,
  handleInputChange,
  externalIds,
  updateModal,
  setFormValues,
}) => {
  const { t } = useTranslation();
  const {
    isEditClicked: isFormInEditMode,
    isUserInViewMode,
    existingTocPoc,
  } = useSelector((state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer);
  const existingTocsOrPoc =
    fetchedData && fetchedData.map((tocPocData: FetchedTOCPOCData) => transformTOCPOCData(tocPocData));

  const { successMessage, isSaveClicked } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { fetchedDataForCreateTocPocModal } = useSelector((state: SearchReducer) => state.searchReducer);
  const [selectedPanels, setSelectedPanels] = useState<string[]>([]);

  const shouldDisplayEditableFields = () => {
    const updatedExistingTocOrPoc = existingTocsOrPoc.filter((tocPocData) => {
      return tocPocData.outOfChargeStatus === formValues.outOfChargeStatus;
    });
    return (
      !isSaveClicked &&
      (formValues.outOfChargeStatus === OutOfChargeStatus.TOC ||
        (selectedPanels.length !== 0 &&
          fetchedDataForCreateTocPocModal &&
          fetchedDataForCreateTocPocModal.frameIdentifiers.isMultiPanel &&
          updatedExistingTocOrPoc.length !== fetchedDataForCreateTocPocModal.frameIdentifiers.panelIds.length) ||
        (formValues.outOfChargeStatus === OutOfChargeStatus.POC && updatedExistingTocOrPoc.length === 0) ||
        existingTocPoc.outOfChargeStatus !== OutOfChargeStatus.DEFAULT)
    );
  };

  return (
    <>
      <div
        className={`toc-poc-input-fields-container ${
          !successMessage && existingTocsOrPoc && shouldDisplayEditableFields() ? '' : 'disabled'
        }`}
      >
        <div className="tab-heading2">
          <H5>
            {existingTocPoc.outOfChargeStatus === OutOfChargeStatus.DEFAULT && (
              <>{isFormInEditMode || isUserInViewMode ? t('createNewTocPocModal:edit') : t('createNewTocPocModal:new')}</>
            )}{' '}
            {existingTocPoc.outOfChargeStatus || formValues.outOfChargeStatus} Details
          </H5>
        </div>
        <FrameIDCheckboxes
          fetchedData={fetchedData}
          externalIds={externalIds}
          existingTocPoc={existingTocPoc}
          updateModal={updateModal}
          formValues={formValues}
          setFormValues={setFormValues}
          setSelectedPanels={setSelectedPanels}
          selectedPanels={selectedPanels}
        />
        <RenderTocInputFields
          modalInputFieldsConfig={modalInputFieldsConfig}
          existingTocPoc={existingTocPoc}
          searchFieldDetails={searchFieldDetails}
          isPOCFocused={isPOCFocused}
          isTOCFocused={isTOCFocused}
          formValues={formValues}
          fetchedData={fetchedData}
          handleInputChange={handleInputChange}
        />
        <RenderPocInputFields
          existingTocPoc={existingTocPoc}
          isPOCFocused={isPOCFocused}
          formValues={formValues}
          handleInputChange={handleInputChange}
          existingTocsOrPoc={existingTocsOrPoc}
          setSelectedPanels={setSelectedPanels}
          selectedPanels={selectedPanels}
        />
      </div>
    </>
  );
};

export default RenderInputFields;
