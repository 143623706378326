import { Action } from 'src/types/searchReducer';
import {
  SET_LOADING,
  SET_MODAL_LOADING,
  SET_AUTO_SUGGEST_LOADING,
  SET_SYNC_STATUS_TAB_LOADING,
  SET_AUDIT_TABLE_LOADING,
  SET_BULK_PAGE_LOADING,
  SET_BULK_UPDATE_SUMMARIES_PAGE_LOADING,
  SET_BULK_UPDATE_DETAILS_TAB_LOADING,
} from '../reducers/loadingReducer/loadingReducer';

export const setLoading = (loading: boolean): Action<boolean> => ({
  type: SET_LOADING,
  payload: loading,
});

export const setModalLoading = (modalLoading: boolean): Action<boolean> => ({
  type: SET_MODAL_LOADING,
  payload: modalLoading,
});

export const setAutoSuggestLoading = (autoSuggestLoading: boolean): Action<boolean> => ({
  type: SET_AUTO_SUGGEST_LOADING,
  payload: autoSuggestLoading,
});

export const setSyncStatusTabLoading = (syncStatusTabLoading: boolean): Action<boolean> => ({
  type: SET_SYNC_STATUS_TAB_LOADING,
  payload: syncStatusTabLoading,
});

export const setAuditTableLoading = (auditTableLoading: boolean): Action<boolean> => ({
  type: SET_AUDIT_TABLE_LOADING,
  payload: auditTableLoading,
});

export const setBulkPageLoading = (bulkPageLoading: boolean): Action<boolean> => ({
  type: SET_BULK_PAGE_LOADING,
  payload: bulkPageLoading,
});

export const setBulkUpdateSummarriesPageLoading = (bulkUpdateSummarriesPageLoading: boolean): Action<boolean> => ({
  type: SET_BULK_UPDATE_SUMMARIES_PAGE_LOADING,
  payload: bulkUpdateSummarriesPageLoading,
});

export const setBulkUpdateDetailsTabLoading = (bulkUpdateDetailsTabLoading: boolean): Action<boolean> => ({
  type: SET_BULK_UPDATE_DETAILS_TAB_LOADING,
  payload: bulkUpdateDetailsTabLoading,
});
