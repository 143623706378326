import { AnyAction } from 'redux';
import { Role } from 'src/enums/enum';
import { AuthTokensReducerState } from 'src/types/componentReducers';

export const SET_ID_TOKEN = 'SET_ID_TOKEN';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_DEOCODED_ID_TOKEN = 'SET_DEOCODED_ID_TOKEN';
export const SET_USER_ROLE = 'SET_USER_ROLE';

const initialState: AuthTokensReducerState = {
  idToken: '',
  accessToken: '',
  refreshToken: '',
  userDetails: [],
  decodedIdToken: undefined,
  userRole: Role.READ,
};

const autheTokensReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_ID_TOKEN:
      return {
        ...state,
        idToken: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_DEOCODED_ID_TOKEN:
      return {
        ...state,
        decodedIdToken: action.payload,
      };
    case SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    default:
      return state;
  }
};

export default autheTokensReducer;
