import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import MultiSelectCheckBox from 'src/components/multiSelectCheckBox/multiSelectCheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { OutOfChargeStatus } from 'src/enums/enum';
import { BulkOOCSearchRequestBody } from 'src/types/apiModels';
import { OutOfChargeCheckBoxesProps } from 'src/types/componentProps';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { LoadingReducer } from 'src/types/loadinReducer';
import { setBulkTOCSearchFormValues } from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';

const OutOfChargeCheckBoxes: React.FC<OutOfChargeCheckBoxesProps> = ({
  fetchedData,
  externalIds,
  existingTocPoc,
  updateModal,
  isPocFocused,
}) => {
  const [selectedOOCType, setSelectedOOCType] = useState<OutOfChargeStatus[]>([]);
  const { isResetClicked, bulkTOCSearchFormValues } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );
  const outOfChargeStatuses = [OutOfChargeStatus.TOC, OutOfChargeStatus.POC];
  const { loading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedOOCType([OutOfChargeStatus.TOC]);
  }, []);

  useEffect(() => {
    setSelectedOOCType([OutOfChargeStatus.TOC]);
  }, [isResetClicked]);

  useEffect(() => {
    if (_.isEqual(selectedOOCType, [OutOfChargeStatus.POC])) {
      const bulkFormData = { ...bulkTOCSearchFormValues };
      bulkFormData.tocReasons = [];
      dispatch(setBulkTOCSearchFormValues(bulkFormData));
    }
  }, [selectedOOCType]);

  const handleCheckboxChange = (oocStatus: OutOfChargeStatus): void => {
    if (selectedOOCType.includes(oocStatus) && selectedOOCType.length === 1) {
      return;
    }
    const updatedOOCStatuses = selectedOOCType.includes(oocStatus)
      ? selectedOOCType.filter((id) => id !== oocStatus)
      : [...selectedOOCType, oocStatus];

    setSelectedOOCType(updatedOOCStatuses);
    updateFormValuesWithSelectedOOCType(updatedOOCStatuses);
  };

  const updateFormValuesWithSelectedOOCType = (outOfChargeStatuses: OutOfChargeStatus[]): void => {
    const bulkOOCFormData = { ...(bulkTOCSearchFormValues as BulkOOCSearchRequestBody) };
    bulkOOCFormData.oocTypes = outOfChargeStatuses;
    dispatch(setBulkTOCSearchFormValues(bulkOOCFormData));
  };

  return (
    <div className="toc-poc-checkbox">
      <MultiSelectCheckBox
        updateModal={updateModal}
        formValues={bulkTOCSearchFormValues}
        setFormValues={setBulkTOCSearchFormValues}
        externalIds={externalIds}
        isPocFocused={isPocFocused}
        handleCheckboxChange={handleCheckboxChange}
        shouldCheckboxBeDisabled={() => loading}
        fetchedData={fetchedData}
        existingTocPoc={existingTocPoc}
        systemBasedExternalIds={outOfChargeStatuses}
        setSelectedItems={setSelectedOOCType as React.Dispatch<React.SetStateAction<string[] | OutOfChargeStatus[]>>}
        selectedItems={selectedOOCType}
      />
    </div>
  );
};

export default OutOfChargeCheckBoxes;
