import React from 'react';
import { P, H3 } from '@clearchannelinternational/ecooh-system';
import { AlertMessageStates } from 'src/enums/enum';
import { AlertMsgObj } from 'src/types/saveReducer';

type AlertProps = {
  message: AlertMsgObj;
  onClose: any;
};

const Alert: React.FC<AlertProps> = ({ message, onClose }) => {
  return (
    <div>
      <div className={`alert ${message.actionStatus}`} data-testid="alert">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <H3>
          {message.actionStatus === AlertMessageStates.PENDING ? 'Sync is partially accepted  ' : message.actionStatus}
          !!
        </H3>

        <P>
          <pre className="pre-wrap-text">{message.actionMessage}</pre>
        </P>
      </div>
    </div>
  );
};

export default Alert;
