import { AnyAction } from 'redux';
import { OperatingPeriodType } from 'src/enums/enum';
import { OperatingPeriodsFormValues } from 'src/pages/openingHoursSidebarTab/openingHoursSidebarTab';
import { OpeingHoursSidebarTabReducerState } from 'src/types/openingHoursReducers';

export const actionTypes = {
  SET_INITIAL_OPENING_HOURS_CALENDER_EVENTS: 'SET_INITIAL_OPENING_HOURS_CALENDER_EVENTS',
  SET_SELECTED_DAY_PART: 'SET_SELECTED_DAY_PART',
  IS_VIEW_BUTTON_CLICKED: 'IS_VIEW_BUTTON_CLICKED',
};

const initialFormValues: OperatingPeriodsFormValues = {
  operatingPeriodDbId: '',
  startDate: '',
  endDate: '',
  operatingPeriod: {},
  name: OperatingPeriodType.DEFAULT,
  effectiveDateFrom: '',
};

const initialState: OpeingHoursSidebarTabReducerState = {
  initialOpeningHoursCalanderEvents: {
    configData: [],
  },
  selectedDayPartDate: initialFormValues,
  isViewButtonClicked: false,
};

const OpeningHoursSidebarTabReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_OPENING_HOURS_CALENDER_EVENTS:
      return { ...state, initialOpeningHoursCalanderEvents: action.payload };
    case actionTypes.SET_SELECTED_DAY_PART:
      return { ...state, selectedDayPartDate: action.payload };
    case actionTypes.IS_VIEW_BUTTON_CLICKED:
      return { ...state, isViewButtonClicked: action.payload };
    default:
      return state;
  }
};

export default OpeningHoursSidebarTabReducer;
