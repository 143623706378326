import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { H5 } from '@clearchannelinternational/ecooh-system';
import { FrameScheduleDetails, OpeningHoursTableData } from 'src/types/apiModels';
import { OperatingPeriodsFormValues, SelectedFrameDetailRow } from '../../openingHoursSidebarTab';
import { useSelector } from 'react-redux';
import { SearchReducer } from 'src/types/searchReducer';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import dayjs from 'dayjs';

type CalanderViewProps = {
  operatingPeriodsData: OpeningHoursTableData[];
  selectedDayPartData: OperatingPeriodsFormValues;
  selectedFrameDetailRow: SelectedFrameDetailRow;
};

const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

const CalendarView: React.FC<CalanderViewProps> = ({
  operatingPeriodsData,
  selectedDayPartData,
  selectedFrameDetailRow,
}) => {
  const { initialOpeningHoursCalanderEvents } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );

  const getEventText = (eventInfo) => {
    const groupId = eventInfo.event.groupId;
    const panelIds = eventInfo.event.extendedProps.panelIds;

    return `${groupId} (${panelIds.join(', ')})`;
  };

  const renderEventContent = (eventInfo) => {
    const startTime = eventInfo.event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endTime = eventInfo.event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    // Adjusting if end time is midnight
    const adjustedEndTime = endTime === '12:00 AM' ? '11:59 PM' : endTime;
    const timeText = `${startTime}-${adjustedEndTime}`;

    // Render based on event group type
    if (eventInfo.event.groupId === 'TOC' || eventInfo.event.groupId === 'POC') {
      return (
        <div className="calendar-view-event-text">
          <H5 tone="textAlert">{getEventText(eventInfo)}</H5>
        </div>
      );
    }

    return (
      <div className="calendar-view-event-text">
        <H5 tone="textBase">{timeText}</H5>
      </div>
    );
  };

  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);

  function splitPeriods(periods) {
    const result: any = [];
    const processedTOCIds = new Set(); // Store processed TOCs by date only

    periods.forEach((period, index) => {
      if (period.name === 'BASE') {
        let baseStart = new Date(period.startDate);
        const baseEnd = new Date(period.endDate);

        for (let i = index + 1; i < periods.length; i++) {
          if (periods[i].name === 'TOC' || periods[i].name === 'POC') {
            const tocStart = new Date(periods[i].startDate);
            const tocEnd = new Date(periods[i].endDate);

            // Add BASE period before TOC/POC
            if (baseStart < tocStart) {
              result.push({
                name: 'BASE',
                startDate: baseStart.toISOString().slice(0, 16),
                endDate: new Date(tocStart.getTime() - 1).toISOString().slice(0, 16),
                operatingPeriod: period.operatingPeriod,
              });
            }

            // Extract just the date part of startDate
            const tocDateString = tocStart.toISOString().slice(0, 10); // Get YYYY-MM-DD

            // Add TOC/POC period only if it's not processed yet
            if (!processedTOCIds.has(tocDateString)) {
              result.push(periods[i]);
              processedTOCIds.add(tocDateString); // Add the date to the set
            }

            // Adjust the baseStart to one day after the end of the TOC/POC
            baseStart = new Date(tocEnd.getTime());
            baseStart.setDate(baseStart.getDate() + 1); // Move baseStart to the next day
          }
        }

        // Add remaining BASE period after TOC/POC
        if (baseStart < baseEnd) {
          result.push({
            name: 'BASE',
            startDate: baseStart.toISOString().slice(0, 16),
            endDate: baseEnd.toISOString().slice(0, 16),
            operatingPeriod: period.operatingPeriod,
          });
        }
      } else if (period.name === 'TOC' || period.name === 'POC') {
        const periodDateString = new Date(period.startDate).toISOString().slice(0, 10); // Get YYYY-MM-DD

        if (!processedTOCIds.has(periodDateString)) {
          result.push(period);
          processedTOCIds.add(periodDateString);
        }
      } else {
        result.push(period);
      }
    });

    return result;
  }

  function convertCalendarOperatingPeriodEvents(configData) {
    const result = {
      SUNDAY: [],
      MONDAY: [],
      TUESDAY: [],
      WEDNESDAY: [],
      THURSDAY: [],
      FRIDAY: [],
      SATURDAY: [],
    };

    configData.forEach(({ start, end }) => {
      const startDate = new Date(start);
      const endDate = new Date(end);

      startDate.setHours(startDate.getHours() + 5, startDate.getMinutes() + 30);
      endDate.setHours(endDate.getHours() + 5, endDate.getMinutes() + 30);

      const dayOfWeek = daysOfWeek[startDate.getUTCDay()];

      result[dayOfWeek].push({
        start: startDate.toISOString().substr(11, 8),
        end: endDate.toISOString().substr(11, 8),
      });
    });

    return result;
  }

  const getEventsForCalanderView = () => {
    const operatingPeriodEvents = convertCalendarOperatingPeriodEvents(initialOpeningHoursCalanderEvents.configData);

    const nonBlockingPeriods = operatingPeriodsData
      .map((period) => {
        if (period.startDate === selectedDayPartData.startDate && period.endDate === selectedDayPartData.endDate) {
          return {
            name: selectedDayPartData.name,
            startDate: selectedDayPartData.startDate,
            endDate: selectedDayPartData.endDate,
            operatingPeriod: operatingPeriodEvents,
          };
        }
        return null; // return null when the condition is not met
      })
      .filter((item) => item !== null); // remove null elements

    const openingHoursData = fetchedDataForOpeningHoursSearchRequest.find((data) =>
      data.frameIdentifiers.panelIds.includes(selectedFrameDetailRow.panelId)
    ) as FrameScheduleDetails;

    const blockingPeriods =
      openingHoursData.blockingOperatingPeriods.map((period) => {
        return {
          name: period.oocType,
          startDate: period.dateAndTimeRanges.startDate,
          endDate: period.dateAndTimeRanges.endDate,
          operatingPeriod: daysOfWeek.reduce((acc, day) => {
            acc[day] = [{ start: '00:00', end: '23:59' }];
            return acc;
          }, {} as any),
          panelIds: period.applicablePanelIds,
        };
      }) ?? [];

    const updatedPeriods = [...nonBlockingPeriods, ...blockingPeriods];
    // const updatedPeriods = [...blockingPeriods];

    const sortedOperatingPeriods = updatedPeriods.sort(
      (a, b) => (a?.startDate ? new Date(a.startDate).getTime() : 0) - (b?.startDate ? new Date(b.startDate).getTime() : 0)
    );

    let splitOperatingPeriods: any = sortedOperatingPeriods;

    if (needsSplitting(sortedOperatingPeriods)) {
      splitOperatingPeriods = splitPeriods(sortedOperatingPeriods);
    }

    return splitOperatingPeriods.flatMap((period) => {
      return daysOfWeek.flatMap((day, index) => {
        return period.operatingPeriod[day].map((timePeriod) => {
          return {
            title: `${timePeriod.start}-${timePeriod.end}`,
            groupId: period.name,
            daysOfWeek: [index], // Numeric day of the week (0=Sunday)
            startTime: timePeriod.start,
            endTime: timePeriod.end,
            startRecur: period.startDate,
            endRecur: period.endDate,
            allDay: false,
            panelIds: period.panelIds || [],
          };
        });
      });
    });
  };

  function needsSplitting(periods) {
    for (let i = 0; i < periods.length - 1; i++) {
      const currentEnd = new Date(periods[i].endDate);
      const nextStart = new Date(periods[i + 1].startDate);

      // If the next period starts after the current period ends, splitting is needed
      if (currentEnd.getTime() >= nextStart.getTime()) {
        return true; // Split is needed
      }
    }
    return false; // No split needed
  }

  return (
    <FullCalendar
      height={'70rem'}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      eventContent={renderEventContent}
      events={getEventsForCalanderView()}
      eventOverlap={false}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek',
      }}
      titleFormat={{ year: 'numeric', month: 'long' }}
      buttonText={{
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
      }}
      validRange={{
        start: selectedDayPartData.startDate,
		end: selectedDayPartData.endDate
      }}
    />
  );
  return <></>;
};

export default CalendarView;
