/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import {
  setSearchFieldDetails,
  handlerFailureResponse,
  onClickSearch,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import {
  AutoSuggestSearchType,
  BusinessUnits,
  FrameTypeForAutoSuggest,
  Methods,
  OOC_API_PAGE_LIMITS,
  RequestType,
  SearchFields,
} from 'src/enums/enum';
import { AxiosResponse } from 'axios';
import { fetchNextPageOptions } from 'src/redux/actions/auditSidebarTabAction/auditSidebarTabAction';
import { Autocomplete, AutocompleteItem, Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { DebouncedInputProps } from 'src/types/componentProps';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from 'src/contexts/appContext';
import { DEBOUNCE_TIME, SearchFieldDetails } from '../searchBox/inputFieldsConfig';
import { TFunction } from 'i18next';
import { API_ENDPOINTS, api, headers } from 'src/api/apiConfig';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { SearchReducer } from '../../types/searchReducer';
import { AnyAction } from 'redux';
import { User } from 'src/types/apiModels';
import { getInputFieldAccessorKey } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { queryClient } from '../../../src';
import { initialSearchFieldDetails } from 'src/redux/reducers/tocPocSidebarTabReducers/searchReducer';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { AuditSidebarTabReducer } from 'src/types/auditSidebarTabReducer';
import { LoadingReducer } from 'src/types/loadinReducer';
import { setAutoSuggestLoading } from 'src/redux/actions/loadingActions';
import {
  CalendarEventType,
  confirmOpeningHoursDataChange,
} from 'src/pages/openingHoursSidebarTab/openingHoursTabsComponent/calendarConfig/calendarConfig';
import { convertDataToEvents } from 'src/pages/openingHoursSidebarTab/openingHoursTabsComponent/openingHoursTabsComponent';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import _ from 'lodash';
import { setInitialOpeningHoursCalanderEvents } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';

export function shouldResetValueAndSearchDetails(selectedField: string | undefined, searchFieldDetails: SearchFieldDetails) {
  return selectedField && searchFieldDetails.label !== selectedField;
}

const AutocompleteWithApi: React.FC<DebouncedInputProps> = (inputField) => {
  const { t }: { t: TFunction } = useTranslation();
  const { searchFieldDetails, fetchedDataForCreateTocPocModal, fetchedDataForTocPocTableDetails } = useSelector(
    (state: SearchReducer) => state.searchReducer
  );
  const { autoSuggestLoading, loading, modalLoading, auditTableLoading } = useSelector(
    (state: LoadingReducer) => state.loadingReducer
  );
  const { isSearchForAllRecordsButtonClicked } = useSelector(
    (state: AuditSidebarTabReducer) => state.auditSidebarTabReducer
  );
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<string[]>([]);
  const debouncedValueObject = useDebouncedValue(value, DEBOUNCE_TIME);
  const debouncedValue = debouncedValueObject[0];
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const { selectedDayPartDate, initialOpeningHoursCalanderEvents } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );

  useEffect(() => {
    setOptions([searchFieldDetails.value as string]);
  }, [fetchedDataForCreateTocPocModal, fetchedDataForTocPocTableDetails]);

  useEffect(() => {
    fetchedDataForTocPocTableDetails && setValue(searchFieldDetails.value.toString());
  }, []);

  useEffect(() => {
    if (
      isSearchForAllRecordsButtonClicked ||
      (value && shouldResetValueAndSearchDetails(inputField.selectedField, searchFieldDetails))
    ) {
      setValue('');
      dispatch(setSearchFieldDetails(initialSearchFieldDetails));
    }
  }, [inputField.selectedField, isSearchForAllRecordsButtonClicked]);

  useEffect(() => {
    handleValueChange(debouncedValue);
  }, [debouncedValue]);

  function hasSpace(inputString: string) {
    return inputString.includes(' ');
  }

  const fetchData = () => {
    if (shouldNotCallSuggestApi()) {
      return;
    }
    const options: RequestOptions = getRequestOptions();
    sendAxiosRequest(options, dispatch, initialExistingTocPoc, [initialExistingTocPoc]);
  };
  const getUsersAutoSuggestValues = async (value: string) => {
    const response = await api.get(API_ENDPOINTS.USER_SEARCH, {
      params: { text: value },
    });
    setOptions(response.data.map((userInfo: User) => `${userInfo.lastName} ${userInfo.firstName}`));
    dispatch(setAutoSuggestLoading(false));
  };
  const handleValueChange = (value: string) => {
    if (searchFieldDetails.accessorKey === SearchFields.USER) {
      if (hasSpace(value)) {
        const updatedValue = value.trim().split(' ')[0];
        getUsersAutoSuggestValues(updatedValue);
      } else {
        getUsersAutoSuggestValues(value);
      }
    } else {
      fetchData();
    }
  };

  const getSuggestApiParams = () => {
    const getSearchType = () => {
      if (
        inputField.requestType === RequestType.FRAME_SEARCH_REQUEST &&
        searchFieldDetails.accessorKey === SearchFields.SITE_KEY
      ) {
        return AutoSuggestSearchType.PREFIX_AND_CONTAINS;
      }
      return undefined;
    };

    const getFrameTypes = () => {
      if (inputField.requestType === RequestType.FRAME_SEARCH_REQUEST) {
        return undefined;
      }
      return [FrameTypeForAutoSuggest.DIGITAL].join(',');
    };

    return {
      bu: BusinessUnits.GB,
      field_name: getInputFieldAccessorKey(appContext, inputField.label, t),
      field_value: value.trim(),
      number_of_values: OOC_API_PAGE_LIMITS.SUGGEST_API_LIMIT,
      search_type: getSearchType(),
      frame_types: getFrameTypes(),
    };
  };
  const getRequestOptions = (): RequestOptions => {
    return {
      url: API_ENDPOINTS.AUTO_SUGGEST_URL,
      method: Methods.GET,
      params: getSuggestApiParams(),
      headers: headers,
      errorCallback: handlerFailureResponse,
      successCallback: handleSuccessResponse,
    };
  };

  const shouldNotCallSuggestApi = () => {
    const shouldNotCallSuggestApi = !debouncedValue || debouncedValue.length < 3 || isOptionSelected || modalLoading;
    setIsOptionSelected(false);
    return shouldNotCallSuggestApi;
  };

  const handleSuccessResponse = (response: AxiosResponse) => {
    const responseData = response.data;
    const optionsInString = responseData.values[0].map(String);
    setOptions(optionsInString);
  };

  const shouldDisplayNoSuggestionsMessage = () => {
    return !autoSuggestLoading && value.length >= 3 && options.length === 0 && !loading && !modalLoading;
  };

  function handleAuditSearchRequest() {
    queryClient.clear();
    setTimeout(async () => {
      if (inputField.fetchNextPage) {
        await inputField.fetchNextPage({
          ...fetchNextPageOptions,
        });
      }
    });
  }

  const handleSubmittedItem = (autoCompleteItem: AutocompleteItem) => {
    if (inputField.requestType === RequestType.OPENING_HOURS_SEARCH_REQUEST) {
      const initialEvents: CalendarEventType = { configData: convertDataToEvents(selectedDayPartDate.operatingPeriod) };
      const isDataChanged =
        Object.entries(selectedDayPartDate.operatingPeriod).length > 0
          ? !_.isEqual(initialEvents, initialOpeningHoursCalanderEvents)
          : false;
      if (!confirmOpeningHoursDataChange(isDataChanged, dispatch, initialEvents)) {
        return;
      } else {
        dispatch(setInitialOpeningHoursCalanderEvents(initialEvents));
      }
    }

    const serachFieldDetailsWithSelectedAutoSggestValue: SearchFieldDetails = {
      ...searchFieldDetails,
      value: autoCompleteItem.value,
      accessorKey: getInputFieldAccessorKey(appContext, searchFieldDetails.label, t),
    };
    dispatch(setSearchFieldDetails(serachFieldDetailsWithSelectedAutoSggestValue));
    const { requestType } = inputField;

    if (requestType === RequestType.AUDIT_SEARCH_REQUEST) {
      handleAuditSearchRequest();
    } else if (searchFieldDetails.value) {
      dispatch(
        onClickSearch(serachFieldDetailsWithSelectedAutoSggestValue, appContext, t, requestType) as unknown as AnyAction
      );
    }
  };

  const handleOnChangeValue = (newValue: string) => {
    if (newValue.length >= 3) {
      dispatch(setAutoSuggestLoading(true));
    } else {
      dispatch(setAutoSuggestLoading(false));
    }
    setValue(newValue);
    dispatch(
      setSearchFieldDetails({
        label: inputField.label,
        value: newValue,
        accessorKey: getInputFieldAccessorKey(appContext, inputField.label, t),
      })
    );
    setOptions([]);
  };

  return (
    <div>
      <div>
        <Autocomplete
          data-testid="autocomplete"
          data={options}
          value={value ? value : searchFieldDetails.value.toString()}
          defaultValue={searchFieldDetails.value.toString()}
          disabled={loading || modalLoading || inputField.isFetching || auditTableLoading}
          onChange={handleOnChangeValue}
          placeholder={`${t('createNewTocPocModal:enter')} ${inputField.label}`}
          type={inputField.inputType}
          size="xl"
          radius={'0.3rem'}
          limit={20}
          error={value && value.length < 3 ? 'Type atleast 3 characters' : ''}
          onItemSubmit={(autoCompleteItem) => {
            setIsOptionSelected(true);
            setOptions([autoCompleteItem.value]);
            dispatch(setAutoSuggestLoading(false));
            handleSubmittedItem(autoCompleteItem);
          }}
        />
      </div>
      <span className="loader-container">{autoSuggestLoading && <Loader size="md" />}</span>
      <div className={shouldDisplayNoSuggestionsMessage() ? 'no-suggestions-message' : ''}>
        {shouldDisplayNoSuggestionsMessage() && <div>No matching {searchFieldDetails.label}(s) found</div>}
      </div>
    </div>
  );
};

export default AutocompleteWithApi;
