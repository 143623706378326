import React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  setCreateNewTocModal,
  setExistingTocPoc,
  setIsEditClicked,
} from 'src/redux/actions/tocPocSidebarTabActions/componentActions';
import { ButtonKind, ModalSize, OutOfChargeStatus, RequestType, SearchFields, SyncStatusStates } from 'src/enums/enum';
import {
  onClickSearch,
  setFetchedDataForCreateTocPocModal,
  setSearchFieldDetails,
} from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import TocPocDetailsTab from '../tocPocDetailsTable/tabsComponent/tocPocDetailsTab/tocPocDetailsTab';
import Alert from 'src/components/alert/alert';
import { setSuccessMessage, setIsSaveClicked, onClickSave } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { SearchReducer, TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { Button, useModal } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SaveReducerState } from 'src/types/saveReducer';
import { AuthTokensReducer, TocPocSidebarTabComponentState } from 'src/types/componentReducers';
import { AnyAction } from 'redux';
import { AppContext } from 'src/contexts/appContext';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { BaseTOCFields } from 'src/types/apiModels';
import { ModalConfig, TocPocFormData } from 'src/types/TocPocModal';
import {
  getFormattedDateToBeDisplayed,
  DATE_FORMAT,
  DATE_FORMAT_TO_BE_DISPLAYED,
  getFormattedDate,
} from 'src/utils/dateUtils';
import { isReadWriteRole } from 'src/utils/roleUtils';

export function hasFailedSyncStatus(existingTocsPoc: TOCPOCDetailsTableData[]) {
  return existingTocsPoc?.some(
    (item) =>
      (item.broadsignSyncStatus !== SyncStatusStates.SUCCESS && item.broadsignSyncStatus) ||
      (item.oasisSyncStatus !== SyncStatusStates.SUCCESS && item.oasisSyncStatus)
  );
}

const CreateTocPocModal = () => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { showModal, updateModal } = useModal();
  const { t } = useTranslation();
  const { fetchedDataForCreateTocPocModal, fetchedDataForTocPocTableDetails, searchFieldDetails } = useSelector(
    (state: SearchReducer) => state.searchReducer
  );
  const { isSaveClicked, successMessage } = useSelector((state: SaveReducerState) => state.saveReducer);
  const { isEditClicked, createNewTocModal, existingTocPoc } = useSelector(
    (state: TocPocSidebarTabComponentState) => state.TocPocSidebarTabReducer
  );
  const [createTocPocformData, setCreateTocPocformData] = useState<TocPocFormData>(initialExistingTocPoc);
  const { userRole } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);

  const handleOnClickSearch = (searchRequestType: RequestType) => {
    dispatch(onClickSearch(searchFieldDetails, appContext, t, searchRequestType) as unknown as AnyAction);
  };

  const handleOnClickSave = (formData: TocPocFormData) => {
    const saveRequestType = isCreateOOCRequest(existingTocPoc) ? RequestType.CREATE_REQUEST : RequestType.UPDATE_REQUEST;
    if (fetchedDataForCreateTocPocModal) {
      dispatch(
        onClickSave(formData, fetchedDataForCreateTocPocModal, existingTocPoc, saveRequestType) as unknown as AnyAction
      );
    }
  };

  useEffect(() => {
    return updateModal(createNewTocModal, configureCreateNewTocPocModal());
  }, [fetchedDataForCreateTocPocModal, createTocPocformData, isEditClicked, successMessage]);

  useEffect(() => {
    const modalConfig: ModalConfig = {
      primaryButtonLabel: isSaveClicked ? 'Saving...' : 'Close',
      primaryButtonDisabled: isSaveClicked ? true : false,
      primaryButtonAction: () => {
        'Close';
      },
      ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT'],
    };
    return updateModal(createNewTocModal, modalConfig);
  }, [isSaveClicked]);

  const showCreateNewTocPocModal = () => {
    const id = showModal(configureCreateNewTocPocModal());
    dispatch(setSuccessMessage(undefined));
    dispatch(setCreateNewTocModal(id));
    dispatch(setExistingTocPoc(initialExistingTocPoc));
  };

  const getFormattedStartDate = () => {
    return getFormattedDateToBeDisplayed(createTocPocformData.startDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED);
  };

  const displayPOCCreationMessage = () =>
    window.confirm(`Are you sure you wish to create the POC starting on ${getFormattedStartDate()}`);

  const configureCreateNewTocPocModal = (): ModalConfig => {
    return {
      content: renderCreateTocPocModalContent(),
      size: ModalSize.MediumFullHeight,
      ignoreCloseType: ['ESC_KEY', 'FOCUS_OUT', 'PRIMARY_BUTTON'],
      primaryButtonKind: ButtonKind.Primary,
      primaryButtonAction: () => {
        if (createTocPocformData.outOfChargeStatus === OutOfChargeStatus.POC && !displayPOCCreationMessage()) {
          return true;
        }
        handleOnClickSave(createTocPocformData);
        dispatch(setIsSaveClicked(true));
      },
      primaryButtonLabel: isSaveClicked ? 'Saving...' : 'Save',
      primaryButtonDisabled:
        createTocPocformData.outOfChargeStatus === OutOfChargeStatus.TOC
          ? isPrimaryButtonDisabledForTOCModal()
          : isPrimaryButtonDisabledForPOCModal(),
      title: t('createNewTocPocModal:modalHeading'),
      onCloseModal: () => {
        handleOnClickSearchOnCreate();
        resetStateVariables();
      },
    };
  };

  const renderCreateTocPocModalContent = () => {
    return (
      <>
        <TocPocDetailsTab
          setFormData={setCreateTocPocformData}
          fetchedData={fetchedDataForCreateTocPocModal ? fetchedDataForCreateTocPocModal.existingOOCDetails : []}
          updateModal={updateModal}
        />

        {successMessage && <Alert message={successMessage} onClose={() => 'Closed'} />}
      </>
    );
  };

  const isPrimaryButtonDisabledForTOCModal = () => {
    const tocFormData = { ...(createTocPocformData as BaseTOCFields) };
    const existingTocData = { ...(existingTocPoc as BaseTOCFields) };

    if (existingTocPoc.outOfChargeStatus === OutOfChargeStatus.DEFAULT) {
      return isPrimaryButtonDisabledForCreateTOCModal(tocFormData);
    }
    return isPrimaryButtonDisabledForUpdateTOCModal(existingTocData, tocFormData);
  };

  const isPrimaryButtonDisabledForCreateTOCModal = (tocFormData: BaseTOCFields) => {
    return !createTocPocformData.startDate || !tocFormData.endDate || !tocFormData.tocReasonCode;
  };

  const isPrimaryButtonDisabledForUpdateTOCModal = (existingTocData: BaseTOCFields, tocFormData: BaseTOCFields) => {
    return (
      getFormattedDate(new Date(existingTocData.startDate)) === getFormattedDate(new Date(tocFormData.startDate)) &&
      getFormattedDate(new Date(existingTocData.endDate)) === getFormattedDate(new Date(tocFormData.endDate)) &&
      existingTocData.tocReasonCode === tocFormData.tocReasonCode &&
      existingTocData.comment === tocFormData.comment
    );
  };

  const isPrimaryButtonDisabledForPOCModal = () => {
    return !createTocPocformData.startDate;
  };

  const handleOnClickSearchOnCreate = () => {
    if (successMessage) {
      handleOnClickSearch(RequestType.TOC_POC_SEARCH_REQUEST);
    } else if (!fetchedDataForTocPocTableDetails) {
      dispatch(
        setSearchFieldDetails({
          label: t('table:digitalUnitId'),
          accessorKey: SearchFields.DIGITAL_UNIT_ID,
          value: '',
        })
      );
    }
  };

  const resetStateVariables = () => {
    dispatch(setFetchedDataForCreateTocPocModal(undefined));
    dispatch(setSuccessMessage(undefined));
    dispatch(setIsEditClicked(false));
    dispatch(setIsSaveClicked(false));
    dispatch(setExistingTocPoc(initialExistingTocPoc));
  };

  const isCreateOOCRequest = (existingTocPoc: TOCPOCDetailsTableData) => {
    return existingTocPoc.outOfChargeStatus === OutOfChargeStatus.DEFAULT;
  };

  return (
    <div className="new-button">
      {isReadWriteRole(userRole) && (
        <Button
          label={t('createNewTocPocModal:new')}
          renderIcon="edit"
          onClick={() => {
            setCreateTocPocformData(initialExistingTocPoc);
            showCreateNewTocPocModal();
            searchFieldDetails.value != '' && handleOnClickSearch(RequestType.CREATE_TOC_POC_MODAL_SEARCH_REQUEST);
          }}
        />
      )}
    </div>
  );
};

export default CreateTocPocModal;
