import React, { Dispatch, useState } from 'react';
import _ from 'lodash';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DayCheckboxGroup from './dayCheckboxGroup/dayCheckboxGroup';
import OpeningHoursCalendarConfigDatePicker from './openingHoursCalendarConfigDatePicker/openingHoursCalendarConfigDatePicker';
import { Button, H4 } from '@clearchannelinternational/ecooh-system';
import { CalendarConfigType } from 'src/types/componentProps';
import { DaysToIndexMapping, OperatingPeriodType, OperatingPeriodRequestType } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from 'src/utils/dateUtils';
import OpeningHoursSyncModal from './openingHoursSyncModal/openingHoursSyncModal';
import { setInitialOpeningHoursCalanderEvents } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';
import { useDispatch, useSelector } from 'react-redux';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';
import { AnyAction } from 'redux';
import { SearchReducer } from 'src/types/searchReducer';
import { areDisplayUnitIdsUnique } from '../../utils/utils';

export type CalendarEventType = {
  configData: {
    id: number;
    start: string;
    end: string;
  }[];
};

const CalendarConfig: React.FC<CalendarConfigType> = ({
  selectedDayPartData,
  shouldOpeningHoursDetailedSectionBeEnabled,
  openingHoursTableData,
  setOpeningHoursTableData,
  openingHoursFormValues,
  setOpeningHoursFormValues,
  initialEvents,
  eventId,
  setEventId,
  dateValue,
  setDateValue,
  calendarRef,
  convertDataToEvents,
  effectiveDateFrom,
  setEffectiveFromDate,
  selectedFrameDetailRow,
}) => {
  const [checkedStates, setCheckedStates] = useState([false, false, false, false, false, false, false]);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { isViewButtonClicked } = useSelector((state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer);
  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);
  const { initialOpeningHoursCalanderEvents } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );
  const getTimeString = (date: Date) => {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  function addNewEvent(calendarApi, startStr, endStr) {
    const newEvent = {
      id: eventId,
      start: startStr,
      end: endStr,
    };
    calendarApi.addEvent(newEvent);
  }

  function createOpeningHours(dayIndex, startStr, endStr) {
    const dayOpeningHours = openingHoursFormValues.operatingPeriod[DaysToIndexMapping[dayIndex]] || [];
    const startTime = getTimeString(new Date(startStr));
    const endTime = getTimeString(new Date(endStr));
    return [...dayOpeningHours, { start: startTime, end: endTime }];
  }

  function updateOpeningHours(startStr, endStr) {
    const startTime = getTimeString(new Date(startStr));
    const endTime = getTimeString(new Date(endStr));
    return [{ start: startTime, end: endTime }];
  }

  function updateDateSelect(selectInfo) {
    const selectInfoEvent = selectInfo.event;
    const dayIndex = selectInfoEvent.start.getDay();

    selectInfoEvent.setProp('start', selectInfoEvent.startStr);
    selectInfoEvent.setProp('end', selectInfoEvent.endStr);

    const updatedOpeningHours = updateOpeningHours(selectInfoEvent.startStr, selectInfoEvent.endStr);

    setOpeningHoursFormValues({
      ...openingHoursFormValues,
      operatingPeriod: { ...openingHoursFormValues.operatingPeriod, [DaysToIndexMapping[dayIndex]]: updatedOpeningHours },
    });

    const updatedInitialEvents = {
      ...initialOpeningHoursCalanderEvents,
      configData: initialOpeningHoursCalanderEvents.configData.map((event) => {
        return event.id.toString() === selectInfoEvent.id.toString()
          ? { ...event, start: selectInfoEvent.startStr, end: selectInfoEvent.endStr }
          : event;
      }),
    };
    dispatch(setInitialOpeningHoursCalanderEvents(updatedInitialEvents));
  }

  function handleDateSelect(selectInfo) {
    const dayIndex = selectInfo.start.getDay();
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();

    addNewEvent(calendarApi, selectInfo.startStr, selectInfo.endStr);

    const updatedOpeningHours = createOpeningHours(dayIndex, selectInfo.startStr, selectInfo.endStr);

    setOpeningHoursFormValues({
      ...openingHoursFormValues,
      operatingPeriod: { ...openingHoursFormValues.operatingPeriod, [DaysToIndexMapping[dayIndex]]: updatedOpeningHours },
    });
    const updatedInitialEvents = {
      ...initialOpeningHoursCalanderEvents,
      configData: [
        ...initialOpeningHoursCalanderEvents.configData,
        {
          id: eventId + 1,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
        },
      ],
    };

    dispatch(setInitialOpeningHoursCalanderEvents(updatedInitialEvents));
    setEventId(eventId + 1);
  }
  function handleEventClick(clickInfo) {
    const newTitle = prompt('Edit event title:', clickInfo.event.title);
    const newStart = prompt('Edit event start date and time (YYYY-MM-DDTHH:mm:ss):', clickInfo.event.startStr);
    const newEnd = prompt('Edit event end date and time (YYYY-MM-DDTHH:mm:ss):', clickInfo.event.endStr);

    if (newTitle && newStart && newEnd) {
      clickInfo.event.setProp('title', newTitle);
      clickInfo.event.setStart(newStart);
      clickInfo.event.setEnd(newEnd);
    }
  }

  function renderEventContent(eventInfo) {
    const shouldDayBeDisabled = handleSelectAllow(new Date(eventInfo.event.startStr));
    const startTime = eventInfo.event.startStr.slice(11, 16);
    let endTime = eventInfo.event.endStr.slice(11, 16);
    const adjustedEndTime = endTime === '00:00' ? '23:59' : endTime;

    const endTimeDate = new Date(`1970-01-01T${adjustedEndTime}:00Z`);

    const minutes = endTimeDate.getUTCMinutes();
    if (minutes === 29 || minutes === 59) {
      endTimeDate.setUTCMinutes(minutes + 1);
      endTime = endTimeDate.toISOString().slice(11, 16);
    }

    const timeText = `${startTime}-${endTime}`;

    return (
      <div className={`text-delete-button-container ${!shouldDayBeDisabled ? 'disabled' : ''}`}>
        <H4 tone="backgroundAlt1">{timeText}</H4>
        {!isViewButtonClicked && (
          <div className="delete-button">
            <Button renderIcon="delete" kind="tertiary" onClick={() => handleEventDelete(eventInfo.event)} label="" />
          </div>
        )}
      </div>
    );
  }

  function handleEventDelete(eventToBeDeleted) {
    if (window.confirm(`Are you sure you want to delete the event`)) {
      const startTime = getTimeString(new Date(eventToBeDeleted.start));
      const endTime = getTimeString(new Date(eventToBeDeleted.end));
      const dayIndex = new Date(eventToBeDeleted.start).getDay();
      const dayOpeningHours = openingHoursFormValues.operatingPeriod[DaysToIndexMapping[dayIndex]];
      const updatedOpeningHours = dayOpeningHours.filter((hour) => hour.start !== startTime && hour.end !== endTime);
      setOpeningHoursFormValues({
        ...openingHoursFormValues,
        operatingPeriod: {
          ...openingHoursFormValues.operatingPeriod,
          [DaysToIndexMapping[dayIndex]]: updatedOpeningHours,
        },
      });

      const updatedInitialEvents = {
        ...initialOpeningHoursCalanderEvents,
        configData: initialOpeningHoursCalanderEvents.configData.filter(
          (event) => event.id.toString() !== eventToBeDeleted.id.toString()
        ),
      };
      dispatch(setInitialOpeningHoursCalanderEvents(updatedInitialEvents));
      eventToBeDeleted.remove();
    }
  }

  function handleReset() {
    if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
      const nonBlockingBaseOperatingPeriods = fetchedDataForOpeningHoursSearchRequest[0].nonBlockingOperatingPeriods.map(
        (op) => op.operatingPeriodType === OperatingPeriodType.BASE
      );

      if (nonBlockingBaseOperatingPeriods?.length === 1) {
        setEffectiveFromDate(null);
      } else {
        setEffectiveFromDate(new Date(selectedDayPartData.startDate));
      }
      const calendarApi = calendarRef.current && calendarRef.current.getApi();
      const currentOpeningHoursData = selectedDayPartData.operatingPeriod;
      const initialEvents: CalendarEventType = { configData: convertDataToEvents(currentOpeningHoursData) };
      if (calendarApi) {
        calendarApi.removeAllEvents();
        initialEvents.configData.forEach((event) => {
          calendarApi.addEvent({ start: event.start, end: event.end });
        });
      }
      setOpeningHoursFormValues(selectedDayPartData);
      dispatch(setInitialOpeningHoursCalanderEvents(initialEvents));
    }
  }

  function getMatchingStartDateBetweenOpeningHoursDates(currentDate: Date) {
    const updatedTableData = openingHoursTableData.filter(
      (data) =>
        data.operatingPeriodDBId !== selectedDayPartData.operatingPeriodDbId && data.name !== OperatingPeriodType.BASE
    );
    return updatedTableData.find((data) => {
      const startDate = new Date(data.startDate);
      const endDate = new Date(data.endDate);

      if (
        getFormattedDate(currentDate) === getFormattedDate(startDate) ||
        (currentDate >= startDate && currentDate <= endDate)
      ) {
        return { startDate, endDate };
      }
    });
  }

  const handleSelectAllow = (startDate: Date) => {
    if (selectedDayPartData.startDate && selectedDayPartData.endDate) {
      return true;
    }
    const day = startDate.getDay();
    const matchingOpeningHoursData = dateValue[0] ? getMatchingStartDateBetweenOpeningHoursDates(dateValue[0]) : undefined;
    if (matchingOpeningHoursData) {
      const openingHours = matchingOpeningHoursData.operatingPeriod;
      return openingHours[DaysToIndexMapping[day]].length === 0;
    }
    if (shouldOpeningHoursDetailedSectionBeEnabled) {
      return true;
    }
    return false;
  };

  return (
    <div className="calendar-config-container">
      <OpeningHoursCalendarConfigDatePicker
        selectedDayPartData={selectedDayPartData}
        openingHoursFormValues={openingHoursFormValues}
        setOpeningHoursFormValues={setOpeningHoursFormValues}
        dateValue={dateValue}
        setDateValue={setDateValue}
        getMatchingStartDateBetweenOpeningHoursDates={getMatchingStartDateBetweenOpeningHoursDates}
        handleReset={handleReset}
        openingHoursTableData={openingHoursTableData}
        setOpeningHoursTableData={setOpeningHoursTableData}
      />
      {!isViewButtonClicked && (
        <div className="save-discard-button-container">
          <OpeningHoursSyncModal
            openingHoursFormValues={openingHoursFormValues}
            setOpeningHoursFormValues={setOpeningHoursFormValues}
            dateValue={dateValue}
            setDateValue={setDateValue}
            setEffectiveFromDate={setEffectiveFromDate}
            effectiveDateFrom={effectiveDateFrom}
            selectedDayPartData={selectedDayPartData}
            handleReset={handleReset}
            operatingPeriodRequestType={OperatingPeriodRequestType.UPDATE}
            checkedStates={checkedStates}
            shouldHideEffectiveFromField={true}
            selectedFrameDetailRow={selectedFrameDetailRow}
          />
        </div>
      )}
      <div className="reset-new-button" style={{ visibility: 'hidden' }}>
        <Button label={translate('openingHoursSIdebarTab:reset')} renderIcon="edit" onClick={() => handleReset()} />
      </div>
      {!isViewButtonClicked && (
        <DayCheckboxGroup
          openingHoursFormValues={openingHoursFormValues}
          setOpeningHoursFormValues={setOpeningHoursFormValues}
          calendarRef={calendarRef}
          checkedStates={checkedStates}
          setCheckedStates={setCheckedStates}
        />
      )}
      <div className="full-calendar-container">
        <FullCalendar
          ref={calendarRef}
          dayHeaderFormat={{ weekday: 'long' }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          initialView="timeGridWeek"
          editable={!isViewButtonClicked}
          selectable={!isViewButtonClicked}
          eventResizableFromStart={!isViewButtonClicked}
          eventColor={isViewButtonClicked ? '#808080' : '#3888d8'}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          select={handleDateSelect}
          eventResize={updateDateSelect}
          eventDrop={updateDateSelect}
          eventContent={renderEventContent}
          slotDuration={'00:30:00'}
          eventOverlap={false}
          slotLabelInterval={'01:00'}
          events={initialEvents.configData as any}
          selectOverlap={false}
          height={'80rem'}
          contentHeight={'20'}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          initialDate={'2023-12-31'}
          allDaySlot={false}
          expandRows={true}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            meridiem: true,
            omitZeroMinute: true,
          }}
          selectAllow={(selectInfo) => {
            const startDate = selectInfo.start;
            return handleSelectAllow(startDate);
          }}
        />
      </div>
      <div className="bottom-save-discard-button-container">
        <OpeningHoursSyncModal
          openingHoursFormValues={openingHoursFormValues}
          setOpeningHoursFormValues={setOpeningHoursFormValues}
          dateValue={dateValue}
          setDateValue={setDateValue}
          selectedDayPartData={selectedDayPartData}
          handleReset={handleReset}
          operatingPeriodRequestType={OperatingPeriodRequestType.UPDATE}
          checkedStates={checkedStates}
          effectiveDateFrom={effectiveDateFrom}
          setEffectiveFromDate={setEffectiveFromDate}
          shouldHideEffectiveFromField={false}
          selectedFrameDetailRow={selectedFrameDetailRow}
        />
      </div>
    </div>
  );
};

export default CalendarConfig;

function handleEventUpdate(event) {
  const newTitle = prompt('Edit event title:', event.title);
  const newStartTime = prompt('Edit event start time (HH:mm):', formatTime(event.start));
  const newEndTime = prompt('Edit event end time (HH:mm):', formatTime(event.end));

  if (newTitle && newStartTime && newEndTime) {
    const [startHour, startMinute] = newStartTime.split(':');
    const [endHour, endMinute] = newEndTime.split(':');

    const newStart = new Date(event.start);
    newStart.setHours(parseInt(startHour), parseInt(startMinute));

    const newEnd = new Date(event.end);
    newEnd.setHours(parseInt(endHour), parseInt(endMinute));

    event.setProp('title', newTitle);
    event.setStart(newStart);
    event.setEnd(newEnd);
  }
}

function formatTime(date) {
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}

export function getDayDate2(dayIndex, timeString, index = null) {
  const startDayOfTheWeek = new Date('2023-12-31');
  const day = startDayOfTheWeek.getDay();
  let diff = 0;
  if (index === null) {
    diff = dayIndex + day;
  } else if (dayIndex && index % 7 === dayIndex) {
    diff = day + index + (index % 7);
  } else {
    diff = day + index;
  }
  const newDate = new Date(startDayOfTheWeek.setDate(startDayOfTheWeek.getDate() + diff));
  const dateString = newDate.toISOString().split('T')[0];
  return `${dateString}T${timeString}`;
}

export function getDayDate(dayIndex, timeString) {
  const startDayOfTheWeek = new Date('2023-12-31');
  const newDate = new Date(startDayOfTheWeek.setDate(startDayOfTheWeek.getDate() + dayIndex));
  const dateString = newDate.toISOString().split('T')[0];
  return `${dateString}T${timeString}`;
}

export const confirmOpeningHoursDataChange = (
  isDataChanged: boolean,
  dispatch: Dispatch<AnyAction>,
  initialEvents: CalendarEventType
) => {
  if (isDataChanged) {
    const isConfirmed = window.confirm(
      'You have unsaved changes that will be lost if you leave this page. Are you sure you want to leave this page?'
    );
    if (!isConfirmed) {
      return false;
    } else {
      dispatch(setInitialOpeningHoursCalanderEvents(initialEvents));
    }
  }
  return true;
};
