/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import {
  actionsAccessorKey,
  digitalUnitIdAccessorKey,
  getTocPocTableColumns,
} from 'src/pages/tocPocTabSidebarTab/tocPocDetailsTable/tocPocDetailsTableConfig';
import {
  setBulkTOCSearchFormValues,
  setBulkUpdateDetailsTableResponseData,
  setIsBulkRecordsDownloading,
} from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';
import BulkTOCUpdateModal from '../bulkTOCUpdateModal/bulkTOCUpdateModal';
import BulkOOCSearchBar, { getInitialBulkOOCSearchForm } from '../bulkOOCSearchBar/bulkOOCSearchBar';
import { getUpdatedSearchFormValues } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { useMantineReactTable, MRT_TableInstance, MantineReactTable } from 'mantine-react-table';
import { BulkOOCDownlaodStatus as BulkOOCDownloadStatus, Methods, OutOfChargeStatus, RequestType } from 'src/enums/enum';
import { AppContext } from 'src/contexts/appContext';
import { ColumnVisibility, OOCDetailsTableType } from 'src/types/table';
import { InitialState } from 'src/types/TocPocModal';
import { Loading, Button, P, H3, snackbar } from '@clearchannelinternational/ecooh-system';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SearchReducer } from 'src/types/searchReducer';
import { MantineProvider } from '@mantine/core';
import { LoadingReducer } from 'src/types/loadinReducer';
import { BulkOOCSearchUpdateTabProps } from 'src/types/componentProps';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { RequestOptions, sendAxiosRequest } from 'src/api/axios';
import { API_ENDPOINTS, buParam, headers } from 'src/api/apiConfig';
import { handleFailureResponse } from '../bulkOOCSearchSidebarTab';
import { initialExistingTocPoc } from 'src/redux/reducers/tocPocSidebarTabReducers/componentReducer';
import { initialSearchFieldDetails } from 'src/redux/reducers/tocPocSidebarTabReducers/searchReducer';
import { AxiosResponse } from 'axios';
import { initialUpdateDetailsResponseData } from 'src/redux/reducers/bulkOOCSearchSidebarTabReducer/bulkOOCSearchSidebarTabReducer';
import { BulkOOCSearchDownloadStatusResponseBody, BulkOOCSearchDownloadTriggerResponseBody } from 'src/types/apiModels';

const BulkOOCSearchUpdateTab: React.FC<BulkOOCSearchUpdateTabProps> = ({
  bulkUpdateDetailsTableData,
  onClickBulkUpdateSummary,
  enabledPagination,
}) => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const isBulkTocSearchTab = true;
  const { t: translate } = useTranslation();
  const [downloadLabel, setDownloadLabel] = useState<string>(translate('bulkOOCSearchSidebarTab:exportCurrentView'));
  const { tableData, totalRecordsForOOCSearchTable } = useSelector((state: SearchReducer) => state.searchReducer);
  const { loading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { bulkUpdateDetailsResponseData, bulkTOCSearchFormValues, isBulkRecordsDownloading } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );

  const getBulkTableInitialState = (): InitialState => {
    const bulkTocSearchTabFieldVisibility = {
      dismantleSite: false,
      outOfChargeStatus: false,
    };
    const oasisSystemColumnVisibility = {
      slotId: false,
      aida: false,
      panelIds: false,
      broadsignControlFrameIds: false,
      commercialFrameIds: false,
    };
    const columnVisibilitySettingsForOasis = { ...bulkTocSearchTabFieldVisibility, ...oasisSystemColumnVisibility };
    const columnVisibility: ColumnVisibility = {
      oasis: columnVisibilitySettingsForOasis,
    };

    return {
      columnVisibility: columnVisibility[appContext.system],
      columnPinning: {
        right: [actionsAccessorKey],
        left: [digitalUnitIdAccessorKey],
      },
      showColumnFilters: isBulkTocSearchTab,
    };
  };

  const tableInstance = useMantineReactTable({
    columns: getTocPocTableColumns(
      tableData,
      isBulkTocSearchTab,
      translate,
      RequestType.OOC_BULK_SEARCH_REQUEST,
      bulkUpdateDetailsTableData,
      onClickBulkUpdateSummary
    ),
    data: bulkUpdateDetailsTableData ? bulkUpdateDetailsTableData : (tableData as any),
    enableGrouping: true,
    enablePinning: true,
    enableRowSelection: bulkUpdateDetailsTableData === undefined,
    enablePagination: false,
    enableRowVirtualization: true,
    enableColumnFilterModes: true,
    enableTopToolbar: true,
    enableFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    enableColumnFilters: true,
    initialState: getBulkTableInitialState(),
  }) as MRT_TableInstance<OOCDetailsTableType>;
  const selectedRows = tableInstance.getSelectedRowModel().rows;

  const getExportButtonLabel = () => {
    return selectedRows.length > 0
      ? translate('bulkOOCSearchSidebarTab:exportSelectedRecords')
      : translate('bulkOOCSearchSidebarTab:exportCurrentView');
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setDownloadLabel(translate('bulkOOCSearchSidebarTab:exportSelectedRecords'));
    } else {
      setDownloadLabel(translate('bulkOOCSearchSidebarTab:exportCurrentView'));
    }
  }, [selectedRows]);

  useEffect(() => {
    tableInstance.resetRowSelection();
  }, [tableData]);

  useEffect(() => {
    if (isBulkRecordsDownloading) {
      setDownloadLabel(translate('bulkOOCSearchSidebarTab:csvDownalodingLabel'));
    } else {
      setDownloadLabel(getExportButtonLabel());
    }
  }, [isBulkRecordsDownloading]);

  useEffect(() => {
    if (enabledPagination === undefined) {
      dispatch(setBulkUpdateDetailsTableResponseData(initialUpdateDetailsResponseData));
    }
    dispatch(setBulkTOCSearchFormValues(getInitialBulkOOCSearchForm()));
  }, []);

  const handleSuccessResponseForDownloadStatus = (response: AxiosResponse) => {
    const responseData: BulkOOCSearchDownloadStatusResponseBody = response.data;

    if (responseData.status === BulkOOCDownloadStatus.TRIGGERED) {
      checkDownloadStatus(responseData.downloadUUID);
    } else if (responseData.status === BulkOOCDownloadStatus.COMPLETED && responseData.presignedUrl) {
      downloadCsv(responseData.presignedUrl);
    }
  };

  const downloadCsv = async (presignedUrl: string) => {
    try {
      const response = await fetch(presignedUrl);
      if (!response.ok) {
        throw new Error('Failed to download file');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      downloadFile(url);
      dispatch(setIsBulkRecordsDownloading(false));
      snackbar.success('File downloaded successfully!');
    } catch (e) {
      handleDownloadError();
    }
  };

  const checkDownloadStatus = (downloadUUID: string) => {
    const params = {
      ...buParam,
      downloadUUID: downloadUUID,
    };

    const axiosOptions: RequestOptions = {
      method: Methods.GET,
      params: params,
      errorCallback: handleDownloadError,
      headers: headers,
      successCallback: handleSuccessResponseForDownloadStatus,
      url: API_ENDPOINTS.OOC_DOWNLOAD_STATUS,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [], initialSearchFieldDetails);
  };

  const handleSuccessResponseForCSVDownloadTrigger = (response: AxiosResponse) => {
    const responseData: BulkOOCSearchDownloadTriggerResponseBody = response.data;
    const downloadUUID = responseData.downloadUUID;
    checkDownloadStatus(downloadUUID);
  };

  const downloadFile = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'toc-poc-data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleDownloadError = () => {
    snackbar.error('Failed to download file', {
      title: 'Download Failure!',
    });
    setDownloadLabel(getExportButtonLabel());
    dispatch(setIsBulkRecordsDownloading(false));
  };

  const handleExportButtonClick = () => {
    dispatch(setIsBulkRecordsDownloading(true));
    snackbar.info('Download Triggered!');
    const bulkSearchRequestFormValues = getUpdatedSearchFormValues(bulkTOCSearchFormValues);
    const selectedOOCIds = tableInstance.getSelectedRowModel().rows.map((row) => row.original.id);
    const totalOOCIds = tableInstance.getRowModel().rows.map((row) => row.original.id);
    const oocIdsToExclude = selectedOOCIds.length > 0 ? totalOOCIds.filter((id) => !selectedOOCIds.includes(id)) : [];
    const bulkTOCUpdateRequestBody = {
      ...bulkSearchRequestFormValues,
      oocIdsToExclude: oocIdsToExclude,
    };

    const axiosOptions: RequestOptions = {
      method: Methods.POST,
      params: buParam,
      data: bulkTOCUpdateRequestBody,
      errorCallback: handleFailureResponse,
      headers: headers,
      successCallback: handleSuccessResponseForCSVDownloadTrigger,
      url: API_ENDPOINTS.TRIGGER_OOC_DOWNLOAD,
    };
    sendAxiosRequest(axiosOptions, dispatch, initialExistingTocPoc, [], initialSearchFieldDetails);
  };

  const getBulkSearchTableRecordsText = () => {
    const ferchedRecords = tableInstance.getRowModel().rows.length;
    if (bulkTOCSearchFormValues.oocTypes.length === 2) {
      return `Fetched ${ferchedRecords} of ${totalRecordsForOOCSearchTable} TOC(s) / POC(s)`;
    } else if (
      bulkTOCSearchFormValues.oocTypes.length === 1 &&
      bulkTOCSearchFormValues.oocTypes[0] === OutOfChargeStatus.TOC
    ) {
      return `Fetched ${ferchedRecords} of ${totalRecordsForOOCSearchTable} TOC(s)`;
    } else if (
      bulkTOCSearchFormValues.oocTypes.length === 1 &&
      bulkTOCSearchFormValues.oocTypes[0] === OutOfChargeStatus.POC
    ) {
      return `Fetched ${ferchedRecords} of ${totalRecordsForOOCSearchTable} POC(s)`;
    }
  };

  return (
    <div data-testid="audit-search-sidebar-tab" className="bulk-ooc-table-container">
      {(!bulkUpdateDetailsTableData || !bulkUpdateDetailsResponseData) && <BulkOOCSearchBar />}
      {loading ? (
        <div className="bulk-search-tab-loading">
          <Loading />
        </div>
      ) : (
        <>
          {!bulkUpdateDetailsTableData && (
            <div className="bulk-update-export-search-button">
              <BulkTOCUpdateModal bulkOOCTableInstance={tableInstance} onClickBulkUpdateSummary={onClickBulkUpdateSummary} />
              <Button
                renderIcon="download"
                kind="secondary"
                size="small"
                disabled={tableData.length === 0 || isBulkRecordsDownloading}
                label={downloadLabel}
                onClick={() => handleExportButtonClick()}
              />
            </div>
          )}
          <div className="bulk-ooc-table-text-container">
            <div className="fetched-records-text">
              {' '}
              {enabledPagination === undefined && (
                <P>
                  {/* {_.isEqual(bulkTOCSearchFormValues, getInitialBulkOOCSearchForm()) && */}
                  {tableData.length !== 0 && getBulkSearchTableRecordsText()}
                </P>
              )}
            </div>
            {enabledPagination === false && (
              <div className="detailed-text">
                <H3>Detailed</H3>
              </div>
            )}
            <div className="bulk-table-container">
              <MantineProvider theme={{ primaryColor: 'blue', primaryShade: 8 }}>
                <MantineReactTable table={tableInstance as any} />
              </MantineProvider>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BulkOOCSearchUpdateTab;
