import React from 'react';
import { Footer } from '@clearchannelinternational/ecooh-system';
import { useTranslation } from 'react-i18next';

const FrameManagerFooter: React.FC<any> = ({ translate }) => {
  const { t } = useTranslation();

  const handleEmailClick = () => {
    const contactEmail = t('footer:contactEmail');
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <div className="footer">
      <Footer
        copyright={t('footer:copyright')}
        links={[
          {
            label: t('footer:contact'),
            onClick: handleEmailClick,
          },
        ]}
        logoAlt="cc logotype"
        // logoHeight="1rem"
        // logoSrc="cciLogo.png"
      />
    </div>
  );
};

export default FrameManagerFooter;
