/* eslint-disable camelcase */
import React from 'react';
import Table from 'src/components/table/table';
import { MRT_ColumnDef } from 'mantine-react-table';
import { AuditTableDetails, ChangeDetails } from 'src/types/table';
import { AuditTableChangeDetailsProps } from 'src/types/componentProps';
import { useTranslation } from 'react-i18next';
import { BaseTOCFields } from 'src/types/apiModels';
import { OOCAttributes, OutOfChargeStatus } from 'src/enums/enum';
import { generateCellValue } from '../auditViewSidebarTabConfig/auditViewSidebarTableConfig';

type AuditTableChangeDetails = {
  oldStartDate: string;
  newStartDate: string;
  oldEndDate: string;
  newEndDate: string;
  oldReason: string;
  newReason: string;
  oldComments: string;
  newComments: string;
};

const AuditTableChangeDetails: React.FC<AuditTableChangeDetailsProps> = ({ changeDetailsIndex, auditTableData }) => {
  const changeDetails: AuditTableDetails = auditTableData[changeDetailsIndex];
  const { t: translate } = useTranslation();

  const auditTableChangeDetailsColumns = (): MRT_ColumnDef<ChangeDetails>[] => {
    const commonColumnProps = {
      size: 100,
    };

    const getCellOpacity = (cell) => (cell.row.original.oldValue === cell.row.original.newValue ? 0.4 : 1);

    return [
      {
        accessorKey: 'tocPocFields',
        header: 'Fields',
        size: 10,
      },
      {
        accessorKey: 'oldValue',
        header: 'Old Value',
        ...commonColumnProps,
        size: 150,
        Cell: (cell) => (
          <div style={{ opacity: getCellOpacity(cell) }}>
            {generateCellValue(changeDetails, cell.row.original.oldValue, cell.row.original.tocPocFields, 100)}
          </div>
        ),
      },
      {
        accessorKey: 'newValue',
        header: 'New Value',
        ...commonColumnProps,
        size: 150,
        Cell: (cell) => (
          <div style={{ opacity: getCellOpacity(cell) }}>
            {generateCellValue(changeDetails, cell.row.original.newValue, cell.row.original.tocPocFields, 100)}
          </div>
        ),
      },
    ];
  };

  const changeDetailsData = () => {
    const POCAttributesArray = [OOCAttributes.START_DATE, OOCAttributes.END_DATE, OOCAttributes.DISMANTLE_SITE];
    const TOCAttributesArray = [
      OOCAttributes.START_DATE,
      OOCAttributes.END_DATE,
      OOCAttributes.REASON_TEXT,
      OOCAttributes.COMMENT,
    ];
    const attributesToBeUsed =
      changeDetails.outOfChargeType === OutOfChargeStatus.POC ? POCAttributesArray : TOCAttributesArray;
    return changeDetails
      ? attributesToBeUsed.map((field) => {
          return {
            tocPocFields: translate(`table:${field}`),
            oldValue: changeDetails.oldState ? (changeDetails.oldState as BaseTOCFields)[field] : '',
            newValue: changeDetails.newState ? (changeDetails.newState as BaseTOCFields)[field] : '',
          };
        })
      : [];
  };

  return (
    <>
      <div data-testid="table-container" className="existingTocPoc-table-container">
        <Table
          columns={auditTableChangeDetailsColumns()}
          data={changeDetailsData()}
          enableGrouping={false}
          enablePinning={false}
          enableRowSelection={false}
          enablePagination={false}
          enableRowVirtualization={false}
          enableColumnFilterModes={false}
          enableTopToolbar={false}
          enableFilters={false}
          enableSorting={false}
          enableColumnActions={false}
          enableColumnFilters={false}
        />
      </div>
    </>
  );
};

export default AuditTableChangeDetails;
