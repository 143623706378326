/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { MRT_Cell, MRT_ColumnDef, MRT_TableInstance, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { FrameSearchTableData, OpeningHoursTableData, OperatingPeriod, WeeklyTimeRange } from 'src/types/apiModels';
import { ExternalSystems, OperatingPeriodType, SyncStatusStates, WeekToShortFormMapping } from 'src/enums/enum';
import { syncStatusCell } from '../../tocPocTabSidebarTab/tocPocDetailsTable/tocPocDetailsTableConfig';
import { OOCDetailsTableType } from 'src/types/table';
import { MantineProvider } from '@mantine/core';
import { OpeningHoursTableProps } from 'src/types/componentProps';
import { useDispatch, useSelector } from 'react-redux';
import { SearchReducer } from 'src/types/searchReducer';
import {
  getFormattedDateToBeDisplayed,
  DATE_FORMAT,
  DATE_FORMAT_TO_BE_DISPLAYED,
  formatDateToMMMDDYYYY,
} from 'src/utils/dateUtils';
import {
  buildOperatingPeriodsData,
  setIsViewButtonClicked,
  setSelectedDayPartData,
} from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';
import OpeningHoursResyncModal from '../openingHoursResyncModal/openingHoursResyncModal';
import { Button, Icon } from '@clearchannelinternational/ecooh-system';
import Tippy from '@tippyjs/react';
import { areDisplayUnitIdsUnique } from '../utils/utils';
import { isReadWriteRole } from 'src/utils/roleUtils';
import { AuthTokensReducer } from 'src/types/componentReducers';

const OpeningHoursTable: React.FC<OpeningHoursTableProps> = ({
  setShouldOpeningHoursDetailedSectionBeEnabled,
  selectedDayPartData: openingHoursFormValues,
  openingHoursTableData,
  setOpeningHoursTableData,
  selectedFrameDetailRow,
}) => {
  const { t: translate } = useTranslation();
  const { fetchedDataForOpeningHoursSearchRequest } = useSelector((state: SearchReducer) => state.searchReducer);
  const { userRole } = useSelector((state: AuthTokensReducer) => state.autheTokensReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchedDataForOpeningHoursSearchRequest && fetchedDataForOpeningHoursSearchRequest.length > 0) {
      let nonBlockingOperatingPeriods: OperatingPeriod[];

      if (!areDisplayUnitIdsUnique(fetchedDataForOpeningHoursSearchRequest)) {
        nonBlockingOperatingPeriods = fetchedDataForOpeningHoursSearchRequest[0].nonBlockingOperatingPeriods;
      } else {
        nonBlockingOperatingPeriods = fetchedDataForOpeningHoursSearchRequest.find((data) =>
          data.frameIdentifiers.panelIds.includes(selectedFrameDetailRow.panelId)
        )?.nonBlockingOperatingPeriods as OperatingPeriod[];
      }

      const operatingPeriodsTableData = buildOperatingPeriodsData(
        nonBlockingOperatingPeriods,
        selectedFrameDetailRow.panelId
      );
      setOpeningHoursTableData(operatingPeriodsTableData);
      //   setOpeningHoursTableData([...openingHoursTableData, ...operatingPeriodsTableData]);
    } else {
      setOpeningHoursTableData([]);
    }
  }, [selectedFrameDetailRow, fetchedDataForOpeningHoursSearchRequest]);

  const getOpeningHoursTableColumns = (): MRT_ColumnDef<OpeningHoursTableData>[] => {
    return [
      {
        accessorKey: 'name',
        header: translate('table:name'),
        Cell: (cell) => {
          const startDate = cell.row.original.startDate;
          return new Date(startDate) > new Date() ? 'Future Base/Default' : 'Current Base/Default';
        },
      },
      {
        accessorKey: 'startDate',
        header: `${translate('table:startDate')}`,
        Cell: (cell) => {
          const startDate = cell.row.original.startDate;
          return getFormattedDateToBeDisplayed(startDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED);
        },
      },
      {
        accessorKey: 'endDate',
        header: translate('table:endDate'),
        Cell: (cell) => {
          const endDate = cell.row.original.endDate;
          return getFormattedDateToBeDisplayed(endDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED);
        },
        size: 130,
      },
      {
        accessorKey: 'broadsignSyncStatus',
        header: translate('table:broadsignSyncStatus'),
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        size: 120,
        accessorFn: (originalRow) =>
          originalRow.oasisSystemSyncStatus ? originalRow.oasisSystemSyncStatus : SyncStatusStates.NA,
        Cell: (cell) => {
          const syncStatus = cell.row.original.broadsignSystemSyncStatus;
          const syncStatusToDisplay = syncStatusCell(syncStatus);
          return (
            <Tippy
              appendTo={document.body}
              maxWidth="200rem"
              placement="top"
              interactive
              arrow={true}
              disabled={syncStatus !== SyncStatusStates.SCHEDULED}
              content={
                <div className="google-maps-content">
                  <Icon fontSize="1rem" iconName="information" />
                  <div
                    className="street-view-tooltip-text"
                    style={{ width: '25rem', padding: '1rem', marginTop: '-0.2rem', lineHeight: '2rem' }}
                  >
                    {getTooltipText()}
                  </div>
                </div>
              }
            >
              <div>{syncStatusToDisplay}</div>
            </Tippy>
          );

          function getTooltipText() {
            const startDate = new Date(cell.row.original.startDate);
            startDate.setDate(startDate.getDate() - 1);
            return syncStatus === SyncStatusStates.SCHEDULED
              ? `This change will be synced with Broadsign Control around 11 PM on ${formatDateToMMMDDYYYY(
                  startDate.toISOString()
                )}`
              : undefined;
          }
        },
      },
      {
        accessorKey: 'oasisSystemSyncStatus',
        header: translate('table:oasisSyncStatus'),
        Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
        accessorFn: (originalRow) =>
          originalRow.oasisSystemSyncStatus ? originalRow.oasisSystemSyncStatus : SyncStatusStates.NA,
        size: 120,
        Cell: (cell) => {
          const syncStatus = cell.row.original.oasisSystemSyncStatus;
          const syncStatusToDisplay = syncStatusCell(syncStatus);
          return (
            <Tippy
              appendTo={document.body}
              maxWidth="200rem"
              placement="top"
              interactive
              arrow={true}
              disabled={syncStatus !== SyncStatusStates.SCHEDULED}
              content={
                <div className="google-maps-content">
                  <Icon fontSize="1rem" iconName="information" />
                  <div
                    className="street-view-tooltip-text"
                    style={{ width: '30rem', padding: '1rem', marginTop: '-0.2rem' }}
                  >
                    {getTooltipText()}
                  </div>
                </div>
              }
            >
              <div>{syncStatusToDisplay}</div>
            </Tippy>
          );

          function getTooltipText() {
            return syncStatus === SyncStatusStates.SCHEDULED
              ? `An e-mail has been sent to Oasis with the details of this update. The status will get reflected to "Success" once Oasis updates their system and communicates the same to Frame Service.`
              : undefined;
          }
        },
      },
      {
        accessorKey: 'daysOfWeek',
        header: translate('table:weeklySchedule'),
        Cell: (cell) => {
          const openingHours = cell.row.original.operatingPeriod;
          return (
            <div className="daysOfWeek">
              {Object.entries(WeekToShortFormMapping).map(([key, value], index) => {
                const isFilled = openingHours[key].length !== 0;
                return (
                  <span key={index}>
                    <div className={`dayButton ${isFilled ? 'filled' : ''}`}>{value}</div>
                  </span>
                );
              })}
            </div>
          );
        },
      },
      {
        accessorKey: 'actions',
        header: translate('table:actions'),
        Cell: (row) => renderActionsColumnContent(row.cell),
      },
    ];

    function renderActionsColumnContent(row: MRT_Cell<OpeningHoursTableData>): React.ReactNode {
      const getToolTipText = () => {
        return 'Click to sync with BroadSign';
      };

      return (
        <>
          <div className="buttonContainer">
            <Button
              hasIconOnly
              label="Button"
              renderIcon="view"
              size="small"
              kind="tertiary"
              renderIconSize="small"
              onClick={() => {
                const isChangeOHFromFutureDateRequest = false;
                dispatch(setIsViewButtonClicked(true));
                handleOnClickEditButton(isChangeOHFromFutureDateRequest);
              }}
            />
            {isReadWriteRole(userRole) && (
              <>
                <Tippy
                  appendTo={document.body}
                  maxWidth="200rem"
                  placement="top"
                  interactive
                  arrow={true}
                  disabled={!checkIfOPIsActive(row)}
                  content={
                    <div className="google-maps-content">
                      <Icon fontSize="1rem" iconName="information" />
                      <div
                        className="street-view-tooltip-text"
                        style={{ width: '25rem', padding: '1rem', marginTop: '-0.2rem', lineHeight: '2rem' }}
                      >
                        {
                          'Edits are not allowed in the current Operating Period if a future Operating Period exists. Please edit the future Operating Period.'
                        }
                      </div>
                    </div>
                  }
                >
                  <div>
                    <Button
                      hasIconOnly
                      label="Button"
                      renderIcon="edit"
                      size="small"
                      kind="tertiary"
                      renderIconSize="small"
                      disabled={checkIfOPIsActive(row)}
                      onClick={() => {
                        dispatch(setIsViewButtonClicked(false));
                        const isChangeOHFromFutureDateRequest = true;
                        handleOnClickEditButton(isChangeOHFromFutureDateRequest);
                      }}
                    />
                  </div>
                </Tippy>
                <OpeningHoursResyncModal
                  row={row}
                  tableData={openingHoursTableData}
                  selectedFrameDetailRow={selectedFrameDetailRow}
                />
              </>
            )}
          </div>
        </>
      );

      function checkIfOPIsActive(row: MRT_Cell<OpeningHoursTableData>) {
        const selectedOPStratDate = new Date(row.row.original.startDate);
        const selectedOpEndDate = new Date(row.row.original.endDate);
        const isOPActive = selectedOPStratDate <= new Date() && selectedOpEndDate >= new Date();

        if (openingHoursTableData.length > 1 && isOPActive) {
          return isOPActive;
        }
        return false;
      }

      function handleOnClickEditButton(isChangeOHFromFutureDateRequest: boolean) {
        const selectedIndex = row.row.index;
        const startDate = openingHoursTableData[selectedIndex].startDate;
        const endDate = openingHoursTableData[selectedIndex].endDate;
        const openingHours = openingHoursTableData[selectedIndex].operatingPeriod;
        const operatingPeriodDbId = openingHoursTableData[selectedIndex].operatingPeriodDBId;
        const operatingPeriodName = openingHoursTableData[selectedIndex].name;

        const selectedPanelDetails = fetchedDataForOpeningHoursSearchRequest.find((data) =>
          data.frameIdentifiers.panelIds.includes(selectedFrameDetailRow.panelId)
        );
        const effectiveFrom =
          selectedPanelDetails?.nonBlockingOperatingPeriods.length !== 1
            ? openingHoursTableData[selectedIndex].startDate
            : '';
        dispatch(
          setSelectedDayPartData({
            ...openingHoursFormValues,
            startDate,
            endDate,
            operatingPeriod: openingHours,
            operatingPeriodDbId,
            name: operatingPeriodName,
            effectiveDateFrom: effectiveFrom,
          })
        );
        setShouldOpeningHoursDetailedSectionBeEnabled(true);
      }
    }
  };

  const openingHoursSearchTableIntance = useMantineReactTable({
    columns: getOpeningHoursTableColumns(),
    data: fetchedDataForOpeningHoursSearchRequest ? openingHoursTableData : [],
    enableGrouping: false,
    enablePinning: true,
    enableRowSelection: false,
    enablePagination: false,
    enableRowVirtualization: false,
    enableColumnFilterModes: false,
    enableTopToolbar: false,
    enableFilters: false,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    enableMultiRowSelection: false,
  }) as MRT_TableInstance<OOCDetailsTableType | FrameSearchTableData | OpeningHoursTableData>;

  return (
    <>
      <div className="audit-table-container">
        <MantineProvider theme={{ primaryColor: 'blue', primaryShade: 8 }}>
          <MantineReactTable table={openingHoursSearchTableIntance as any} />
        </MantineProvider>
      </div>
    </>
  );
};

export default OpeningHoursTable;
