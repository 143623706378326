import React, { useEffect, useState } from 'react';
import { DaysToIndexMapping } from 'src/enums/enum';
import { Checkbox } from '@clearchannelinternational/ecooh-system';
import { DayCheckboxGroupProps } from 'src/types/componentProps';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialOpeningHoursCalanderEvents } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';
import { OpeningHoursSidebarTabReducer } from 'src/types/openingHoursReducers';

const DayCheckboxGroup: React.FC<DayCheckboxGroupProps> = ({
  openingHoursFormValues,
  setOpeningHoursFormValues,
  calendarRef,
  checkedStates,
  setCheckedStates,
}) => {
  const dispatch = useDispatch();
  const { initialOpeningHoursCalanderEvents } = useSelector(
    (state: OpeningHoursSidebarTabReducer) => state.OpeningHoursSidebarTabReducer
  );

  useEffect(() => {
    const updatedCheckedStates = checkedStates.map((state, index) => {
      const dayName = DaysToIndexMapping[index];
      const dayOpeningHours = openingHoursFormValues.operatingPeriod[dayName];
      if (Array.isArray(dayOpeningHours) && dayOpeningHours.length > 0) {
        return true;
      }
      return state;
    });

    setCheckedStates(updatedCheckedStates);
  }, [openingHoursFormValues]);

  const handleCheckboxChange = (dayIndex: number) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[dayIndex] = !checkedStates[dayIndex];
    setCheckedStates(newCheckedStates);

    const dayName = DaysToIndexMapping[dayIndex];
    const dayOpeningHours = openingHoursFormValues.operatingPeriod[dayName];

    if (!newCheckedStates[dayIndex]) {
      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi) {
        const events = calendarApi.getEvents();
        events.forEach((event) => {
          if (event.start?.getDay() === dayIndex) {
            event.remove();
          }
        });
        const updatedInitialEvents = {
          ...initialOpeningHoursCalanderEvents,
          configData: initialOpeningHoursCalanderEvents.configData.filter(
            (event) => new Date(event.start).getDay() !== dayIndex
          ),
        };
        dispatch(setInitialOpeningHoursCalanderEvents(updatedInitialEvents));
      }
    }

    setOpeningHoursFormValues((prevValues) => ({
      ...prevValues,
      operatingPeriod: {
        ...prevValues.operatingPeriod,
        [dayName]: newCheckedStates[dayIndex] ? dayOpeningHours : [],
      },
    }));
  };

  return (
    <>
      <div className="checkbox-container">
        {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
          const dayName = DaysToIndexMapping[dayIndex];

          return (
            <div key={dayIndex}>
              <Checkbox
                key={dayIndex}
                checked={checkedStates[dayIndex]}
                onChange={() => handleCheckboxChange(dayIndex)}
                disabled={false}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DayCheckboxGroup;
