/* eslint-disable camelcase */
import React from 'react';
import TocPocActions from './tocPocActions/tocPocActions';
import FrameSearchDetailsModal from './tocPocActions/FrameSearchDetailsModal/FrameSearchDetailsModal';
import StreetView from 'src/components/steetView/streetView';
import {
  getFormattedDateToBeDisplayed,
  DATE_FORMAT,
  DATE_FORMAT_TO_BE_DISPLAYED,
  DATE_TIME_INPUT_FORMAT,
  DATE_TIME_OUTPUT_FORMAT_WITHOUT_SECONDS,
} from 'src/utils/dateUtils';
import { Box } from '@mantine/core';
import { Icon } from '@clearchannelinternational/ecooh-system';
import { Translate } from 'src/types/TocPocModal';
import { MRT_ColumnDef } from 'mantine-react-table';
import {
  BaseTOCFields,
  BasePOCFields,
  FrameSearchTableData,
  AttributesForPanel as FrameIdentifiersTableData,
  FrameScheduleSearchResponse,
  OperatingPeriod,
} from 'src/types/apiModels';
import { generateCellValue } from 'src/pages/auditViewSidebarTab/auditViewSidebarTabConfig/auditViewSidebarTableConfig';
import { OOCDetailsTableType } from 'src/types/table';
import { getMappingForPanelId } from 'src/redux/actions/tocPocSidebarTabActions/saveActions';
import { TOCPOCDetailsTableData } from 'src/types/searchReducer';
import { ExternalSystems, OutOfChargeStatus, RequestType, SyncStatusStates, TOCPOCCurrentStatus } from 'src/enums/enum';
import { DatePickerInput } from '@mantine/dates';
import { renderDay } from 'src/components/searchField/searchField';
import { Console } from 'console';
import { SelectedFrameDetailRow } from 'src/pages/openingHoursSidebarTab/openingHoursSidebarTab';
import { setSelectedDayPartData } from 'src/redux/actions/opeingHoursSidebarTabAction/openingHoursSidebarTabAction';

export const actionsAccessorKey = 'actions';
export const digitalUnitIdAccessorKey = 'digitalUnitId';
export const commercialFrameIdAccessorKey = 'commercialFrameId';
export const siteKeyAccessorKey = 'siteKey';

export const getTooltipText = (panelIds: string[]) => {
  if (panelIds.length > 2) {
    return 'Multi-sided Panel';
  }

  return 'Double-sided Frame';
};

const handleRowSelection = (
  row: any,
  setSelectedFrameDetailRow: React.Dispatch<React.SetStateAction<SelectedFrameDetailRow>> | undefined,
  setShouldOpeningHoursDetailedSectionBeEnabled: React.Dispatch<React.SetStateAction<boolean>> | undefined
) => {
  setShouldOpeningHoursDetailedSectionBeEnabled && setShouldOpeningHoursDetailedSectionBeEnabled(false);
  if (setSelectedFrameDetailRow) {
    setSelectedFrameDetailRow({
      index: row.index,
      panelId: row.original.panelId,
    });
  }
};

const isDisplayUnitIdColumnMerged = (table: any, currentDisplayUnitId: string | undefined) => {
  if (table.getRowModel().rows.length === 1) {
    return false;
  }
  return table.getRowModel().rows.every((r: any) => r.original.displayUnitId === currentDisplayUnitId);
};

const renderSelectColumn = (
  row: any,
  column: any,
  table: any,
  selectedFrameDetailRow: SelectedFrameDetailRow | undefined,
  setSelectedFrameDetailRow: React.Dispatch<React.SetStateAction<SelectedFrameDetailRow>> | undefined,
  setShouldOpeningHoursDetailedSectionBeEnabled: React.Dispatch<React.SetStateAction<boolean>> | undefined
) => {
  const currentDisplayUnitId = row.original.displayUnitId;
  const previousRowDisplayUnitId = table.getRowModel().rows[row.index - 1]?.original.displayUnitId;
  const isMerged = isDisplayUnitIdColumnMerged(table, currentDisplayUnitId);
  const currentRowPanelId = row.original.panelId;
  const currentRowValue = row.getValue(column.id);

  //   const marginTop = isMerged ? '0.4rem' : '';
  // Assuming the height of a single row is 3rem
  const singleRowHeight = 3.5; // in rem

  // Calculate the number of rows that have the same value
  const numberOfRowsWithSameValue = table
    .getRowModel()
    .rows.filter((r: any) => r.getValue(column.id) === currentRowValue).length;

  // Calculate the total height of the merged cells
  const totalHeight = singleRowHeight * numberOfRowsWithSameValue; // in rem

  // Calculate the marginTop to center the content vertically
  const marginTop = isMerged ? `${totalHeight - totalHeight / 2 - 3}rem` : '';

  if (!currentDisplayUnitId) {
    return row.index === 0 ? (
      <div className={isMerged ? 'radio-button-container' : ''} style={{ marginTop }}>
        <input
          type="radio"
          name="selectFrame"
          className="select-radio-button"
          checked={selectedFrameDetailRow?.panelId === currentRowPanelId}
          onChange={() => handleRowSelection(row, setSelectedFrameDetailRow, setShouldOpeningHoursDetailedSectionBeEnabled)}
        />
      </div>
    ) : (
      <div className={isMerged ? 'empty-field' : ''} style={{ marginTop }}></div>
    );
  }

  if (previousRowDisplayUnitId === currentDisplayUnitId) {
    return <div className={isMerged ? 'empty-field' : ''} style={{ marginTop }}></div>;
  }

  return (
    <div className={isMerged ? 'radio-button-container' : ''} style={{ marginTop }}>
      <input
        type="radio"
        name="selectFrame"
        className="select-radio-button"
        checked={selectedFrameDetailRow?.panelId === currentRowPanelId}
        onChange={() => handleRowSelection(row, setSelectedFrameDetailRow, setShouldOpeningHoursDetailedSectionBeEnabled)}
      />
    </div>
  );
};

const isSiteKeyColumnMerged = (table: any, siteKey: string | undefined) => {
  if (table.getRowModel().rows.length === 1) {
    return false;
  }
  return table.getRowModel().rows.every((r: any) => r.original.siteKey === siteKey);
};

const renderMergedCells = (row: any, column: any, table: any, isMerged: boolean) => {
  const currentRowValue = row.getValue(column.id);
  const previousRowValue = table.getRowModel().rows[row.index - 1]?.getValue(column.id);

  // Assuming the height of a single row is 3rem
  const singleRowHeight = 3.5; // in rem

  // Calculate the number of rows that have the same value
  const numberOfRowsWithSameValue = table
    .getRowModel()
    .rows.filter((r: any) => r.getValue(column.id) === currentRowValue).length;

  // Calculate the total height of the merged cells
  const totalHeight = singleRowHeight * numberOfRowsWithSameValue; // in rem

  // Calculate the marginTop to center the content vertically
  const marginTop = isMerged ? `${totalHeight - totalHeight / 2 - 3}rem` : '-2.1rem';

  if (previousRowValue === currentRowValue) {
    return <div className="empty-field"></div>;
  }

  return (
    <div className="merged-cells" style={{ marginTop }}>
      <div>{currentRowValue as string}</div>
    </div>
  );
};

export const getOpeningHoursSearchTableColumns = (
  translate: Translate,
  selectedFrameDetailRow: SelectedFrameDetailRow | undefined,
  setSelectedFrameDetailRow: React.Dispatch<React.SetStateAction<SelectedFrameDetailRow>> | undefined,
  fetchedDataForOpeningHoursSearchRequest: FrameScheduleSearchResponse,
  setShouldOpeningHoursDetailedSectionBeEnabled: React.Dispatch<React.SetStateAction<boolean>> | undefined
): MRT_ColumnDef<FrameIdentifiersTableData>[] => {
  return [
    {
      id: 'select',
      size: 50,
      header: 'Select',
      Cell: (cell) =>
        renderSelectColumn(
          cell.row,
          cell.column,
          cell.table,
          selectedFrameDetailRow,
          setSelectedFrameDetailRow,
          setShouldOpeningHoursDetailedSectionBeEnabled
        ),
    },
    {
      accessorKey: 'digitalUnitId',
      header: translate('table:digitalUnitId'),
      Cell: (cell) => cell.row.original.digitalUnitId || '',
    },
    {
      accessorKey: 'panelId',
      header: translate('table:panelIdsWithPanelNos'),
      Cell: (cell) => {
        const { panelId, panaleNo } = cell.cell.row.original;
        return `${panelId} (${panaleNo})`;
      },
    },
    {
      accessorKey: 'siteKey',
      header: translate('table:siteKey'),
      size: 120,
      Cell: (cell) => {
        const isMerged = isSiteKeyColumnMerged(cell.table, cell.row.original.siteKey);
        return renderMergedCells(cell.row, cell.column, cell.table, isMerged);
      },
    },
    {
      accessorKey: commercialFrameIdAccessorKey,
      header: 'Commercial Frame Id',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
    },
    {
      accessorKey: 'broadsignFrameId',
      header: `${translate('table:broadsignControlId')}(s)`,
    },
    {
      accessorKey: 'displayUnitId',
      header: translate('table:displayUnitId'),
      size: 100,
      Cell: (cell) => {
        const isMerged = isDisplayUnitIdColumnMerged(cell.table, cell.row.original.displayUnitId);
        return renderMergedCells(cell.row, cell.column, cell.table, isMerged);
      },
    },
    {
      id: 'pendingActions',
      header: translate('table:pendingActions'),
      size: 100,
      Cell: (cell) => {
        const curretRowPanelId = cell.row.original.panelId;

        const nonBlockingOperatingPeriods = findNonBlockingOperatingPeriods(
          fetchedDataForOpeningHoursSearchRequest,
          curretRowPanelId
        );

        const broadsignSyncFailure = checkSyncFailure(nonBlockingOperatingPeriods, ExternalSystems.BROADSIGN);
        const oasisSyncFailure = checkSyncFailure(nonBlockingOperatingPeriods, ExternalSystems.OASIS);

        if (broadsignSyncFailure && oasisSyncFailure) {
          return (
            <div className="pending-actions">
              <ul>
                <li>Resync for Broadsign</li>
                <li>Resync for Oasis</li>
              </ul>
            </div>
          );
        } else if (broadsignSyncFailure) {
          return <div className="pending-actions">Resync for Broadsign</div>;
        } else if (oasisSyncFailure) {
          return <div className="pending-actions">Resync for Oasis</div>;
        } else {
          return '-';
        }
      },
    },
  ];
};

// Function to find non-blocking operating periods
const findNonBlockingOperatingPeriods = (fetchedDataForOpeningHoursSearchRequest: any[], curretRowPanelId: string) => {
  return fetchedDataForOpeningHoursSearchRequest.find((data) => data.frameIdentifiers.panelIds.includes(curretRowPanelId))
    ?.nonBlockingOperatingPeriods as OperatingPeriod[];
};

// Function to check sync failure
const checkSyncFailure = (nonBlockingOperatingPeriods: OperatingPeriod[], externalSystem: ExternalSystems) => {
  return nonBlockingOperatingPeriods.some((op) => {
    return op.syncStatusDetails.some(
      (ssd) =>
        (ssd.syncInfo.syncDetails.syncStatus === SyncStatusStates.FAILURE ||
          ssd.syncInfo.syncDetails.syncStatus === SyncStatusStates.PENDING ||
          ssd.syncInfo.syncDetails.syncStatus === SyncStatusStates.EMAIL_FAILED) &&
        ssd.externalSystem === externalSystem
    );
  });
};

export const getFrameSearchTableColumns = (translate: Translate): MRT_ColumnDef<FrameSearchTableData>[] => {
  return [
    {
      accessorKey: 'digitalUnitId',
      header: `${translate('table:digitalUnitIdWithPanelNo')}`,
      Cell: (cell) => {
        const digitalUnitId = cell.row.original.digitalUnitId ? cell.row.original.digitalUnitId : '';
        const digitalUnitIdWithPanelNo = `${digitalUnitId} (${cell.row.original.panaleNo})`;
        const latitude = cell.row.original.latitude;
        const longitude = cell.row.original.longitude;
        return renderDigitalUnitWithStreetView(digitalUnitIdWithPanelNo, latitude, longitude);
      },
      size: 120,
    },
    {
      accessorKey: 'siteKey',
      header: translate('table:siteKey'),
      size: 120,
    },
    {
      accessorKey: 'panelId',
      header: 'Panel Id',
      size: 120,
    },
    {
      accessorKey: 'siteNo',
      header: `${translate('table:siteNo')}`,
      size: 120,
    },
    {
      accessorKey: commercialFrameIdAccessorKey,
      header: 'Commercial Frame Id',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: 50,
    },
    {
      accessorKey: 'broadsignFrameId',
      header: `${translate('table:broadsignControlId')}(s)`,
      size: 200,
    },
    {
      accessorKey: 'screenId',
      header: `${translate('table:broadsignDirectId')}`,
      size: 120,
    },
    {
      accessorKey: 'displayUnitId',
      header: `${translate('table:displayUnitId')}`,
      size: 120,
    },
    {
      accessorKey: 'frameName',
      header: `${translate('table:frameName')}`,
      size: 120,
    },
    {
      accessorKey: 'isActive',
      header: `${translate('table:isActive')}`,
      size: 120,
      Cell: (cell) => {
        return cell.renderedCellValue?.toString();
      },
    },
    {
      accessorKey: 'postcode',
      header: `${translate('table:postcode')}`,
      size: 120,
    },
    {
      accessorKey: 'address',
      header: `${translate('table:address')}`,
    },
    {
      accessorKey: 'frameType',
      header: `${translate('table:frameType')}`,
      size: 120,
    },
    {
      accessorKey: actionsAccessorKey,
      header: translate('table:actions'),
      Cell: (cell) => <FrameSearchDetailsModal row={cell.row} />,
      size: 200,
    },
  ];
};

export const getTocPocTableColumns = (
  tableData: TOCPOCDetailsTableData[],
  isBulkTocSearchTab: boolean,
  t: Translate,
  searchRequestType: RequestType,
  bulkUpdateDetailsTableData?: TOCPOCDetailsTableData[],
  onClickBulkUpdateSummary?: (batchId: string) => void
): MRT_ColumnDef<OOCDetailsTableType>[] => {
  return [
    {
      accessorKey: 'siteKey',
      header: t('table:siteKey'),
      size: isBulkTocSearchTab ? 130 : 100,
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      Cell: (cell) => {
        const isMultiPanel = cell.row.original.isMultiPanel;
        const panelIds = cell.row.original.panelIds;
        return (
          <>
            <div className="tooltip-column">
              <>{cell.cell.getValue()}</>
              {isMultiPanel && <Icon iconName="book" tooltip={getTooltipText(panelIds)} tooltipIcon="information" />}
            </div>
          </>
        );
      },
      enableSorting: isBulkTocSearchTab,
    },
    {
      header: `${t('table:digitalUnitIdWithPanelNo')}`,
      id: digitalUnitIdAccessorKey,
      accessorFn: (row) => getDigitalUnitIdsWithPanelNo(row.id, tableData).join(','),
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      filterFn: 'fuzzy',
      filterVariant: 'text',
      Cell: (cell) => {
        const targetId = cell.row.original.id as string;
        const currentTableData = bulkUpdateDetailsTableData ? bulkUpdateDetailsTableData : tableData;
        return <div className="new-line">{getDigitalUnitIdsWithPanelNumbersAndStreetView(targetId, currentTableData)}</div>;
      },
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: isBulkTocSearchTab ? 135 : 120,
    },
    {
      header: `${t('table:broadsignFrameId')}(s)`,
      id: 'broadsignControlFrameIds',
      accessorFn: (row) => {
        const targetId = row.id as string;
        return getBroadSignFrameId(targetId, tableData);
      },
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      Cell: (cell) => {
        const targetId = cell.row.original.id as string;
        return getBroadSignFrameId(targetId, tableData);
      },
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: isBulkTocSearchTab ? 145 : 110,
      enableSorting: isBulkTocSearchTab,
    },
    {
      header: `${t('table:commercialFrameId')}(s)`,
      accessorKey: 'commercialFrameIds',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      Cell: (cell) => {
        const targetId = cell.row.original.id as string;
        const commercialFrameIds = getAssociatedAttributesForPanel(targetId, tableData)?.map(
          (attributes) => attributes?.commercialFrameId
        );

        if (commercialFrameIds) {
          return commercialFrameIds.join(', ');
        }
        return [];
      },
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: isBulkTocSearchTab ? 160 : 100,
      enableSorting: isBulkTocSearchTab,
    },
    {
      header: `${t('table:panelId')}(s)`,
      accessorKey: 'panelIds',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      Cell: (cell) => {
        return <>{(cell.cell.getValue() as string[]).join(', ') as string}</>;
      },
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: isBulkTocSearchTab ? 120 : 95,
      enableSorting: isBulkTocSearchTab,
    },
    {
      accessorKey: 'outOfChargeStatus',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      header: t('table:outOfChargeType'),
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      size: isBulkTocSearchTab ? 120 : 50,
      enableSorting: isBulkTocSearchTab,
    },
    {
      accessorFn: (row) => new Date(row.startDate),
      id: 'startDate',
      header: t('table:startDate'),
      size: isBulkTocSearchTab ? 185 : 110,
      Cell: (cell) => {
        const startDate = cell.row.original.startDate.slice(0, 10);
        return getFormattedDateToBeDisplayed(startDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED);
      },
      filterFn: 'equals',
      columnFilterModeOptions: ['greaterThanOrEqualTo', 'lessThanOrEqualTo', 'equals'],
      Filter: (filterProp) => {
        return (
          <DatePickerInput
            type={'default'}
            clearable={true}
            renderDay={renderDay}
            placeholder="Filter by Start Date"
            onChange={(newValue: Date) => {
              filterProp.column.setFilterValue(newValue);
            }}
          />
        );
      },
    },
    {
      accessorFn: (row) => {
        const endDate = getFormattedDateToBeDisplayed(
          (row as BaseTOCFields).endDate,
          DATE_FORMAT,
          DATE_FORMAT_TO_BE_DISPLAYED
        );
        return endDate ? new Date(endDate) : null;
      },
      id: 'endDate',
      header: t('table:endDate'),
      size: isBulkTocSearchTab ? 185 : 110,
      Cell: (cell) => {
        const endDate = (cell.row.original as BaseTOCFields).endDate;
        return endDate ? getFormattedDateToBeDisplayed(endDate, DATE_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED) : 'NA';
      },
      filterFn: 'equals',
      columnFilterModeOptions: ['greaterThanOrEqualTo', 'lessThanOrEqualTo', 'equals'],
      Filter: (filterProp) => {
        return (
          <DatePickerInput
            style={{
              zIndex: 1000,
            }}
            type={'default'}
            clearable={true}
            renderDay={renderDay}
            placeholder="Filter by End Date"
            onChange={(newValue: Date) => {
              filterProp.column.setFilterValue(newValue);
            }}
          />
        );
      },
    },
    {
      accessorKey: 'tocReasonText',
      header: t('table:reason'),
      size: isBulkTocSearchTab ? 130 : 80,
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      Cell: (cell) => {
        const cellValue = (cell.row.original as BaseTOCFields).tocReasonText;
        return cellValue ? cellValue : 'NA';
      },
      enableSorting: isBulkTocSearchTab,
    },
    {
      accessorKey: 'comment',
      header: t('table:comment'),
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      size: isBulkTocSearchTab ? 150 : 127,
      Cell: (cell) => {
        const cellValue = (cell.row.original as BaseTOCFields).comment;
        const fieldName = t('table:comment');
        const maximumVisibleCharacters = 21;
        const isTocPoc = cell.row.original.outOfChargeStatus;
        if (isTocPoc === OutOfChargeStatus.POC) {
          return 'NA';
        }
        return cellValue ? generateCellValue([] as any, cellValue, fieldName, maximumVisibleCharacters) : '-';
      },
      enableSorting: isBulkTocSearchTab,
    },
    {
      accessorKey: 'dismantleSite',
      header: t('table:dismantleSite'),
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: 10,
      Cell: (cell) => {
        const cellValue = (cell.row.original as BasePOCFields).dismantleSite;
        if (cellValue === undefined) {
          return 'NA';
        }
        return cellValue ? 'Yes' : 'No';
      },
      enableSorting: isBulkTocSearchTab,
    },
    {
      header: t('table:administrationBranch'),
      accessorKey: 'administrationBranch',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      size: isBulkTocSearchTab ? 175 : 120,
      enableSorting: isBulkTocSearchTab,
    },
    {
      header: t('table:statusStage'),
      accessorKey: 'tocPocCurrentStatus',
      Cell: (cell) => {
        const currentStatus = cell.row.original.tocPocCurrentStatus;
        return <div>{currentStatusCell(currentStatus)}</div>;
      },
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      size: isBulkTocSearchTab ? 170 : 120,
      enableSorting: isBulkTocSearchTab,
    },
    {
      accessorKey: 'broadsignSyncStatus',
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      header: t('table:broadsignSyncStatus'),
      accessorFn: (originalRow) => (originalRow.broadsignSyncStatus ? originalRow.broadsignSyncStatus : SyncStatusStates.NA),
      Cell: (cell) => {
        const syncStatus = cell.row.original.broadsignSyncStatus;
        return <div>{syncStatusCell(syncStatus)}</div>;
      },
      size: isBulkTocSearchTab ? 130 : 127,
      enableSorting: isBulkTocSearchTab,
    },
    {
      accessorKey: 'oasisSyncStatus',
      header: t('table:oasisSyncStatus'),
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      accessorFn: (originalRow) => (originalRow.oasisSyncStatus ? originalRow.oasisSyncStatus : SyncStatusStates.NA),
      Cell: (cell) => {
        const syncStatus = cell.row.original.oasisSyncStatus;
        return <div>{syncStatusCell(syncStatus)}</div>;
      },
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: isBulkTocSearchTab ? 130 : 120,
      enableSorting: isBulkTocSearchTab,
    },
    // {
    //   id: 'aida',
    //   header: t('table:aidaSyncStatus'),
    //   Cell: () => {
    //     return <div>{syncStatusCell(SyncStatusStates.DEFAULT)}</div>;
    //   },
    //   size: 137,
    // },
    {
      accessorKey: 'updatedDate',
      header: t('table:lastUpdatedDate'),
      Cell: (cell) => {
        const updatedDate = cell.row.original.updatedDate;
        return getFormattedDateToBeDisplayed(updatedDate, DATE_TIME_INPUT_FORMAT, DATE_TIME_OUTPUT_FORMAT_WITHOUT_SECONDS);
      },
      columnFilterModeOptions: ['greaterThanOrEqualTo', 'lessThanOrEqualTo', 'equals'],
      accessorFn: (originalRow) => {
        return new Date(
          getFormattedDateToBeDisplayed(originalRow.updatedDate, DATE_TIME_INPUT_FORMAT, DATE_FORMAT_TO_BE_DISPLAYED)
        );
      },
      filterFn: 'equals',
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      size: isBulkTocSearchTab ? 160 : 110,
      enableSorting: isBulkTocSearchTab,
      Filter: (filterProp) => {
        return (
          <DatePickerInput
            type={'default'}
            clearable={true}
            renderDay={renderDay}
            placeholder="Filter by Updated Date"
            onChange={(newValue: Date) => {
              filterProp.column.setFilterValue(newValue);
            }}
          />
        );
      },
    },
    {
      accessorKey: 'updatedBy',
      header: t('table:lastUpdatedBy'),
      Header: (filterProp) => <div className="column-header">{filterProp.column.columnDef.header}</div>,
      accessorFn: (originalRow) => {
        const updateByUser: any = originalRow.updatedByUser;
        const userName = `${updateByUser.lastName}, ${updateByUser.firstName}`;
        return !updateByUser.lastName || !updateByUser.firstName ? (updateByUser as string) : userName;
      },
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals'],
      Cell: (cellProp) => {
        const updateByUser = cellProp.row.original.updatedByUser;
        if (updateByUser) {
          const updatedBy = cellProp.row.original.updatedBy;
          const userName = `${updateByUser.lastName}, ${updateByUser.firstName}`;
          return !updateByUser.lastName || !updateByUser.firstName ? <>{updatedBy}</> : userName;
        }
        return '';
      },
      size: 150,
      enableSorting: isBulkTocSearchTab,
    },
    {
      accessorKey: actionsAccessorKey,
      header: t('table:actions'),
      Cell: (row) => (
        <>
          <TocPocActions
            row={row}
            isBulkTocSearchTab={isBulkTocSearchTab}
            searchRequestType={searchRequestType}
            bulkUpdateDetailsTableData={bulkUpdateDetailsTableData}
            tableData={bulkUpdateDetailsTableData ? bulkUpdateDetailsTableData : tableData}
            onClickBulkUpdateSummary={onClickBulkUpdateSummary}
          />
        </>
      ),
      size: 200,
      enableSorting: false,
      enableColumnFilter: false,
      enableGrouping: false,
    },
  ];
};

const getBroadSignFrameId = (targetId: string, tableData: TOCPOCDetailsTableData[]) => {
  const broadSignFrameIdsInArrayForm = getAssociatedAttributesForPanel(targetId, tableData)?.map(
    (attributes) => attributes?.broadsignFrameIds
  );

  if (broadSignFrameIdsInArrayForm) {
    const broadSignFrameIds: any = Array.from(broadSignFrameIdsInArrayForm.flat());
    return broadSignFrameIds.join(', ');
  }
  return '';
};

const getBackgroundColor = (syncStatusState: SyncStatusStates) => {
  switch (syncStatusState) {
    case SyncStatusStates.SUCCESS:
      return '#96f88eb1';
    case SyncStatusStates.PENDING:
      return '#E3E3E3';
    case SyncStatusStates.FAILURE:
    case SyncStatusStates.EMAIL_FAILED:
      return 'rgb(255, 208, 208)';
    case SyncStatusStates.SCHEDULED:
      return '#96f88eb1';
    default:
      return 'rgb(224, 211, 211)';
  }
};

const getBackgroundColoForCurrentStatus = (tocPocCurrentStatus: TOCPOCCurrentStatus) => {
  switch (tocPocCurrentStatus) {
    case TOCPOCCurrentStatus.Active:
      return '#96f88eb1';
    case TOCPOCCurrentStatus.Upcoming:
      return '#a6d7ff';
    case TOCPOCCurrentStatus.Deleted:
      return 'rgb(255, 208, 208)';
    case TOCPOCCurrentStatus.Expired:
      return 'rgb(255, 208, 208)';
    default:
      return '#ff6b6b';
  }
};

const getTextColor = (syncStatusState: SyncStatusStates) => {
  switch (syncStatusState) {
    case SyncStatusStates.SUCCESS:
    case SyncStatusStates.SCHEDULED:
      return '#008008';
    case SyncStatusStates.FAILURE:
    case SyncStatusStates.EMAIL_FAILED:
      return 'red';
    default:
      return 'black';
  }
};

export const syncStatusCell = (syncStatusState: SyncStatusStates) => {
  return (
    <Box
      className="syncStatusCell"
      sx={() => ({
        backgroundColor: getBackgroundColor(syncStatusState),
        color: getTextColor(syncStatusState),
      })}
    >
      <b>{syncStatusState ? syncStatusState : 'NA'}</b>
    </Box>
  );
};

const currentStatusCell = (currentStatus: TOCPOCCurrentStatus) => {
  return (
    <Box
      className="syncStatusCell"
      sx={{
        backgroundColor: getBackgroundColoForCurrentStatus(currentStatus),
      }}
    >
      {currentStatus}
    </Box>
  );
};

export function getDigitalUnitIdsWithPanelNumbersAndStreetView(targetId: string, tableData: TOCPOCDetailsTableData[]) {
  const currentTableData = tableData.find((data) => data.id === targetId);

  if (!currentTableData) {
    return null;
  }

  const associatedAttributes = currentTableData.panelIds.map((panelId) =>
    getMappingForPanelId(panelId, currentTableData.panelIdIdentifierMappings)
  );

  return associatedAttributes.map((attributes) => {
    const { geoLocation } = attributes || {};
    const digitalUnitIdWithPanelNumber = `${attributes?.digitalUnitId} (${attributes?.panelNumber})`;
    const latitude = geoLocation ? geoLocation.latitude : null;
    const longitude = geoLocation ? geoLocation.longitude : null;
    return renderDigitalUnitWithStreetView(digitalUnitIdWithPanelNumber, latitude, longitude);
  });
}

export function renderDigitalUnitWithStreetView(
  digitalUnitIdWithPanelNumber: string,
  latitude: number | null,
  longitude: number | null
) {
  return (
    <div key={digitalUnitIdWithPanelNumber} className="digital-unit-ids-with-street-view">
      {digitalUnitIdWithPanelNumber} <StreetView latitude={latitude} longitude={longitude} />
    </div>
  );
}

export function getAssociatedAttributesForPanel(targetId: string, tableData: TOCPOCDetailsTableData[]) {
  const currentTableData = tableData.find((data) => data.id === targetId);
  const associatedAttributesForPanel = currentTableData?.panelIds.map((panelId) =>
    getMappingForPanelId(panelId, currentTableData.panelIdIdentifierMappings)
  );
  return associatedAttributesForPanel;
}

export function getDigitalUnitIdsWithPanelNo(targetId: string, tableData: TOCPOCDetailsTableData[]) {
  const associatedAttributes = getAssociatedAttributesForPanel(targetId, tableData);

  if (associatedAttributes) {
    const digitalUnitIds = associatedAttributes.map((attributes) => attributes?.digitalUnitId);
    const panelNo = associatedAttributes.map((attributes) => attributes?.panelNumber);
    const digitalUnitIdsWithPanelNo = digitalUnitIds?.map(
      (digitalUnitId, index) => `${digitalUnitId} (${panelNo?.[index]})`
    );
    return digitalUnitIdsWithPanelNo as string[];
  }

  return [];
}
