import { AnyAction } from 'redux';
import { LoadingReducerState } from 'src/types/loadinReducer';

export const SET_LOADING = 'SET_LOADING';
export const SET_MODAL_LOADING = 'SET_MODAL_LOADING';
export const SET_AUTO_SUGGEST_LOADING = 'SET_AUTO_SUGGEST_LOADING';
export const SET_SYNC_STATUS_TAB_LOADING = 'SET_SYNC_STATUS_TAB_LOADING';
export const SET_AUDIT_TABLE_LOADING = 'SET_AUDIT_TABLE_LOADING';
export const SET_BULK_PAGE_LOADING = 'SET_BULK_PAGE_LOADING';
export const SET_BULK_UPDATE_SUMMARIES_PAGE_LOADING = 'SET_BULK_UPDATE_SUMMARIES_PAGE_LOADING';
export const SET_BULK_UPDATE_DETAILS_TAB_LOADING = 'SET_BULK_UPDATE_DETAILS_TAB_LOADING';

const initialState: LoadingReducerState = {
  loading: false,
  modalLoading: false,
  autoSuggestLoading: false,
  syncStatusTabLoading: false,
  auditTableLoading: false,
  bulkPageLoading: false,
  bulkUpdateSummarriesPageLoading: false,
  bulkUpdateDetailsTabLoading: false,
};

const loadingReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_MODAL_LOADING:
      return { ...state, modalLoading: action.payload };
    case SET_AUTO_SUGGEST_LOADING:
      return { ...state, autoSuggestLoading: action.payload };
    case SET_SYNC_STATUS_TAB_LOADING:
      return { ...state, syncStatusTabLoading: action.payload };
    case SET_AUDIT_TABLE_LOADING:
      return { ...state, auditTableLoading: action.payload };
    case SET_BULK_PAGE_LOADING:
      return { ...state, bulkPageLoading: action.payload };
    case SET_BULK_UPDATE_SUMMARIES_PAGE_LOADING:
      return { ...state, bulkUpdateSummarriesPageLoading: action.payload };
    case SET_BULK_UPDATE_DETAILS_TAB_LOADING:
      return { ...state, bulkUpdateDetailsTabLoading: action.payload };
    default:
      return state;
  }
};

export default loadingReducer;
