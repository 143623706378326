import React, { useEffect, useState } from 'react';
import MultiSelectCheckBox from 'src/components/multiSelectCheckBox/multiSelectCheckBox';
import { EventStatusCheckBoxesProps } from 'src/types/componentProps';
import { OOCCurrentStatus, OutOfChargeStatus, TOCPOCCurrentStatus } from 'src/enums/enum';
import { BulkOOCSearchRequestBody } from 'src/types/apiModels';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingReducer } from 'src/types/loadinReducer';
import { setBulkTOCSearchFormValues } from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';

const EventStatusCheckBoxes: React.FC<EventStatusCheckBoxesProps> = ({
  fetchedData,
  externalIds,
  existingTocPoc,
  updateModal,
  isPocFocused,
}) => {
  const [selectedEventStatus, setSelectedEventStatus] = useState<TOCPOCCurrentStatus[]>([]);
  const eventStatuses = [TOCPOCCurrentStatus.Active, TOCPOCCurrentStatus.Upcoming, TOCPOCCurrentStatus.Expired];
  const { isResetClicked, bulkTOCSearchFormValues } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );
  const { loading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedEventStatus([TOCPOCCurrentStatus.Active, TOCPOCCurrentStatus.Upcoming]);
  }, [isResetClicked]);

  useEffect(() => {
    setSelectedEventStatus([TOCPOCCurrentStatus.Active, TOCPOCCurrentStatus.Upcoming]);
  }, []);

  const handleCheckboxChange = (eventStatus: TOCPOCCurrentStatus): void => {
    const updatedeventStatuses = selectedEventStatus.includes(eventStatus)
      ? selectedEventStatus.filter((id) => id !== eventStatus)
      : [...selectedEventStatus, eventStatus];

    setSelectedEventStatus(updatedeventStatuses);
    updateFormValuesWithSelectedOOCType(updatedeventStatuses);
  };

  const updateFormValuesWithSelectedOOCType = (eventStatuses: TOCPOCCurrentStatus[]): void => {
    const tocPocFormData = { ...(bulkTOCSearchFormValues as BulkOOCSearchRequestBody) };
    const statusKeys = eventStatuses.map((status) => OOCCurrentStatus[status]);
    tocPocFormData.oocStatus = statusKeys;
    dispatch(setBulkTOCSearchFormValues(tocPocFormData));
  };

  return (
    <div className={'externalID-checkboxes-container'}>
      <MultiSelectCheckBox
        updateModal={updateModal}
        formValues={bulkTOCSearchFormValues}
        setFormValues={setBulkTOCSearchFormValues}
        externalIds={externalIds}
        isPocFocused={isPocFocused}
        handleCheckboxChange={handleCheckboxChange}
        shouldCheckboxBeDisabled={() => loading}
        fetchedData={fetchedData}
        existingTocPoc={existingTocPoc}
        systemBasedExternalIds={eventStatuses}
        setSelectedItems={
          setSelectedEventStatus as React.Dispatch<
            React.SetStateAction<string[] | OutOfChargeStatus[] | TOCPOCCurrentStatus[]>
          >
        }
        selectedItems={selectedEventStatus}
      />
    </div>
  );
};

export default EventStatusCheckBoxes;
