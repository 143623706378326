import React, { useEffect, useState } from 'react';
import BulkUpdateSummariesTab from '../bulkUpdateSummariesTab/bulkUpdateSummariesTab';
import BulkOOCSearchUpdateTab from '../bulkOOCSearchUpdateTab/bulkOOCSearchUpdateTab';
import { useDispatch, useSelector } from 'react-redux';
import { BulkOOCSearchSidebarTabReducer } from 'src/types/bulkOOCSearchSidebarTab';
import { BulkUpdateDetailsTabProps } from 'src/types/componentProps';
import { Loading, P, Button, Checkbox } from '@clearchannelinternational/ecooh-system';
import { LoadingReducer } from 'src/types/loadinReducer';
import { processFetchedTOCPOCData } from 'src/redux/actions/tocPocSidebarTabActions/searchActions';
import { BulkUpdatebatchStatus, RequestType } from 'src/enums/enum';
import { useTranslation } from 'react-i18next';
import { setAutoRefreshSetChecked } from 'src/redux/actions/bulkOOCSearchSidebarTabAction/bulkOOCSearchSidebarTabAction';

const BulkUpdateDetailsTab: React.FC<BulkUpdateDetailsTabProps> = ({
  setBulkSummariesResponseData,
  onClickBulkUpdateSummary,
}) => {
  const { bulkUpdateDetailsResponseData, autoRefreshChecked } = useSelector(
    (state: BulkOOCSearchSidebarTabReducer) => state.bulkOOCSearchSidebarTabReducer
  );
  const dispatch = useDispatch();
  const { bulkUpdateDetailsTabLoading } = useSelector((state: LoadingReducer) => state.loadingReducer);
  const { t: translate } = useTranslation();

  useEffect(() => {
    setBulkSummariesResponseData([]);
  }, []);

  const [timer, setTimer] = useState(10);

  useEffect(() => {
    if (bulkUpdateDetailsResponseData.batchSummary.batchStatus === BulkUpdatebatchStatus.COMPLETED) {
      dispatch(setAutoRefreshSetChecked(false));
    }
    if (autoRefreshChecked) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);

        if (timer === 0) {
          setTimer(10);
          onClickBulkUpdateSummary((bulkUpdateDetailsResponseData as any).batchSummary.batchUUID);
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimer(10);
    }
  }, [timer, autoRefreshChecked]);

  return (
    <>
      {bulkUpdateDetailsTabLoading ? (
        <Loading />
      ) : (
        <>
          <div className="auto-refresh">
            <Button
              renderIcon="connect--recursive"
              kind="secondary"
              label=""
              tooltip="Click to refresh"
              onClick={() => onClickBulkUpdateSummary((bulkUpdateDetailsResponseData as any).batchSummary.batchUUID)}
              disabled={bulkUpdateDetailsResponseData.batchSummary.batchStatus === BulkUpdatebatchStatus.COMPLETED}
            />
            <Checkbox
              checked={autoRefreshChecked}
              label={translate('bulkOOCSearchSidebarTab:autoRefresh')}
              onChange={() => dispatch(setAutoRefreshSetChecked(!autoRefreshChecked))}
              disabled={bulkUpdateDetailsResponseData.batchSummary.batchStatus === BulkUpdatebatchStatus.COMPLETED}
            />
          </div>
          {autoRefreshChecked && (
            <div className="refresh-text">
              <P>Refreshing in {timer} seconds</P>
            </div>
          )}
          <BulkUpdateSummariesTab
            bulkSummariesResponseData={[bulkUpdateDetailsResponseData.batchSummary]}
            handleBulkUpdateSummariesTabClick={() => void 0}
            bulkUpdateSummariesTableRowCount={0}
            isBulkUpdateSummariesTabClicked={false}
            enabledpagination={false}
            onClickBulkUpdateSummary={onClickBulkUpdateSummary}
          />

          <BulkOOCSearchUpdateTab
            bulkUpdateDetailsTableData={processFetchedTOCPOCData(
              bulkUpdateDetailsResponseData.tocs,
              RequestType.OOC_BULK_SEARCH_REQUEST
            )}
            onClickBulkUpdateSummary={onClickBulkUpdateSummary}
            enabledPagination={false}
          />
        </>
      )}
    </>
  );
};

export default BulkUpdateDetailsTab;
